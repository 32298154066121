import {
  TableContainer as MuiTableContainer,
  Toolbar as MuiToolbar,
  styled,
  TableCell as MuiTableCell,
  Table as MuiTable,
} from "@mui/material";

const Toolbar = styled(MuiToolbar)(({ theme: { palette, typography } }) => ({
  justifyContent: "space-between",
  backgroundColor: palette.background.light,
  marginBottom: 8,
  borderTop: "none",
  "@media all": {
    minHeight: 32,
    padding: 0,
    ".MuiTablePagination-toolbar": {
      minHeight: 20,
      ".MuiTablePagination-displayedRows": {
        margin: "2px 5px",
        color: palette.text.light,
        ...typography.semiBoldSmall,
      },
      ".MuiTablePagination-actions": {
        marginLeft: 0,
      },
    },
  },
}));

const TableContainer = styled(MuiTableContainer)(
  ({ theme: { palette, customShadows } }) => ({
    height: "calc(100% - 68px)", //40 -> top bar height + 28 -> space from below
    borderRadius: "12px",
    border: `1px solid ${palette.lightGreyOutline}`,
    borderCollapse: "separate",
    backgroundColor: palette.white,
    boxShadow: customShadows.component,
  })
);

const Table = styled(MuiTable)(({ theme: { palette } }) => ({
  overflow: "auto",
  "& .MuiTableRow-root th:first-of-type": {
    borderTopLeftRadius: "12px",
  },
  "& .MuiTableRow-root th:last-child": {
    borderTopRightRadius: "12px",
    borderRight: "0px",
  },
  "& .MuiTableRow-root td:last-child": {
    borderRight: "0px",
  },
  "& .MuiTableRow-root td": {
    borderRight: `1px solid ${palette.lightGreyOutline}`,
  },
  "& .MuiTableRow-root th": {
    borderRight: `1px solid ${palette.lightGreyOutline}`,
  },
  "& .MuiTableCell-root": {
    borderBottom: `1px solid ${palette.lightGreyOutline}`,
  },
  "& .MuiTableBody-root": {
    "& .MuiTableRow-root": {
      "&:hover": {
        boxShadow: palette.header.headerMenu.boxShadow,
        backgroundColor: "white !important",
        border: `1px solid ${palette.lightGreyOutline}`,
      },
    },
  },
  borderRadius: "12px",
  paddingBottom: "12px",
}));

const HeaderCell = styled(MuiTableCell)(({ theme: { palette } }) => ({
  height: "44px",
  padding: "0",
  position: "sticky",
  top: 0,
  zIndex: 2,
  backgroundColor: palette.background.light,
}));

const TableCell = styled(MuiTableCell)({
  padding: "13px 0",
});

const Styled = {
  Toolbar,
  TableContainer,
  Table,
  TableCell,
  HeaderCell,
};

export default Styled;
