import React, { useState } from "react";
import { Box } from "@mui/material";

import { SUGGESTED_AREA_CONFIDENCE_LEVEL_COLOR } from "../action-center.constants";
import { CircleIcon } from "../../../assets/icons";

import { AreaSelect } from "../../../common/components/form-field/area-select";

const SuggestedArea = ({
  confidence,
  address,
  theme,
  areaOptions,
  placeholder,
  name,
  order,
  resolvedOrders,
  setResolvedOrders,
}) => {
  const { city, suggestedArea } = address;
  const color = SUGGESTED_AREA_CONFIDENCE_LEVEL_COLOR[confidence];

  // for UI display purposes
  const [addressValue, setAddressValue] = useState({
    label: suggestedArea ?? "",
    value: suggestedArea ?? "",
    group: city,
  });

  const setValue = (_, value) => {
    setAddressValue(value);
    const resolvedAddress = {
      id: order.id,
      area: value.value,
      city: value.group,
    };

    const valueExists = resolvedOrders.some((a) => a.id === order.id);

    // check if current order is already resolved
    if (!valueExists) {
      setResolvedOrders([...resolvedOrders, resolvedAddress]);
    } else {
      // append the current value
      const updatedResolvedOrders = resolvedOrders.map((resolvedOrder) => {
        if (resolvedOrder.id === order.id) {
          // If the order matches, update its area
          return { ...resolvedOrder, area: resolvedAddress.area };
        } else {
          // If the order doesn't match, keep it unchanged
          return resolvedOrder;
        }
      });
      setResolvedOrders(updatedResolvedOrders);
    }
  };

  return (
    <Box display="flex" gap="4px" alignItems="center" width={"100%"} pl={2}>
      <Box pr={1}>
        <CircleIcon fill={theme.palette[color]} />
      </Box>

      <AreaSelect
        theme={theme}
        placeholder={placeholder}
        setValue={setValue}
        value={addressValue}
        name={name}
        hasError={false}
        options={areaOptions}
        height={36}
        noBorders={true}
        noLeftPadding={true}
      />
    </Box>
  );
};

export default SuggestedArea;
