import React from "react";

const CheckedCircleIcon = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="7" cy="7" r="6" fill="#7F7D80" stroke="#7F7D80" />
      <path
        d="M10.4284 4.85718L5.857 9.14289L3.57129 7.00003"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CheckedCircleIcon;
