/* eslint-disable max-len */
import React from "react"

const SingleOrderIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15.9231 3H4.07692C3.48215 3 3 3.48215 3 4.07692V15.9231C3 16.5178 3.48215 17 4.07692 17H15.9231C16.5178 17 17 16.5178 17 15.9231V4.07692C17 3.48215 16.5178 3 15.9231 3Z" stroke="#2A272B" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M12.1538 3V8.38462C12.1538 8.52742 12.0971 8.66438 11.9961 8.76536C11.8951 8.86635 11.7582 8.92308 11.6154 8.92308H8.38459C8.24178 8.92308 8.10482 8.86635 8.00384 8.76536C7.90286 8.66438 7.84613 8.52742 7.84613 8.38462V3" stroke="#2A272B" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M11.6154 14.3076H14.3077" stroke="#2A272B" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
)

export default SingleOrderIcon