import React, { useCallback } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import { useMemo } from "react";
import {
  MENU_ITEMS_ENUM,
  MENU_ITEMS_INDEX,
  getMenuItems,
} from "./navbar.utils";
import { useDispatch, useSelector } from "react-redux";
import {
  getNavbarSelectedIndex,
  getNavbarState,
  setSelectedIndex,
  toggleNavbar,
} from "./navbar.slice";
import { selectAuthData } from "../../utils/common.slice";
import Styled from "./navbar.styled";
import { useTheme } from "@emotion/react";
import { useEffect } from "react";
import { FincartLogo, FincartSmLogo } from "../../assets/icons";
import {
  BottomNavigation,
  IconButton,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import useViewport from "../../utils/use-viewport";
import { useTranslation } from "react-i18next";

export default function NavBar() {
  const menuItems = useMemo(() => getMenuItems(), []);
  const dispatch = useDispatch();
  const selectedIndex = useSelector(getNavbarSelectedIndex);
  const authData = useSelector(selectAuthData);
  const isOpen = useSelector(getNavbarState);
  const theme = useTheme();
  const { isMobileView } = useViewport();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(setSelectedIndex(MENU_ITEMS_INDEX.OVERVIEW));
  }, []);

  const toggleDrawer = () => dispatch(toggleNavbar());

  const mobileMenuItems = useMemo(
    () => menuItems.filter(({ key }) => key !== MENU_ITEMS_ENUM.ACTION_CENTER),
    [menuItems]
  );

  const renderDrawerBtn = (isDrawerOpen) => {
    if (isDrawerOpen)
      return (
        <Button onClick={toggleDrawer} sx={{ width: "100%" }}>
          <Box
            width="90%"
            display="flex"
            justifyContent="space-between"
            gap="8px"
            alignItems="center"
          >
            <Box display="flex" gap="8px" alignItems="center">
              <FincartSmLogo />
              <FincartLogo />
            </Box>
          </Box>
        </Button>
      );

    return (
      <IconButton onClick={toggleDrawer}>
        <FincartSmLogo />
      </IconButton>
    );
  };

  const DrawerList = useCallback(
    (isFullView) => (
      <Box
        role="presentation"
        width={isFullView ? "172px" : "50px"}
        onClick={toggleDrawer}
      >
        <List>
          {menuItems.map(({ key, component, path, icon: Icon, PERMS_ID = undefined }, index) => {
            // If permission ID is provided and authData at the position of accessRestriction is set, render the menu item
            if (!PERMS_ID || (authData && (authData.permissions || []).includes(PERMS_ID))) {
              return (
                <Styled.ListItm
                  key={key}
                  component={component}
                  to={path}
                  onClick={(e) => {
                    dispatch(setSelectedIndex(index));
                    e.stopPropagation();
                  }}
                  disablePadding
                >
                  <Styled.ListItemBtn
                    selected={index === selectedIndex}
                    disableRipple
                  >
                    <Styled.ListIcon>
                      <Icon width="20" height="20" />
                    </Styled.ListIcon>
                    {isFullView && (
                      <Styled.ListItmText
                        primary={t(`navbar.listItemsLabels.${key}`)}
                        open={isFullView}
                      />
                    )}
                  </Styled.ListItemBtn>
                </Styled.ListItm>
              );
            }})}
        </List>
      </Box>
    ),
    [authData, selectedIndex],
  )


  const DrawerContent = (isFullView) => (
    <div>
      <Styled.DrawerHeader>{renderDrawerBtn(isFullView)}</Styled.DrawerHeader>
      {DrawerList(isFullView)}
    </div>
  );

  const MobileViewNavBar = useCallback(
    () => (
      <Paper
        sx={{
          position: "fixed",
          bottom: 0,
          left: 0,
          right: 0,
          marginTop: 8,
          zIndex: 2,
        }}
        elevation={3}
      >
        <BottomNavigation
          sx={{ backgroundColor: theme.palette.black, height: "60px" }}
          value={selectedIndex}
          onChange={(event, newValue) => {
            dispatch(setSelectedIndex(newValue));
          }}
        >
          {menuItems.map(({ key, component, path, icon: Icon, PERMS_ID = undefined }, index) => {
          // If permission ID is provided and authData at the position of accessRestriction is set, render the menu item
            if (!PERMS_ID || (authData && (authData.permissions || []).includes(PERMS_ID))) {
              return (
                <Styled.ListItm
                  key={key}
                  sx={{ width: "25%" }}
                  component={component}
                  to={path}
                  onClick={() => dispatch(setSelectedIndex(index))}
                  disablePadding
                >
                  <Styled.ListItemBtn
                    disableRipple
                    isMobileView={true}
                    selected={index === selectedIndex}
                    sx={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Styled.ListIcon>
                      <Icon width="20" height="20" />
                    </Styled.ListIcon>
                    <Typography
                      variant="semiBoldSmall"
                      color={
                        index === selectedIndex
                          ? theme.palette.white
                          : theme.palette.gray
                      }
                    >
                      {t(`navbar.listItemsLabels.${key}`)}
                    </Typography>
                  </Styled.ListItemBtn>
                </Styled.ListItm>
              )}})}
        </BottomNavigation>
      </Paper>
    ),
    [authData, selectedIndex],
  )

  return (
    <Box>
      {isMobileView ? (
        <MobileViewNavBar />
      ) : (
        <>
          <Stack
            borderRadius="0px 10px 10px 0"
            height="100%"
            sx={{ background: theme.palette.black }}
          >
            {DrawerContent(false)}
          </Stack>
          <Drawer
            open={isOpen}
            onClose={toggleDrawer}
            PaperProps={{
              sx: {
                backgroundColor: theme.palette.black,
                borderRadius: "0px 10px 10px 0",
              },
            }}
          >
            {DrawerContent(true)}
          </Drawer>
        </>
      )}
    </Box>
  );
}
