import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

//SLICE NAMES CANNOT BE DUPLICATES!
const sliceName = "orderDetailsSlice";

export const getOrderDetails = createAsyncThunk(
  `${sliceName}/fetchOrderDetails`,
  async (orderId, thunkAPI) => {
    //TODO: translate
    const response = await thunkAPI.extra.apiClient.get({
      url: `orders/${orderId}/details`,
      isJsonContent: false,
      logErrorMsg: true,
      errorMsg: "Failed to get order details.",
    });
    return response;
  }
);

export const getOrderLogs = createAsyncThunk(
  `${sliceName}/fetchOrderLogs`,
  async (orderId, thunkAPI) => {
    //TODO: translate
    const response = await thunkAPI.extra.apiClient.get({
      url: `orders/${orderId}/logs`,
      isJsonContent: true,
      logErrorMsg: true,
      errorMsg: "Failed to get order logs.",
    });
    return response?.data?.body?.logs || [];
  }
);

export const orderDetailsSlice = createSlice({
  name: sliceName,
  initialState: {},
  reducers: {
    extraReducers: (builder) => {
      // Add reducers for additional action types here
      builder.addCase(getOrderDetails.fulfilled, (state, action) => ({
        ...state,
        ...action.payload,
      }));

      builder.addCase(getOrderLogs.fulfilled, (state, action) => ({
        ...state,
        ...action.payload,
      }));
    },
  },
});

export default orderDetailsSlice.reducer;
