import { updateBulkOrdersCourierAction, updateOrderCourierAction } from "../../../select-courier/select-courier.slice";
import { NEW_STATUS, STATUS_IDS } from "../../orders-table.constants";

const updateSingleOrderCourier = (props) => {
  const { dispatch, selectedOrders, setIsUpdatingCourier, selectedCourierId, setOrders, couriers, toast, closeDrawer} = props || {}

  const getUpdatedOrders = (prevOrders) => {
    return prevOrders.map((order) => {
      if(order.id === selectedOrders[0].id) {
        return {...order, courierLogo: couriers.find(({id}) => id === selectedCourierId)?.logo}
      }

      return order
    })
  }

  dispatch( updateOrderCourierAction({ order: selectedOrders[0], courierId: selectedCourierId }))
    .then((res) => {
      if(res?.payload?.status === 'success') {
        setOrders(getUpdatedOrders)

        toast(`${selectedOrders[0].code} updated successfully`)
      } else {
        toast.error(`Failed to update courier for ${selectedOrders[0].code}`)
      }
      closeDrawer();
    })
    .finally(() => setIsUpdatingCourier(false));
}

const updateMultiOrdersCouriers = (props, isSelectAll, activeFilters, searchTerm) => {
  const getUpdatedOrders = (prevOrders, successUpdatedOrdersIds) => prevOrders.map((order) => {
    if(successUpdatedOrdersIds.includes(order.id)) {
      return {...order, courierLogo: couriers.find(({_id}) => _id === selectedCourierId)?.logo}
    }
    return order
  })

  const { dispatch, setIsUpdatingCourier, selectedOrders, selectedCourierId, setOrders, couriers, toast, closeDrawer} = props || {}

  let paramObject = {}

  if(isSelectAll) {
    paramObject = {
      isSelectAll: true,
      filters: {
        ...activeFilters,
        status: STATUS_IDS.PENDING,
        subStatus: NEW_STATUS,
      },
      ...(searchTerm && { code: searchTerm })
    }
  } else {
    const selectedOrdersIds = selectedOrders?.map((order) => order.id)

    paramObject = {
      orderIds: selectedOrdersIds,
      courier: selectedCourierId,
    }
  }

  dispatch(updateBulkOrdersCourierAction(paramObject))
    .then((res) => {
      const responses = res.payload?.replace('event: close', '')?.split('data: ')?.filter((value) => value)?.map((value) => JSON.parse(value)) || []//TODO: check with BE how to handle this

      const failedUpdateOrders = responses.filter((res) => !res?.success)
      const successUpdatedOrdersIds = responses.filter((res) => res.success)?.map(({ id }) => id)

      if(successUpdatedOrdersIds.length > 0) {
        toast(`${successUpdatedOrdersIds.length} orders updated successfully${failedUpdateOrders.length > 0? `and ${failedUpdateOrders.length} failed` : ''}`)

        setOrders((prevOrders) => getUpdatedOrders(prevOrders, successUpdatedOrdersIds))
      } else {
        toast.error('Failed to update orders\' couriers')
      }
      closeDrawer()
    })
    .finally(() => setIsUpdatingCourier(false));
}

export { updateSingleOrderCourier, updateMultiOrdersCouriers }