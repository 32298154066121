import { Box, Stack, Typography } from "@mui/material"
import React, {  useState } from "react"

import { LeftArrowIcon, RightArrowIcon } from "../../../assets/icons"
import { FILTERS, FILTERS_IDS, PRINT_LABELS } from "./filters.constants"
import StatusFilter from "./status-filter"
import DateFilter from "./date-filter"
import CityFilter from "./city-filter"
import RadioButtonsGroup from "./components/radio-buttons-group"
import PickupLocationFilter from "./pickup-location-filter"
import Button from "../../../common/components/button"
import Styled from "./filters.styled"
import { useTranslation } from "react-i18next"
import CourierFilter from "./courier-filter"

const Filters = ({ theme, activeFilters, onApplyFilter, closeDrawer, filtersFormProps, isMobileView, appDirection }) => {
  const { t } = useTranslation();

  const [selectedFilter, setSelectedFilter] = useState({id: null })

  const { form, dirtyFields, isDirty, handleSubmit, reset, resetField, setValue, submitData, resettedFields } = filtersFormProps

  const submitCallBack = (filters) => { onApplyFilter(filters); closeDrawer(); }

  const resetAllData = () => { reset(); onApplyFilter({}); }

  const onResetField = (id, e) => {resetField(id); e.stopPropagation(); }

  const ResetButton = ({id}) => {
    if(!Object.keys(dirtyFields).includes(id)) return

    return (
      <Typography
        onClick={(e) => onResetField(id, e)}
        sx={{ cursor: 'pointer', "&:hover": { color: theme.palette.black } }}
        variant="semiBoldSmallUnderline"
        color={theme.palette.text.light}>
        Reset
      </Typography>
    )
  }

  const PrintLabelFilter = () => (
    <Box padding="16px 24px">
      <RadioButtonsGroup defaultValue={null} setValue={(value) => setValue('printLabel', value)} value={form.printLabel} options={PRINT_LABELS}/>
    </Box>
  )

  const checkIfApplyButtonDisabled = () => {
    if(resettedFields.length > 0 && Object.keys(activeFilters)?.length > 0) return false
    return !isDirty
  }

  if(!selectedFilter.id) return (
    <Styled.FiltersWrapper>
      <Stack gap="20px">
        {FILTERS.map(({id}) =>
          <Box sx={{'&:hover': {"svg": {">path": {stroke: theme.palette.black}}}}} key={id} paddingBottom="20px" borderBottom={`1px solid ${theme.palette.lightGreyOutline}`}>
            <Box display="flex" justifyContent="space-between" alignItems="center" onClick={() => setSelectedFilter({ id })} sx={{ cursor: 'pointer' }}>
              <Typography variant="boldText">{t(`listing.filters.${id}.title`)}</Typography>
              <Box display="flex" gap="8px" justifyContent="flex-end" alignItems="center">
                <ResetButton id={id} />
                {appDirection === 'rtl'? <LeftArrowIcon /> : <RightArrowIcon/> }
              </Box>
            </Box>
          </Box>
        )}
      </Stack>

      <Box display="flex" gap="8px">
        {<Button
          isDisabled={Object.keys(dirtyFields).length === 0}
          onClick={resetAllData}
          size={{height: '36px', width: '50%'}}
          color='secondary'
          label={t('listing.filters.resetAllButton')}
        /> }

        <Button isDisabled={checkIfApplyButtonDisabled()}
          onClick={handleSubmit((data) => submitData(data, submitCallBack))}
          size={{height: '36px', width: '50%'}}
          color='primary' label={t('listing.filters.applyButton')}>
        </Button>
      </Box>
    </Styled.FiltersWrapper>
  )

  const renderContent = () => {
    switch(selectedFilter.id) {
    case FILTERS_IDS.STATUS:
      return <StatusFilter formValues={form.orderStatus} theme={theme} setValue={setValue} t={t} />
    case FILTERS_IDS.DATE:
      return <DateFilter fieldValues={form.dateRange} setValue={setValue} isMobileView={isMobileView} t={t} />
    case FILTERS_IDS.CITY:
      return <CityFilter setValue={setValue} value={form.city} t={t} />
    case FILTERS_IDS.COURIERS:
      return <CourierFilter t={t} setValue={setValue} courierIds={form.courierIds}/>
    // case FILTERS_IDS.PRINT_LABEL:
    //   return <PrintLabelFilter /> //no logic implemented yet
    case FILTERS_IDS.PICKUP_LOCATION:
      return <PickupLocationFilter setValue={setValue} formValue={form.pickupLocation} />
    }
  }

  return (
    <Stack direction="column" height='100%' overflow="auto">
      <Stack
        direction="row"
        padding="14px 24px 14px 18px"
        justifyContent="space-between"
        alignItems="center"
        onClick={() => setSelectedFilter({ id: null })}
        sx={{ backgroundColor: theme.palette.background.light, cursor: 'pointer' }}
      >
        <Box display="flex" gap="8px" sx={{'&:hover': {"svg": {">path": {stroke: theme.palette.black}}}}}>
          {appDirection === 'rtl'? <RightArrowIcon /> : <LeftArrowIcon/> }
          <Typography variant="boldText">{t(`listing.filters.${selectedFilter.id}.title`)}</Typography>
        </Box>
        {<ResetButton id={selectedFilter.id} />}
      </Stack>
      {renderContent()}
    </Stack>
  )
}

export default Filters