import React, { useState } from 'react';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Box, IconButton, Popper, Stack, Typography, useTheme } from '@mui/material';

import { LeftArrowIcon, RightArrowIcon } from '../../../../assets/icons'
import Styled from '../filters.styled';
import { MONTHS, MONTHS_SHORT } from '../filters.constants';
import SelectButton from '../../../../common/components/select-button';
import DrawerWithTitle from '../../../../common/components/drawer';
import Button from '../../../../common/components/button';

export default function Calendar({ isDisabled, duration, setDuration, isMobileView, t }) {
  const [value, setValue] = useState(dayjs(new Date()));
  const [isCalendarOpen, setIsCalendarOpen] = useState(false)
  const [calendarAnchor, setCalendarAnchor] = useState(null)

  const theme = useTheme()

  const calendarPopperId = 'calendar-popper-id'

  //utils - I haven't moved them to utils to keep all on one place if we needed to use it on future - better to separate file
  const getTimeFromDate = (date) => new Date (date).getTime()
  const formateDate = (date) => date? date.format('DD/MM/YYYY'): null

  const handleChangeDate = (oldValue, newValue) => {
    const oldValueTime = getTimeFromDate(oldValue)
    const newValueTime = getTimeFromDate(newValue)
    const startTime =  duration.start && getTimeFromDate(duration.start)
    const endTime =  duration.end && getTimeFromDate(duration.end)

    setValue(newValue);

    if(startTime && newValueTime === startTime) {
      return setDuration({ start: null, end: null })
    }

    if(startTime && endTime && newValueTime > endTime) {
      return setDuration((prevDuration) => ({...prevDuration, end: newValue}))
    }

    if(oldValueTime < newValueTime) {
      if(duration.start) {
        setIsCalendarOpen(false)
        return setDuration({ start: oldValue, end: newValue})
      }
      return setDuration({ start: newValue, end: null})
    }

    if(oldValueTime > newValueTime) {
      return setDuration({ start: newValue, end: null })
    }


    setDuration({ start: value, end: null })
  }

  const getButtonLabel = (startDate, endDate) => {
    const formateButtonDates = (date) =>  `${date.format('DD')} ${MONTHS_SHORT[date.month()]}`
    if(startDate || endDate) return (`${startDate? formateButtonDates(startDate): ''}${endDate? ` - ${(formateButtonDates(endDate))}`: ''}`)
    return <Typography variant='regular' color={theme.palette.text.light}>{t('listing.filters.dateRange.selectPlaceholder')}</Typography>
  }

  //Calendar header component
  const calendarHeader = (props) => {
    const {currentMonth, onMonthChange} = props

    const updateMonth = (isNextMonth) => {
      const currentMonthIndex = currentMonth.month()
      if(isNextMonth) {
        const newMonthIndex = currentMonthIndex < 11? currentMonthIndex + 1 : 0
        onMonthChange(((dayjs().month(newMonthIndex))), 'right')
      } else {
        const newMonthIndex = currentMonthIndex >= 0? currentMonthIndex - 1 : 11
        onMonthChange(((dayjs().month(newMonthIndex))), 'left')
      }
    }

    return (
      <Stack direction="row" width="100%" justifyContent="space-between" alignItems="center" padding="0px 20px">
        <Styled.CalendarButton disableRipple onClick={() => updateMonth(false)}> <LeftArrowIcon /></Styled.CalendarButton>
        <Typography variant='boldText'>{MONTHS[currentMonth.month()]}</Typography>
        <Styled.CalendarButton disableRipple onClick={() => updateMonth(true)}> <RightArrowIcon /></Styled.CalendarButton>
      </Stack>
    )
  }

  const isStartOrEndDate = (start, end, date) => [formateDate(start), formateDate(end)].includes(formateDate(date))

  const isInDuration = (start, end, day) => {
    const startTime = start ? getTimeFromDate(start) : null
    const endTime = end ? getTimeFromDate(end) : null
    const dayTime = getTimeFromDate(day)

    return isStartOrEndDate(start, end, day) || (end && start && dayTime > startTime && dayTime < endTime)
  }

  //Day component
  const Day = (props) => {
    const { day, ...other } = props;

    const { start, end } = duration
    const isSelected = isStartOrEndDate(start, end, day)

    return (
      <Styled.CalendarDayWrapper isStartDate={end && formateDate(start) === formateDate(day)} isEndDate={start && formateDate(end) === formateDate(day)}>
        <Styled.CalendarDay
          {...other}
          day={day}
          sx={{ px: 2.5 }}
          disableMargin
          selected={false}
          defaultValue={dayjs(new Date())}
          isStartOrEndDate={isSelected}
          isInDuration={isInDuration(start, end, day)}
          isDefaultDate={formateDate(dayjs(day)) === formateDate(dayjs(new Date()))}
        />
      </Styled.CalendarDayWrapper>
    );
  }

  return (
    <Stack gap="4px" direction="column">
      <Typography variant='boldSmall' color={theme.palette[isDisabled? 'gray': 'black']}>
        {t('listing.filters.dateRange.calendarLabel')}
      </Typography>
      <SelectButton
        theme={theme}
        width={isMobileView? '100%' : 316}
        isOpen={isCalendarOpen}
        describedBy={calendarPopperId}
        isDisabled={isDisabled}
        label={getButtonLabel(duration.start, duration.end)}
        onClick={(e) => {setCalendarAnchor(e.currentTarget); setIsCalendarOpen(!isCalendarOpen)}}
      />
      {isMobileView?
        <DrawerWithTitle
          open={isCalendarOpen}
          theme={theme}
          maxHeightInMobileView={452}
          isMobileView={isMobileView}
          anchor='bottom'
          closeDrawer={() => setIsCalendarOpen(false)}
          title={
            <Box display="flex" gap="8px">
              <IconButton disableRipple sx={{ padding: 0 }} onClick={() => setIsCalendarOpen(false)}><LeftArrowIcon />
              </IconButton>
              {t('listing.filters.dateRange.customDatesDrawerTitle')}
            </Box>
          }
          content={
            <Stack>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Styled.Calendar
                  slots={{ day: Day, calendarHeader }}
                  showDaysOutsideCurrentMonth
                  value={value}
                  onChange={(newValue) => handleChangeDate(value, newValue)}
                  defaultValue={new Date()}
                />
              </LocalizationProvider>
              <Button color='secondary' label={t('listing.filters.dateRange.doneButton')} onClick={() => setIsCalendarOpen(false)} />
            </Stack>}
        /> :
        <Popper sx={{ zIndex: 4000 }} id={calendarPopperId} open={isCalendarOpen} anchorEl={calendarAnchor}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Styled.Calendar
              slots={{ day: Day, calendarHeader }}
              showDaysOutsideCurrentMonth
              value={value}
              onChange={(newValue) => handleChangeDate(value, newValue)}
              defaultValue={new Date()}
            />
          </LocalizationProvider>
        </Popper>
      }
    </Stack>
  );
}