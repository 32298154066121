import * as React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { RadioGroupCheckedIcon } from '../../../../assets/icons';
import RadioGroupUnCheckedIcon from '../../../../assets/icons/RadioGroupUnCheckedIcon';
import { Typography } from '@mui/material';

export default function RadioButtonsGroup({ defaultValue, options = [], optionsI18nKey, value, setValue, t }) {
  return (
    <FormControl>
      <RadioGroup
        aria-labelledby="radio-buttons-group-label"
        defaultValue={defaultValue}
        value={value}
        color='basic'
        onChange={(e) => setValue(e.currentTarget.value)}
        name="radio-buttons-group"
        sx={{ gap: '8px' }}
      >
        {options.map((({ id  }) =>
          <FormControlLabel key={id}
            value={id}
            control={
              <Radio
                sx={{
                  padding: '0 8px 0 9px',
                  '& .MuiSvgIcon-root': {
                    fontSize: 16,
                  },
                }}
                disableRipple
                checkedIcon={<RadioGroupCheckedIcon />}
                icon={<RadioGroupUnCheckedIcon />} />
            }
            label={<Typography variant='regular'>{t(`${optionsI18nKey}.${id}`)}</Typography>} />
        ))}
      </RadioGroup>
    </FormControl>
  );
}