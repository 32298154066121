import React from "react";
import {
  Box,
  Drawer,
  Skeleton,
  TableBody,
  TableRow,
  Typography,
} from "@mui/material";

import { getActivities } from "../orders-table.utils";
import { STATUS_IDS, headerCells } from "../orders-table.constants";
import { CourierLogo, SubStatus, Activities } from "../components";
import Checkbox from "../../../common/components/checkbox";
import DateTimeLabel from "../../../common/components/date-time-label";
import TableToolbar from "../../../common/components/table/table-toolbar";
import TableHeader from "./table-header";
import Styled from "../orders.styled";
import { MoreIcon } from "../../../assets/icons";
import { OrderDetails } from "../../order-details";
import getActionBarComponent from "../components/action-bar";
import { ROWS_PER_PAGE_COUNT } from "../../../constants";

function OrdersTable({
  rows,
  scrollableRef,
  isOrdersLoading,
  handleNavigation,
  selectedOrders,
  toggleSelectAll,
  setPendingOrder,
  toggleOrder,
  openActionsMenu,
  actionBarProps,
  totalOrdersCount,
  page,
  theme,
  isOpenOrderDetailsDrawer,
  setOpenOrderDetailsDrawer,
  setClickedOrderId,
  clickedOrderId,
  t,
}) {
  const openOrderDetails = (orderId) => {
    setClickedOrderId(orderId);
    setOpenOrderDetailsDrawer(true);
  };

  const renderCellContent = (row, { title, details, titleVariant }) => (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      typography="regular"
    >
      {titleVariant ? (
        <Typography
          variant={titleVariant}
          onClick={() => {
            openOrderDetails(row.id);
            setPendingOrder(row);
          }}
          sx={{ cursor: "pointer" }}
        >
          {title}
        </Typography>
      ) : (
        title
      )}
      {details && (
        <Typography variant="boldSmall" paddingRight="2px">
          {details}
        </Typography>
      )}
    </Box>
  );

  const Attempts = ({ attempts }) => {
    const isValueMoreThanThree = attempts > 3;

    const getLabel = () => {
      if (!attempts) return "?";

      if (isValueMoreThanThree) return `${attempts} +`;

      return `${attempts}/3`;
    };

    return (
      <Box
        display="flex"
        justifyContent="center"
        flexDirection="column"
        alignItems="center"
        gap="4px"
      >
        <Typography variant="boldSmall">{getLabel()}</Typography>
        <Styled.AttemptsProgressLine
          variant="determinate"
          value={isValueMoreThanThree ? 100 : attempts * 30}
        />
      </Box>
    );
  };

  const ProcessedOnDetails = ({ processedOn, status }) => {
    if (status === STATUS_IDS.PENDING) {
      return "Pending";
    } //TODO translate

    return <DateTimeLabel dateTime={processedOn} theme={theme} />;
  };

  const renderActivityContent = (
    { status, needDataUpdate, supportNote, needReprintLabel },
    order
  ) => {
    const isPendingStatus = status === STATUS_IDS.PENDING;
    const activities = getActivities({
      needDataUpdate,
      supportNote,
      needReprintLabel,
    });
    const hasActivities = Boolean(activities.find(({ enabled }) => enabled));

    return (
      <Box
        display="flex"
        width="100%"
        gap="25px"
        justifyContent={isPendingStatus ? "flex-end" : "center"}
      >
        {hasActivities && (
          <Box width="fit-content">
            <Activities
              activities={getActivities({
                needDataUpdate,
                supportNote,
                needReprintLabel,
              })}
            />
          </Box>
        )}
        {isPendingStatus && (
          <Styled.TableIconButton
            disableRipple
            onClick={(event) => openActionsMenu(event, order)}
          >
            <MoreIcon />
          </Styled.TableIconButton>
        )}
      </Box>
    );
  };

  const getSubStatus = ({ rejectionReason, subStatus }) => {
    if (subStatus === "failed attempt" && rejectionReason)
      return rejectionReason;
    return subStatus;
  };

  const getCellsContent = (order) => {
    const {
      id,
      code,
      createdAt,
      customerName,
      fees,
      courierLogo,
      amount,
      city,
      processedOn,
      rejectionReason,
      status,
      subStatus,
      attempts,
      needDataUpdate,
      needReprintLabel,
      supportNote,
    } = order;
    return [
      {
        id: "code",
        title: code,
        details: <DateTimeLabel dateTime={createdAt} theme={theme} />,
        titleVariant: "regularWithUnderline",
      },
      {
        id: "details",
        title: customerName,
        details: amount
          ? `${t("mutualizedEgpLabel")} ${+amount?.toFixed(2)}`
          : t("listing.labels.withoutCash"),
      },
      {
        id: "courier",
        title: courierLogo ? (
          <CourierLogo imgPath={courierLogo} />
        ) : (
          t("listing.noCourierAssignedLabel")
        ),
        details: courierLogo
          ? `${t("mutualizedEgpLabel")} ${+fees?.toFixed(2)}`
          : "",
      },
      {
        id: "processedOn",
        title: city,
        details: (
          <ProcessedOnDetails processedOn={processedOn} status={status} />
        ),
      },
      {
        id: "status",
        title: (
          <SubStatus
            status={status}
            subStatus={getSubStatus({ rejectionReason, subStatus })}
            theme={theme}
          />
        ),
      },
      { id: "attempts", title: <Attempts attempts={attempts} /> },
      {
        id: "activity",
        title: renderActivityContent(
          { id, status, needDataUpdate, supportNote, needReprintLabel },
          order
        ),
      },
    ];
  };

  const renderTableBody = (isOrdersLoading) => {
    if (isOrdersLoading) {
      const rows = Array(50).fill(null);
      const cellsCount = Array(7).fill(null);
      return rows.map((_, index) => {
        return (
          <TableRow key={index}>
            <Styled.TableCell padding="checkbox" align="center">
              <Box width="100%" display="flex" justifyContent="center">
                <Skeleton height="27px" width="15px" />
              </Box>
            </Styled.TableCell>
            {cellsCount.map((_, index) => (
              <Styled.TableCell key={index} align="center">
                <Box width="100%" display="flex" justifyContent="center">
                  <Skeleton width="90%" />
                </Box>
              </Styled.TableCell>
            ))}
          </TableRow>
        );
      });
    }

    if (rows.length === 0) return;

    return rows.map((row, index) => {
      const { id: orderId } = row;
      const isItemSelected = Boolean(
        selectedOrders?.find((order) => order.id === orderId)
      );

      return (
        <TableRow
          hover
          role="checkbox"
          aria-checked={isItemSelected}
          tabIndex={-1}
          key={orderId}
          selected={false}
        >
          <Styled.TableCell padding="checkbox" align="center">
            <Checkbox
              onChange={() => toggleOrder(row)}
              isItemSelected={isItemSelected}
              index={index}
              disableRipple
              labelId={`orders-table-checkbox-${index}`}
            />
          </Styled.TableCell>
          {getCellsContent(row).map(({ id, ...content }) => (
            <Styled.TableCell key={id} align="center">
              {renderCellContent(row, content)}
            </Styled.TableCell>
          ))}
        </TableRow>
      );
    });
  };

  return (
    <Box width="100%" overflow="auto" height="100%">
      <Box margin="0 40px" height="100%">
        <TableToolbar
          actionBarProps={{ totalOrdersCount, selectedOrders, orders: rows, ...actionBarProps }}
          rowsPerPage={ROWS_PER_PAGE_COUNT}
          page={page}
          t={t}
          handleNavigation={handleNavigation}
          totalOrdersCount={totalOrdersCount}
          theme={theme}
          getActionBarComponent={getActionBarComponent}
        />
        <Styled.TableContainer>
          <Styled.Table
            aria-labelledby="tableTitle"
            size="medium"
            ref={scrollableRef}
          >
            <TableHeader
              t={t}
              onSelectAllClick={toggleSelectAll}
              isAllOrdersSelected={
                selectedOrders.length && selectedOrders.length === rows.length
              }
              theme={theme}
              headerCells={headerCells}
            />
            <TableBody>{renderTableBody(isOrdersLoading)}</TableBody>
          </Styled.Table>
        </Styled.TableContainer>
      </Box>

      <Drawer
        anchor="right"
        open={isOpenOrderDetailsDrawer}
        variant="temporary"
        onClose={() => setOpenOrderDetailsDrawer(false)}
        PaperProps={{
          style: {
            width: "100%",
            maxWidth: 364,
            borderBottomLeftRadius: "8px",
            borderTopLeftRadius: "8px",
          },
        }}
      >
        <OrderDetails
          orderId={clickedOrderId}
          onCloseOrderDetails={() => {
            setOpenOrderDetailsDrawer(false);
            setPendingOrder({});
          }}
        />
      </Drawer>
    </Box>
  );
}

export default OrdersTable;
