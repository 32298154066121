import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { Box } from "@mui/material"

import { fetchLocations } from "../../create-order/create-order.slice"
import Chip from "./components/chip"

const PickupLocationFilter = ({ setValue, formValue }) => {
  const [pickupLocations, setPickupLocations] = useState([])

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(fetchLocations()).then((res) => {
      if(!Array.isArray(res.payload?.locations)) return
      setPickupLocations(res.payload.locations.map(({name, _id, area}) => ({ label: name, value: _id, area: area?.split(' - ')[0] })))
    })
  }, [])

  return (
    <Box display="flex" flexWrap="wrap" maxHeight="100%" overflow="auto" padding="16px 24px" gap="4px" columnGap="4px">
      {pickupLocations.map(({ label, value, area }) =>
        <Chip
          key={value}
          isSelected={formValue === label}
          label={`${label} (${area})`}
          onClick={() => setValue('pickupLocation', label)}
        />
      )}
    </Box>
  )
}

export default PickupLocationFilter