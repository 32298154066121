import React from "react"
import { Box, Typography } from "@mui/material"
import { getFormattedDate, getFormattedTime } from "../../../utils/formatDateTime"

const DateTimeLabel = ({ dateTime, dateColor = 'primary', timeColor = 'light', theme, variant="boldSmall" }) => {
  if(!dateTime) return

  const date = getFormattedDate(dateTime)
  const time = getFormattedTime(dateTime)

  return (
    <Box>
      <Typography variant={variant} paddingRight="2px" color={theme.palette.text[dateColor]}>{date}</Typography>
      <Typography variant={variant} color={theme.palette.text[timeColor]}>({time})</Typography>
    </Box>
  )}

export default DateTimeLabel
