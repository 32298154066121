import React, { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Typography, useTheme } from "@mui/material";

import { handleExportSelectedOrders } from "../orders-table.utils";
import IconButton from "../../../common/components/icon-button";
import {
  CheckIcon,
  DeleteIcon,
  ExportIcon,
  FiltersIcon,
  SearchIcon,
} from "../../../assets/icons";
import {
  STATUS_IDS,
  ORDERS_ACTIONS_IDS,
  actionBarButtonsProps,
  MISSING_AREA_SUBSTATUS,
} from "../orders-table.constants";
import Styled from "./components.styled";
import { selectIsFiltersApplied } from "../orders.slice";
import { Trans } from "react-i18next";

const getActionBarComponent = ({
  selectedTab,
  searchTerm,
  activeFilters,
  isSelectAll,
  setIsSelectAll,
  totalOrdersCount,
  selectedOrders = [],
  orders = [],
  t,
  onSearch = () => {},
  onFilter = () => {},
  fetchAwaitingActionsOrders = () => {},
  onApplyOrderAction = () => {},
  theme = {},
  isMobileView,
}) => {
  const dispatch = useDispatch();

  const [isAwaitingActionsApplied, setIsAwaitingActionsApplied] =
    useState(false);

  const isFiltersApplied = useSelector(selectIsFiltersApplied);

  const selectedOrdersCount = useMemo(
    () => selectedOrders?.length,
    [selectedOrders]
  );

  const toggleAwaitingActions = () => {
    setIsAwaitingActionsApplied(!isAwaitingActionsApplied);
    fetchAwaitingActionsOrders(!isAwaitingActionsApplied);
  };

  const includesMissingAreas = useMemo(
    () =>
      Boolean(selectedOrdersCount) &&
      selectedOrders?.find(
        (order) => order.subStatus === MISSING_AREA_SUBSTATUS
      ),
    [selectedOrdersCount, orders]
  );

  const isAllSelectedPending = useMemo(
    () =>
      !isSelectAll && Boolean(selectedOrdersCount) &&
      !selectedOrders?.find((order) => order.status !== STATUS_IDS.PENDING),
    [selectedOrdersCount, orders, isSelectAll]
  );

  const renderSelectedLabel = () => (
    <>
      <Typography
        paddingLeft="12px"
        variant="semiBoldSmall"
        color={theme.palette.text.light}
      >
        <Trans
          i18nKey={'listing.labels.ordersSelected'}
          values={{ ordersCount: isSelectAll? totalOrdersCount : selectedOrdersCount}}
        />
      </Typography>
      <Styled.SelectAllLabel
        onClick={() => setIsSelectAll(!isSelectAll)}
        disableRipple
      >
        {isSelectAll
          ? t("listing.labels.clearSelectAll")
          : <Trans
            i18nKey={'listing.labels.selectAll'}
            values={{ ordersCount: totalOrdersCount}}
          />
        }
      </Styled.SelectAllLabel>
    </>
  );

  if (includesMissingAreas)
    return (
      <Box>
        <IconButton
          icon={<DeleteIcon />}
          onClick={onApplyOrderAction(ORDERS_ACTIONS_IDS.DELETE)}
        />
        {renderSelectedLabel()}
      </Box>
    );

  if (isAllSelectedPending)
    return (
      <Box display="flex" alignItems="center" gap="4px">
        {actionBarButtonsProps.map(({ key, icon: Icon }) => (
          <IconButton
            theme={theme}
            icon={
              <Icon width="20px" height="20px" color={theme.palette.gray} />
            }
            key={key}
            tooltip={true}
            title={t(`listing.actions.${key}`)}
            onClick={onApplyOrderAction(key)}
          />
        ))}
        {renderSelectedLabel()}
      </Box>
    );

  return selectedOrdersCount > 0 ? (
    <Box>
      <IconButton
        icon={<ExportIcon />}
        onClick={() =>
          handleExportSelectedOrders(
            selectedTab,
            selectedOrders,
            isSelectAll,
            searchTerm,
            activeFilters,
            dispatch,
            t,
          )
        }
      />
      {renderSelectedLabel()}
    </Box>
  ) : (
    <Box
      display="flex"
      gap="8px"
      flexDirection={isMobileView ? "row-reverse" : "row"}
      justifyContent={isMobileView ? "space-between" : "flex-start"}
      width={isMobileView ? "100%" : "fit-content"}
    >
      {isMobileView && (
        <IconButton
          onClick={onSearch}
          icon={<SearchIcon color={theme.palette.gray} />}
        />
      )}

      <Styled.FiltersBadge
        badgeContent={isFiltersApplied ? <CheckIcon /> : null}
      >
        <IconButton
          isDisabled={orders.length === 0 && !isFiltersApplied}
          icon={<FiltersIcon color={theme.palette.gray} />}
          onClick={() => onFilter(isMobileView ? "bottom" : "right")}
        />
      </Styled.FiltersBadge>

      {/* TODO: uncomment when BE logic implemented */}
      {/* <Styled.AwaitingActionsButton color='primary' isActive={isAwaitingActionsApplied} onClick={toggleAwaitingActions}>
          <Typography variant='semiBoldSmall'>
            {locales.awaitingActionsButton}
          </Typography>
        </Styled.AwaitingActionsButton> */}
    </Box>
  );
};

export default getActionBarComponent;
