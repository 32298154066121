import React from "react";

const CheckCircleOutline = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 17C13.866 17 17 13.866 17 10C17 6.13401 13.866 3 10 3C6.13401 3 3 6.13401 3 10C3 13.866 6.13401 17 10 17Z"
      stroke="#7F7D80"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.8 8.13333L9.06668 11.8667L7.20001 10"
      stroke="#7F7D80"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default CheckCircleOutline;
