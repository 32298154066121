import React, { useEffect, useState } from 'react';
import { Stack } from '@mui/material';

import RadioButtonsGroup from './components/radio-buttons-group';
import { DATE_RANGES } from './filters.constants';
import Calendar from './components/calendar';
import dayjs from 'dayjs';

function DateFilter ({ setValue, fieldValues, isMobileView, t }) {
  const { value, duration: durationFieldValue = {} } = fieldValues

  const getStartOrEndDate = (value) => durationFieldValue[value] > 0 ? dayjs(durationFieldValue[value]) : ''

  const [duration, setDuration] = useState({ start: getStartOrEndDate('startDate'), end: getStartOrEndDate('endDate') })

  useEffect(() => {
    if(duration?.start || duration?.end) {
      setValue('dateRange.duration', {startDate: new Date(duration.start).getTime(), endDate: new Date(duration.end).getTime()})
    }
  }, [duration])

  useEffect(() => {
    if(!durationFieldValue) { //handling reset case
      setDuration({ start: '', end: '' })
    }
  }, [durationFieldValue])

  return (
    <Stack direction="column" justifyContent="center" padding="16px 24px" gap="20px">
      <RadioButtonsGroup
        options={DATE_RANGES}
        optionsI18nKey='listing.filters.dateRange.options'
        setValue={(value) => {setValue('dateRange.value', value, Boolean(durationFieldValue.startDate || durationFieldValue.endDate))}}
        value={value}
        defaultValue={value}
        t={t}
      />
      <Calendar duration={duration} setDuration={setDuration} isDisabled={!value} isMobileView={isMobileView}  t={t} />
    </Stack>
  )
}

export default DateFilter