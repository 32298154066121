import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

const Container = styled(Box, {
  shouldForwardProp: (prop) => prop !== "$isMobileView",
})(({ $isMobileView }) => ({
  width: "100%",
  display: "flex",
  flexWrap: "wrap",
  flexDirection: "column",
  gap: 12,
  padding: $isMobileView ? "16px 20px" : "16px 48px 24px 40px",
  height: $isMobileView ? "100%" : "auto",
}));

const ContentWrapper = styled(Box)({
  width: "100%",
  display: "flex",
  flexWrap: "wrap",
  gap: 10,
});

const OrderSummarySubContentWrapper = styled(Box)({
  width: "100%",
  height: "120px",
  display: "flex",
  flexDirection: "column",
  gap: 12,
  marginTop: "12px",
});

const SubContentWrapper = styled(Box)({
  width: "100%",
  minHeight: "236px",
  display: "flex",
  flexDirection: "column",
  gap: 12,
  marginTop: "12px",
});

const OrderSummaryGrid = styled(Box, {
  shouldForwardProp: (prop) => prop !== "$isMobileView",
})(({ $isMobileView }) => ({
  height: "100%",
  display: "flex",
  flexDirection: "row",
  width: "100%",
  gap: $isMobileView ? "8px" : "12px",
}));

const Grid = styled(Box)(({ $isMobileView }) => ({
  height: "calc(100% - 32px)",
  display: "flex",
  justifyContent: "space-between",
  flexDirection: "row",
  width: "100%",
  gap: $isMobileView ? "8px" : "12px",
}));

const DeliveryPerformanceCard = styled(Box)({
  display: "grid",
  gridTemplateColumns: "1fr",
  gap: "12px",
  "@media (min-width: 1100px)": {
    gridTemplateColumns: "1fr 1fr",
    gap: "12px",
  },
});

const OrderSummaryCard = styled(Box, {
  shouldForwardProp: (prop) => prop !== "$isMobileView",
})(({ $isMobileView, theme: { palette } }) => ({
  maxWidth: "100%",
  width: "100%",
  height: $isMobileView ? "auto" : "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "12px",
  border: `1px solid ${palette.lightGreyOutline}`,
  background: palette.white,
  boxShadow: palette.boxShadow.dashboardCards,
}));

const Card = styled(Box, {
  shouldForwardProp: (prop) => prop !== "$isMobileView",
})(({ $isMobileView, theme: { palette } }) => ({
  maxWidth: "100%",
  width: "100%",
  height: $isMobileView ? "auto" : "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "12px",
  border: `1px solid ${palette.lightGreyOutline}`,
  background: palette.white,
  boxShadow: palette.boxShadow.dashboardCards,
}));

const CardContentWrapper = styled(Box)({
  display: "inline-flex",
  alignItems: "center",
  gap: 5,
});

const Styled = {
  Container,
  ContentWrapper,
  SubContentWrapper,
  OrderSummarySubContentWrapper,
  OrderSummaryGrid,
  Grid,
  Card,
  OrderSummaryCard,
  CardContentWrapper,
  DeliveryPerformanceCard,
};

export default Styled;
