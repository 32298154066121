import {
  Box, Typography, TableContainer as MuiTableContainer, TableCell as MuiTableCell, Table as MuiTable,
  LinearProgress, linearProgressClasses, IconButton, ListItem
} from "@mui/material";
import CircleIcon from '@mui/icons-material/Circle';
import { styled } from "@mui/material/styles";

const Container = styled(Box, {
  shouldForwardProp: (prop) => prop !== "$isMobileView"
})(({ $isMobileView, theme }) => ({
  height: "100%",
  display: 'flex',
  flexDirection: "column",
  gap: ($isMobileView ? '16px' : '12px'),
  padding: ($isMobileView ? "24px 15px 20px 15px" : "36px 48px 44px 40px"),
  backgroundColor: theme.palette.background.light
}));

const HeaderWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== "$isMobileView"
})(({ $isMobileView }) => ({
  width: "100%",
  display: 'flex',
  gap: ($isMobileView ? '16px' : '24px'),
  justifyContent: 'space-between',
  alignItems: "center",
}));

const BackIconButton = styled(IconButton)(({ theme }) => ({
  padding: 0,
  "&.MuiIconButton-root": {
    '&:hover': {
      backgroundColor: 'transparent'
    },
    ">svg": {
      ">path": {
        stroke: `${theme.palette.gray}`,
      },
    },
    ">svg:hover": {
      ">path": {
        stroke: `${theme.palette.black}`
      }
    },
  }
}));

const TitleWrapper = styled(Box)({
  justifyContent: "space-between",
  marginBottom: "10px",
});

const OrderSummaryContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== "$isMobileView"
})(({ $isMobileView , theme: { palette, customShadows } }) => ({
  flex: "1",
  width: ($isMobileView ? "100%" : "fit-content"),
  justifyContent: ($isMobileView ? "center" : "flex-start"),
  background: palette.white,
  borderRadius: '20px',
  paddingBottom: 4,
  boxShadow: customShadows.component,
  border: `1px solid ${palette.lightGreyOutline}`,
  padding: "8px 20px",
  display: 'flex',
  gap: ($isMobileView? 8: 10),
  alignItems: 'center',
  overflow: 'hidden',
  marginTop: ($isMobileView? '16px': 10),
}));

const OrderSummarySubContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== "$isMobileView"
})(({ $isMobileView }) => ({
  display: "flex",
  alignItems: "center",
  gap: ($isMobileView? 8: 12)
}));

const OrderSummaryTitle = styled(Typography)(() => ({
  display: 'block',
  maxHeight:"16px",
  overflow: "hidden",
  whiteSpace: 'nowrap',
  textOverflow: "ellipsis"
}));

const SortButton = styled(IconButton)(({ theme: { palette } }) => ({
  padding: 0,
  alignContent: 'center',
  alignItems: 'center',
  background: palette.white,
  border: `1px solid ${palette.lightGreyOutline}`,
  borderRadius: 8,
  boxShadow: "0px 0px 12px 0px rgba(42, 39, 43, 0.04)",
  "&.MuiIconButton-root": {
    width: "32px",
    height: "32px",
  }
}));

const SortingListItem = styled(ListItem)(({ theme: { palette } }) => ({
  borderBottom: `1px solid ${palette.lightGreyOutline}`
}))

const TableContainer = styled(MuiTableContainer)(({ theme: { palette, customShadows } }) => ({
  width: "100%",
  backgroundColor: palette.white,
  borderRadius: '8px',
  border: `1px solid ${palette.lightGreyOutline}`,
  boxShadow: customShadows.component,
}));

const Table = styled(MuiTable)(({ theme: { palette } }) => ({
  width: "100%",
  maxHeight: "100%",
  "& .MuiTableRow-root td": {
    borderRight: `1px solid ${palette.lightGreyOutline}`,
    borderBottom: `1px solid ${palette.lightGreyOutline}`,
  },
  "& .MuiTableRow-root": {
    "&.Mui-selected, .Mui-selected:hover": {
      backgroundColor: 'transparent',
      border: `2px solid ${palette.black}`,
    }
  },
  "& .MuiTableBody-root": {
    "& .MuiTableRow-root": {
      "&:hover": {
        boxShadow: palette.header.headerMenu.boxShadow,
        backgroundColor: 'white !important',
        border: `1px solid ${palette.lightGreyOutline}`
      }
    }
  },
  paddingBottom: 4,
  backgroundColor: palette.white
}));

const HeaderCell = styled(MuiTableCell)(({ theme: { palette } }) => ({
  color: palette.grey.main,
  alignItems: "center",
  justifyContent: "center",
  textAlign: "center",
  borderRight: `1px solid ${palette.lightGreyOutline}`,
  padding: 12,
  backgroundColor: palette.background.light
}));

const TableHeaderText = styled(Typography)(({ theme: { palette, typography } }) => ({
  color: palette.grey.main,
  ...(typography.boldText),
}));

const TableCell = styled(MuiTableCell) ({
  width: "184px",
  height: "60px",
  padding: "5px 0px 5px 0px"
});

const OrderSummaryDot = styled(CircleIcon)(({ theme: { palette } }) => ({
  color: palette.grey.main,
  fontSize: "8px",
  margin: "0px 5px",
}));

const ListContainer = styled(Box)({
  width: "100%",
});

const ProgressLine = styled(LinearProgress)(({ theme, value }) => ({
  height: 5,
  borderRadius: 5,
  width: 20,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.progressLineBar[value <= 30 ? "green" : value <= 60 ? "orange" : "red"],
  },
}));

const ActionButtonsContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== "$isMobileView",
})(({ $isMobileView, $isEditMode }) => ({
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: ($isMobileView? "center": $isEditMode? 'space-between': 'flex-end')
}));

const Styled = {
  Container,
  HeaderWrapper,
  BackIconButton,
  TitleWrapper,
  OrderSummaryContainer,
  OrderSummarySubContainer,
  OrderSummaryTitle,
  SortButton,
  SortingListItem,
  TableContainer,
  Table,
  HeaderCell,
  TableHeaderText,
  TableCell,
  OrderSummaryDot,
  ListContainer,
  ProgressLine,
  ActionButtonsContainer,
};

export default Styled;
