import { Button as MuiButton, CircularProgress as MuiCircularProgress, styled } from "@mui/material";

const Button = styled(MuiButton,  {
  shouldForwardProp: (prop) => prop !== "$color" && prop !== '$size',
})(({ theme: { palette, direction }, $color, $size: {  width, height } }) => ({
  backgroundColor: ($color === 'primary'? palette.blue: palette.white),
  borderRadius: '20px',
  color: ($color === 'primary'? palette.white: palette.blue),
  ...($color === 'secondary' && { border: `1px solid ${palette.lightGreyOutline}` }),
  height,
  width,
  textTransform: 'none',
  ":hover" : {
    backgroundColor: ($color === 'primary'? palette.buttonHover : palette.background.light),
  },
  "&:disabled": {
    opacity: $color === 'primary'? 0.4: 0.6,
    backgroundColor: ($color === 'primary'? palette.blue: palette.white),
    color: ($color === 'primary'? palette.white: palette.blue),
  },
  padding: '7px 16px',
  ".MuiButton-endIcon": {
    ...(direction === 'rtl'? { marginRight: 6 } : { marginLeft: 6 })
  },
  ".css-1mouu0g-MuiCircularProgress-root": {
    color: palette.white
  }
}));

const CircularProgress = styled(MuiCircularProgress,  {
  shouldForwardProp: (prop) => prop !== "$color",
})(({ theme: { palette }, $color}) => ({
  color: ($color === 'primary'? palette.white: palette.blue),
}));

const Styled = { Button, CircularProgress }

export default Styled