import React, { useState, Fragment, useEffect, useMemo } from 'react';
import { Grid, Typography, useTheme, Box, FormControl, Skeleton, } from '@mui/material';
import toast from "react-hot-toast"
import { useNavigate } from "react-router-dom";

import {  expandOrderData, getCustomerDetailsFormFields, getEditedOrderData, getOrderDetailsFormFields, optionalFields } from "./create-order.utils";
import {  fetchLocations, fetchOrdersTypes, fetchPaymentMethods, fetchPackageTypes, fetchEditedOrderDetails, editOrder, validateArea, fetchCustomAreas } from "./create-order.slice";
import { fetchAreas } from '../../utils/common.slice';
import { CustomerIcon, CourierIcon, LeftArrowIcon } from "../../assets/icons";
import { Select } from '../../common/components/form-field/components/select/select';
import { useDispatch, useSelector } from "react-redux";
import { ORDERS_IDS, deliveryAmountFieldDetails, returnAmountFieldDetails } from './create-order.constants'
import FormField from '../../common/components/form-field';
import Styled from './create-order.styled';
import useViewport from '../../utils/use-viewport';
import { SelectCourier } from '../select-courier';
import { createNewOrder, fetchCouriers } from '../select-courier/select-courier.slice';
import AddPickupLocationDrawer from './add-pickup-location-drawer';
import useCreateOrderForm from './use-create-order-form';
import { selectCurrentOrderId, setInitActiveTab } from '../orders/orders.slice';
import { setSelectedIndex } from '../navbar/navbar.slice';
// import { selectAuthData } from '../../utils/common.slice'
import Button from '../../common/components/button';
import { useTranslation } from 'react-i18next';
import { onClickingPackagingAndInsurance } from '../../global.utils';

export function CreateOrder() {
  const { isMobileView } = useViewport()
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const editedOrderId = useSelector(selectCurrentOrderId);
  // const authData = useSelector(selectAuthData)

  // const isCitiesOnlyMerchant = useMemo(() => authData.customizationType === 'cities_only', [authData])

  //TODO: commented as it will be needed later
  // const isCustomMerchant = useMemo(() => authData.customizationType === 'custom', [authData])

  // const isSpecialMerchant = useMemo(() => isCitiesOnlyMerchant || isCustomMerchant, [authData])

  const isCustomMerchant = false;
  const isSpecialMerchant = false;

  const { t } = useTranslation();

  const {
    errors, setValue, form, reset, handleSubmit, register,
    unregister, dirtyFields, isDirty, defaultValues,
    registerCommonOrderDetailsField, registerDeliveryOrderDetailsFields,
  } = useCreateOrderForm({ t })

  const [isSettingsLoading, setIsSettingsLoading] = useState(false)
  const [isOrderDetailsLoading, setIsOrderDetailsLoading] = useState(false)
  const [amountFieldDetails, setAmountFieldDetails] = useState(deliveryAmountFieldDetails)
  const [isOptionsFieldsVisible, setIsOptionsFieldsVisible] = useState(false);
  const [disabledFieldsIds, setDisabledFieldsIds] = useState([])
  const [hiddenFieldsIs, setHiddenFieldsIds] = useState([])
  const [isSelectingCourier, setIsSelectingCourier] = useState(false)
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const [isSelectCourierBtnLoading, setIsSelectCourierBtnLoading] = useState(false)
  const [couriers, setCouriers] = useState([])
  const [orderData, setOrderData] = useState({})
  const [dropDownsOptions, setDropDownsOptions] = useState({})
  const [orderCode, setOrderCode] = useState(null)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [allAreas, setAllAreas] = useState([])

  const isFormLoading = useMemo(() => isSettingsLoading || isOrderDetailsLoading, [isSettingsLoading, isOrderDetailsLoading])

  const orderDetailsFormFields = useMemo(() => [
    ...getOrderDetailsFormFields({
      openDrawer: () => togglePickupLocationDrawer(true),
      amountFieldDetails
    })], [amountFieldDetails])

  const togglePickupLocationDrawer = (open) => { setIsDrawerVisible(open); };

  const onSelectCourier = (data) => {
    const adaptedData = expandOrderData(data);
    const { amount = 0, area, packageType = '', orderType, city } = adaptedData
    setIsSelectCourierBtnLoading(true)
    dispatch(fetchCouriers({ amount, zone: area, city, orderType, packageType })).then((res) => {
      if(res?.payload?.length === 0 ) toast.error(t('listing.errorMsgs.noCouriers'))
      setIsSelectingCourier(true);
      setIsSelectCourierBtnLoading(false);
      setCouriers(res.payload);
    })
    setOrderData(adaptedData)
  }

  const onError = () => { setIsSubmitting(false); }

  const fetchAndUpdatePaymentMethods = (value, skipUpdatingType) => {
    dispatch(fetchPaymentMethods({ orderType: value })).then((res) =>
    {
      const paymentMethods = res.payload?.data
      setDropDownsOptions((prevDropDownOptions) => ({...prevDropDownOptions, paymentMethods }))
      if(!skipUpdatingType) setValue('orderDetails.paymentType', paymentMethods[0])
    });
  }

  const setOrderType = (type) => {
    const { value } = type
    unregister('orderDetails', { keepDefaultValue: true }) //unregister all order details
    registerCommonOrderDetailsField(); //register default values
    setValue('orderDetails.type', type)

    if(dropDownsOptions?.pickupLocations[0]) {
      setValue('orderDetails.pickupLocation', dropDownsOptions.pickupLocations[0])
    }

    fetchAndUpdatePaymentMethods(value);

    if (value === ORDERS_IDS.RETURN) {
      unregister('orderDetails.collectionAmount', { keepDefaultValue: true })
      setHiddenFieldsIds(['openPackageAllowed'])
      setDisabledFieldsIds(['packageType'])
      setAmountValue('orderDetails.paymentType', {value: 'with_cash_refund'})
      setAmountFieldDetails(returnAmountFieldDetails)
      return
    }

    if(value === ORDERS_IDS.EXCHANGE) {
      setDisabledFieldsIds(['packageType'])
      setHiddenFieldsIds(['openPackageAllowed'])
      setAmountValue('orderDetails.paymentType', {value: 'with_cash_collection'}, { shouldDirty: true }, value)
      return
    }

    setDisabledFieldsIds([])
    setHiddenFieldsIds([])
    setAmountFieldDetails(deliveryAmountFieldDetails)
    registerDeliveryOrderDetailsFields()
  }

  const setAmountValue = (name, field, config, orderType) => {
    setValue(name, field, config)

    //refund case in exchange/return
    if(field.value === 'with_cash_refund') {
      unregister(`orderDetails.collectionAmount`, { keepDefaultValue: true })
      setAmountFieldDetails({ name: 'refundAmount' })
      register('orderDetails.refundAmount', { required: true, validate: (value) => Boolean(value?.toString()?.trim()) })
      setDisabledFieldsIds((prevIds) => prevIds.filter((id) => id !== 'refundAmount'))
      return
    }

    const currentOrderType = orderType || form.orderDetails.type.value;

    if(['without_cash_difference', 'without_cash_collection'].includes(field.value) || currentOrderType === ORDERS_IDS.RETURN) {
      //disable collection amount or refundAmount
      setDisabledFieldsIds((prevIds) => [...prevIds, 'collectionAmount', 'refundAmount']);
      unregister(`orderDetails.collectionAmount`, { keepDefaultValue: true })
      unregister(`orderDetails.refundAmount`, { keepDefaultValue: true })
    } else {
      //enable collection amount
      unregister(`orderDetails.refundAmount`, { keepDefaultValue: true })
      register('orderDetails.collectionAmount', { required: true, validate: (value) => Boolean(value?.toString().trim()) })
      setAmountFieldDetails({ name: 'collectionAmount' })
      setDisabledFieldsIds((prevIds) => prevIds.filter((id) => id !== 'collectionAmount'))
    }

  }

  const handleEditOrder = (courierId) => {
    return editOrder({ id: editedOrderId, orderDetails: getEditedOrderData(dirtyFields, form), courierId, orderType: form.orderDetails.type.value})
  }

  const validateAreaField = () => dispatch(validateArea({
    addressLine: form.customerDetails.address,
    area: form.customerDetails.area.value || form.customerDetails.area.group,
    city: form.customerDetails.area.group || form.customerDetails.area.value
  })).then((res) => {
    const areaData = res?.payload
    if (areaData?.area && areaData?.city) {
      toast(t('createOrder.successMsgs.areaValidateSuccess'))
      setValue('customerDetails.area', {group: areaData.city, label: areaData.area, value: areaData.area})
      setValue('isOrderValidated', true)
      if(isCustomMerchant) {
        setDropDownsOptions((prevOptions) => ({ ...prevOptions, areas: allAreas }))
      }
    } else {
      toast.error(t('createOrder.errorMsgs.areaValidateFailure'))
    }
  });

  const primarySubmitFunction = (data) => {
    if(isSpecialMerchant && !form.isOrderValidated) {
      return validateAreaField()
    }

    return onSelectCourier(data)
  }

  //secondary -> instaship or edit order
  const handleSecondarySubmit = () => {
    setIsSubmitting(true)
    const submitFn = (data) => {
      if (isSpecialMerchant && !form.isOrderValidated) return validateAreaField
      if(editedOrderId) return handleEditOrder()
      return createNewOrder({...expandOrderData(data), instaShip: true})
    }

    handleSubmit((data) => {dispatch(submitFn(data)).then((res) => {
      if(res?.payload?.status === 'success'){
        navigate("/orders")
        dispatch(setInitActiveTab('pending'))
      }
      setIsSubmitting(false)
    })}, onError)()
  }

  useEffect(()=> {
    // Navbar orders focus
    dispatch(setSelectedIndex(null))
  }, []);

  useEffect(() => {
    let promises = [
      dispatch(isCustomMerchant && !form.isOrderValidated ? fetchCustomAreas() : fetchAreas()),
      dispatch(fetchLocations()),
      dispatch(fetchOrdersTypes()),
      dispatch(fetchPackageTypes()),
      dispatch(fetchPaymentMethods({ orderType: 'delivery' }))
    ]

    if(isCustomMerchant) { dispatch(fetchAreas()).then((res) => setAllAreas(res.payload.data)) }

    setIsSettingsLoading(true)

    Promise.all(promises).then((res) => {
      let options = {}
      res.forEach((res) => {options = {...options, [res.payload?.id]: res.payload?.data }})
      setDropDownsOptions(options)
      if(Object.keys(options).length > 0 && !editedOrderId) {
        if(options?.pickupLocations[0]) {
          setValue('orderDetails.pickupLocation', options.pickupLocations[0])
        }

        setValue('orderDetails.paymentType', options.paymentMethods[0])
        setValue('orderDetails.packageType', options.packageTypes[0])
      }
      setIsSettingsLoading(false)
    })
  }, [editedOrderId, isCustomMerchant]);

  useEffect(() => {
    if(editedOrderId) {
      setIsOrderDetailsLoading(true);
      dispatch(fetchEditedOrderDetails({id: editedOrderId}))
        .then((data) => {
          if(!data.payload.code) {
            toast.error('Failed to get order details');
            navigate('/orders');
            return
          }
          reset(data.payload?.details, { keepDirtyValues: true });
          setOrderCode(data.payload.code);
          const orderType = data?.payload?.details?.orderDetails?.type?.value
          fetchAndUpdatePaymentMethods(orderType, true);
          if([ORDERS_IDS.EXCHANGE, ORDERS_IDS.RETURN].includes(orderType)){
            setDisabledFieldsIds((prevIds) => [...new Set([...prevIds, 'packageType'])])
            setHiddenFieldsIds((prevIds) => [...new Set([...prevIds, 'openPackageAllowed'])])
          }
          setAmountValue(
            'orderDetails.paymentType',
            {
              value: data?.payload?.details?.orderDetails?.paymentType?.value?.toLowerCase()?.replaceAll(' ', '_'),
              label: data?.payload?.details?.orderDetails?.paymentType?.label
            },
            {
              shouldDirty: false
            },
            orderType
          )
        })
        .finally(() => setIsOrderDetailsLoading(false))
    } else {
      reset(defaultValues, { keepDirtyValues: true })
      setDisabledFieldsIds([])
      setHiddenFieldsIds([])
    }
    //TODO remove editedOrderId from here and refactor this -> reason when create order with pre edited order, old values applied
  }, [editedOrderId])

  if(isSelectingCourier && couriers?.length > 0) return (
    <SelectCourier
      couriers={couriers}
      orderData={orderData}
      isEditMode={Boolean(editedOrderId)}
      handleEditOrder={handleEditOrder}
      isCreateOrderView={true}
      showBackButton={true}
      pageTitle={t('selectCourier.title')}
      actionButtonTitle={editedOrderId? t('createOrder.updateOrderButton') : t('createOrder.createButton')}
      setIsSelectingCourier={setIsSelectingCourier}
    />
  )

  const renderFormTitle = () => {
    if(isFormLoading) return <Skeleton height="40px" width="300px" />

    return (
      <Box marginBottom={isMobileView? '16px' : '24px'} display="flex" alignItems="center" height='fit-content'>
        {editedOrderId && !isMobileView &&
        (
          <Styled.BackIconButton onClick={() => navigate(-1)} sx={{ margin: '0 20px 0 -40px' }}>
            <LeftArrowIcon />
          </Styled.BackIconButton>
        )
        }
        <Typography variant="bold" color={theme.palette.text.primary}>
          {editedOrderId? orderCode : t('createOrder.title')}
        </Typography>
      </Box>
    )
  }

  const renderCardTitle = (Icon, title) => {
    if(isFormLoading) return <Skeleton height="25px" width="150px" />

    return (
      <Styled.CardTitleWrapper>
        <Icon /> <Typography variant="boldSubtitles" color={theme.palette.text.primary}>{title}</Typography>
      </Styled.CardTitleWrapper>
    )
  }

  return (
    <>
      <AddPickupLocationDrawer
        areas={dropDownsOptions?.areas}
        dropDownsOptions={dropDownsOptions}
        isDrawerVisible={isDrawerVisible}
        isMobileView={isMobileView}
        theme={theme}
        t={t}
        toggleDrawer={togglePickupLocationDrawer}
        setDropDownsOptions={setDropDownsOptions}
        setFormValue={setValue}
      />
      <Styled.CreateOrderForm isMobileView={isMobileView} onSubmit={handleSubmit(primarySubmitFunction, onError)}>
        {renderFormTitle()}
        <Styled.FormWrapper isMobileView={isMobileView}>
          <Box>
            <Styled.CardHeaderWrapper isMobileView={isMobileView}>
              {renderCardTitle(CustomerIcon, t('createOrder.customerDetailsFormTitle'))}

              <Box gap="8px" display={isMobileView ? 'none' : 'flex'} alignItems="center">
                <Typography variant="semiBoldSmall" color={theme.palette.gray}>
                  {isOptionsFieldsVisible ? t('createOrder.hideOptionalInfoSwitcherLabel') : t('createOrder.viewOptionInfoSwitcherLabel')}
                </Typography>
                <Styled.Switch
                  onChange={(event) => setIsOptionsFieldsVisible(event.target.checked)}
                  disableRipple
                  defaultChecked={isOptionsFieldsVisible}
                  isActive={isOptionsFieldsVisible}
                />
              </Box>
            </Styled.CardHeaderWrapper>

            <Styled.Card>
              <Styled.FormSection isMobileView={isMobileView}>
                <Grid container spacing={2}>
                  {getCustomerDetailsFormFields().map(({ name, ...fieldProps }) => (
                    <FormField
                      key={name}
                      fieldKey={name}
                      isSpecialMerchant={isSpecialMerchant}
                      errorType={errors?.customerDetails?.[name]?.type}
                      props={fieldProps}
                      value={form.customerDetails[name]}
                      isOrderValidated={form.isOrderValidated}
                      validateAreaField={validateAreaField}
                      isAreaRefreshButtonDisabled={isSpecialMerchant && !(form.customerDetails.area && form.customerDetails.address)}
                      // hideRefreshIcon={isCustomMerchant && form.isOrderValidated}
                      setValue={setValue}
                      name={`customerDetails.${name}`}
                      translationKey='createOrder.customerDetailsFormFields'
                      dropDownsOptions={dropDownsOptions}
                      isLoading={isFormLoading}
                      isMobileView={isMobileView}
                      t={t}
                    />
                  ))}
                </Grid>
              </Styled.FormSection>
              {isOptionsFieldsVisible && (
                <Fragment>
                  <Styled.Divider isMobileView={isMobileView} />
                  <Styled.FormSection isMobileView={isMobileView} paddingTop={20}>
                    <Grid container spacing={2}>
                      {optionalFields.map(({ name, ...fieldProps }) => (
                        <FormField
                          key={name}
                          fieldKey={name}
                          errorType={errors?.optionalInfo?.[name]?.type}
                          props={fieldProps}
                          value={form.optionalInfo[name]}
                          setValue={setValue}
                          name={`optionalInfo.${name}`}
                          translationKey='createOrder.customerDetailsFormFields'
                          t={t}
                          isLoading={isFormLoading}
                        />
                      ))}
                    </Grid>
                  </Styled.FormSection>
                </Fragment>
              )}
              {isMobileView &&
                <Box display="flex" justifyContent='center' paddingBottom="16px">
                  <Styled.ToggleOptionalInfo
                    variant='semiBoldSmallUnderline'
                    onClick={() => setIsOptionsFieldsVisible(!isOptionsFieldsVisible)}
                    color={theme.palette.text.light}
                  >
                    {isOptionsFieldsVisible ? t('createOrder.hideOptionalInfoSwitcherLabel') : t('createOrder.viewOptionInfoSwitcherLabel')}
                  </Styled.ToggleOptionalInfo>
                </Box>
              }
            </Styled.Card>
          </Box>

          <Box>
            <Styled.CardHeaderWrapper isMobileView={isMobileView}>
              <Box display='flex' alignItems='center'>
                <FormControl variant="standard">
                  <Box display="flex" alignItems="center">
                    {editedOrderId? renderCardTitle(CourierIcon, form.orderDetails.type.value) : (
                      <Box display="flex" gap="8px" alignItems="center">
                        {isFormLoading? <Skeleton height="25px" width="150px" /> :
                          <>
                            <CourierIcon color='#2A272B' />
                            <Select
                              isSearchable={false}
                              options={dropDownsOptions?.orderTypes}
                              value={form.orderDetails.type}
                              onChange={setOrderType}
                              noBorders={true}
                              noLeftPadding
                              placeholderTypo='boldSubtitles'
                            />
                          </>
                        }
                      </Box>
                    )}
                  </Box>
                </FormControl>
              </Box>
              {form.orderDetails.type?.value === 'delivery' && (
                <Styled.PackingGuideLines
                  onClick={onClickingPackagingAndInsurance}
                >
                  {t('createOrder.packagingGuidelinesLink')}
                </Styled.PackingGuideLines>
              )}
            </Styled.CardHeaderWrapper>
            <Styled.Card>
              <Styled.FormSection isMobileView={isMobileView}>
                <Grid container spacing={2}>
                  {orderDetailsFormFields.map(({ name, ...fieldProps }) => (
                    <FormField
                      t={t}
                      key={name}
                      fieldKey={name}
                      translationKey='createOrder.orderDetailsFormFields'
                      errorType={errors?.orderDetails?.[name]?.type}
                      props={fieldProps}
                      value={form.orderDetails[name]}
                      setValue={name=== 'paymentType' ? setAmountValue : setValue}
                      name={`orderDetails.${name}`}
                      isDisabled={disabledFieldsIds.includes(name)}
                      dropDownsOptions={dropDownsOptions}
                      isLoading={isFormLoading}
                      isHidden={hiddenFieldsIs.includes(name)}
                    />
                  ))}
                </Grid>
              </Styled.FormSection>
            </Styled.Card>
          </Box>
        </Styled.FormWrapper>

        <Box width="100%" display="flex" justifyContent="flex-end">
          <Box display="flex" flexDirection={isMobileView? 'column' : 'row'} gap="16px" marginTop={isMobileView? "20px" : "24px"} width={isMobileView? '100%' : 'fit-content'}>
            <Button
              disableRipple
              isDisabled={isSelectCourierBtnLoading || isSubmitting || !isDirty}
              color='secondary'
              type='submit'
              isLoading={isSelectCourierBtnLoading}
              size={{ width: isMobileView? '100%' : '141px', height: '36px' }}
              label={t('createOrder.selectCourierButton')}
            />
            <Button
              isDisabled={!isDirty || isSelectCourierBtnLoading || isSubmitting}
              disableRipple
              type='button'
              isLoading={isSubmitting}
              size={{ width: isMobileView? '100%' : `${editedOrderId? '150px': '104px'}`, height: '36px' }}
              onClick={handleSecondarySubmit}
              label={editedOrderId? t('createOrder.updateOrderButton') : t('createOrder.instaShipButton')}
            />
          </Box>
        </Box>
      </Styled.CreateOrderForm >
    </>
  );
}