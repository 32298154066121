import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  expandOrderData,
  adaptOrderDataToBackendFormat,
  getSortedLocations,
} from "./create-order.utils";
import toEnglishNumber from "../../utils/toEnglishNumber";

//SLICE NAMES CANNOT BE DUPLICATES!
const sliceName = "createOrder";

export const editOrder = createAsyncThunk(
  `${sliceName}/editOrder`,
  async (data, thunkAPI) => {
    const { orderDetails, courierId, orderType } = data || {};
    //TODO: translate
    const response = await thunkAPI.extra.apiClient.put({
      url: `orders/${data.id}`,
      data: {
        ...adaptOrderDataToBackendFormat({
          ...expandOrderData(orderDetails),
          orderType,
          hasOrderDetails: true,
        }),
        courierId,
      },
      errorMsg: "Failed to update order",
      successMsg: "Order updated successfully",
    });
    return response?.data;
  }
);

export const fetchLocations = createAsyncThunk(
  `${sliceName}/fetchLocations`,
  async (data, thunkAPI) => {
    const response = await thunkAPI.extra.apiClient.get({ url: "locations" });
    return {
      id: "pickupLocations",
      data: getSortedLocations(response.data.body).map(({ _id, name, area }) => ({
        label: name,
        value: _id,
        area: area?.split(' - ')?.[0] || ''
      })),
      locations: response.data.body,
    };
  }
);

export const fetchOrdersTypes = createAsyncThunk(
  `${sliceName}/fetchOrdersTypes`,
  async (data, thunkAPI) => {
    const response = await thunkAPI.extra.apiClient.get({
      url: "config/order_types",
    });
    return {
      id: "orderTypes",
      data: response.data.body.orderTypes?.map(
        ({ value, label_en, label }) => ({ value, label: label_en || label })
      ),
    };
  }
);

export const fetchPaymentMethods = createAsyncThunk(
  `${sliceName}/fetchPaymentMethods`,
  async (data, thunkAPI) => {
    const response = await thunkAPI.extra.apiClient.get({
      url: "config/payment_methods/" + data.orderType,
    });
    return {
      id: "paymentMethods",
      data: response.data.body.paymentMethods?.map(
        ({ value, label_en, label }) => ({ value, label: label_en || label })
      ),
    };
  }
);

export const fetchPackageTypes = createAsyncThunk(
  `${sliceName}/fetchPackageTypes`,
  async (data, thunkAPI) => {
    const response = await thunkAPI.extra.apiClient.get({
      url: "config/package_types",
    });
    return {
      id: "packageTypes",
      data: response.data.body.packageTypes?.map(
        ({ value, label_en, label }) => ({ value, label: label_en || label })
      ),
    };
  }
);

export const fetchCustomAreas = createAsyncThunk(
  `${sliceName}/fetchCustomAreas`,
  async (data, thunkAPI) => {
    const response = await thunkAPI.extra.apiClient.get({ url: "custom-areas" });

    return {
      id: "areas",
      data: response.data.body.map((record) => {
        return {
          name: record.name,
          name_ar: record.name_ar,
        };
      }),
    };
  }
);

export const validateArea = createAsyncThunk(
  `${sliceName}/validateArea`,
  async ({addressLine, city, area}, thunkAPI) => {
    const response = await thunkAPI.extra.apiClient.post({
      url: "orders/validate/address",
      data: {
        addressLine,
        area,
        city
      }
    });
    return response.data.body;
  }
);

export const addPickupLocation = createAsyncThunk(
  `${sliceName}/addPickupLocation`,
  async (data, thunkAPI) => {
    //TODO: translate
    const response = await thunkAPI.extra.apiClient.post({
      url: "locations",
      successMsg: "Location added successfully.",
      errorMsg: "Failed to add location.",
      data: {
        name: data.locationName,
        city: data.pickupLocationArea?.group,
        area: data?.pickupLocationArea?.value,
        address_line: data.addressLine,
        is_default: data.setAsDefault ? true : false,
        contacts: {
          name: data.contactName,
          phone: toEnglishNumber(data.contactPhone),
          is_default: data.setAsDefault ? true : false,
        },
      },
    });
    return response;
  }
);

export const fetchEditedOrderDetails = createAsyncThunk(
  `${sliceName}/fetchEditedOrderDetails`,
  async (data, thunkAPI) => {
    const response = await thunkAPI.extra.apiClient.get({
      url: `orders/${data.id}/details`,
    });

    const {
      customerDetails = {},
      orderDetails = {},
      orderNumber,
    } = response?.data?.body?.order || {};
    const area = customerDetails.address?.area;

    const getValue = (param) => {
      if (typeof param === "object") {
        return param?.value;
      }

      return param;
    };

    const getLabel = (param) => {
      if (typeof param === "object") {
        return param?.en;
      }

      return param;
    };

    const details = {
      customerDetails: {
        name: customerDetails.name,
        phone: customerDetails.phone,
        area: {
          label: `${area}`,
          value: `${area}`,
          group: customerDetails.address?.city,
        },
        address: customerDetails.address?.addressLine,
      },
      optionalInfo: {
        shippingNotes: customerDetails.shippingNotes || "",
        backupPhone: customerDetails.backupPhone || "",
        referenceNumber: customerDetails.referenceNumber || "",
      },
      orderDetails: {
        type: { value: orderDetails.type, label: orderDetails.type },
        pickupLocation: {
          value: orderDetails.location?._id,
          label: orderDetails.location?.name,
        },
        paymentType: {
          value: getValue(orderDetails.paymentType),
          label: getLabel(orderDetails.paymentType),
        },
        collectionAmount: orderDetails.amount,
        refundAmount: orderDetails.amount,
        packageType: {
          value: getValue(orderDetails.packageType),
          label: getLabel(orderDetails.packageType),
        },
        noOfItems: orderDetails.noOfItems,
        description: orderDetails.description,
        openPackageAllowed: orderDetails.openPackageAllowed || false,
      },
    };

    return { details, code: orderNumber } || {};
  }
);

export const createOrderSlice = createSlice({
  name: sliceName,
  initialState: {
    orderData: {},
  },
  reducers: {},
});

export default createOrderSlice.reducer;
