import {
  CourierIcon,
  DeleteIcon,
  EditIcon,
  ExportIcon,
  PrintLabelIcon,
  PrintManifestoIcon,
} from "../../assets/icons";
import { getStatusTabDetails } from "./orders-table.utils";

const rows = [
  {
    id: "6464c57accd54e3dfeb50ce8",
    type: "delivery",
    code: "CSE-DR-0000178",
    customerName: "joud imam",
    cashCollection: true,
    amount: 285,
    externalId: "32483095",
    courierLogo: "images/bosta.png",
    courierName: "Bosta",
    fees: 41,
    rtoFees: 41,
    status: "pending",
    subStatus: "New",
    rejectionReason: "",
    createdAt: 1684325754172,
    endDate: "",
    processedOn: "",
    supportNote: "",
    city: "القاهرة",
    area: "",
    attempts: "",
    needDataUpdate: false,
    needReprintLabel: false,
  },
  {
    id: "65464b1540f56ef1ae9ce0b5",
    type: "delivery",
    code: "TAC-DR-0004854",
    customerName: "Omar el Nasharty",
    cashCollection: true,
    amount: 300,
    externalId: "JEG000123007548",
    courierLogo: "images/jt.png",
    courierName: "J&T",
    fees: 41,
    rtoFees: 41,
    status: "unsuccessful",
    subStatus: "Delivery failed & returned to merchant",
    rejectionReason: "",
    createdAt: 1685372882271,
    endDate: 1701509294684,
    processedOn: 1701509277805,
    supportNote: "",
    city: "القاهرة",
    area: "",
    attempts: "",
    needDataUpdate: false,
    needReprintLabel: false,
  },
  {
    id: "6474ba482d425bddade40be6",
    type: "delivery",
    code: "CSE-DR-0000191",
    customerName: "amina shawky",
    cashCollection: true,
    amount: 550,
    externalId: "72328656",
    courierLogo: "images/bosta.png",
    courierName: "Bosta",
    fees: 41,
    rtoFees: 41,
    status: "unsuccessful",
    subStatus: "Delivery Failed & Returned To Merchant",
    rejectionReason: "",
    createdAt: 1685371464350,
    endDate: "",
    processedOn: "",
    supportNote: "",
    city: "القاهرة",
    area: "",
    attempts: "",
    needDataUpdate: false,
    needReprintLabel: false,
  },
  {
    id: "6474ba6f2d425bddade40e1c",
    type: "delivery",
    code: "CSE-DR-0000192",
    customerName: "nardine azmy",
    cashCollection: true,
    amount: 550,
    externalId: "35290670",
    courierLogo: "images/bosta.png",
    courierName: "Bosta",
    fees: 41,
    rtoFees: 41,
    status: "processing",
    subStatus: "Out For Delivery",
    rejectionReason: "",
    createdAt: 1685371503366,
    endDate: "",
    processedOn: "",
    supportNote: "",
    city: "القاهرة",
    area: "",
    attempts: "",
    needDataUpdate: false,
    needReprintLabel: false,
  },
  {
    id: "6474bb462d425bddade41703",
    type: "delivery",
    code: "CSE-DR-0000196",
    customerName: "lujain",
    cashCollection: true,
    amount: 250,
    externalId: "83591683",
    courierLogo: "images/bosta.png",
    courierName: "Bosta",
    fees: 41,
    rtoFees: 41,
    status: "pending",
    subStatus: "New",
    rejectionReason: "",
    createdAt: 1685371718657,
    endDate: "",
    processedOn: "",
    supportNote: "",
    city: "القاهرة",
    area: "",
    attempts: "",
    needDataUpdate: false,
    needReprintLabel: false,
  },
  {
    id: "6474be9b2d425bddade428aa",
    type: "delivery",
    code: "CSE-DR-0000202",
    customerName: "omar samy",
    cashCollection: true,
    amount: 585,
    externalId: "53005781",
    courierLogo: "images/bosta.png",
    courierName: "Bosta",
    fees: 41,
    rtoFees: 41,
    status: "pending",
    subStatus: "New",
    rejectionReason: "",
    createdAt: 1685372571433,
    endDate: "",
    processedOn: "",
    supportNote: "",
    city: "القاهرة",
    area: "",
    attempts: "",
    needDataUpdate: false,
    needReprintLabel: false,
  },
  {
    id: "6474bfaf2d425bddade43062",
    type: "delivery",
    code: "CSE-DR-0000204",
    customerName: "farida elmasry",
    cashCollection: true,
    amount: 250,
    externalId: "72952207",
    courierLogo: "images/bosta.png",
    courierName: "Bosta",
    fees: 41,
    rtoFees: 41,
    status: "successful",
    subStatus: "New",
    rejectionReason: "",
    createdAt: 1685372847502,
    endDate: "",
    processedOn: "",
    supportNote: "",
    city: "القاهرة",
    area: "",
    attempts: "",
    needDataUpdate: false,
    needReprintLabel: false,
  },
  {
    id: "6474bfd22d425bddade43298",
    type: "delivery",
    code: "CSE-DR-0000205",
    customerName: "laila mohamed",
    cashCollection: true,
    amount: 300,
    externalId: "36136369",
    courierLogo: "images/bosta.png",
    courierName: "Bosta",
    fees: 41,
    rtoFees: 41,
    status: "unsuccessful",
    subStatus: "New",
    rejectionReason: "",
    createdAt: 1685372882271,
    endDate: "",
    processedOn: "",
    supportNote: "",
    city: "القاهرة",
    area: "",
    attempts: "",
    needDataUpdate: false,
    needReprintLabel: false,
  },
];

const headerCells = [
  {
    id: "code",
    width: 176,
  },
  {
    id: "details",
    width: 168,
  },
  {
    id: "courier",
    width: 132,
  },
  {
    id: "processed",
    width: 168,
  },
  {
    id: "status",
    width: 204,
  },
  {
    id: "attempts",
    width: 108,
  },
  {
    id: "actions",
    width: 132,
  },
];

const DRAWERS_IDS = {
  SEARCH: "search",
  FILTERS: "filters",
  DETAILS: "details",
  EDIT_COURIER: "edit courier",
  DELETE_ORDER: "delete order",
};

const STATUS_IDS = {
  ALL: "all",
  PENDING: "pending",
  SUCCESSFUL: "successful",
  UNSUCCESSFUL: "unsuccessful",
  PROCESSING: "processing",
};

const STATUS_COLORS = {
  [STATUS_IDS.PENDING]: "gray",
  [STATUS_IDS.SUCCESSFUL]: "limeGreen",
  [STATUS_IDS.UNSUCCESSFUL]: "red",
  [STATUS_IDS.PROCESSING]: "orange",
};

const statusTabsInfo = [
  //we still have them in filters
  { key: STATUS_IDS.PENDING, index: 1 },
  { key: STATUS_IDS.PROCESSING, index: 2 },
  { key: STATUS_IDS.SUCCESSFUL, index: 3 },
  { key: STATUS_IDS.UNSUCCESSFUL, index: 4 },
];

const statusesTabs = [
  {
    key: STATUS_IDS.ALL,
    noIcon: true,
    getStatusCount: (allOrders) => allOrders?.length || 0,
    index: 0,
  },
  ...statusTabsInfo.map(getStatusTabDetails),
];

const ACTIVITIES_IDS = {
  UPDATE: "needDataUpdate",
  REPRINT: "needReprintLabel",
  NOTE: "supportNote",
};

const postData = {
  filters: {
    city: "Cairo",
    awaitingAction: true,
    type: "Delivery", //order type
    dateRange: {
      createdOn: { from: -1, to: 1703608376000 },
      processedOn: { from: -1, to: 1703608376000 },
      closedOn: { from: -1, to: 1703608376000 },
    },
    subStatus: "new",
    status: "processing",
    code: "TAC-DR-0000020",
    ids: ["657794bdf781fb05f81cd408"],
    pickupLocation: "arrive 2",
    printLabel: true,
  },
  sortAttribute: "",
  sortDirection: "asc",
  page: 1,
  limit: 1,
};

const ORDERS_ACTIONS_IDS = {
  EDIT: "edit",
  PRINT_LABEL: "printLabel",
  EXPORT: "export",
  PRINT_MANIFESTO: "printManifesto",
  EDIT_COURIER: "editCourier",
  DELETE: "delete",
};

const actionsMenuItems = [
  { key: ORDERS_ACTIONS_IDS.EDIT, icon: EditIcon, hasBottomDivider: true },
  {
    key: ORDERS_ACTIONS_IDS.EDIT_COURIER,
    icon: CourierIcon,
    hasBottomDivider: true,
  },
  {
    key: ORDERS_ACTIONS_IDS.PRINT_LABEL,
    icon: PrintLabelIcon,
    hasBottomDivider: true,
  },
  { key: ORDERS_ACTIONS_IDS.DELETE, icon: DeleteIcon },
];

const actionBarButtonsProps = [
  { key: ORDERS_ACTIONS_IDS.PRINT_LABEL, icon: PrintLabelIcon },
  { key: ORDERS_ACTIONS_IDS.PRINT_MANIFESTO, icon: PrintManifestoIcon },
  { key: ORDERS_ACTIONS_IDS.EDIT_COURIER, icon: CourierIcon },
  { key: ORDERS_ACTIONS_IDS.EXPORT, icon: ExportIcon },
  { key: ORDERS_ACTIONS_IDS.DELETE, icon: DeleteIcon },
];

const MISSING_AREA_SUBSTATUS = "missing area";
const NEW_STATUS = "new";

export {
  rows,
  headerCells,
  STATUS_COLORS,
  statusTabsInfo,
  postData,
  statusesTabs,
  STATUS_IDS,
  ACTIVITIES_IDS,
  DRAWERS_IDS,
  ORDERS_ACTIONS_IDS,
  actionsMenuItems,
  actionBarButtonsProps,
  MISSING_AREA_SUBSTATUS,
  NEW_STATUS,
};
