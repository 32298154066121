import React from "react";
import { Box, IconButton, Tooltip, Typography, useTheme } from "@mui/material";

import Styled from "../order-details.styled";
import DateTimeLabel from "../../../common/components/date-time-label";
import { BILLING_STATUSES_LABELS } from "../order-details.constants";
import { AmountFilledIcon, CardIcon, CrossIcon } from "../../../assets/icons";
import { BILLING_STATUSES_IDS } from "../order-details.constants";
import RenderCourierLogo from "../../../common/components/courier-logo";
import { getStatusColor } from "../order-details.utils";
import { useTranslation } from "react-i18next";
import { BlackTooltip } from "../../../common/components/black-tooltip";

const Header = ({ order, onCloseOrderDetails }) => {

  const theme = useTheme();
  const { t } = useTranslation();
  const headerI18nKey = 'listing.orderDetails.header';

  const {
    orderNumber,
    status,
    subStatus,
    shippingAge,
    courier: {
      logo: courierLogo
    },
    creationDate,
    rejectionReason,
    orderBillingStatus: {
      merchant: billingStatus
    }} = order;

  const renderCloseIcon = () => {
    return (
      <Styled.CloseIconButton
        disableRipple
        onClick={onCloseOrderDetails}>
        <CrossIcon color={theme.palette.gray} />
      </Styled.CloseIconButton>
    );
  }

  const renderShippingSection = () => {
    return (
      <Box display="flex" justifyContent="space-between" alignItems="center" maxHeight="24px">
        <Box display="flex" gap="2px">
          <Typography variant="semiBoldSmall" color={theme.palette.gray}>{t(`${headerI18nKey}.shippingAgeLabel`)}:</Typography>
          <Typography variant="semiBoldSmall" color={shippingAge > 10 ? theme.palette.red : theme.palette.gray}>{shippingAge}/10</Typography>
          <Typography variant="semiBoldSmall" color={theme.palette.gray}>{t(`${headerI18nKey}.daysLabel`)}</Typography>
        </Box>
        {RenderCourierLogo({logo: courierLogo})}
      </Box>
    );
  }

  const renderStatus = () => {
    const statusColor = getStatusColor(status)
    return (
      <Box display="flex" gap="4px" alignItems="center">
        <Styled.Dot $color={theme.palette[statusColor]}/>
        <Typography variant="semiBoldSmall" textTransform="capitalize" color={theme.palette.gray}>{subStatus}</Typography>
        {rejectionReason? <Typography variant="semiBoldSmall" textTransform="capitalize" color={theme.palette.gray}> - {rejectionReason}</Typography>: null}
      </Box>
    );
  }

  const renderOrderBillingStatusIcon = () => {
    //TODO: translate status
    return (
      <BlackTooltip
        title={`${t(`${headerI18nKey}.orderIsLabel`)} ${BILLING_STATUSES_LABELS[billingStatus]}`}
        sx={{ cursor: 'pointer' }}
      >
        <Styled.OrderBillingStatusContainer display="flex" alignItems="center" justifyContent="center" borderRadius="50%" >
          {billingStatus === BILLING_STATUSES_IDS.COMPLETE? (
            <CardIcon color={theme.palette.limeGreen} />
          ) : (
            <AmountFilledIcon color={theme.palette.limeGreen} />
          )}
        </Styled.OrderBillingStatusContainer>
      </BlackTooltip>
    );
  }

  const renderTitle = () => {
    return (
      <Box display="flex" alignItems="center" gap={1}>
        <Typography variant="boldSideScreenTitle">{orderNumber}</Typography>
        {(billingStatus !== BILLING_STATUSES_IDS.NEW) && renderOrderBillingStatusIcon()}
      </Box>
    );
  };

  return (
    <Box
      maxHeight="150px"
      display="flex"
      justifyContent="space-between"
      padding={"12px 18px"}
      flexDirection={'column'}
      gap="8px"
    >
      <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
        <DateTimeLabel variant="semiBoldSmall" dateTime={creationDate} theme={theme} dateColor="light" timeColor="light"/>
        {renderCloseIcon()}
      </Box>
      <Box display="flex" gap="4px" flexDirection="column">
        {renderTitle()}
        {renderStatus()}
        {renderShippingSection()}
      </Box>
    </Box>
  );
};

export default Header;
