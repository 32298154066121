import { Switch as MuiSwitch, Divider as MuiDivider, Typography, styled, IconButton, Link } from '@mui/material';
import { auto } from '@popperjs/core';

const BackIconButton = styled(IconButton)(({ theme })=>({
  padding: 0,
  "&.MuiIconButton-root": {
    '&:hover': {
      backgroundColor: 'transparent'
    },
    ">svg": {
      ">path": {
        stroke: `${theme.palette.gray}`,
      },
    },
    ">svg:hover": {
      ">path": {
        stroke: `${theme.palette.black}`
      }
    },
  }
}));

const FormWrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== "isMobileView",
})(({ isMobileView }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: (isMobileView? 28 : 20)
}))

const FormSection = styled('div', {
  shouldForwardProp: (prop) => (prop !== "paddingTop" && prop !== 'isMobileView'),
})(({ paddingTop, isMobileView }) => ({
  padding: (isMobileView? '16px 20px' : '16px 28px 24px 28px'),
  display: 'flex',
  ...(paddingTop && { paddingTop: 20 }),
}))

const Card = styled('div')(({ theme: { palette } }) => ({
  background: palette.white,
  borderRadius: 12,
  border: `1px solid ${palette.lightGreyOutline}`,
}))

const CardTitleWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  textTransform: 'capitalize',
  gap: 8,
})

const CreateOrderForm = styled('form',{
  shouldForwardProp: (prop) => prop !== "isMobileView",
})(({ isMobileView }) =>({
  padding: (isMobileView? '24px 15px 40px 15px' : '24px 104px 28px 96px'),
  overflow: auto,
  height: '100%',
}))

const CardHeaderWrapper = styled('div',
  {
    shouldForwardProp: (prop) => prop !== "isMobileView",
  })(({ isMobileView }) =>({
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: (isMobileView? 8: 12),
  alignItems: 'center'
}))

const PackingGuideLines = styled(Link)(({ theme: { palette, typography } }) => ({
  color: palette.gray,
  textDecorationColor: palette.gray,
  cursor: 'pointer',
  ":hover": {
    color: `${palette.black}`,
  },
  ...(typography.semiBoldSmallUnderline)
}))

const Switch = styled(MuiSwitch,{
  shouldForwardProp: (prop) => prop !== "isActive",
})(({ theme: { palette }, isActive }) => ({
  width: 40,
  height: 25,
  padding: 0,
  borderRadius: 25 / 2,
  paddingBottom: 1,
  border: `1px solid ${palette.lightGreyOutline}`,
  boxShadow: `0px 0px 12px 0px ${palette.boxShadow.showOptionalFieldsSwitch}`,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    transitionDuration: '300ms',
    height: '100%',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      '& .MuiSwitch-thumb:before': { backgroundColor: 'red' },
      '& + .MuiSwitch-track': {
        backgroundColor: palette.white,
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: palette.lightGreyOutline
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: 0.7,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    boxShadow: 'none',
    backgroundColor: isActive ? palette.limeGreen : palette.greyBorder,
    margin: 2,
    width: 20,
    height: 20,
  },
  '& .MuiSwitch-track': {
    borderRadius: 25 / 2,
    backgroundColor: palette.white,
    opacity: 1,
  },
}));

const Divider = styled(MuiDivider, {
  shouldForwardProp: (prop) => prop !== "isMobileView",
})(({ theme: { palette }, isMobileView }) => ({
  margin: (isMobileView? '4px 20px 0 20px' : '4px 28px 0 28px'),
  borderColor: palette.lightGreyOutline
}))

const StyledButton = styled('button')(({ theme: { palette, typography }, $color, $fontColor, $width, disabled }) => ({
  backgroundColor: $color,
  width: $width,
  height: 36,
  padding: '7px 16px 7px 16px',
  color: $fontColor,
  textTransform: 'none',
  borderRadius: '8px',
  ...(disabled? {opacity: 0.4} : {}),
  border: `1px solid ${palette.header.shadowColor}`,
  ...(typography.boldSubtitles),
  cursor: (disabled? 'default': 'pointer')
}))

const ToggleOptionalInfo = styled(Typography)({
  cursor: 'pointer'
})

const Styled = {
  BackIconButton,
  FormSection,
  Card,
  CardTitleWrapper,
  CreateOrderForm,
  Switch,
  Divider,
  ToggleOptionalInfo,
  FormWrapper,
  CardHeaderWrapper,
  PackingGuideLines,
  StyledButton,
}

export default Styled