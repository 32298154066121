/* eslint-disable max-len */
import React from "react"

const LogoutIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.3077 13.2308V15.0769C12.3077 15.3217 12.2104 15.5565 12.0373 15.7296C11.8642 15.9027 11.6294 16 11.3846 16H4.92308C4.67826 16 4.44347 15.9027 4.27036 15.7296C4.09725 15.5565 4 15.3217 4 15.0769V4.92308C4 4.67826 4.09725 4.44347 4.27036 4.27036C4.44347 4.09725 4.67826 4 4.92308 4H11.3846C11.6294 4 11.8642 4.09725 12.0373 4.27036C12.2104 4.44347 12.3077 4.67826 12.3077 4.92308V6.76923" stroke="#2A272B" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M9.53857 10H16.0001" stroke="#2A272B" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M14.1538 8.15381L16 9.99996L14.1538 11.8461" stroke="#2A272B" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
)

export default LogoutIcon