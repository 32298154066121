import { configureStore } from "@reduxjs/toolkit";

import counterReducer from "../components/counter/counter.slice";
import navbarReducer from "../components/navbar/navbar.slice";
import selectCourierReducer from "../components/select-courier/select-courier.slice";
import createOrderReducer from "../components/create-order/create-order.slice";
import dashboardReducer from "../components/dashboard/dashboard.slice";
import ordersSliceReducer from "../components/orders/orders.slice";
import commonSliceReducer from "../utils/common.slice";
import orderDetailsReducer from "../components/order-details/order-details.slice";
import actionCenterSlice from "../components/action-center/action-center.slice";
import { client } from "./apiClient";

export default configureStore({
  reducer: {
    counter: counterReducer,
    navbar: navbarReducer,
    createOrder: createOrderReducer,
    selectCourier: selectCourierReducer,
    dashboard: dashboardReducer,
    ordersSlice: ordersSliceReducer,
    common: commonSliceReducer,
    orderDetails: orderDetailsReducer,
    actionCenter: actionCenterSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      thunk: {
        extraArgument: client,
      },
      serializableCheck: false,
    }),
});
