import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Typography, useTheme, Box } from "@mui/material";
import { useTranslation } from "react-i18next";

import useViewPort from "../../utils/use-viewport";

import Menu from "../../common/components/menu";

import { CalendarIcon } from "../../assets/icons";

import Styled from "./analytics.styled";
import { STATUS_IDS } from "../orders/orders-table.constants";
import {
  ORDERS_CARDS,
  ORDERS_IDS,
  DATE_MENU_ITEMS_KEYS,
  SLA_SECTION_KEYS,
  SLA_TYPES,
  SLA_BUCKET_TYPES,
  MENU_IDS,
  DATE_MENU_ITEMS,
} from "./analytics.constants";

import { fetchOrdersCount, selectAppDirection } from "../../utils/common.slice";
import {
  fetchSuccessRate,
  fetchTopCouriers,
  fetchGovernorateSections,
  fetchSLA,
  fetchUnsuccessfulReasons,
  fetchFirstAttemptSLA,
  fetchTopCities,
  fetchTopAreas,
} from "./analytics.slice";

import OrdersSummary from "./components/orders-summary";
import DeliveryPerformance from "./components/delivery-performance";
import GeographicalAnalysis from "./components/geographical-analysis";
import { getDateRange, didValueChange } from "./analytics.utils";
import MobileErrorView from "../../common/components/Mobile-error-view";

const Analytics = () => {
  const dispatch = useDispatch();
  const { isMobileView } = useViewPort();
  const theme = useTheme();
  const { t } = useTranslation();
  const appDirection = useSelector(selectAppDirection);

  const [ordersSummary, setOrdersSummary] = useState([]);
  const [topCouriers, setTopCouriers] = useState({});
  const [isTopCouriersLoading, setIsTopCouriersLoading] = useState(false);
  const [successRate, setSuccessRate] = useState({});
  const [governorateSections, setGovernorateSections] = useState([]);
  const [isGovernorateSectionsLoading, setIsGovernorateSectionsLoading] =
    useState(false);

  const prevSection = useRef(null);
  const prevDateRange = useRef(null);
  const prevCouriersIds = useRef(null);
  const prevDeliverySLASection = useRef(null);
  const prevReturnSLASection = useRef(null);

  const [deliverySLAs, setDeliverySLAs] = useState({});
  const [deliverySLASection, setDeliverySlaSection] = useState(
    SLA_SECTION_KEYS.ALL
  );
  const [returnSLAs, setReturnSLAs] = useState({});
  const [returnSLASection, setReturnSlaSection] = useState(
    SLA_SECTION_KEYS.ALL
  );

  const [isDeliverySLALoading, setIsDeliverySLALoading] = useState(false);
  const [isReturnSLALoading, setIsReturnSLALoading] = useState(false);

  const [unsuccessfulReasons, setUnsuccessfulReasons] = useState({});
  const [isUnsuccessfulReasonsLoading, setIsUnsuccessfulReasonsLoading] =
    useState(false);

  const [firstAttemptSLAs, setFirstAttemptSLAs] = useState([]);
  const [isFirstAttemptSLALoading, setIsFirstAttemptSLALoading] =
    useState(false);

  const [topCities, setTopCities] = useState([]);
  const [isTopCitiesLoading, setIsTopCitiesLoading] = useState(false);

  const [topAreas, setTopAreas] = useState([]);
  const [isTopAreasLoading, setIsTopAreasLoading] = useState(false);

  const [selectedFilters, setSelectedFilters] = useState({});

  const [selectedDateKey, setSelectedDateKey] = useState(
    DATE_MENU_ITEMS_KEYS.THIS_MONTH
  );
  const [dateMenuAnchorEl, setDateMenuAnchorEl] = useState(null);
  const openDateMenu = (event) => setDateMenuAnchorEl(event.currentTarget);
  const closeDateMenu = () => setDateMenuAnchorEl(null);
  const isDateMenuOpen = Boolean(dateMenuAnchorEl);
  const [dateRange, setDateRange] = useState(getDateRange());

  const getOrdersSummary = () => {
    dispatch(fetchOrdersCount({ filters: dateRange })).then((res) =>
      setOrdersSummary([
        ...ORDERS_CARDS.map((tab) => {
          if (tab.key === ORDERS_IDS.TOTAL_ORDERS)
            return {
              ...tab,
              count:
                res?.payload?.count?.[STATUS_IDS.ALL] -
                  res?.payload?.count?.[STATUS_IDS.PENDING] || 0,
            };

          return {
            ...tab,
            count: res?.payload?.count?.[tab.key] || 0,
          };
        }),
      ])
    );
  };

  const getTopCouriers = (currentSelectedFilters) => {
    setIsTopCouriersLoading(true);
    const selectedFilters = { ...currentSelectedFilters, ...dateRange };
    dispatch(fetchTopCouriers({ filters: selectedFilters })).then((res) => {
      const data = {
        couriers: res?.payload?.couriers || [],
        totalOrders: res?.payload?.totalOrders || 0,
      };
      setTopCouriers(data || {});
      setIsTopCouriersLoading(false);
    });
  };

  const getSuccessRate = (currentSelectedFilters) => {
    const selectedFilters = { ...currentSelectedFilters, ...dateRange };
    dispatch(fetchSuccessRate({ filters: selectedFilters })).then((res) => {
      const data = {
        statusSuccessRate: res?.payload?.statusSuccessRate ?? [],
      };
      setSuccessRate(data || {});
    });
  };

  const getGovernorateSections = () => {
    setIsGovernorateSectionsLoading(true);
    dispatch(fetchGovernorateSections({})).then((res) => {
      setGovernorateSections(res?.payload || []);
      setIsGovernorateSectionsLoading(false);
    });
  };

  const getSLAS = (type, currentSelectedFilters) => {
    let section;
    if (type === SLA_TYPES.DELIVERY) {
      setIsDeliverySLALoading(true);
      section = deliverySLASection;
    } else {
      setIsReturnSLALoading(true);
      section = returnSLASection;
    }

    let bucketType;
    if (type === SLA_TYPES.DELIVERY) {
      bucketType = SLA_BUCKET_TYPES.DELIVERY;
    } else {
      bucketType = SLA_BUCKET_TYPES.RETURN;
    }

    const selectedFilters = {
      section: section,
      courierIds: currentSelectedFilters?.courierIds ?? null,
      bucketType: bucketType,
      dateRange: {
        closedOn: dateRange.dateRange?.processedOn,
        processedOn: dateRange.dateRange?.processedOn,
      },
    };

    dispatch(fetchSLA({ filters: selectedFilters })).then((res) => {
      if (type === SLA_TYPES.DELIVERY) {
        setIsDeliverySLALoading(false);
        setDeliverySLAs(res?.payload?.body || {});
      } else {
        setIsReturnSLALoading(false);
        setReturnSLAs(res?.payload?.body || {});
      }
    });
  };

  const getUnsuccessfulReasons = (currentSelectedFilters) => {
    setIsUnsuccessfulReasonsLoading(true);
    const selectedFilters = { ...currentSelectedFilters, ...dateRange };
    dispatch(fetchUnsuccessfulReasons({ filters: selectedFilters })).then(
      (res) => {
        setUnsuccessfulReasons(res?.payload?.body || {});
        setIsUnsuccessfulReasonsLoading(false);
      }
    );
  };

  const getFirstAttemptSLA = (currentSelectedFilters) => {
    setIsFirstAttemptSLALoading(true);
    const selectedFilters = { ...currentSelectedFilters, ...dateRange };
    dispatch(fetchFirstAttemptSLA({ filters: selectedFilters })).then((res) => {
      setFirstAttemptSLAs(res?.payload?.body?.result || []);
      setIsFirstAttemptSLALoading(false);
    });
  };

  const getTopCities = (currentSelectedFilters) => {
    setIsTopCitiesLoading(true);
    const selectedFilters = { ...currentSelectedFilters, ...dateRange };
    dispatch(fetchTopCities({ filters: selectedFilters })).then((res) => {
      setTopCities(res?.payload?.body || []);
      setIsTopCitiesLoading(false);
    });
  };

  const getTopAreas = (currentSelectedFilters) => {
    setIsTopAreasLoading(true);
    const selectedFilters = { ...currentSelectedFilters, ...dateRange };
    dispatch(fetchTopAreas({ filters: selectedFilters })).then((res) => {
      setTopAreas(res?.payload?.body || []);
      setIsTopAreasLoading(false);
    });
  };

  useEffect(() => {
    const filters = { dateRange, ...selectedFilters };

    const didDateChange = didValueChange(prevDateRange.current, dateRange);
    const didCouriersChange = didValueChange(
      prevCouriersIds.current,
      filters.courierIds
    );
    const didSectionChange = didValueChange(
      prevSection.current,
      filters.section
    );
    const didDeliverySLASectionChange = didValueChange(
      prevDeliverySLASection.current,
      deliverySLASection
    );
    const didReturnSLASectionChange = didValueChange(
      prevReturnSLASection.current,
      returnSLASection
    );
    // call apis when date or couriers change
    if (didCouriersChange || didDateChange) {
      getSuccessRate(filters);
      getSLAS(SLA_TYPES.DELIVERY, filters);
      getSLAS(SLA_TYPES.RETURN, filters);
      getUnsuccessfulReasons(filters);
      getFirstAttemptSLA(filters);
      getTopCities(filters);
      getTopAreas(filters);
    } else {
      if (didSectionChange) getSuccessRate(filters);

      if (didDeliverySLASectionChange) getSLAS(SLA_TYPES.DELIVERY, filters);

      if (didReturnSLASectionChange) getSLAS(SLA_TYPES.RETURN, filters);
    }
    // only call top courier api when date range changes
    if (didDateChange) {
      getTopCouriers(filters);
      getOrdersSummary();
    }

    prevCouriersIds.current = filters.courierIds;
    prevDateRange.current = dateRange;
    prevSection.current = filters.section;
    prevDeliverySLASection.current = deliverySLASection;
    prevReturnSLASection.current = returnSLASection;
  }, [selectedFilters, deliverySLASection, returnSLASection]);

  useEffect(() => {
    getGovernorateSections();
  }, []);

  const onChangeDate = (key) => {
    const selectedRange = getDateRange(key);
    setDateRange(selectedRange);
    setSelectedFilters({});
    setDeliverySlaSection(SLA_SECTION_KEYS.ALL);
    setReturnSlaSection(SLA_SECTION_KEYS.ALL);
    setSelectedDateKey(key);
    closeDateMenu();
  };

  return (
    <Styled.Container $isMobileView={isMobileView}>
      {isMobileView ? (
        <MobileErrorView errorMsg={t(`analytics.notAvailable`)} />
      ) : (
        <>
          <Box display={"flex"} flexDirection={"row"}>
            <Typography variant="bold" color={theme.palette.text.primary}>
              {t("analytics.title")}
            </Typography>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="flex-end"
              ml="auto"
              onClick={openDateMenu}
              sx={{
                cursor: "pointer",
              }}
            >
              <Typography
                variant="regularWithUnderline"
                color={theme.palette.text.primary}
                mr={1}
              >
                {t(`analytics.dateMenuItems.${selectedDateKey}`)}
              </Typography>
              <CalendarIcon />
            </Box>
          </Box>

          <Menu
            id={MENU_IDS.DATE_MENU}
            theme={theme}
            anchorEl={dateMenuAnchorEl}
            onCloseMenu={closeDateMenu}
            onClickMenuItem={onChangeDate}
            isMenuOpen={isDateMenuOpen}
            items={DATE_MENU_ITEMS}
            dir={appDirection}
            t={t}
            translationKey={"analytics.dateMenuItems"}
          />

          <Styled.ContentWrapper>
            <OrdersSummary ordersSummary={ordersSummary} />
            <DeliveryPerformance
              topCouriers={topCouriers}
              isTopCouriersLoading={isTopCouriersLoading}
              successRate={successRate}
              governorateSections={governorateSections}
              isGovernorateSectionsLoading={isGovernorateSectionsLoading}
              deliverySLAs={deliverySLAs}
              returnSLAs={returnSLAs}
              selectedDeliverySLASection={deliverySLASection}
              selectedReturnSLASection={returnSLASection}
              setDeliverySlaSection={setDeliverySlaSection}
              setReturnSlaSection={setReturnSlaSection}
              isDeliverySLALoading={isDeliverySLALoading}
              isReturnSLALoading={isReturnSLALoading}
              unsuccessfulReasons={unsuccessfulReasons}
              isUnsuccessfulReasonsLoading={isUnsuccessfulReasonsLoading}
              firstAttemptSLAs={firstAttemptSLAs}
              isFirstAttemptSLALoading={isFirstAttemptSLALoading}
              selectedFilters={selectedFilters}
              setSelectedFilters={setSelectedFilters}
            />
            <GeographicalAnalysis
              topCities={topCities}
              isTopCitiesLoading={isTopCitiesLoading}
              topAreas={topAreas}
              isTopAreasLoading={isTopAreasLoading}
            />
          </Styled.ContentWrapper>
        </>
      )}
    </Styled.Container>
  );
};

export default Analytics;
