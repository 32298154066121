import React, { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { Box, CircularProgress, Typography, useTheme } from "@mui/material";

import Styled from "./select-courier.styled";
import useViewport from "../../utils/use-viewport";
import Header from "./components/header";
import { CourierTable } from "./components/couriers-table";
import { CourierCards } from "./components/courier-card";
import {
  createNewOrder,
  updateOrderCourierAction,
} from "./select-courier.slice";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Button from "../../common/components/button";

export function SelectCourier({ orderData, isLoading, isEditMode = false, handleEditOrder, couriers, setIsSelectingCourier, isCreateOrderView, showBackButton, pageTitle, actionButtonTitle }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const { t } = useTranslation();
  const { isMobileView } = useViewport();

  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [selectedCourierId, setSelectedCourierId] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onBack = () => setIsSelectingCourier(false);

  const orderDetails = useMemo(
    () => ({
      orderType: {
        key: "orderType",
        title: t('selectCourier.summary.labels.order'),
        value: isCreateOrderView ? orderData?.orderType : orderData?.type,
      },
      amount: {
        key: "amount",
        title: t('selectCourier.summary.labels.amount'),
        value: orderData?.amount || orderData?.collectionAmount || 0,
      },
      packageType: {
        key: "packageType",
        title: t('selectCourier.summary.labels.type'),
        value: orderData?.packageType || "parcel", //TODO remove when api is fixed
      },
      city: {
        key: "city",
        title: t('selectCourier.summary.labels.city'),
        value: orderData?.city,
      },
      zone: {
        key: "zone",
        title: t('selectCourier.summary.labels.area'),
        value: orderData?.area,
      },
    }),
    [orderData]
  );

  const orderSummaryDetails = useMemo(
    () => Object.values(orderDetails),
    [orderDetails]
  );

  const createOrderAction = () => {
    const createFunction = isEditMode? handleEditOrder(selectedCourierId) : createNewOrder({ ...orderData, selectedCourierId })
    setIsSubmitting(true);
    dispatch(createFunction).then((response)=> {
      setIsSubmitting(false)
      if(response.payload?.status && response.payload?.status === "success") {
        navigate("/orders");
      }
    }
    );
  };

  const updateOrderAction = () => {
    setIsSubmitting(true)
    dispatch(
      updateOrderCourierAction({
        order: orderData,
        courierId: selectedCourierId,
      })
    ).then((res) => {
      setIsSubmitting(false);
      if(res.payload.msg === 'success') {
        toast(res.payload.msg)
      } else {
        toast.error(res.payload.msg)
      }
      setIsSelectingCourier(false);
      navigate("/orders");
    });
  };

  if (!couriers || couriers?.length === 0) { navigate("/"); return; }

  return (
    <Box height='100%' overflow="auto">
      {isLoading ? (
        <Box height="100%" width="100%" display={'flex'} alignItems={'center'} justifyContent={'center'}>
          <CircularProgress color="secondary"/>
        </Box>
      ) : (
        <Styled.Container $isMobileView={isMobileView}>
          {/* Header, Summary */}
          <Header
            t={t}
            onBack={onBack}
            isMobileView={isMobileView}
            isDrawerOpen={isDrawerOpen}
            setDrawerOpen={setDrawerOpen}
            orderSummary={orderSummaryDetails}
            showBackButton={showBackButton}
            pageTitle={pageTitle}
          />

          {/* Content */}
          {isMobileView ? (
            <CourierCards
              rows={couriers}
              selectedCourierId={selectedCourierId}
              setSelectedCourierId={setSelectedCourierId}
              isDrawerOpen={isDrawerOpen}
              setDrawerOpen={setDrawerOpen}
            />
          ) : (
            <CourierTable
              rows={couriers}
              selectedCourierId={selectedCourierId}
              setSelectedCourierId={setSelectedCourierId}
            />
          )}

          {/* Action Buttons */}
          <Styled.ActionButtonsContainer $isMobileView={isMobileView} $isEditMode={isEditMode}>
            { isEditMode? <Typography variant='boldSmall' color={theme.palette.red}>{t('selectCourier.editOrderMessage')}</Typography>: <></> }
            <Button
              disableElevation={true}
              onClick={isCreateOrderView? createOrderAction: updateOrderAction}
              label={actionButtonTitle}
              size={{ width: isMobileView? '100%' : `${isEditMode? '150px': '130px'}`, height: '36px' }}
              isLoading={isSubmitting}
            />
          </Styled.ActionButtonsContainer>
        </Styled.Container>
      )}
    </Box>
  );
}
