/* eslint-disable max-len */
import React from "react";

const PartialDelivery = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.0942 4H3.12443C2.72428 4 2.3999 4.33062 2.3999 4.73846V12.8615C2.3999 13.2694 2.72428 13.6 3.12443 13.6H11.0942C11.4944 13.6 11.8188 13.2694 11.8188 12.8615V4.73846C11.8188 4.33062 11.4944 4 11.0942 4Z" stroke="#2A272B" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M8.55827 4V7.69231C8.55827 7.79023 8.5201 7.88415 8.45217 7.95339C8.38423 8.02264 8.29208 8.06154 8.19601 8.06154H6.02242C5.92634 8.06154 5.8342 8.02264 5.76626 7.95339C5.69832 7.88415 5.66016 7.79023 5.66016 7.69231V4" stroke="#2A272B" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M8.19629 11.7539H10.0076" stroke="#2A272B" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M5.14697 2.4H12.7545C12.9787 2.4 13.1937 2.49077 13.3522 2.65234C13.5107 2.81391 13.5998 3.03305 13.5998 3.26154V11.0154" stroke="#2A272B" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

export default PartialDelivery;
