/* eslint-disable max-len */
import React from "react";

const SortingIcon = () => (
  <svg
    width="12"
    height="14"
    viewBox="0 0 12 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Icon">
      <g id="Icon_2">
        <path
          id="Vector"
          d="M0.500046 4.58263L4.50005 1V13"
          stroke="#7F7D80"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_2"
          d="M11.5 9.53608L7.50005 13V1"
          stroke="#7F7D80"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </g>
  </svg>
);

export default SortingIcon;
