import { createSlice } from '@reduxjs/toolkit'

//SLICE NAMES CANNOT BE DUPLICATES!
const sliceName = 'navbar'

export const navbarSlice = createSlice({
  name: sliceName,
  initialState: {
    isOpen: false,
    selectedIndex: null
  },
  reducers: {
    toggleNavbar: (state) => {
      state.isOpen = !state.isOpen
    },
    setSelectedIndex: (state, action) => {
      state.selectedIndex = action.payload;
    },
    handleListItemSelected: (state, action) => {
      state.selectedIndex = action.payload;
    }
  }
})

export const { toggleNavbar, setSelectedIndex } = navbarSlice.actions

export const getNavbarState = (state) => state[sliceName].isOpen
export const getNavbarSelectedIndex = (state) => state[sliceName].selectedIndex

export default navbarSlice.reducer