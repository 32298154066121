/* eslint-disable max-len */
import React from "react"

const CustomerIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.99997 7.94455C9.97241 7.94455 11.5714 6.38996 11.5714 4.47227C11.5714 2.55459 9.97241 1 7.99997 1C6.02752 1 4.42854 2.55459 4.42854 4.47227C4.42854 6.38996 6.02752 7.94455 7.99997 7.94455Z" stroke="#2A272B" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M1 15C1.32788 13.683 2.2039 12.4989 3.47963 11.6484C4.75536 10.7979 6.35235 10.3333 8 10.3333C9.64765 10.3333 11.2446 10.7979 12.5204 11.6484C13.7961 12.4989 14.6721 13.683 15 15H1Z" stroke="#2A272B" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
)

export default CustomerIcon
