import React, { useEffect, useState, useRef } from "react";
import toast from "react-hot-toast";
import ReactToPrint from "react-to-print";
import { Box, CircularProgress } from "@mui/material";

import Styled from "./print-view.styled";
import { LargeCrossIcon } from "../../../assets/icons";
import { getAWBLabels } from "../orders.slice";
import Button from "../../../common/components/button";
import PrintThermal from "./print-thermal";
import { PRINT_VIEWS_ID } from "./print-view.constants";
import PrintPaper from "./print-paper";
import { Select } from "../../../common/components/form-field/components/select/select";
import { STATUS_IDS, NEW_STATUS } from "../orders-table.constants";
import { Trans } from "react-i18next";

const PrintView = ({
  setIsPrintView,
  dispatch,
  selectedOrders,
  t,
  isMobileView,
  setPendingOrder,
  isSelectAll,
  activeFilters,
  searchTerm
}) => {
  const [thermalLabels, setThermalLabels] = useState([]);
  const [isViewLoading, setIsViewLoading] = useState(true);
  const [viewType, setViewType] = useState(PRINT_VIEWS_ID.THERMAL);

  const ref = useRef();

  useEffect(() => {
    const ordersIds = selectedOrders
      .filter(({ externalId }) => !!externalId)
      .map(({ id }) => id);
    if (ordersIds?.length < selectedOrders?.length) {
      const noTrackedLabelsLength = selectedOrders?.length - ordersIds?.length;

      toast(
        <Trans
          i18nKey={'listing.printView.notTrackedOrdersMsg'}
          values={{
            ordersCount: noTrackedLabelsLength,
            labelsLabel: t(noTrackedLabelsLength === 1? t('listing.printView.label') : t('listing.printView.labels'))
          }}
        />
      );
    }
    const parameterObject = {};
    if (isSelectAll) {
      const filters = {
        ...activeFilters,
        status: STATUS_IDS.PENDING,
        subStatus: NEW_STATUS,
      };
      if (searchTerm) filters.code = searchTerm;
      parameterObject.filters = filters;
      parameterObject.isSelectAll = true;
    } else {
      const filters = { ids: ordersIds };
      parameterObject.filters = filters;
    }
    dispatch(getAWBLabels(parameterObject)).then((res) => {
      if (Array.isArray(res?.payload) && res?.payload?.length > 0) {
        setThermalLabels(res.payload);
      } else {
        setIsPrintView(false);
        toast.error("Couldn't fetch labels info");
      }
      setPendingOrder({});
      setIsViewLoading(false);
    })
      .finally(() => setIsViewLoading(false))
      .catch(() => setIsViewLoading(false));
  }, []);

  const Labels = () => {
    if (isViewLoading) {
      return (
        <Box
          width="100%"
          display="flex"
          height="100%"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress color="secondary" />
        </Box>
      );
    }

    if (viewType === PRINT_VIEWS_ID.THERMAL)
      return (
        <PrintThermal
          isViewLoading={isMobileView}
          thermalLabels={thermalLabels}
          currentRef={ref}
        />
      );

    return (
      <PrintPaper
        labels={thermalLabels}
        isMobileView={isMobileView}
        currentRef={ref}
      />
    );
  };

  const options = [
    { value: PRINT_VIEWS_ID.PAPER, label: t("listing.printView.paperButton") },
    {
      value: PRINT_VIEWS_ID.THERMAL,
      label: t("listing.printView.thermalButton"),
    },
  ];

  return (
    <Styled.PrintViewWrapper>
      <Styled.HeaderBar isMobileView={isMobileView}>
        {isMobileView ? (
          <Box width="fit-content">
            <Select
              options={options}
              defaultValue={options[1]}
              arrowSize={32}
              onChange={({ value }) => setViewType(value)}
              noBorders={true}
              noLeftPadding={true}
              darkTheme
              placeholderTypo="underLineDropDown"
            />
          </Box>
        ) : (
          <Styled.ButtonsWrapper>
            <Styled.ButtonBase
              disableRipple
              selected={viewType === PRINT_VIEWS_ID.PAPER}
              onClick={() => setViewType(PRINT_VIEWS_ID.PAPER)}
            >
              {t("listing.printView.paperButton")}
            </Styled.ButtonBase>

            <Styled.ButtonBase
              disableRipple
              selected={viewType === PRINT_VIEWS_ID.THERMAL}
              onClick={() => setViewType(PRINT_VIEWS_ID.THERMAL)}
            >
              {t("listing.printView.thermalButton")}
            </Styled.ButtonBase>
          </Styled.ButtonsWrapper>
        )}

        <Styled.HeaderRightBlock isMobileView={isMobileView}>
          <ReactToPrint
            bodyClass="print-agreement"
            content={() => ref.current}
            pageStyle={
              viewType === PRINT_VIEWS_ID.PAPER
                ? "@page { margin: 40px;  size: auto; height: 100% }"
                : "@page { margin: 0mm; size: auto;}"
            }
            trigger={() => (
              <Button
                type="primary"
                disabled={!thermalLabels.length}
                label={`${
                  isMobileView
                    ? "Print Labels"
                    : t("listing.printView.downloadButton")
                } ${isMobileView ? "" : `(${thermalLabels.length})`}`}
              />
            )}
          />
          <Styled.IconButton
            onClick={() => {
              setIsPrintView(false);
              setPendingOrder({});
            }}
          >
            <LargeCrossIcon />
          </Styled.IconButton>
        </Styled.HeaderRightBlock>
      </Styled.HeaderBar>

      <Labels />
    </Styled.PrintViewWrapper>
  );
};

export default PrintView;
