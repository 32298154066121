import { styled } from "@mui/material/styles";
import { Box, Skeleton } from "@mui/material";

const TopCitiesCard = styled(Box)(() => ({
  width: "100%",
  height: "100%",
  borderRadius: "10px",
  display: "grid",
  gridTemplateColumns: "1fr 1fr",
  columnGap: "20px",
  marginBottom: "10px",
}));

const Sections = styled(Box, {
  shouldForwardProp: (prop) => prop !== "theme",
})(({ theme }) => ({
  width: "100%",
  height: "60px",
  backgroundColor: theme.palette.lightgrey,
  borderRadius: "10px",
  display: "grid",
  gridTemplateColumns: "1fr 1fr",
  marginBottom: "10px",
}));

const SkeletonSections = styled(Skeleton)(() => ({
  width: "100%",
  height: "60px",
  borderRadius: "10px",
  display: "grid",
  gridTemplateColumns: "1fr 1fr",
  marginBottom: "10px",
}));

const TopCitiesStyled = {
  TopCitiesCard,
  Sections,
  SkeletonSections,
};

export default TopCitiesStyled;
