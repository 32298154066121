/* eslint-disable max-len */
import React from "react"

const WalletOutlineIcon = ({ stroke="#2A272B", width="16", height="12" }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 16 12" fill="none">
    <path d="M13.9231 1H2.07692C1.48215 1 1 1.47128 1 2.05263V9.94737C1 10.5287 1.48215 11 2.07692 11H13.9231C14.5178 11 15 10.5287 15 9.94737V2.05263C15 1.47128 14.5178 1 13.9231 1Z" stroke={stroke} strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M13.9231 1H2.07692C1.48215 1 1 1.47128 1 2.05263V9.94737C1 10.5287 1.48215 11 2.07692 11H13.9231C14.5178 11 15 10.5287 15 9.94737V2.05263C15 1.47128 14.5178 1 13.9231 1Z" stroke={stroke} strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M10 8L12 8" stroke={stroke} strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M1 4L15 4" stroke={stroke} strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
)

export default WalletOutlineIcon
