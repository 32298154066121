import React from 'react'
import { Box } from '@mui/material'

import { BlackTooltip } from '../../../common/components/black-tooltip'
import { BulkInfoIcon, ArrowIcon } from '../../../assets/icons'
import Styled from '../bulk-create-order.styled';


const ErrorPlaceholder = ({ isForDropdown = false, value, failureReason }) => {
  const ErrorTooltip = ({ failureReason }) => (
    <BlackTooltip title={failureReason} isError={true}>
      <Styled.InfoIconWrapper isError={true}>
        <BulkInfoIcon />
      </Styled.InfoIconWrapper>
    </BlackTooltip>
  )

  const ErrorMsg = () => (
    <Box display="flex" alignItems="center" gap="4px" paddingLeft="8px" width="100%" className="error">
      <Styled.ErrorMsg  color='red'>
        {/* TODO: translate */}
        {value || 'Missing'}
      </Styled.ErrorMsg>

      {failureReason && <ErrorTooltip failureReason={failureReason} />}
    </Box>
  )

  if(isForDropdown) return (
    <Box display="flex" alignItems="center" gap="4px" justifyContent="space-between"  flexGrow="1" paddingRight="10px" width="100%" className="error">
      <ErrorMsg />
      <ArrowIcon isDown size={20} />
    </Box>
  )

  return (
    <ErrorMsg/>
  )
}

export default ErrorPlaceholder;