import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography} from "@mui/material";

import Styled from "../../../../analytics.styled";
import SectionCards from "./section-cards";

const FirstAttemptSLA = ({ isMobileView, theme, data, isLoading }) => {

  const { t } = useTranslation();

  return (
    <Styled.Card $isMobileView={isMobileView}>
      <Box
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"space-between"}
        width={"95%"}
        height={"100%"}
      >
        <Typography
          variant="mediumText"
          color={theme.palette.text.light}
          sx={{ mt: 2, mb: 2, pl: 1 }}
        >
          {t("analytics.firstAttemptSLA.title")}
        </Typography>
        <Box mb={"20px"}>
          <SectionCards data={data} isLoading={isLoading} />
        </Box>
      </Box>
    </Styled.Card>
  );
};

export default FirstAttemptSLA;
