import { styled, Button, Stack, Typography } from "@mui/material";
import React from "react";

import { CalendarIcon, LargeArrowDownIcon, LargeArrowUpIcon } from "../../../assets/icons";

//Here we added styled in same file as it's one component
const StyledSelectButton = styled(Button,
  {
    shouldForwardProp: (prop) => prop !== "width",
  })(({ $isOpen, $isDisabled, theme: { palette }, width }) => ({
  textTransform: 'none',
  padding: '8px 12px',
  border: ($isOpen? `1px solid ${palette.black}`:`1px solid ${palette.greyBorder}`),
  borderRadius: '8px',
  width: width,
  justifyContent: 'space-between',
  ...($isDisabled && { opacity: '0.5' })
}))

const SelectButton = ({ isOpen, isDisabled, onClick, onBlur = () => {}, label, describedBy, width = 316 }) => (
  <StyledSelectButton
    $isOpen={isOpen}
    $isDisabled={isDisabled}
    endIcon={isOpen? <LargeArrowUpIcon /> : <LargeArrowDownIcon  />}
    disabled={isDisabled}
    onBlur={onBlur}
    color='secondary'
    onClick={onClick}
    aria-describedby={describedBy}
    type="button"
    width={width}
  >
    <Stack direction="row" alignItems="center" gap="4px">
      <CalendarIcon />
      <Typography variant='regular'>
        {label}
      </Typography>
    </Stack>
  </StyledSelectButton>
)

export default SelectButton