import React from "react";
import { Box, Typography } from "@mui/material";

const RenderCourierRecommended = ({ theme,  t }) => (
  <Box borderRadius="4px" backgroundColor={theme.palette.green.main}>
    <Typography variant="boldSmall" fontSize={"11px"} color={theme.palette.white} padding={'0px 5px'}>
      {t('selectCourier.recommendedLabel')}
    </Typography>
  </Box>
);

export default RenderCourierRecommended;
