const toEnglishNumber = (strNum) => {
  if(!['string', 'number'].includes(typeof strNum)) return

  const updatedString = typeof strNum === 'number'? strNum.toString() : strNum

  if(!updatedString  && !(updatedString >= 0)) return ''

  const numbersInString = updatedString?.match(/[0-9٠-٩.]+/g)?.join('') || '';
  return numbersInString.replace(/[٠-٩]/g, d => '٠١٢٣٤٥٦٧٨٩'.indexOf(d))
}

export default toEnglishNumber