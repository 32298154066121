import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";

const Sections = styled(Box, {
  shouldForwardProp: (prop) => prop !== "theme" && prop !== "isSelected",
})(({ theme, isSelected }) => ({
  width: "250px",
  height: "60px",
  ["@media (min-width: 1100px) and (max-width: 1205px)"]: {
    width: "180px",
  },
  backgroundColor: theme.palette.lightgrey,
  justifyContent: "center",
  alignItems: "center",
  border: isSelected ? "1px solid black" : "none",
  borderRadius: "10px",
  display: "flex",
  marginBottom: "10px",
  cursor: "pointer",
}));

const Styled = {
  Sections,
};

export default Styled;
