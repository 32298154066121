/* eslint-disable max-len */
import React from "react"

const VerifyIcon = ({ iconOuterStroke = "#D52A5D", iconInnerStroke = "#F4F4F4" }) => (
  <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.99989 9.94455C11.9723 9.94455 13.5713 8.38996 13.5713 6.47227C13.5713 4.55459 11.9723 3 9.99989 3C8.02745 3 6.42847 4.55459 6.42847 6.47227C6.42847 8.38996 8.02745 9.94455 9.99989 9.94455Z" fill={iconOuterStroke} stroke={iconOuterStroke} strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M3 17C3.32788 15.683 4.2039 14.499 5.47963 13.6485C6.75536 12.798 8.35235 12.3334 10 12.3334C11.6477 12.3334 13.2446 12.798 14.5204 13.6485C15.7961 14.499 16.6721 15.683 17 17" fill={iconOuterStroke}/>
    <path d="M3 17C3.32788 15.683 4.2039 14.499 5.47963 13.6485C6.75536 12.798 8.35235 12.3334 10 12.3334C11.6477 12.3334 13.2446 12.798 14.5204 13.6485C15.7961 14.499 16.6721 15.683 17 17H3Z" stroke={iconOuterStroke} strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M21.5 13C21.5 15.4853 19.4853 17.5 17 17.5C14.5147 17.5 12.5 15.4853 12.5 13C12.5 10.5147 14.5147 8.5 17 8.5C19.4853 8.5 21.5 10.5147 21.5 13Z" fill={iconOuterStroke} stroke={iconInnerStroke} strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M16.9597 10.0574C17.5143 10.0574 17.9597 10.2094 18.2957 10.5134C18.637 10.812 18.8077 11.2254 18.8077 11.7534C18.8077 12.276 18.6397 12.676 18.3037 12.9534C17.9677 13.2254 17.5223 13.3614 16.9677 13.3614L16.9357 13.9774H15.9517L15.9117 12.6174H16.2717C16.7357 12.6174 17.093 12.556 17.3437 12.4334C17.5943 12.3107 17.7197 12.0867 17.7197 11.7614C17.7197 11.5267 17.6503 11.34 17.5117 11.2014C17.3783 11.0627 17.1943 10.9934 16.9597 10.9934C16.7143 10.9934 16.5223 11.06 16.3837 11.1934C16.2503 11.3267 16.1837 11.5107 16.1837 11.7454H15.1277C15.1223 11.42 15.1917 11.1294 15.3357 10.8734C15.4797 10.6174 15.6903 10.4174 15.9677 10.2734C16.2503 10.1294 16.581 10.0574 16.9597 10.0574ZM16.4397 15.8894C16.237 15.8894 16.069 15.828 15.9357 15.7054C15.8077 15.5774 15.7437 15.42 15.7437 15.2334C15.7437 15.0467 15.8077 14.892 15.9357 14.7694C16.069 14.6414 16.237 14.5774 16.4397 14.5774C16.637 14.5774 16.7997 14.6414 16.9277 14.7694C17.0557 14.892 17.1197 15.0467 17.1197 15.2334C17.1197 15.42 17.0557 15.5774 16.9277 15.7054C16.7997 15.828 16.637 15.8894 16.4397 15.8894Z" fill={iconInnerStroke}/>
  </svg>
)

export default VerifyIcon