import { Button, styled } from "@mui/material";

const MenuButton = styled(Button)(({ theme: { typography } }) => ({
  textTransform: 'none',
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-start',
  gap: '4px',
  ...(typography.regular)
}))

const Styled = { MenuButton }

export default Styled