/* eslint-disable max-len */
import React from "react"

const MultiOrdersIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.8679 5H3.90566C3.40548 5 3 5.41328 3 5.92308V16.0769C3 16.5867 3.40548 17 3.90566 17H13.8679C14.3681 17 14.7736 16.5867 14.7736 16.0769V5.92308C14.7736 5.41328 14.3681 5 13.8679 5Z" stroke="#2A272B" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M10.6981 5V9.61538C10.6981 9.73779 10.6504 9.85519 10.5655 9.94174C10.4806 10.0283 10.3654 10.0769 10.2453 10.0769H7.52833C7.40823 10.0769 7.29305 10.0283 7.20813 9.94174C7.12321 9.85519 7.0755 9.73779 7.0755 9.61538V5" stroke="#2A272B" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M10.2453 14.6924H12.5095" stroke="#2A272B" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M6.43396 3H15.9434C16.2236 3 16.4924 3.11346 16.6905 3.31542C16.8887 3.51739 17 3.79131 17 4.07692V13.7692" stroke="#2A272B" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
)

export default MultiOrdersIcon
