import React from "react"
import { Box, Typography, Drawer, IconButton } from "@mui/material"

import { CrossIcon } from "../../../assets/icons"

const DrawerWithTitle = ({
  open, anchor, content, theme, closeDrawer, title, hideCloseIcon,
  maxHeightInMobileView = 664, isMobileView, height, dir,
  // appDirection
}) => {

  return (
    <Drawer
      open={open}
      //TODO: figure out with translate
      // SlideProps={{ direction: appDirection === 'rtl'? 'right' : 'left' }}
      anchor={anchor}
      onClose={closeDrawer}
      dir={dir}
      PaperProps={{
        style: {
          height: height,
          width: '100%',
          maxWidth: isMobileView? '100%' : 364,
          maxHeight: isMobileView? maxHeightInMobileView : '100%',
          borderTopRightRadius: isMobileView? '8px': '0px',
          borderTopLeftRadius: '8px',
          borderBottomLeftRadius: isMobileView? '0px': '8px'
        }
      }}>
      <Box
        height="68px"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        padding={isMobileView? "0 15px": "0 24px"}
        borderBottom={`1px solid ${theme.palette.lightGreyOutline}`}
      >
        <Typography variant="boldSideScreenTitle">{title}</Typography>
        {!hideCloseIcon &&
        <IconButton sx={{ padding: 0, '&:hover': {"svg": {">path": {stroke: theme.palette.black}}} }} disableRipple onClick={closeDrawer} >
          <CrossIcon color={theme.palette.gray} />
        </IconButton>
        }
      </Box>
      {content}
    </Drawer>
  )
}

export default DrawerWithTitle