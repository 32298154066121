import { EditIcon, ImportIcon } from '../../assets/icons'
import { onClickingPackagingAndInsurance } from '../../global.utils'
import { getNextTuesdayFormatted } from './dashboard.utils'


const ACCOUNT_CARDS = [
  { id: 'schedule', href: 'https://docs.google.com/forms/d/e/1FAIpQLSdwMC3YCOZIOkOjsljM631YyrWJofGnOJ-xK9unaD2iN2F-3Q/viewform', icon: EditIcon },
  { id: 'packaging', onClick: onClickingPackagingAndInsurance, icon: ImportIcon },
  { id: 'support', href: 'https://docs.google.com/forms/d/e/1FAIpQLScnXCJSmXZdGq5kkMtAloZ67qDP29oQuMSnAdunH6pv1E2oyg/viewform?pli=1', icon: EditIcon },
  { id: 'billing', extraDescription: getNextTuesdayFormatted()}
]


export { ACCOUNT_CARDS }