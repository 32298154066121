import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

//SLICE NAMES CANNOT BE DUPLICATES!
const sliceName = 'change-password'

export const updatePassword = createAsyncThunk(`${sliceName}/fetchDataAndDontLogError`, async (newPassword, thunkAPI) => {
  const response = await thunkAPI.extra.apiClient.put({
    url: "password",
    data: { newPassword, },
    isJsonContent: true,
    logErrorMsg: true,
    errorMsg: 'Failed to update password',
    successMsg: 'Password updated successfully',
  });

  return response
})

export const changePassword = createSlice({
  name: sliceName,
  extraReducers: (builder) => {
    // Add reducers for additional action types here
    builder
      .addCase(updatePassword.fulfilled, (state, action) => ({
        ...state,
        ...action.payload
      }))
  },
})

export default changePassword.reducer