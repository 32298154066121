export const roundBalanceText = ({ balance }) => {
  const getRoundedBalanceValue = (positiveValue) => {
    if (positiveValue > 1000 && positiveValue < 1000000) {
    // Thosands
      return `${parseFloat((positiveValue / 1000).toFixed(2))} K`;
    } else if (positiveValue >= 1000000) {
    // Millions
      return `${parseFloat((positiveValue / 1000000).toFixed(2))} M`;
    } else {
    // Less than thosand
      return `${parseFloat(positiveValue.toFixed(2))}`;
    }
  }

  return `${balance < 0 ? '-' : ''} ${getRoundedBalanceValue(Math.abs(balance))}`
};

//TODO: recheck this
export const getNextTuesdayFormatted = () => {
  const today = new Date();
  const dayOfWeek = today.getDay();
  const daysUntilTuesday = (2 - dayOfWeek + 7) % 7;

  // If today is Tuesday, return the next Tuesday
  const nextTuesday = new Date(today);
  nextTuesday.setDate(today.getDate() + (daysUntilTuesday === 0 ? 7 : daysUntilTuesday));

  // Format the date as '2 April'
  const day = nextTuesday.getDate();
  const monthNames = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ];
  const monthName = monthNames[nextTuesday.getMonth()];

  return `${day} ${monthName}`;
}

