import React from "react";
import ProgressBar from "@ramonak/react-progress-bar";
import { Box, Typography, Skeleton } from "@mui/material";

import { getAreaCompletedCount } from "./top-areas.utils";
import Styled from "./top-areas.styled";

const TopAreaBars = ({ area, maxValue, theme, isLoading, t }) => (
  <Box display={"flex"} alignItems={"center"} mb={"5px"}>
    <Styled.ProgressBar>
      {isLoading ? (
        <Skeleton width={"100%"} height={60} />
      ) : (
        <ProgressBar
          completed={getAreaCompletedCount(maxValue, area.count)}
          maxCompleted={maxValue > 0 ? maxValue : 100}
          bgColor={theme.palette.lightgrey}
          baseBgColor={"transparent"}
          height="60px"
          borderRadius="10px"
          customLabel={area.area}
          labelAlignment="left"
          labelColor={theme.palette.text.primary}
          customLabelStyles={{ marginLeft: 16 }}
        />
      )}
    </Styled.ProgressBar>

    <Typography variant="semiBoldSmall" color={theme.palette.text.light}>
      {t("analytics.topAreas.orders", {
        count: area.count === -1 ? 0 : area.count,
      })}
    </Typography>
  </Box>
);

export default TopAreaBars;
