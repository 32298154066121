/* eslint-disable max-len */
import React from "react";

const HomeIcon = ({ width="24", height="24", color="white" }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path 
      d="M19.8736 8.56581C19.9584 8.76182 20.0014 8.97332 20 9.18686V17.7407C20 18.1487 19.8379 18.54 19.5494 18.8285C19.2609 19.117 18.8695 19.2791 18.4615 19.2791H1.53849C1.13047 19.2791 0.739155 19.117 0.450639 18.8285C0.162122 18.54 3.52252e-05 18.1487 3.52252e-05 17.7407V9.18686C-0.00140975 8.97332 0.0416157 8.76182 0.126373 8.56581C0.211131 8.36981 0.335764 8.1936 0.492341 8.0484L8.64404 0.529846C9.40999 -0.176615 10.59 -0.176615 11.356 0.529846L19.5077 8.0484C19.6642 8.1936 19.7889 8.36981 19.8736 8.56581ZM7.27273 11.7585L7.27273 18.3701H12.7273L12.7273 11.7585C12.7273 11.5832 12.6554 11.415 12.5276 11.291C12.3997 11.167 12.2263 11.0974 12.0455 11.0974L7.95455 11.0974C7.77372 11.0974 7.60029 11.167 7.47243 11.291C7.34456 11.415 7.27273 11.5832 7.27273 11.7585Z"
      fill={color}
    />
  </svg>
);

export default HomeIcon;
