/* eslint-disable no-undef */
import React, { useState, useEffect, useCallback, useMemo, useLayoutEffect } from "react";
import toast, { ToastBar, Toaster } from "react-hot-toast";
import {
  Box,
  CssBaseline,
  Dialog,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { styled, ThemeProvider } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { BrowserRouter } from "react-router-dom";
import { hotjar } from 'react-hotjar';

import DrawerWithTitle from "./common/components/drawer/index.jsx";
import Header from "./components/header";
import { Router } from "./components/router";
import NavBar from "./components/navbar";
import { getTheme } from "./utils/theme";
import { CrossIcon } from "./assets/icons";
import { selectAuthData, setNewAuthData } from "./utils/common.slice";
import "./i18n.js";
import ChangePassword from "./common/components/change-password/index.jsx";
import useViewport from "./utils/use-viewport.js";

const OuterContainer = styled(Box)({
  display: "flex",
  overflow: "auto",
  height: "inherit",
});

const InnerContainer = styled(Box)({
  display: "flex",
  flex: 1,
  overflow: open ? "auto" : "hidden",
  height: "inherit",
  flexDirection: "column",
  width: "100%",
  "@media (max-width: 767px)": {
    height: "calc(100% - 60px)",
  },
});

function App() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const locallyEnabledFeats = localStorage.getItem("locallyEnabledFeats");
  const [appDirection, setAppDirection] = useState("ltr");
  const [theme, setTheme] = useState(getTheme(appDirection));
  const [isForceChangePassword, setIsForceChangePassword] = useState();

  const locallyEnabledFeatsIds = useMemo(() => JSON.parse(locallyEnabledFeats)?.ids, [locallyEnabledFeats])

  useEffect(() => {
    setTheme(getTheme(appDirection));
  }, [appDirection]);

  useLayoutEffect(() => {
    hotjar.initialize(5119564, 6);

    if (hotjar.initialized()) {
      console.log('Hotjar session recording is active');
    }

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const encodedData = urlParams.get('data');

    const decodedData = JSON.parse(decodeURIComponent(encodedData));
    const storedData = JSON.parse(localStorage.getItem("fincartLoginData"))

    const newAuthData = JSON.parse(decodedData) || storedData || {}

    dispatch(setNewAuthData({...newAuthData, locallyEnabledFeatsIds}));

    if (decodedData) {
      localStorage.setItem("fincartLoginData", decodedData);
    }
    if(Object.keys(newAuthData).length === 0) {
      window.location.replace(process.env.REACT_APP_LANDING_URL)
    } else if(queryString) {
      const newUrl = window.location.href.split('?')[0]
      window.history.replaceState({ path: newUrl },'', newUrl)
    }

  }, [])

  const authData = useSelector(selectAuthData);

  const { isMobileView } = useViewport();

  const onForceChangePassword = () => {
    localStorage.setItem(
      "fincartLoginData",
      JSON.stringify({ ...JSON.parse(authData), forceChangePassword: false })
    );
    setIsForceChangePassword(false);
  };

  const renderContent = useCallback(() => {
    if (isForceChangePassword) {
      if (isMobileView)
        return (
          <DrawerWithTitle
            title={t("changePassword.mutualizedLabel")}
            theme={theme}
            isMobileView={isMobileView}
            anchor="bottom"
            content={
              <Stack padding="12px 15px" display="flex" gap="16px">
                <Stack display="flex" gap="4px">
                  <Typography
                    variant="semiBoldSmall"
                    color={theme.palette.text.light}
                  >
                    {t("changePassword.subtitle")}
                  </Typography>
                </Stack>

                <ChangePassword
                  t={t}
                  paddings={0}
                  onSuccessCbk={onForceChangePassword}
                />
              </Stack>
            }
            hideCloseIcon={true}
            open={true}
          />
        );

      return (
        <Dialog open={true}>
          <Stack
            width="380px"
            height="348px"
            padding="28px 32px"
            display="flex"
            gap="16px"
          >
            <Stack display="flex" gap="4px">
              <Typography variant="boldSubtitles">
                {t("changePassword.mutualizedLabel")}
              </Typography>
              <Typography
                variant="semiBoldSmall"
                color={theme.palette.text.light}
              >
                {t("changePassword.subtitle")}
              </Typography>
            </Stack>

            <ChangePassword
              t={t}
              paddings={0}
              onSuccessCbk={onForceChangePassword}
            />
          </Stack>
        </Dialog>
      );
    }

    return <Router />;
  }, [isForceChangePassword, isMobileView]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <BrowserRouter>
        <OuterContainer dir={appDirection}>
          <NavBar />
          <InnerContainer>
            <Header
              setAppDirection={setAppDirection}
              appDirection={appDirection}
            />
            {renderContent()}
          </InnerContainer>
        </OuterContainer>
      </BrowserRouter>
      <Toaster
        position="top-right"
        reverseOrder={false}
        toastOptions={{
          duration: 8000,
          success: {
            style: {
              background: theme.palette.white,
            },
          },
          error: {
            style: {
              background: theme.palette.lightRed,
            },
          },
        }}
        containerStyle={{
          top: 55,
          left: 20,
          bottom: 20,
          right: 20,
          ...theme.typography.regular,
        }}
      >
        {(t) => (
          <ToastBar toast={t}>
            {({ message }) => (
              <>
                {message}
                {t.type !== "loading" && (
                  <IconButton disableRipple onClick={() => toast.dismiss(t.id)}>
                    <CrossIcon />
                  </IconButton>
                )}
              </>
            )}
          </ToastBar>
        )}
      </Toaster>
    </ThemeProvider>
  );
}

export default App;
