import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

//SLICE NAMES CANNOT BE DUPLICATES!
const sliceName = "actionCenterSlice";

export const fetchMissingAreaOrders = createAsyncThunk(
  `${sliceName}/fetchMissingAreaOrders`,
  async (data, thunkAPI) => {
    const response = await thunkAPI.extra.apiClient.post({
      url: "missing-area-orders",
      data,
    });
    return response?.data?.body || [];
  }
);

export const clearMissingAreaOrder = createAsyncThunk(
  `${sliceName}/clearMissingAreaOrder`,
  async (data, thinkAPI) => {
    const response = await thinkAPI.extra.apiClient.post({
      url: "missing-area-orders/clear",
      data: data,
    });
    return response;
  }
);

export const actionCenterSlice = createSlice({
  name: sliceName,
  initialState: {
    value: 0,
    isOrdersLoading: false,
  },
  reducers: {},
});

export default actionCenterSlice.reducer;
