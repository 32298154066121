/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from "react";
import { Box, Typography, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";

import Styled from "../../../../analytics.styled";
import TopAreaBars from "./top-area-bar";

const TopAreas = ({ isMobileView, theme, data, isLoading }) => {
  const { t } = useTranslation();

  const [topAreas, setTopAreas] = useState(
    Array(5).fill({ area: "-", count: -1 })
  );

  useEffect(() => {
    if (data) {
      if (data.length < 5) {
        const diff = 5 - data.length;
        for (let i = 0; i < diff; i++) {
          data.push({ area: "-", count: 0 });
        }
      }
      setTopAreas(data);
    }
  }, [data]);
  return (
    <Styled.Card $isMobileView={isMobileView}>
      <Box
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"space-between"}
        width={"95%"}
        height={"100%"}
      >
        <Typography
          variant="mediumText"
          color={theme.palette.text.light}
          sx={{ mt: 2, mb: 2, pl: 1 }}
        >
          {t("analytics.topAreas.title")}
        </Typography>
        <Stack width={"100%"} height={"100%"}>
          {topAreas.map((area, i) => (
            <TopAreaBars
              key={i}
              area={area}
              maxValue={topAreas.length ? topAreas[0].count : 100}
              isLoading={isLoading}
              theme={theme}
              t={t}
            />
          ))}
        </Stack>
      </Box>
    </Styled.Card>
  );
};

export default TopAreas;
