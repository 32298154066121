import { Button, Tooltip as MuiTooltip, styled } from "@mui/material";

const Tooltip = styled(MuiTooltip)(({ theme: { palette} })=>({
  '&.MuiTooltip-tooltip': {
    backgroundColor: `${palette.red}`
  },
}));

const IconButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== "$iconSize",
})(({ $iconSize, theme: { palette } }) => ({
  backgroundColor: palette.white,
  border: `1px solid ${palette.lightGreyOutline}`,
  borderRadius: 8,
  minWidth: $iconSize,
  height: $iconSize,
  boxShadow: '0px 0px 12px 0px rgba(42, 39, 43, 0.04)',
  padding: 6,
  '&.MuiButton-root': {
    '&.Mui-disabled': {
      opacity: 0.4,
    },
    '&:hover': {
      backgroundColor: 'transparent',
      'svg': {
        'path': {
          stroke: palette.black
        }
      }
    }
  }
}))

const Styled = { Tooltip, IconButton }

export default Styled