/* eslint-disable max-len */
import React from 'react'


const SpecialHandlingIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.2308 3H3.76923C3.3444 3 3 3.3444 3 3.76923V12.2308C3 12.6557 3.3444 13 3.76923 13H12.2308C12.6557 13 13 12.6557 13 12.2308V3.76923C13 3.3444 12.6557 3 12.2308 3Z" stroke="#2A272B" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M9.53836 3V6.84617C9.53836 6.94818 9.49784 7.04601 9.42571 7.11814C9.35358 7.19027 9.25575 7.23079 9.15375 7.23079H6.84604C6.74404 7.23079 6.64621 7.19027 6.57408 7.11814C6.50195 7.04601 6.46143 6.94818 6.46143 6.84617V3" stroke="#2A272B" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M12.1294 10.0223L12.5 10.4314L12.8706 10.0223C13.1596 9.70321 13.6019 9.5 14.075 9.5C14.9165 9.5 15.5 10.0924 15.5 10.7984C15.5 11.244 15.2916 11.6897 14.8036 12.2468C14.3194 12.7995 13.6216 13.3944 12.7322 14.1526L12.6848 14.1929L12.5 14.3478L12.3152 14.1929L12.268 14.1527C11.3785 13.3944 10.6807 12.7996 10.1964 12.2468C9.70837 11.6898 9.5 11.244 9.5 10.7984C9.5 10.0924 10.0835 9.5 10.925 9.5C11.3981 9.5 11.8403 9.70321 12.1294 10.0223Z" fill="white" stroke="#2A272B"/>
  </svg>
)

export default SpecialHandlingIcon