import React, { useEffect, useState } from "react";
import { Box, Typography, useTheme  } from "@mui/material";

import Styled from "../order-details.styled";
import { getFormattedDate, getFormattedTime } from "../../../utils/formatDateTime";
import { SequenceIcon } from "../../../assets/icons";

export function ActivityLogsTab({ orderLogs }) {

  const theme = useTheme();
  const [groupedOrderLogs, setGroupedOrderLogs] = useState([]);
  const [sortedDates, setSortedDates] = useState([]);

  useEffect(()=>{
    groupLogsWithSameDate();
  },[orderLogs]);

  const groupLogsWithSameDate = () => {
    // change created at to date and time
    const newOrderLogs = orderLogs.map((log)=>{
      const date = getFormattedDate(log.created_at);
      const time = getFormattedTime(log.created_at);
      return {
        title: log.title,
        desc: log.desc,
        createdAt: log.created_at,
        createdAtDate: date,
        createdAtTime: time,
        by: log.by
      }
    });

    const groupedObjects = newOrderLogs.reduce((grouped, object) => {
      const { createdAtDate } = object;
      if (!grouped[createdAtDate]) {
        grouped[createdAtDate] = [];
      }
      grouped[createdAtDate].push(object);
      return grouped;
    }, {});

    // Sort Array Desc
    const sortedArray = Object.keys(groupedObjects).sort(function(a, b) {
      let dateA = new Date(a);
      let dateB = new Date(b);
      return dateB - dateA;
    });

    setGroupedOrderLogs(groupedObjects);
    setSortedDates(sortedArray);
  }

  const renderDateLogs = (date) => {
    return (
      <Styled.LogDateContainer>
        <Typography variant="semiBoldSmall" color={theme.palette.text.light}>{date}</Typography>
        <Box width={'100%'}  display={'flex'}  flexDirection={'column'}>
          {(groupedOrderLogs[date] || []).map(({ title, desc, createdAt, createdAtTime }, index)=>{
            return (
              <Box width={'100%'} key={createdAt} display={'flex'} gap={'8px'} alignItems={'flex-start'}>
                {index === groupedOrderLogs[date]?.length -1? <Styled.Dot $marginTop={2} $color={theme.palette.greyBorder} $fontSize="10px"/>: <SequenceIcon/>}
                <Box width={'100%'} display={'flex'} gap={'8px'} justifyContent={'space-between'}>
                  <Box display={'flex'} flexDirection={'column'}>
                    <Typography variant="boldSmall" color={theme.palette.black}>{title}</Typography>
                    <Typography variant="semiBoldSmall" color={theme.palette.text.light}>{desc}</Typography>
                  </Box>
                  <Box display={'flex'}>
                    <Typography variant="semiBoldSmall" color={theme.palette.text.light}>{createdAtTime}</Typography>
                  </Box>
                </Box>
              </Box>
            );
          })}
        </Box>
      </Styled.LogDateContainer>
    );
  }

  return (
    <Styled.TabContainer>
      <Box width={'100%'} marginTop='16px' padding={'0px 24px'} display={'flex'} flexDirection={'column'} gap={'8px'}>
        {sortedDates.map((date)=><Box key={date}>{renderDateLogs(date)}</Box>)}
      </Box>
    </Styled.TabContainer>
  );
}
