import toast from "react-hot-toast";
import React, {
  useMemo,
  useState,
  useCallback,
  useEffect,
  useRef,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Typography, useTheme } from "@mui/material";

import { CircleIcon } from "../../assets/icons";
import {
  setCurrentOrderId,
  setIsFiltersApplied,
  selectInitActiveTab,
  setInitActiveTab,
  printLabelAction,
} from "./orders.slice";
import {  fetchOrders} from "./orders.slice"
import { deleteOrdersAction, selectAuthData } from "../../utils/common.slice";
import {
  DRAWERS_IDS,
  STATUS_COLORS,
  STATUS_IDS,
  actionsMenuItems,
  statusTabsInfo,
  statusesTabs,
  NEW_STATUS,
} from "./orders-table.constants";
import { getDeletedOrdersBEParams, getOrderAction, openFileInNewTab } from "./orders-table.utils";
import SearchField from "../../common/components/search-field";
import Cards from "./cards";
import Menu from "../../common/components/menu";
import OrdersTable from "./Table";
import Styled from "./orders.styled";
import useViewport from "../../utils/use-viewport";
import Drawer from "../../common/components/drawer";
import Filters from "./filters/filters";
import useFiltersForm from "./hooks/use-filters-form";
import EditCourier from "./components/edit-courier-drawer";
import { SelectCourier } from "../select-courier";
import {
  fetchOrdersCount,
  selectAppDirection,
  selectOrdersCount,
} from "../../utils/common.slice";
import {
  fetchCouriers,
  fetchCouriersForBulkOrders,
} from "../select-courier/select-courier.slice";
import { OrderDetails } from "../order-details";
import { Dialog } from "../../common/components/dialog";
import { DeleteOrderContent } from "../../common/components/delete-order-content";
import { PrintLabelContent } from "../../common/components/print-label-content";
import { MENU_ITEMS_INDEX } from "../navbar/navbar.utils";
import { setSelectedIndex } from "../navbar/navbar.slice";
import Button from "../../common/components/button";
import { useTranslation } from "react-i18next";
import PrintView from "./print-view";
import { PERMS_IDS, ROWS_PER_PAGE_COUNT } from "../../constants";


function Orders() {
  const dispatch = useDispatch();

  const theme = useTheme();
  const { isMobileView } = useViewport();
  const navigate = useNavigate();
  const initOrdersSummary = useSelector(selectOrdersCount);
  const initActiveTab = useSelector(selectInitActiveTab);
  const appDirection = useSelector(selectAppDirection);
  const authData = useSelector(selectAuthData);
  const { t } = useTranslation();

  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [isSearchModeOn, setIsSearchModeOn] = useState(false);
  const [orders, setOrders] = useState([]);
  const [actionsMenuAnchorEl, setActionsMenuAnchorEl] = useState();
  const [isOrdersLoading, setIsOrdersLoading] = useState(true);
  const [totalOrdersCount, setTotalOrdersCount] = useState(0);
  const [ordersSummary, setOrdersSummary] = useState(initOrdersSummary);
  const [activeFilters, setActiveFilters] = useState({});
  const [page, setPage] = useState(1);
  const [drawerState, setDrawerState] = useState({
    isOpened: false,
    type: "",
    anchor: "bottom",
    title: "",
  });
  const [orderDetailsPage, setOrderDetailsPage] = useState(false);
  const [clickedOrderId, setClickedOrderId] = useState(null);
  const [isOpenOrderDetailsDrawer, setOpenOrderDetailsDrawer] = useState(false);
  const [isDeleteOrderDialog, setDeleteOrderDialog] = useState(false);
  const [isPrintLabelDialog, setPrintLabelDialog] = useState(false);
  const [dialogDescription, setDialogDescription] = useState("");
  const [isPrintView, setIsPrintView] = useState(false);

  const [selectedOrders, setSelectedOrders] = useState([]);
  const [pendingOrder, setPendingOrder] = useState({}); //on click action menu, order pending action

  const [isEditCourierView, setIsEditCourierView] = useState(false);
  const [isEditCourierLoading, setIsEditCourierLoading] = useState(false);
  const [couriers, setCouriers] = useState([]);
  const [isSelectAll, setIsSelectAll] = useState(false);

  const scrollableRef = useRef();

  const deleteOrderDialogActions = [
    {
      key: "close",
      content: (
        <Button
          color="secondary"
          onClick={() => setDeleteOrderDialog(false)}
          label={t("listing.deleteModal.noButton")}
          size={{ width: 156, height: 36 }}
        />
      ),
    },
    {
      key: "delete",
      content: (
        <Button
          onClick={() => deleteOrders()}
          label={t("listing.deleteModal.deleteButton")}
          size={{ width: 156, height: 36 }}
        />
      ),
    },
  ];

  const printLabelDialogActions = [
    {
      key: "thermal",
      content: (
        <Button
          color="secondary"
          onClick={() => printLabel("thermal")}
          label={t("listing.printLabelModal.printThermalButton")}
          size={{ width: 156, height: 36 }}
        />
      ),
    },
    {
      key: "paper",
      content: (
        <Button
          color="secondary"
          onClick={() => printLabel("paper")}
          label={t("listing.printLabelModal.printPaperButton")}
          size={{ width: 156, height: 36 }}
        />
      ),
    },
  ];

  const tabs = useMemo(
    () => [
      ...statusesTabs.map((tab) => ({
        ...tab,
        count: ordersSummary?.[tab.key] || 0,
      })),
    ],
    [ordersSummary]
  );

  const openActionsMenu = (event, order) => {
    setActionsMenuAnchorEl(event.currentTarget);
    setPendingOrder(order);
  };

  const onCloseOrderDetails = () => {
    setOrderDetailsPage(false);
    setPendingOrder({});
  };

  const openDrawer = ({ type, anchor, title }) => {
    let nextAnchor = anchor;
    if (anchor === "left" && appDirection === "ltr") {
      nextAnchor = "right";
    }
    if (anchor === "right" && appDirection === "rtl") {
      nextAnchor = "left";
    }
    setDrawerState({ isOpened: true, type, anchor: nextAnchor, title });
  };

  const toggleOrder = (order) =>
    setSelectedOrders((prevOrders) => {
      if (prevOrders.find(({ id }) => id === order.id)) {
        return prevOrders.filter(({ id }) => id !== order.id);
      }
      return [...prevOrders, order];
    });

  const closeDrawer = () => {
    if (Object.keys(pendingOrder).length > 0 && !isOpenOrderDetailsDrawer) {
      setPendingOrder({});
    }
    setDrawerState((prevState) => ({ ...prevState, isOpened: false }));
  }; //here we haven't clear anchor or type as drawer should always have an anchor otherwise it'll toggle in wrong direction on close

  const handleFetchOrders = ({
    code = searchTerm,
    tabStatus = tabs[selectedTabIndex].key,
    page = 1,
    appliedFilters = activeFilters,
    awaitingActions = false,
    skipUpdateSummary = false,
    isFromSearch = false,
  }) => {
    if (!isOrdersLoading) setIsOrdersLoading(true);
    //order is important here as we override tabs filter if we have applied filters
    const nextData = {
      filters: {
        status: tabStatus,
        ...appliedFilters,
        ...(awaitingActions && { awaitingAction: true }),
        ...(code && { code }),
      },
      limit: ROWS_PER_PAGE_COUNT,
      page,
    };

    if (!skipUpdateSummary) {
      dispatch(
        fetchOrdersCount({
          filters: {
            ...nextData.filters,
            status:
              isFromSearch && !appliedFilters?.status
                ? STATUS_IDS.ALL
                : nextData.filters.status,
          },
          updateCount: false,
        })
      ).then((res) => setOrdersSummary(res.payload?.count));
    }

    dispatch(fetchOrders(nextData)).then((res) => {
      setOrders(res.payload?.orders || []);
      setTotalOrdersCount(res.payload?.count || 1);
      setIsOrdersLoading(false);
      setSelectedOrders([]);
    });
  };

  const onSearch = ({ e, nextSearchTerm }) => {
    setSearchTerm(e?.target?.value || nextSearchTerm);
    setIsSearchModeOn(true);
    if (e?.target?.value?.length >= 4 || nextSearchTerm) {
      setPage(1);
      handleFetchOrders({
        code: e?.target?.value || nextSearchTerm,
        isFromSearch: true,
      });
    }
  };

  const handleNavigation = (direction) => {
    scrollableRef?.current?.scrollIntoView();
    setSelectedOrders([]);
    const nextPageIndex = page + (direction === "left" ? -1 : 1);
    handleFetchOrders({ page: nextPageIndex, skipUpdateSummary: true });
    setPage(nextPageIndex);
  };

  const updateTabsValue = (e, nextIndex) => {
    setIsSelectAll(false);
    setSelectedTabIndex(nextIndex);
    handleFetchOrders({
      tabStatus: tabs[nextIndex].key,
      skipUpdateSummary: true,
    });
    setPage(1);
  };

  const handlePrintLabel = () => {
    setOrderDetailsPage(false);
    setOpenOrderDetailsDrawer(false);
    (authData.permissions || []).includes(PERMS_IDS.PRINT_OVERLAY_VIEW)
      ? setIsPrintView(true)
      : setPrintLabelDialog(true);
  };

  const printLabel = (type) => {
    const parameterObject = { type };
    if (isSelectAll) {
      const filters = {
        ...activeFilters,
        status: STATUS_IDS.PENDING,
        subStatus: NEW_STATUS,
      };
      if (searchTerm) filters.code = searchTerm;
      parameterObject.filters = filters;
      parameterObject.isSelectAll = true;
    } else {
      const selectedOrderIds = currentSelectedOrders.map(({ id }) => id);
      const filters = { ids: selectedOrderIds };
      parameterObject.filters = filters;
    }

    dispatch(printLabelAction(parameterObject)).then((response) => {
      if (response?.payload) {
        openFileInNewTab(response.payload.data);
      }
      if (response?.error) {
        toast.error(t("listing.errorMsgs.printFailure"));
      }
      setPrintLabelDialog(false);
    });
  };

  const toggleSelectAll = () => {
    setIsSelectAll(false)
    if (selectedOrders?.length === orders.length) {
      setSelectedOrders([]);
    } else {
      setSelectedOrders(orders);
    }
  };

  const onSelectAllOrders = (selectAll) => {
    if (selectAll) {
      setSelectedOrders(orders);
      setIsSelectAll(true);
    } else {
      setSelectedOrders([]);
      setIsSelectAll(false);
    }
  };

  const handleEditOrder = (orders) => {
    navigate("/order/manage");
    dispatch(setCurrentOrderId(orders[0].id));
  };

  const handleEditCourier = (orders = []) => {
    const openEditCourierDrawer = () => {
      return openDrawer({
        type: DRAWERS_IDS.EDIT_COURIER,
        anchor: isMobileView ? "bottom" : "right",
        title: t("listing.actions.editCourier"),
      });
    };

    setIsEditCourierLoading(true);
    if (isSelectAll) {
      const filters = {
        ...activeFilters,
        status: STATUS_IDS.PENDING,
        subStatus: NEW_STATUS,
      };
      if (searchTerm) filters.code = searchTerm;
      openEditCourierDrawer();
      dispatch(fetchCouriersForBulkOrders({ isSelectAll: true, filters })).then(
        (res) => {
          setCouriers(res.payload);
          setIsEditCourierLoading(false);
        }
      );
    } else if (orders.length === 1) {
      let order = orders[0];
      if (isMobileView) {
        setIsEditCourierView(true);
      } else {
        openEditCourierDrawer();
      }

      dispatch(
        fetchCouriers({
          amount: order.amount,
          zone: order.area,
          city: order.city,
          orderType: order.type,
          packageType: order.packageType ? order.packageType : "parcel",
        })
      ).then((res) => {
        setCouriers(res.payload);
        setIsEditCourierLoading(false);
      });
    } else {
      let data = { orders: [] };
      selectedOrders.forEach((order) => {
        if (
          !data.orders.find(
            (obj) => obj.area === order.area && obj.city === order.city
          )
        ) {
          data.orders.push({ area: order.area, city: order.city });
        }
      });
      openEditCourierDrawer();
      dispatch(fetchCouriersForBulkOrders(data)).then((res) => {
        setCouriers(res.payload);
        setIsEditCourierLoading(false);
      });
    }
  };

  const handleDeleteOrder = () => {
    setOrderDetailsPage(false);
    setOpenOrderDetailsDrawer(false);
    let dialogDescription = `${t("listing.deleteModal.msg")} ${
      currentSelectedOrders.length > 1 || isSelectAll
        ? `${ isSelectAll ? totalOrdersCount:currentSelectedOrders.length} ${t("listing.ordersLabel")}`
        : currentSelectedOrders[0]?.code
    }?`;
    if (isMobileView)
      openDrawer({
        type: DRAWERS_IDS.DELETE_ORDER,
        anchor: "bottom",
        title: t("listing.actions.deleteOrder"),
      });
    else setDeleteOrderDialog(true);
    setDialogDescription(dialogDescription);
  };

  const deleteOrders = (currentActiveFilters = activeFilters) => {
    const paramObject = getDeletedOrdersBEParams(currentSelectedOrders, isSelectAll, currentActiveFilters, searchTerm);
    //we set status all to not send pending to delete
    dispatch(deleteOrdersAction(paramObject)).then(() => handleFetchOrders({ tabStatus: STATUS_IDS.ALL }));
    if (isMobileView) closeDrawer();
    else setDeleteOrderDialog(false);
    setPendingOrder({});
  };

  const onApplyFilter = (appliedFilters) => {
    setActiveFilters(appliedFilters);
    const statusFilter = appliedFilters.status;

    handleFetchOrders({
      appliedFilters: appliedFilters,
      tabStatus: statusFilter || STATUS_IDS.ALL,
    });

    dispatch(setIsFiltersApplied(Object.keys(appliedFilters).length > 0));
    setPage(1);

    if (statusFilter) {
      setSelectedTabIndex(tabs.find(({ key }) => key === statusFilter)?.index);
      return;
    }

    if (!statusFilter) {
      setSelectedTabIndex(0);
    }

    if (ordersSummary !== initOrdersSummary) {
      setOrdersSummary(initOrdersSummary);
    }
  };

  const currentSelectedOrders = useMemo(
    () =>
      Object.keys(pendingOrder).length > 0 ? [pendingOrder] : selectedOrders,
    [selectedOrders, pendingOrder]
  );

  const onApplyOrderAction = (key) => {
    return getOrderAction({
      isSelectAll,
      activeFilters,
      searchTerm,
      selectedOrders: currentSelectedOrders,
      selectedTab: tabs[selectedTabIndex].key,
      handleEditOrder,
      handleDeleteOrder,
      handlePrintLabel,
      dispatch,
      handleEditCourier,
      t,
    })[key];
  };

  const onClickMenuItem = (key) => {
    onApplyOrderAction(key)();
    setActionsMenuAnchorEl(null);
  };

  const checkIfTabDisabled = useCallback(
    (index, key) => {
      if (index == 0) return false;
      return Boolean(
        (activeFilters.status && activeFilters.status !== key) ||
          ordersSummary?.[key] === 0
      );
    },
    [activeFilters.status, ordersSummary]
  );

  useEffect(() => {
    // Navbar orders focus
    dispatch(setSelectedIndex(MENU_ITEMS_INDEX.ORDERS));
    //Fetch orders
    handleFetchOrders({});

    //Fetch orders summary
    if (!initOrdersSummary) {
      dispatch(fetchOrdersCount({})).then((res) => {
        setOrdersSummary(res.payload?.count);
      });
    } else {
      setOrdersSummary(initOrdersSummary);
    }
    if (initActiveTab) {
      setSelectedTabIndex(
        statusTabsInfo.find(({ key }) => key === initActiveTab)?.index || 0
      );
    }
    dispatch(setInitActiveTab(null));
    dispatch(setIsFiltersApplied(false));
  }, []);

  useEffect(() => {
    if (!searchTerm && isSearchModeOn) {
      handleFetchOrders({ code: "", isFromSearch: true });
      setPage(1);
      setIsSearchModeOn(false);
    }
  }, [searchTerm, isSearchModeOn]);

  const DRAWERS_CONTENTS = {
    [DRAWERS_IDS.SEARCH]: (
      <SearchField
        searchTerm={searchTerm}
        onSearch={onSearch}
        isFullWidth={true}
        theme={theme}
        placeholder={t("listing.search.placeholder")}
      />
    ),
    [DRAWERS_IDS.FILTERS]: (
      <Filters
        activeFilters={activeFilters}
        theme={theme}
        closeDrawer={closeDrawer}
        filtersFormProps={useFiltersForm()}
        onApplyFilter={onApplyFilter}
        isMobileView={isMobileView}
        appDirection={appDirection}
      />
    ),
    [DRAWERS_IDS.EDIT_COURIER]: (
      <EditCourier
        couriers={couriers}
        selectedOrders={currentSelectedOrders}
        closeDrawer={closeDrawer}
        closeOrderDetails={onCloseOrderDetails}
        isLoading={isEditCourierLoading}
        setOrders={setOrders}
        isSelectAll={isSelectAll}
        activeFilters={activeFilters}
        searchTerm={searchTerm}
      />
    ),
    [DRAWERS_IDS.DELETE_ORDER]: (
      <DeleteOrderContent
        description={dialogDescription}
        actionButtons={deleteOrderDialogActions}
        height="148px"
        isMobileView={isMobileView}
      />
    ),
  };

  const renderTabLabel = useCallback(
    (index, key, count) => {
      const isTabActive = index === selectedTabIndex;
      return (
        <Typography
          variant={isTabActive ? "boldSubtitles" : "regular"}
          color={theme.palette[isTabActive ? "black" : "gray"]}
        >
          {`${t(`listing.tabsLabels.${key}`)} (${count})`}
        </Typography>
      );
    },
    [selectedTabIndex]
  );

  const actionBarProps = {
    t,
    theme,
    setIsSelectAll: onSelectAllOrders,
    isSelectAll: isSelectAll,
    searchTerm: searchTerm,
    activeFilters: activeFilters,
    selectedTab: tabs[selectedTabIndex].key,
    onSearch: () =>
      openDrawer({
        type: DRAWERS_IDS.SEARCH,
        anchor: "bottom",
        title: t("listing.search.title"),
      }),
    onFilter: (anchorPos = "right") =>
      openDrawer({
        type: DRAWERS_IDS.FILTERS,
        anchor: anchorPos,
        title: t("listing.filters.title"),
      }),
    fetchAwaitingActionsOrders: (value) =>
      handleFetchOrders({ awaitingActions: value }),
    onApplyOrderAction,
  };

  //in mobile view we open the cards view from here by setting isEditCourier by true
  if (isEditCourierView && couriers?.length > 0) {
    const selectedOrderData = currentSelectedOrders[0];
    return (
      <SelectCourier
        isEditMode={true}
        couriers={couriers}
        orderData={selectedOrderData}
        isCreateOrderView={false}
        showBackButton={true}
        pageTitle={selectedOrderData?.code}
        actionButtonTitle={t("selectCourier.updateCourierButton")}
        setIsSelectingCourier={setIsEditCourierView}
        isLoading={isEditCourierLoading}
      />
    );
  }

  if (orderDetailsPage)
    return (
      <OrderDetails
        orderId={clickedOrderId}
        onCloseOrderDetails={onCloseOrderDetails}
      />
    );

  return (
    <Styled.Container isMobileView={isMobileView}>
      {isPrintView && (
        <PrintView
          setIsPrintView={setIsPrintView}
          t={t}
          dispatch={dispatch}
          selectedOrders={currentSelectedOrders}
          isMobileView={isMobileView}
          setPendingOrder={setPendingOrder}
          isSelectAll={isSelectAll}
          activeFilters={activeFilters}
          searchTerm={searchTerm}
        />
      )}
      <Styled.HeaderWrapper isMobileView={isMobileView}>
        {/* TABS */}
        <Styled.Tabs
          value={selectedTabIndex}
          onChange={updateTabsValue}
          isMobileView={isMobileView}
          indicatorColor="secondary"
          aria-label="icon position tabs example"
          variant="scrollable"
        >
          {tabs.map(({ key, noIcon, count, index }) => (
            <Styled.Tab
              disabled={count !== 0 ? checkIfTabDisabled(index, key) : true}
              opacity={
                count !== 0 ? (checkIfTabDisabled(index, key) ? 0.4 : 1) : 0.4
              }
              key={key}
              icon={
                noIcon ? null : (
                  <CircleIcon fill={theme.palette[STATUS_COLORS[key]]} />
                )
              }
              label={renderTabLabel(index, key, count)}
              iconPosition="start"
            />
          ))}
        </Styled.Tabs>

        {/* SEARCH (FOR TABLE) */}
        {!isMobileView && (
          <SearchField
            theme={theme}
            onSearch={onSearch}
            searchTerm={searchTerm}
            placeholder={t("listing.search.placeholder")}
          />
        )}
      </Styled.HeaderWrapper>

      {isMobileView ? (
        // MOBILE (CARDS)
        <Cards
          t={t}
          page={page}
          theme={theme}
          orders={orders}
          scrollableRef={scrollableRef}
          actionBarProps={actionBarProps}
          totalOrdersCount={totalOrdersCount}
          selectedOrders={selectedOrders}
          isOrdersLoading={isOrdersLoading}
          setPendingOrder={setPendingOrder}
          toggleOrder={toggleOrder}
          openActionsMenu={openActionsMenu}
          handleNavigation={handleNavigation}
          setOrderDetailsPage={setOrderDetailsPage}
          setClickedOrderId={setClickedOrderId}
          clickedOrderId={clickedOrderId}
          setIsSelectAll={onSelectAllOrders}
          isSelectAll={isSelectAll}
          searchTerm={searchTerm}
          activeFilters={activeFilters}
          selectedTab={tabs[selectedTabIndex].key}
        />
      ) : (
        // DESKTOP (Table)
        <OrdersTable
          t={t}
          page={page}
          theme={theme}
          rows={orders}
          scrollableRef={scrollableRef}
          actionBarProps={actionBarProps}
          totalOrdersCount={totalOrdersCount}
          selectedOrders={selectedOrders}
          isOrdersLoading={isOrdersLoading}
          setPendingOrder={setPendingOrder}
          toggleOrder={toggleOrder}
          openActionsMenu={openActionsMenu}
          handleNavigation={handleNavigation}
          toggleSelectAll={toggleSelectAll}
          isOpenOrderDetailsDrawer={isOpenOrderDetailsDrawer}
          setOpenOrderDetailsDrawer={setOpenOrderDetailsDrawer}
          setClickedOrderId={setClickedOrderId}
          clickedOrderId={clickedOrderId}
        />
      )}

      {/* ACTIONS MENU (on click more on cars/table) */}
      <Menu
        id="actions-drop-down-menu"
        theme={theme}
        anchorEl={actionsMenuAnchorEl}
        onCloseMenu={() => {
          setActionsMenuAnchorEl(null);
          setPendingOrder({});
        }}
        isMenuOpen={Boolean(actionsMenuAnchorEl)}
        items={actionsMenuItems.map((action) => ({
          ...action,
          onClick: () => onClickMenuItem(action.key),
        }))}
        dir={appDirection}
        t={t}
        translationKey={"listing.actions"}
        currentSelectedOrders={currentSelectedOrders}
      />
      <Drawer
        theme={theme}
        title={drawerState.title}
        isMobileView={isMobileView}
        open={drawerState.isOpened}
        anchor={drawerState.anchor}
        content={DRAWERS_CONTENTS[drawerState.type]}
        maxHeightInMobileView={
          drawerState.type === DRAWERS_IDS.SEARCH ? "20%" : undefined
        }
        height={
          [DRAWERS_IDS.FILTERS, DRAWERS_IDS.EDIT_COURIER].includes(
            drawerState.type
          )
            ? "100%"
            : "fit-content"
        }
        closeDrawer={closeDrawer}
        dir={appDirection}
        appDirection={appDirection}
      />
      <Dialog
        content={
          <DeleteOrderContent
            description={dialogDescription}
            actionButtons={deleteOrderDialogActions}
            isMobileView={isMobileView}
          />
        }
        isOpen={isDeleteOrderDialog}
        setIsDialogOpen={setDeleteOrderDialog}
      />

      <Dialog
        content={
          <PrintLabelContent
            description={t("listing.printLabelModal.msg")}
            actionButtons={printLabelDialogActions}
            isMobileView={isMobileView}
          />
        }
        isOpen={isPrintLabelDialog}
        setIsDialogOpen={setPrintLabelDialog}
      />
    </Styled.Container>
  );
}

export default Orders;
