import React from "react";
import ProgressBar from "@ramonak/react-progress-bar";
import { Box, Typography, Skeleton } from "@mui/material";
import Styled from "./courier-progress-bar.styled"

import { getCustomLabel, getCompletedPercentage } from "./courier-progress-bar.utils";

const CourierProgressBar = ({
  courier,
  maxPercentage,
  handleClick,
  isSelected,
  isLoading,
  theme,
  t
}) => {
  return (
    <Box display={"flex"} alignItems={"center"} pb={2}>
      <Styled.ProgressBar
        isSelected={isSelected}
        onClick={() => {
          if (courier._id) handleClick(courier._id);
        }}
      >
        {isLoading ? (
          <Skeleton width={"100%"} height={60} />
        ) : (
          <ProgressBar
            completed={
              getCompletedPercentage(courier.percentage)
            }
            maxCompleted={maxPercentage > 0 ? maxPercentage : 100} // total will be the highest courier
            bgColor={theme.palette.lightgrey}
            baseBgColor={"transparent"}
            height="60px"
            borderRadius="10px"
            customLabel={
              getCustomLabel(courier, theme)
            }
            labelAlignment="left"
            labelColor={theme.palette.primary.main}
          />
        )}
      </Styled.ProgressBar>
      <Box display={"flex"} flexDirection={"column"}>
        <Typography variant="semiBoldSmall" color={theme.palette.text.primary}>
          {`${courier.percentage.toFixed(0)}%`}
        </Typography>

        <Typography variant="semiBoldSmall" color={theme.palette.text.light}>
          {t("analytics.topCouriers.totalOrders", { count: courier?.totalOrders ?? 0 })}
        </Typography>
      </Box>
    </Box>
  );
};

export default CourierProgressBar;
