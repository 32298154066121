import { CourierIcon, DeleteIcon, PrintLabelIcon, EditIcon } from "../../assets/icons";
import { ORDERS_ACTIONS_IDS } from "../orders/orders-table.constants";

const actionBarButtonsProps = [
  {key: ORDERS_ACTIONS_IDS.EDIT, icon: EditIcon},
  {key: ORDERS_ACTIONS_IDS.EDIT_COURIER, icon: CourierIcon},
  {key: ORDERS_ACTIONS_IDS.PRINT_LABEL, icon: PrintLabelIcon},
  {key: ORDERS_ACTIONS_IDS.DELETE, icon: DeleteIcon},
]

const STATUSES = {
  PENDING: "pending",
  SUCCESSFUL: "successful",
  UNSUCCESSFUL: "unsuccessful",
  PROCESSING: "processing",
};

const TABS_IDS = {
  ACTIVITY_LOGS: 'activityLogs',
  DETAILS: 'details'
}

const BILLING_STATUSES_IDS = {
  NEW: "new",
  COMPLETE: "complete",
  INVOICED: "invoice",
  SETTELED: "settled"
};

const BILLING_STATUSES_LABELS = {
  new: "new",
  complete: "completed",
  invoiced: "invoiced",
  settled: "settled"
}

const TABS = [TABS_IDS.ACTIVITY_LOGS, TABS_IDS.DETAILS]

const STATUS_COLORS = {
  [STATUSES.PENDING]: "gray",
  [STATUSES.SUCCESSFUL]: "limeGreen",
  [STATUSES.UNSUCCESSFUL]: "red",
  [STATUSES.PROCESSING]: "orange",
};

export {
  actionBarButtonsProps,
  STATUS_COLORS,
  STATUSES,
  BILLING_STATUSES_IDS,
  BILLING_STATUSES_LABELS,
  TABS
};
