/* eslint-disable max-len */
import React from "react"

const PhoneIcon = ({ width="20", height="20", color="#2A272B" }) => (
  <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.887 16.5623C12.5488 16.9896 13.3374 17.0529 14.1203 16.9667C14.9031 16.8806 15.6325 16.5273 16.1857 15.9663L16.6693 15.4932C16.8813 15.2762 17 14.9848 17 14.6813C17 14.3778 16.8813 14.0864 16.6693 13.8694L14.6166 11.837C14.4016 11.6255 14.1121 11.507 13.8106 11.507C13.5091 11.507 13.2197 11.6255 13.0046 11.837C12.7878 12.0491 12.4965 12.1679 12.1932 12.1679C11.89 12.1679 11.5987 12.0491 11.3819 11.837L8.15783 8.61092C8.05028 8.50487 7.96487 8.37848 7.90658 8.2391C7.84828 8.09971 7.81826 7.95013 7.81826 7.79903C7.81826 7.64794 7.84828 7.49835 7.90658 7.35897C7.96487 7.21959 8.05028 7.0932 8.15783 6.98714C8.36917 6.77196 8.4876 6.48233 8.4876 6.18062C8.4876 5.87892 8.36917 5.58929 8.15783 5.37411L6.11595 3.33094C5.89908 3.11879 5.60785 3 5.30457 3C5.00128 3 4.71005 3.11879 4.49319 3.33094L4.02033 3.81485C3.45972 4.36838 3.10668 5.09818 3.02056 5.88153C2.93445 6.66489 3.12051 7.57686 3.54747 8.2391C5.7721 11.5197 8.60268 14.3448 11.887 16.5623Z" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
  </svg>

)

export default PhoneIcon
