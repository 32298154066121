/* eslint-disable react/no-array-index-key */
import React from "react";

import TopCitiesStyled from "./top-cities.styled";

const TopCitiesSkeleton = () =>
  Array(5)
    .fill()
    .map((_, index) => <TopCitiesStyled.SkeletonSections key={index} />);

export default TopCitiesSkeleton;
