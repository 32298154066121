import React from "react"

const BulkInfoIcon = () => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="6" cy="6" r="6" fill="#D4D4D4"/>
    <path d="M6 6V9" stroke="#7F7D80" strokeWidth="1.5" strokeLinecap="round"/>
    <circle cx="6" cy="3.75" r="0.75" fill="#7F7D80"/>
  </svg>
)

export default BulkInfoIcon