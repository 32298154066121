/* eslint-disable max-len */
import React from "react";

const BulkyIcon = ({ width="14", height="14", color="#2A272B" }) => (
  <svg width={width} height={height} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.6923 3.2876H6.53843C6.02863 3.2876 5.61536 3.69725 5.61536 4.20258V8.32C5.61536 8.82533 6.02863 9.23498 6.53843 9.23498H10.6923C11.2021 9.23498 11.6154 8.82533 11.6154 8.32V4.20258C11.6154 3.69725 11.2021 3.2876 10.6923 3.2876Z"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.23071 11.5223H11.2553C11.4975 11.5233 11.7295 11.6186 11.9015 11.7877L12.9999 12.8948"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1 1.00012H1.92308C2.16789 1.00012 2.40268 1.09652 2.57579 1.26811C2.7489 1.43971 2.84615 1.67244 2.84615 1.9151V9.69246"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.23074 11.2936C4.2353 11.3697 4.2353 11.4461 4.23074 11.5223C4.20086 11.9336 4.01116 12.3173 3.70159 12.5927C3.39202 12.8682 2.98672 13.0138 2.57101 12.999C2.15529 12.9841 1.76159 12.81 1.47278 12.5133C1.18397 12.2165 1.02258 11.8203 1.02258 11.4079C1.02258 10.9956 1.18397 10.5993 1.47278 10.3026C1.76159 10.0058 2.15529 9.83171 2.57101 9.81689C2.98672 9.80207 3.39202 9.9477 3.70159 10.2231C4.01116 10.4985 4.20086 10.8823 4.23074 11.2936Z"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.38452 6.94769H9.3076"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default BulkyIcon;
