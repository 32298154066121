/* eslint-disable max-len */
import React from "react"

const DescriptionIcon = ({ width="14", height="18", color="#2A272B" }) => (
  <svg width={width} height={height} viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10 2.23077H11.8C12.1183 2.23077 12.4235 2.36044 12.6485 2.59126C12.8736 2.82207 13 3.13512 13 3.46154V15.7692C13 16.0957 12.8736 16.4087 12.6485 16.6395C12.4235 16.8703 12.1183 17 11.8 17H2.2C1.88174 17 1.57652 16.8703 1.35147 16.6395C1.12643 16.4087 1 16.0957 1 15.7692V3.46154C1 3.13512 1.12643 2.82207 1.35147 2.59126C1.57652 2.36044 1.88174 2.23077 2.2 2.23077H4" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
    <path d="M8.8 1H5.2C4.53726 1 4 1.55103 4 2.23077V2.84615C4 3.52589 4.53726 4.07692 5.2 4.07692H8.8C9.46274 4.07692 10 3.52589 10 2.84615V2.23077C10 1.55103 9.46274 1 8.8 1Z" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
    <path d="M4 7.15405H10" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
    <path d="M4 10.2307H10" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
    <path d="M4 13.3079H10" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
  </svg>

)

export default DescriptionIcon
