/* eslint-disable max-len */
import React from "react"

const PrintLabelWithWarning = ({ iconOuterStroke = '#D52A5D', iconInnerStroke="#F4F4F4" }) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.625 6.5V13.4999" stroke={iconOuterStroke} strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M7.375 6.5V13.4999" stroke={iconOuterStroke} strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M9.125 6.5V13.4999" stroke={iconOuterStroke} strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M11.75 6.5V13.4999" stroke={iconOuterStroke} strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M14.375 6.5V13.4999" stroke={iconOuterStroke} strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M6.44615 3H3.53846C3.39565 3 3.25869 3.05673 3.15771 3.15771C3.05673 3.25869 3 3.39565 3 3.53846V6.44612" stroke={iconOuterStroke} strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M16.9999 6.44612V3.53846C16.9999 3.39565 16.9431 3.25869 16.8422 3.15771C16.7412 3.05673 16.6042 3 16.4614 3H13.5537" stroke={iconOuterStroke} strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M13.5537 17.0001H16.4614C16.6042 17.0001 16.7412 16.9433 16.8422 16.8424C16.9431 16.7414 16.9999 16.6044 16.9999 16.4616V13.554" stroke={iconOuterStroke} strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M3 13.554V16.4616C3 16.6044 3.05673 16.7414 3.15771 16.8424C3.25869 16.9433 3.39565 17.0001 3.53846 17.0001H6.44615" stroke={iconOuterStroke} strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M15 20C17.7614 20 20 17.7614 20 15C20 12.2386 17.7614 10 15 10C12.2386 10 10 12.2386 10 15C10 17.7614 12.2386 20 15 20Z" fill={iconOuterStroke}/>
    <path d="M15 12.5V15" stroke={iconInnerStroke} strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M15 17.5C15.3452 17.5 15.625 17.2202 15.625 16.875C15.625 16.5298 15.3452 16.25 15 16.25C14.6548 16.25 14.375 16.5298 14.375 16.875C14.375 17.2202 14.6548 17.5 15 17.5Z" fill={iconInnerStroke}/>
  </svg>

)

export default PrintLabelWithWarning