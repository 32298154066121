/* eslint-disable max-len */
import React from "react";

const LeftArrowIcon = ({ width="20", height="20", color= "#7F7D80" }) => (
  <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13 16L7 10L13 4" stroke={color} strokeLinecap="round"/>
  </svg>
);

export default LeftArrowIcon;
