/* eslint-disable max-len */
import React from "react";

const PersonIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.00011 7.94455C6.02766 7.94455 4.42868 6.38996 4.42868 4.47227C4.42868 2.55459 6.02766 1 8.00011 1C9.97255 1 11.5715 2.55459 11.5715 4.47227C11.5715 6.38996 9.97255 7.94455 8.00011 7.94455Z"
      stroke="#2A272B"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15 15C14.6721 13.683 13.7961 12.4989 12.5204 11.6484C11.2446 10.7979 9.64765 10.3333 8 10.3333C6.35235 10.3333 4.75536 10.7979 3.47963 11.6484C2.2039 12.4989 1.32788 13.683 1 15"
      stroke="#2A272B"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default PersonIcon;
