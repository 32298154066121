import { Link, Stack, styled } from "@mui/material";

const ActionButtonWrapper = styled(Link)(({ theme: { palette } }) => ({
  display: 'flex',
  width: '71px',
  minWidth: 71,
  cursor: 'pointer',
  alignItems: 'center',
  background: palette.background.light,
  borderRadius: "8px",
  boxShadow: palette.boxShadow.dashboardCards,
  justifyContent: 'center'
}))

const AccountCardWrapper = styled(Stack, { shouldForwardProp: (prop) => prop !== "$noPaddings" && prop !== "$isMobileView"}
)(({ theme: { palette }, $noPaddings, $isMobileView }) => ({
  display: 'flex',
  ...(!$noPaddings && {padding: '20px'}),
  justifyContent: 'space-between',
  gap: '20px',
  width: $isMobileView? '100%' : '25%',
  borderRadius: "8px",
  border: `1px solid ${palette.lightGreyOutline}`,
  boxShadow: palette.boxShadow.dashboardCards,
  background: palette.white,
}))

const Button = styled(Link)(({ theme: { palette, typography } }) => ({
  color: palette.black,
  textDecorationColor: palette.black,
  cursor: 'pointer',
  ":hover": {
    color: `${palette.black}`,
  },
  ...(typography.boldUnderline)
}))

const Styled = { ActionButtonWrapper, Button, AccountCardWrapper}

export default Styled;