import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { SelectCourierCards } from "../../../common/components/select-courier-cards"
import { CircularProgress, Box } from "@mui/material";
import { fetchCouriersList } from '../../../utils/common.slice';



const   CourierFilter = ({ t, courierIds, setValue }) => {
  const [isCouriersLoading, setIsCouriersLoading] = useState(true)
  const [couriers, setCouriers] = useState([])

  const dispatch = useDispatch()

  const updateCouriers = (selectedCourierId) => {
    if(courierIds.includes(selectedCourierId)) {
      setValue('courierIds', courierIds.filter((id) => id !== selectedCourierId))
      return
    }
    setValue('courierIds', [...courierIds, selectedCourierId])
  }

  useEffect(() => {
    setIsCouriersLoading(true)
    dispatch(fetchCouriersList()).then((res) => {
      setCouriers(res?.payload?.data?.body?.couriers || [])
      setIsCouriersLoading(false)
    })
  }, [])

  if(isCouriersLoading) return <Box display="flex" width="100%" height="100%" alignItems="center" justifyContent="center"><CircularProgress color="secondary" /> </Box>

  return(
    <Box padding="10px">
      <SelectCourierCards t={t} rows={couriers} isMultiSelect={true} updateCouriers={updateCouriers} selectedCourierIds={courierIds} hideNote={true} />
    </Box>
  )
}

export default CourierFilter