import React, { useEffect, useState } from "react";
import { Box, Typography} from "@mui/material";
import Styled from "../../../../analytics.styled";
import TopCitiesStyled from "./top-cities.styled";
import { useTranslation } from "react-i18next";

import Sections from "./sections";

const TopCities = ({ isMobileView, theme, data, isLoading }) => {
  const { t } = useTranslation();

  const [topCities, setTopCities] = useState([]);

  useEffect(() => {
    if (data) {
      if (data.length < 10) {
        const diff = 10 - data.length;
        for (let i = 0; i <= diff; i++) {
          data.push({ city: "", percentage: 0 });
        }
      }
      setTopCities(data);
    }
  }, [data]);
  return (
    <Styled.Card $isMobileView={isMobileView}>
      <Box
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"space-between"}
        width={"95%"}
        height={"100%"}
      >
        <Typography
          variant="mediumText"
          color={theme.palette.text.light}
          sx={{ mt: 2, mb: 2, pl: 1 }}
        >
          {t("analytics.topCities.title")}
        </Typography>
        <TopCitiesStyled.TopCitiesCard>
          <Sections data={topCities?.slice(0, 5)} isLoading={isLoading} />
          <Sections data={topCities?.slice(5, 10)} isLoading={isLoading} isSecondColumn />
        </TopCitiesStyled.TopCitiesCard>
      </Box>
    </Styled.Card>
  );
};

export default TopCities;
