import { AddressIcon, AmountIcon, CustomerIcon, DescriptionIcon, PhoneIcon, SaveBlackIcon } from "../../assets/icons";
import { FIELDS_TYPES } from "../../constants";
import toEnglishNumber from "../../utils/toEnglishNumber";
import { PHONE_REGEX } from "./create-order.constants";

const FIELDS_KEYS = {
  SHIPPING_NOTES: 'shippingNotes',
  BACKUP_PHONE: 'backupPhone',
  REFERENCE_NUMBER: 'referenceNumber',
  NAME: 'name',
  PHONE: 'phone',
  AREA: 'area',
  ADDRESS: 'address',
  SET_AS_DEFAULT: 'setAsDefault',
  CONTACT_PHONE: 'contactPhone',
  CONTACT_NAME: 'contactName',
  ADDRESS_LINE: 'addressLine',
  PICKUP_LOCATION_AREA: 'pickupLocationArea',
  LOCATION_NAME: 'locationName',
  PICKUP_LOCATION: 'pickupLocation',
  PAYMENT_TYPE: 'paymentType',
  COLLECTION_AMOUNT: 'collectionAmount',
  PACKAGE_TYPE: 'packageType',
  NO_OF_ITEMS: 'noOfItems',
  DESCRIPTION: 'description'
}

const optionalFields = [
  {
    key: FIELDS_KEYS.SHIPPING_NOTES,
    name: FIELDS_KEYS.SHIPPING_NOTES,
    prefixIcon: DescriptionIcon,
    isOptional: true,
  },
  {
    key: 'backupPhone',
    name: 'backupPhone',
    prefixIcon: PhoneIcon,
    validation: {
      validate: (value) => PHONE_REGEX.test(toEnglishNumber(value))? true : 'Invalid phone'
    },
    isOptional: true,
  },
  {
    key: 'referenceNumber',
    name: 'referenceNumber',
    prefixIcon: SaveBlackIcon,
    isOptional: true
  }];

const getCustomerDetailsFormFields = () => [
  {
    name: 'name',
    prefixIcon: CustomerIcon,
  },
  {
    name: 'phone',
    prefixIcon: PhoneIcon,
  },
  {
    name: 'area',
    type: FIELDS_TYPES.NESTED_DROP_DOWN,
    prefixIcon: () => { },
    optionsId: 'areas'
  },
  {
    key: 'address',
    name: 'address',
    supportsMultiLine: true,
    prefixIcon: AddressIcon,
    md: 8,
    lg: 8
  },
];

const getAddPickupLocationsFields = () => [
  {
    key: 'locationName',
    name: 'locationName',
    prefixIcon: () => { },
    xs: 12,
    lg: 12,
    md: 12,
  },
  {
    key: 'pickupLocationArea',
    name: 'pickupLocationArea',
    type: FIELDS_TYPES.NESTED_DROP_DOWN,
    prefixIcon: () => { },
    xs: 12,
    lg: 12,
    md: 12,
    optionsId: 'areas'
  },
  {
    key: 'addressLine',
    name: 'addressLine',
    xs: 12,
    lg: 12,
    md: 12,
    prefixIcon: AddressIcon,
  },
  {
    key: 'contactName',
    name: 'contactName',
    prefixIcon: () => { },
    xs: 6,
    lg: 6,
    md: 6,
  },
  {
    key: 'contactPhone',
    name: 'contactPhone',
    prefixIcon: () => { },
    xs: 6,
    lg: 6,
    md: 6
  },
  {
    key: 'setAsDefault',
    name: 'setAsDefault',
    type: FIELDS_TYPES.CHECKBOX,
    noPlaceholder: true,
    xs: 12,
    lg: 12,
    md: 12,
  },
];

const getOrderDetailsFormFields = ({ openDrawer, amountFieldDetails: { name }, }) => [
  {
    name: 'pickupLocation',
    menuButtonProps: { hasMenuButton: true, text: 'Add Pickup Location', onClick: openDrawer },
    type: FIELDS_TYPES.SELECT,
    optionsId: 'pickupLocations',
  },
  {
    name: 'paymentType',
    type: FIELDS_TYPES.SELECT,
    optionsId: 'paymentMethods',
  },
  {
    name,
    prefixIcon: AmountIcon,
  },
  {
    name: 'packageType',
    type: FIELDS_TYPES.SELECT,
    optionsId: 'packageTypes',
    xs: 6,
    md: 2,
    lg: 2
  },
  {
    name: 'noOfItems',
    type: FIELDS_TYPES.NUMBER,
    xs: 6,
    md: 2,
    lg: 2
  },
  {
    name: 'description',
    prefixIcon: DescriptionIcon,
    xs: 12,
    md: 8,
    lg: 8,
    isOptional: true,
  },
  {
    name: 'openPackageAllowed',
    type: FIELDS_TYPES.CHECKBOX,
    prefixIcon: () => { },
    xs: 12,
    lg: 12,
    md: 12,
    isOpenPackage: true
  },
];

const adaptOrderDataToBackendFormat = (data) => {
  const {
    restCustomerDetails, optionalInfo, restOrderDetails, area, city, orderType,
    amount, packageType, paymentType, pickupLocation, hasCustomerDetails, hasOrderDetails,
  } = data

  return({
    ...((hasCustomerDetails || optionalInfo) && {
      customerDetails: {
        ...restCustomerDetails,
        ...(restCustomerDetails.phone && { phone: toEnglishNumber(restCustomerDetails.phone) }),
        ...( city && {
          address: {
            addressLine: restCustomerDetails.address,
            city: city,
            area: area,
          }}),
        ...(optionalInfo && {
          ...optionalInfo,
          ...(optionalInfo.backupPhone && { backupPhone: toEnglishNumber(optionalInfo.backupPhone) })
        })
      }}),
    ...(hasOrderDetails && {
      orderDetails: {
        ...(orderType && { type: orderType }),
        ...(paymentType && { paymentType }),
        ...(pickupLocation && { locationId: pickupLocation }),
        ...(packageType && { packageType }),
        ...((amount || amount === 0) && { amount }),
        ...restOrderDetails,
      }}),
  })}

const getEditedOrderData = (dirtyFields, form) => {
  const getEditedValues = (dirtyFields, allValues) => {
    if (dirtyFields === true || Array.isArray(dirtyFields))
      return allValues;

    return Object.fromEntries(Object.keys(dirtyFields)?.map(key => [key, getEditedValues(dirtyFields[key], allValues[key])]));
  }

  let nextDirtyFields  = {...dirtyFields, orderDetails: { ...dirtyFields.orderDetails, type: true } }
  const { customerDetails } = dirtyFields || {}
  const { address, area, city } = customerDetails || {}
  if(address || area || city) {
    nextDirtyFields = { ...dirtyFields, customerDetails: { ...dirtyFields.customerDetails, address: true, area: true, city: true }}
  }
  return getEditedValues(nextDirtyFields, form)
}

const expandOrderData = (data) => {
  const hasCustomerDetails = Object.keys(data?.customerDetails || {}).length > 0
  const hasOrderDetails = Object.keys(data?.orderDetails || {}).length > 0

  const { customerDetails, optionalInfo, orderDetails } = data || {}
  const { area, ...restCustomerDetails } = customerDetails || {}
  const { type, packageType, paymentType, pickupLocation, collectionAmount, refundAmount, ...restOrderDetails } = orderDetails || {}

  return {
    restCustomerDetails, optionalInfo, restOrderDetails, area: area?.value, city: area?.group, orderType: type?.value, amount: collectionAmount || refundAmount,
    packageType: packageType?.value, paymentType: paymentType?.value, pickupLocation: pickupLocation?.value, hasCustomerDetails, hasOrderDetails,
  }
}

const getSortedLocations = (locations) => {
  return locations.reduce((acc, location) => {
    if (location.is_default) {
      return [location, ...acc];
    }
    return [...acc, location];
  }, []);
}

export { optionalFields, getEditedOrderData, expandOrderData, getAddPickupLocationsFields, getOrderDetailsFormFields, getCustomerDetailsFormFields, getSortedLocations, adaptOrderDataToBackendFormat }
