/* eslint-disable max-len */
import React from "react"

const AmountIcon = ({ width="20", height="20", color = "#2A272B" }) => (
  <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3 5.80017V14.2002C3 15.7402 6.11111 17.0002 10 17.0002C13.8889 17.0002 17 15.7402 17 14.2002V5.80017" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
    <path d="M17 10C17 11.54 13.8889 12.8 10 12.8C6.11111 12.8 3 11.54 3 10" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
    <path d="M10 8.6C13.866 8.6 17 7.3464 17 5.8C17 4.2536 13.866 3 10 3C6.13401 3 3 4.2536 3 5.8C3 7.3464 6.13401 8.6 10 8.6Z" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
  </svg>


)

export default AmountIcon
