/* eslint-disable max-len */
import React from "react"

const AddressIcon = ({ width="20", height="20", color="#2A272B" }) => (
  <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16 7.53846C16 10.6031 10 18 10 18C10 18 4 10.6031 4 7.53846C4 6.06957 4.63214 4.66084 5.75736 3.62218C6.88258 2.58351 8.4087 2 10 2C11.5913 2 13.1174 2.58351 14.2426 3.62218C15.3679 4.66084 16 6.06957 16 7.53846V7.53846Z" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
    <path d="M10 10C11.3807 10 12.5 8.88071 12.5 7.5C12.5 6.11929 11.3807 5 10 5C8.61929 5 7.5 6.11929 7.5 7.5C7.5 8.88071 8.61929 10 10 10Z" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
  </svg>

)

export default AddressIcon
