import React, { useState, useMemo } from 'react'
import { Box , Typography, IconButton } from '@mui/material';

import { ClosedEyeIcon, LockIcon, OpenEyeIcon, PasswordCheckedIcon, PasswordUncheckedIcon } from '../../../assets/icons'
import Styled from "./change-password.styled"
import { PASSWORD_CONDITIONS } from './change-password.constants';
import Button from '../button';
import { useDispatch } from 'react-redux';
import { updatePassword } from './change-password.slice';

const ChangePassword = ({ t, paddings = false, onSuccessCbk  }) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false)
  const [password, setPassword] = useState('')
  const [isSubmitting, setIsSubmitting] = useState(false)

  const dispatch = useDispatch();

  const isPasswordValid = useMemo(() => PASSWORD_CONDITIONS.every(({regex}) => regex.test(password)), [password])

  const handleUpdatePassword = () => {
    setIsSubmitting(true);
    dispatch(updatePassword(password)).then((res) => {
      if(res?.payload?.status === 200 && (typeof onSuccessCbk === 'function')) {
        onSuccessCbk()
      }
      setIsSubmitting(false)
    })
  }

  return (
    <Styled.Wrapper paddings={paddings}>
      <Box>
        <Box marginBottom="8px">
          <Typography variant="boldSmall">
            {t('changePassword.field.label')}
          </Typography>

          <Styled.Input
            type={isPasswordVisible? 'text': 'password'}
            disableUnderline
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            startAdornment={<LockIcon />}
            endAdornment={
              (<IconButton disableRipple sx={{ padding: 0 }} onClick={() => setIsPasswordVisible(!isPasswordVisible)}>
                {isPasswordVisible? <Box paddingRight="4px"> <ClosedEyeIcon  /> </Box>: <OpenEyeIcon /> }
              </IconButton>
              )}
            placeholder={t('changePassword.field.placeholder')}
          />
        </Box>

        <Styled.PasswordConditionsWrapper>
          {PASSWORD_CONDITIONS.map(({ id, regex }) =>
            (
              <Box key={id} display="flex" height="16px">
                <Styled.ConditionRadioWrapper>
                  {regex.test(password)? <PasswordCheckedIcon /> : <PasswordUncheckedIcon />}
                </Styled.ConditionRadioWrapper>
                <Styled.RadioLabel>
                  {t(`changePassword.conditions.${id}`)}
                </Styled.RadioLabel>
              </Box>
            ))}
        </Styled.PasswordConditionsWrapper>
      </Box>

      <Button isLoading={isSubmitting} label={t(`changePassword.mutualizedLabel`)} isDisabled={!isPasswordValid} onClick={handleUpdatePassword} />

    </Styled.Wrapper>
  )
}

export default ChangePassword