import React from "react";
import { InputAdornment, IconButton, Box } from "@mui/material";

import Styled from "./search-field.styled";
import { SearchIcon } from "../../../assets/icons";

const SearchField = ({
  searchTerm,
  placeholder,
  onSearch,
  isFullWidth = false,
  theme,
}) => (
  <Box padding={isFullWidth ? "16px 15px" : "10px 0"}>
    <Styled.SearchField
      isFullWidth={isFullWidth}
      disableUnderline
      endAdornment={
        <InputAdornment position="end">
          <IconButton
            aria-label="toggle search"
            disabled={!searchTerm}
            onClick={() => onSearch({ nextSearchTerm: searchTerm })}
            onMouseDown={() => {}}
            edge="end"
            disableRipple
          >
            <SearchIcon
              color={
                searchTerm ? theme.palette.black : theme.palette.text.light
              }
            />
          </IconButton>
        </InputAdornment>
      }
      aria-describedby="outlined-weight-helper-text"
      inputProps={{ "aria-label": "weight" }}
      onChange={(e) => onSearch({ e })}
      value={searchTerm}
      placeholder={placeholder}
    />
  </Box>
);

export default SearchField;
