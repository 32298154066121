import React from "react";

import Styled from "../../../../analytics.styled";
import { Box } from "@mui/material";
import SuccessMeter from "./success-meter";
import Sections from "./sections";

const successRate = ({
  isMobileView,
  successRate,
  governorateSections,
  isGovernorateSectionsLoading,
  selectedFilters,
  setSelectedFilters,
  theme,
  t,
}) => {
  return (
    <Styled.Card $isMobileView={isMobileView}>
      <Box
        display={"flex"}
        flexDirection={"row"}
        justifyContent={"space-between"}
        width={"100%"}
      >
        <SuccessMeter statusSuccessRate={successRate.statusSuccessRate} t={t} />
        <Sections
          sections={governorateSections}
          selectedFilters={selectedFilters}
          setSelectedFilters={setSelectedFilters}
          isLoading={isGovernorateSectionsLoading}
          theme={theme}
        />
      </Box>
    </Styled.Card>
  );
};

export default successRate;
