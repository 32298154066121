import { styled } from "@mui/material/styles";
import { Box, Skeleton } from "@mui/material";

const Sections = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "60px",
  backgroundColor: theme.palette.lightgrey,
  borderRadius: "10px",
  display: "grid",
  gridTemplateColumns: "1fr 1fr",
  marginBottom: "10px",
}));

const SkeletonSections = styled(Skeleton)({
  width: "100%",
  height: "60px",
  borderRadius: "10px",
  display: "grid",
  gridTemplateColumns: "1fr 1fr",
  marginBottom: "10px",
});

const Styled = {
  Sections,
  SkeletonSections,
};

export default Styled;
