import { BulkyIcon, CalendarIcon, SameDayIcon, CantOpenIcon, CardIcon, CashSmallIcon } from "../../assets/icons";
import PartialDelivery from "../../assets/icons/PartialDeliveryIcon";
import SpecialHandlingIcon from "../../assets/icons/SpecialHandlingIcon";


const tableHeaders = [
  { id: "courier" },
  { id: "success_rate" },
  { id: "estimated_shipping"},
  { id: "services" },
  { id: "estimated_return" },
  { id: "total_fees" },
];

const serviceData = [
  { key: 'bulky', icons: BulkyIcon },
  { key: 'special_handling', icons: SpecialHandlingIcon },
  { key: 'card_on_door', icons: CardIcon },
  { key: 'partial_compensation', icons: CashSmallIcon },
  { key: 'all_week', icons: CalendarIcon },
  { key: 'same_day', icons: SameDayIcon },
  { key: 'partial_delivery', icons: PartialDelivery },
  { key: 'can_t_open', icons: CantOpenIcon },
];

const courierSortingBy = [
  { key: "estimated_shipping" },
  { key: "success_rate"  },
  { key: "estimated_return" },
  { key: "total_fees" },
];


export { tableHeaders, courierSortingBy, serviceData };
