import { alpha, styled } from "@mui/material/styles";
import { AppBar, Toolbar as MuiToolbar, IconButton as MuiIconButton, Avatar as MuiAvatar } from "@mui/material";

const Header = styled(AppBar)(({ theme: { palette } }) => ({
  minHeight: 44,
  height: 44,
  backgroundColor: palette.background.light,
  color: palette.lightGreyOutline,
  justifyContent: 'flex-end',
  boxShadow: `0px 0px 0px 1px ${alpha(palette.lightGreyOutline, 0.5)}`,
}));

const Toolbar = styled(MuiToolbar, {
  shouldForwardProp: (prop) => prop !== "isMobileView",
})(({ isMobileView }) => ({
  justifyContent: 'flex-end',
  gap: 20,
  '@media all': {
    paddingLeft: (isMobileView? 15 : 0),
    minHeight: 44,
    paddingRight: (isMobileView ? 15 : 40)
  },
}))

const Avatar = styled(MuiAvatar)(({ theme }) => ({
  backgroundColor: theme.palette.text.primary,
  color: theme.palette.white,
  width: 32,
  height: 32
}));

const IconButton = styled(MuiIconButton)({
  padding: 0,
  marginRight: 0
})

const Styled = {
  Header,
  Toolbar,
  Avatar,
  IconButton,
};

export default Styled;
