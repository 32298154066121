import { Box, Stack, Typography } from '@mui/material'
import React from 'react'
import { CustomerIcon } from '../../../assets/icons'
import { ACCOUNT_CARDS } from '../dashboard.constants'
import Styled from './account-actions.styeld'
import { Trans } from 'react-i18next'

const AccountActions = ({ renderTitle, t, isMobileView }) => {
  const AccountTitle = () => renderTitle({ title: t('dashboard.account.title'), Icon: CustomerIcon })
  const renderMobileActionButton = (href, onClick, Icon) => {
    if(href) return (
      <Styled.ActionButtonWrapper
        href={href}
        target='_blank'
        download="Packaging Guide.pdf"
        rel="noreferrer noopener"
      >
        <Icon width={28} height={28} stroke='black' />
      </Styled.ActionButtonWrapper>
    )

    if(typeof onClick === 'function') return (
      <Styled.ActionButtonWrapper
        onClick={onClick}
      >
        <Icon width={28} height={28} stroke='black' />
      </Styled.ActionButtonWrapper>
    )
  }

  const renderDesktopActionButton = (href, onClick, id, extraDescription) => {
    if(href) return (
      <Styled.Button
        href={href}
        target='_blank'
        download="Packaging Guide.pdf"
        rel="noreferrer noopener"
      >
        {t(`dashboard.account.list.${id}.action`)}
      </Styled.Button>
    )

    if(typeof onClick === 'function') return (
      <Styled.Button
        onClick={onClick}
      >
        {t(`dashboard.account.list.${id}.action`)}
      </Styled.Button>
    )

    return <Typography variant="boldText">{t(`dashboard.account.list.${id}.action`)} {extraDescription}</Typography>
  }

  if(isMobileView) return (
    <Stack display="flex" gap="12px">
      <AccountTitle />

      <Stack display="flex" gap="8px">
        {ACCOUNT_CARDS.map(({ id, href, extraDescription, onClick, icon: Icon }) => (
          <Styled.AccountCardWrapper $isMobileView={true} $noPaddings key={id}>
            <Box display="flex">
              <Stack display="flex" gap="16px" width="100%" padding="20px">
                <Stack display="flex" gap="4px">
                  <Typography variant="boldSubtitles">{t(`dashboard.account.mobileList.${id}.title`)}</Typography>
                  <Typography variant="mediumText">
                    <Trans
                      i18nKey={`dashboard.account.mobileList.${id}.description`}
                      values={{ replacedValue: t(`dashboard.account.mobileList.${id}.boldValue`)}}
                      components={{ bold: <Typography variant="boldSubtitles"/> }}
                    />
                  </Typography>
                </Stack>

                {!(href || typeof onClick === 'function') &&  (
                  <Typography variant="boldText">{t(`dashboard.account.mobileList.${id}.action`)} {extraDescription}</Typography>
                )}
              </Stack>
              {renderMobileActionButton(href, onClick, Icon)}
            </Box>
          </Styled.AccountCardWrapper>
        ))}
      </Stack>
    </Stack>
  )
  return(
    <Stack display="flex" gap="12px">
      <AccountTitle />

      <Box display="flex" gap="12px">
        {ACCOUNT_CARDS.map(({ id, href, onClick, extraDescription }) => (
          <Styled.AccountCardWrapper key={id}>
            <Stack display="flex" gap="8px">
              <Typography variant="boldSubtitles">{t(`dashboard.account.list.${id}.title`)}</Typography>

              <Typography variant="mediumText">
                <Trans
                  i18nKey={`dashboard.account.list.${id}.description`}
                  values={{ replacedValue: t(`dashboard.account.list.${id}.boldValue`)}}
                  components={{ bold: <Typography variant="boldSubtitles"/> }}
                />
              </Typography>
            </Stack>
            {renderDesktopActionButton(href, onClick, id, extraDescription)}
          </Styled.AccountCardWrapper>
        ))}
      </Box>
    </Stack>
  )
}

export default AccountActions