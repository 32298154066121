import React from "react";
import { useTheme, Typography } from "@mui/material";
import ReactSelect, { components as ReactSelectComponents } from "react-select";

import { ArrowIcon, PlusIcon } from "../../../../../assets/icons";
import { getStyles } from "./select.utils";
import Styled from './select.styled';

export function Select({
  styles, components, onChange, disabled, hasError, placeholder, hasGreyIcon, noBorders, noPlaceholder, arrowSize = 20,
  isRight, darkTheme = false, onInput = () => {}, noLeftPadding, inputLanguage ='en',
  placeholderTypo = 'regular', height, menuButtonProps, ...props
}) {

  const { hasMenuButton, onClick, text } = menuButtonProps || {};
  const { palette, typography, direction } = useTheme();

  const getIconColor = (menuIsOpen) => {
    if(hasGreyIcon && !menuIsOpen) return palette.gray

    if(darkTheme) return palette.white

    if (noBorders && !noPlaceholder) return palette.black

    if (!menuIsOpen) return palette.text.light
  }

  const getBorderColor = () => {
    if (noBorders) return 'transparent'

    if (hasError) return palette.red

    return palette.greyBorder
  }

  const SelectMenuButton = (props) => {
    return (
      <ReactSelectComponents.MenuList  {...props}>
        {props.children}
        <Styled.MenuButton type="button" color="secondary" onClick={(e) => {onClick(e); props?.selectProps?.onMenuClose()}}>
          <PlusIcon />
          <Typography variant="regular">
            {text}
          </Typography>
        </Styled.MenuButton>
      </ReactSelectComponents.MenuList >
    ) }

  const stylesProps = {
    palette, typography, direction, styles, disabled, placeholderTypo, noLeftPadding, darkTheme, isRight,
    getBorderColor, height, noBorders, isRtl: inputLanguage === 'ar'
  }

  return (
    <ReactSelect
      isDisabled={disabled}
      onChange={onChange}
      onInputChange={onInput}
      placeholder={noPlaceholder? '' : placeholder}
      styles={getStyles(stylesProps)}
      autoFocus={hasError}
      menuPosition="fixed"
      components={{
        ...components,
        ...(hasMenuButton? {MenuList: SelectMenuButton} : {}),
        DropdownIndicator: ({ selectProps: { menuIsOpen } }) => <ArrowIcon isDown={!menuIsOpen} size={arrowSize} color={getIconColor(menuIsOpen)} />
      }}
      {...props}
    />
  );
}

