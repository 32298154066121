import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

//SLICE NAMES CANNOT BE DUPLICATES!
const sliceName = 'counter'

export const fetchDataAndDontLogError = createAsyncThunk(`${sliceName}/fetchDataAndDontLogError`, async (_, thunkAPI) => {
  const response = await thunkAPI.extra.apiClient.get({ config: { logErrorMsg: false } })
  return response
})

export const fetchDataAndLogError = createAsyncThunk(`${sliceName}/fetchDataAndLogError`, async (_, thunkAPI) => {
  const response = await thunkAPI.extra.apiClient.get({})
  return response
})

export const counterSlice = createSlice({
  name: sliceName,
  initialState: {
    value: 0,
  },
  reducers: {
    increment: (state) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes.
      // Also, no return statement is required from these functions.
      state.value += 1
    },
    decrement: (state) => {
      state.value -= 1
    },
    incrementByAmount: (state, action) => {
      state.value += action.payload
    },
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here
    builder
      .addCase(fetchDataAndDontLogError.fulfilled, (state, action) => ({
        ...state,
        ...action.payload
      }))
      .addCase(fetchDataAndLogError.fulfilled, (state, action) => ({
        ...state,
        ...action.payload
      }))
  },
})

// Action creators are generated for each case reducer function
export const { increment, decrement, incrementByAmount } = counterSlice.actions

export const selectCounterValue = (state) => state[sliceName].value

export default counterSlice.reducer