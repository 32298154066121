/* eslint-disable max-len */
import React from 'react'

const LockIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.6 8L14.4 8C15.0075 8 15.5 8.50368 15.5 9.125L15.5 15.875C15.5 16.4963 15.0075 17 14.4 17L5.6 17C4.99249 17 4.5 16.4963 4.5 15.875L4.5 9.125C4.5 8.50368 4.99249 8 5.6 8Z" stroke="#2A272B" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M6.5 8L6.5 6.5C6.5 5.57174 6.86875 4.6815 7.52513 4.02513C8.1815 3.36875 9.07174 3 10 3C10.9283 3 11.8185 3.36875 12.4749 4.02513C13.1313 4.6815 13.5 5.57174 13.5 6.5L13.5 8" stroke="#2A272B" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M10 12.5C10 13.0523 10 13.5 10 13.5C10 13.5 10 13.0523 10 12.5C10 11.9477 10 11.5 10 11.5C10 11.5 10 11.9477 10 12.5Z" stroke="#2A272B" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
)

export default LockIcon