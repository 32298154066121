const getAreaCompletedCount = (maxValue, count) => {
  switch (true) {
  case count === -1: // incase of no orders in areas
    return 25;
  case maxValue === 0:
    return 25;
  case count === 0:
    return maxValue / 4;
  default:
    return count;
  }
};

export { getAreaCompletedCount };
