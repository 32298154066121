/* eslint-disable max-len */
import React from "react";
import { Stack, Box } from "@mui/material";

import Styled from "./print-view.styled";
import fincartBlackLogo from "../../../assets/images/fincartBlackLogo.jpeg";
import { adaptDate, truncateText } from "./print-view.utils";

const PrintThermal = ({ thermalLabels, currentRef, }) => {
  const toArabicNumber = (strNum) => {
    const arabicNumbers = ["۰", "۱", "۲", "۳", "۴", "۵", "۶", "۷", "۸", "۹"];
    return strNum?.toString()?.replace(/[0-9]/g, (d) => arabicNumbers[+d]);
  };

  const CourierInfo = ({ pickup, customer, orderPackage, merchantName }) => {
    const { notes, address: customerAddress } = customer;
    const { desc } = orderPackage
    const descLength = desc.length > 150 ? 150 : desc.length
    const noVerticalHeight = `${notes}${customerAddress}`.length + descLength > 200;

    const { phone, address, area, city } = pickup || {};
    const adaptedAddress = truncateText(address)

    return (
      <Styled.PrintThermalInfo minHeight={noVerticalHeight? 0: 120} minHeightPrint={noVerticalHeight? 0: 15}>
        <Stack>
          <Styled.PrintThermalBoldLabel>
            {truncateText(merchantName, 20)} ({phone && phone})
          </Styled.PrintThermalBoldLabel>

          <Styled.PrintThermalRegularLabel>
            {area} {city ? `${area && "-"} ${city}` : ""}
          </Styled.PrintThermalRegularLabel>

          {adaptedAddress && <Styled.PrintThermalRegularLabel>{adaptedAddress}</Styled.PrintThermalRegularLabel>}
        </Stack>

        <Styled.PrintThermalBoldLabel>من</Styled.PrintThermalBoldLabel>
      </Styled.PrintThermalInfo>
    );
  };

  const CustomerInfo = ({ customer }) => {
    const { name, notes, phone, backupPhone, address, city, landmark, zone } = customer;
    const notesMaxLength = notes.length > 150 ? 150 : 300 - notes.length;
    const addressMaxLength = address.length > 150 ? 150 : 300 - address.length;

    const toInfo = `${truncateText(name, 40)} (${phone} ${backupPhone && - backupPhone})`
    //one line chars in name an numbers = 50 char, if more than one chars we
    // need to decrease backup and phone number by toInfo - 50 chars
    const toInfoExtraLength = toInfo.length > 50 ? toInfo.length - 10 : 0;

    const adaptedAddress = truncateText(address, notesMaxLength - toInfoExtraLength/2)
    const adaptedNotes = truncateText(notes, addressMaxLength - toInfoExtraLength/2)


    return (
      <Styled.PrintThermalInfo
        display="flex"
        justifyContent="space-between"
        alignItems="start"
        padding="10px"
      >
        <Stack maxWidth="90%">
          <Styled.PrintThermalBoldLabel>
            {toInfo}
          </Styled.PrintThermalBoldLabel>

          <Styled.PrintThermalRegularLabel>
            {zone} {city ? `${zone && "-"} ${city}` : ""}
          </Styled.PrintThermalRegularLabel>
          {adaptedAddress && <Styled.PrintThermalRegularLabel>{adaptedAddress}</Styled.PrintThermalRegularLabel>}
          {landmark && <Styled.PrintThermalRegularLabel>{landmark}</Styled.PrintThermalRegularLabel>}
          {adaptedNotes && <Styled.PrintThermalRegularLabel>{adaptedNotes}</Styled.PrintThermalRegularLabel>}
        </Stack>

        <Styled.PrintThermalBoldLabel variant="boldMedium">الي</Styled.PrintThermalBoldLabel>
      </Styled.PrintThermalInfo>
    );
  };

  const PackageDescription = ({ orderPackage, customer }) => {
    const {notes, address} = customer || {}
    const { refNumber, desc } = orderPackage || {};
    const noMinHeight = `${notes}${address}`.length > 200;


    const adaptedDesc = truncateText(desc, noMinHeight? 150: 300);

    return (
      <Styled.PrintThermalInfo noBottomBorder minHeight={noMinHeight? 0: 120} minHeightPrint={noMinHeight? 0: 15}>
        <Styled.PrintThermalRegularLabel maxWidth='82%' height="100%">
          {`${refNumber? `Order no. ${refNumber}`: ''}${adaptedDesc && refNumber && " - "}${adaptedDesc}`}
        </Styled.PrintThermalRegularLabel>

        <Stack>
          <Styled.PrintThermalBoldLabel textAlign="end">وصف</Styled.PrintThermalBoldLabel>
          <Styled.PrintThermalBoldLabel textAlign="end">الشحنة</Styled.PrintThermalBoldLabel>
        </Stack>
      </Styled.PrintThermalInfo>
    );
  };

  const LabelHeader = ({ amount, externalId }) => (
    <Styled.PrintThermalInfo minHeight="100" minHeightPrint="20" fontSizePrint="65" lineHightPrint="120">
      <Stack display="flex" gap="10px" padding="10px">
        <Styled.FincartImage src={fincartBlackLogo} width={75} height={14} />

        <Styled.CodLabel variant="boldLarge">COD {amount}</Styled.CodLabel>
      </Stack>
      {externalId && (
        <Styled.Barcode
          value={externalId}
          width={0.5}
          height={20}
          margin={0}
          fontSize="5"
          font="'Poppins', 'Noto Sans Arabic'"
        />
      )}
    </Styled.PrintThermalInfo>
  );

  const IsAllowedToOpen = ({ courierName, sortingCode, orderPackage }) => (
    <Styled.PrintThermalInfo minHeight={20} minHeightPrint={6}
    >
      <Styled.PrintThermalBoldLabel>{`${courierName} ${
        sortingCode && `(${sortingCode})`
      }`}</Styled.PrintThermalBoldLabel>

      <Styled.PrintThermalBoldLabel>
        {orderPackage?.allowedOpenPackage ? "يسمح بفتح" : "لا يسمح بفتح"} :
        {orderPackage?.numberOfItems &&
          ` ${toArabicNumber(orderPackage.numberOfItems)} ${
            orderPackage.numberOfItems > 1 ? "قطع" : "قطعة"
          }`}
      </Styled.PrintThermalBoldLabel>
    </Styled.PrintThermalInfo>
  );

  const Footer = ({ order }) => {
    const { orderNumber, creationDate } = order || {};

    return (
      <Styled.ThermalFooter>
        <Box display="flex" justifyContent="space-between" padding="10px">
          <Styled.FooterLabel>{orderNumber}</Styled.FooterLabel>

          {creationDate && (
            <Styled.FooterLabel>
              {adaptDate(creationDate)}
            </Styled.FooterLabel>
          )}
        </Box>
      </Styled.ThermalFooter>
    );
  };

  return (
    <Styled.PrintLabelsWrapper ref={currentRef}>
      {thermalLabels.map(
        ({
          externalId,
          amount,
          courierName,
          sortingCode,
          package: orderPackage,
          customer,
          merchantName,
          pickup,
          order,
        }) => (
          <Styled.PrintThermalLabelWrapper
            key={order?.orderNumber}
            sx={{ breakAfter: "page" }}
          >
            <Box>
              <LabelHeader amount={amount} externalId={externalId} />

              <IsAllowedToOpen
                courierName={courierName}
                sortingCode={sortingCode}
                orderPackage={orderPackage}
              />

              <CustomerInfo customer={customer} />

              <CourierInfo merchantName={merchantName} pickup={pickup} customer={customer} orderPackage={orderPackage} />

              <PackageDescription orderPackage={orderPackage} customer={customer} />
            </Box>

            <Footer order={order} />
          </Styled.PrintThermalLabelWrapper>
        )
      )}
    </Styled.PrintLabelsWrapper>
  );
};

export default PrintThermal;
