import { styled, Input as MuiInput, Typography } from "@mui/material";

const Wrapper = styled('div', {
  shouldForwardProp: (prop) => (prop !== "paddings"),
})(({ paddings = '16px 24px' }) => ({
  padding: paddings,
  display: "flex",
  flexDirection: "column",
  gap: '20px'
}));

const Input = styled(MuiInput, {
  shouldForwardProp: (prop) => (prop !== "error" && prop !== '$isDisabled'),
})(({ theme: { palette, typography }, error, $isDisabled }) => ({
  ...typography.regular,
  opacity: $isDisabled ? 0.5: 1,
  padding: '8px 12px',
  borderRadius: '8px',
  border: error ? `1px solid ${palette.red}` : `1px solid  ${palette.greyBorder}`,
  display: 'flex',
  gap: 4,
  height: 36,
  "&:hover" : {
    border: `1px solid ${palette.black}`
  },
  ":focus-within": {
    border: `1px solid ${palette.black}`
  },
  'input::-ms-reveal': { display: 'none' },
  'input::-ms-clear': { display: 'none' },
  '.MuiInput-input': {
    borderRadius: '8px',
    border: 'none',
    width : '100%',
    ':-webkit-autofill': {
      '-webkit-box-shadow':'0 0 0 50px white inset',
      '-webkit-text-fill-color': '#333'
    },
    '&:disabled': { backgroundColor: palette.white },
    '&:focus-visible' : {
      outline: 'none'
    },
    ...typography.regular,
  },
}))

const ConditionRadioWrapper = styled('div')({
  paddingRight: 7,
  display: 'flex',
  alignItems: 'center'
})

const RadioLabel = styled(Typography)(({ theme: { palette, typography } }) => ({
  ...typography.semiBoldSmall,
  color: palette.gray
}))

const PasswordConditionsWrapper = styled('div')(({ theme: { palette } }) => ({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: palette.lightGreyOutline,
  padding: '12px 16px',
  borderRadius: 8,
  gap: 4,
  height: 100
}))

const Styled = { Wrapper, Input, PasswordConditionsWrapper, ConditionRadioWrapper, RadioLabel }

export default Styled
