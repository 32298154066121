/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from "react";
import { Box, Button, Typography, Skeleton } from "@mui/material";
import ProgressBar from "@ramonak/react-progress-bar";
import { useTranslation } from "react-i18next";

import { selectAppDirection } from "../../../../../../utils/common.slice";
import { useSelector } from "react-redux";

import { ArrowIcon, ArrowDownIcon } from "../../../../../../assets/icons";
import Menu from "../../../../../../common/components/menu";

import Styled from "./sla-card.styled";

import { MENU_IDS, SECTION_MENU_ITEMS } from "../../../../analytics.constants";

const SLACard = ({
  data,
  selectedSection,
  setSection,
  type,
  isLoading,
  theme,
}) => {
  const { t } = useTranslation();
  const appDirection = useSelector(selectAppDirection);
  const { slas, totalOrders } = data;

  const [percentage, setPercentage] = useState(0);
  const [selectedSLA, setSelectedSLA] = useState(0);
  const [selectedSectionKey, setSelectedSectionKey] = useState(selectedSection);

  const [sectionMenuAnchorEl, setSectionMenuAnchorEl] = useState(null);

  const openSectionMenu = (event) =>
    setSectionMenuAnchorEl(event.currentTarget);
  const closeSectionMenu = () => setSectionMenuAnchorEl(null);
  const isSectionMenuOpen = Boolean(sectionMenuAnchorEl);

  const onSelectSection = (section) => {
    setSelectedSectionKey(section);
    setSection(section);
    closeSectionMenu();
  };

  const calculatePercentage = (slaIndex) => {
    if (!slas) return 0;
    const selectedSLA = slas[slaIndex];
    if (!selectedSLA) return 0;

    const percentage =
      selectedSLA.count === 0
        ? 0
        : Math.round((selectedSLA.count / totalOrders) * 100);
    return percentage;
  };

  const handleSelectSLA = (slaIndex) => {
    const selectedSLAIndex = slaIndex === selectedSLA ? null : slaIndex;
    if (selectedSLAIndex !== null) {
      setSelectedSLA(selectedSLAIndex);
      const percentage = calculatePercentage(selectedSLAIndex);
      setPercentage(percentage);
    }
  };

  useEffect(() => {
    const percentage = calculatePercentage(0);
    setPercentage(percentage);
    setSelectedSLA(0);
  }, [slas]);

  useEffect(() => {
    setSelectedSectionKey(selectedSection);
  }, [selectedSection]);

  const defaultSLAS = Array(3).fill({
    count: 0,
    label: "------",
  });

  return (
    <Box
      display="flex"
      position={"relative"}
      flexDirection="column"
      justifyContent={"space-between"}
      width={"100%"}
      p={1}
    >
      <Box display={"flex"} flexDirection={"row"}>
        <Typography
          alignSelf={"flex-start"}
          variant="mediumText"
          color={theme.palette.text.light}
          sx={{ mt: 2, mb: 2, pl: 1 }}
        >
          {t(`analytics.sla.cardTitles.${type}`, {
            totalOrders: totalOrders ?? 0,
          })}
        </Typography>
        <Button
          onClick={openSectionMenu}
          disableRipple
          endIcon={isSectionMenuOpen ? <ArrowIcon /> : <ArrowDownIcon />}
          sx={{
            marginLeft: "auto",
          }}
        >
          <Typography
            variant="regularWithUnderline"
            color={theme.palette.text.primary}
            mr={1}
          >
            {t(`analytics.sla.sectionMenuItems.${selectedSectionKey}`)}
          </Typography>
        </Button>
      </Box>
      <Menu
        id={MENU_IDS.SLA_SECTION}
        theme={theme}
        anchorEl={sectionMenuAnchorEl}
        onCloseMenu={closeSectionMenu}
        onClickMenuItem={onSelectSection}
        isMenuOpen={isSectionMenuOpen}
        items={SECTION_MENU_ITEMS}
        dir={appDirection}
        t={t}
        translationKey={"analytics.sla.sectionMenuItems"}
      />
      <Box
        flexDirection={"row"}
        display={"flex"}
        justifyContent={"space-around"}
      >
        {(slas || defaultSLAS).map((sla, index) => {
          if (isLoading)
            return <Skeleton width={"140px"} height={"80px"} key={index} />;
          return (
            <Styled.SLACard
              key={index}
              isSelected={selectedSLA === index}
              theme={theme}
              onClick={() => handleSelectSLA(index)}
            >
              <Box display={"flex"} flexDirection={"column"}>
                <Typography className="label" variant="boldSubtitles">
                  {sla.label}
                </Typography>
                <Typography className="count" variant="mediumText">
                  {t(`analytics.sla.cardContent.orders`, {
                    count: sla.count ?? 0,
                  })}
                </Typography>
              </Box>
            </Styled.SLACard>
          );
        })}
      </Box>
      <Box mt={2}>
        <ProgressBar
          completed={percentage}
          maxCompleted={100}
          customLabel={`${percentage}%`}
          labelAlignment="right"
          labelColor="white"
          bgColor={theme.palette.limeGreen}
        />
      </Box>
    </Box>
  );
};

export default SLACard;
