/* eslint-disable no-undef */
import { Box } from "@mui/system";
import React from "react";

const CourierLogo = ({ imgPath}) => (
  <Box width={64} height={20}>
    <img
      src={`${process.env.REACT_APP_BASE_URL_MERCHANT}${imgPath}`}
      width={64}
      height={20}
      style={{ objectFit: "contain" }}
    />
  </Box>
);

export default CourierLogo;
