import React, { useCallback } from "react";
import { Box, Typography } from "@mui/material";

import { STATUS_SUB_FILTERS, STATUS_SUB_FILTERS_IDS } from "./filters.constants";
import { CircleIcon } from "../../../assets/icons";
import { STATUS_COLORS } from "../orders-table.constants";
import Chip from "./components/chip";

const StatusFilter = ({ setValue, theme, formValues, t }) => {

  const checkIfChipSelected = useCallback(
    (subStatusKey, subStatusValue) => formValues[subStatusKey] === subStatusValue,
    [formValues],
  )

  const handleSetValue = (type, value) => setValue(`orderStatus.${type}`, value)

  const doFunctionsAccordingType = ({statusType, typeReturn, statusReturn, subStatusReturn}) => {
    switch (statusType) {
    case STATUS_SUB_FILTERS_IDS.TYPE:
      return typeReturn()

    case STATUS_SUB_FILTERS_IDS.STATUS:
      return statusReturn()

    case STATUS_SUB_FILTERS_IDS.SUB_STATUS:
      return subStatusReturn()

    default:
      break;
    }
  }

  const checkIfChipDisabled = (statusType, statusKey) => (
    doFunctionsAccordingType ({ statusType, typeReturn: () => false, statusReturn: () => (!formValues.type), subStatusReturn: () => (formValues.status !== statusKey)  })
  )

  const onChipClick = ( statusType, optionId ) => {
    const isPrevSelected = formValues[statusType] === optionId

    const setChipValue = () => handleSetValue(statusType, isPrevSelected? '' : optionId)

    doFunctionsAccordingType ({
      statusType,
      typeReturn: () => { setChipValue(); handleSetValue(STATUS_SUB_FILTERS_IDS.STATUS, ''); handleSetValue(STATUS_SUB_FILTERS_IDS.SUB_STATUS, '')},
      statusReturn: () => {setChipValue(); handleSetValue(STATUS_SUB_FILTERS_IDS.SUB_STATUS, '');},
      subStatusReturn: () => setChipValue(),
    })
  };


  return (
    <Box display="flex" flexDirection="column" gap="20px" padding="16px 24px" height={'100%'}>
      {STATUS_SUB_FILTERS.map(({ id: subStatusId, options, withIcons }) =>
        <Box key={subStatusId} display="flex" flexDirection="column" gap="8px">
          {/* title */}
          <Typography variant="boldText">{t(`listing.filters.orderStatus.subStatuses.${subStatusId}.title`)}</Typography>

          {/* cards container */}
          <Box display="flex" gap="4px" flexWrap="wrap">
            {options.map(({ key: optionId, statusKey }) => {
              return (<Chip
                key={optionId}
                label={t(`listing.filters.orderStatus.subStatuses.${subStatusId}.options.${optionId}`)}
                isDisabled={checkIfChipDisabled(subStatusId, statusKey)}
                isSelected={checkIfChipSelected(subStatusId, optionId)}
                icon={withIcons? <CircleIcon fill={theme.palette[STATUS_COLORS[statusKey ?? optionId]]} /> : null}
                onClick={() => onChipClick(subStatusId, optionId)}
              />)
            })}
          </Box>
        </Box>
      )}
    </Box>
  )
}

export default StatusFilter