import { useForm } from "react-hook-form"
import {  SUB_STATUS_FILTERS_OPTIONS } from "../filters/filters.constants"
import { useEffect, useState } from "react"

const useFiltersForm = () => {
  const defaultValues = {
    orderStatus: {
      type: '',
      status: '',
      subStatus: ''
    },
    dateRange: {
      value: '',
      duration: '',
    },
    courierIds: [],
    city: '',
    pickupLocation: '',
    printLabel: ''
  }

  const { formState, setValue: formSetValue, register, getValues, resetField, watch, reset, handleSubmit } = useForm({ defaultValues })

  const form = watch()

  const [resettedFields, setResettedFields] = useState([])

  const setValue = (key, value, shouldDirty = true) => formSetValue(key, value, { shouldDirty })
  const formProps = { formState, setValue, register, getValues }
  const { dirtyFields, isDirty } = formState

  const handleResetField = (id) => {
    setResettedFields((prevFields) => [...new Set([...prevFields, id])]);
    resetField(id );
  }

  const handleReset = () => {
    reset(defaultValues);
    setResettedFields([]);
  }

  useEffect(() => {
    register('orderStatus.type');
    register('orderStatus.status');
    register('orderStatus.subStatus');
    register('dateRange.duration');
    register('dateRange.value')
    register('pickupLocation');
    register('printLabel');
    register('city');
    register('courierIds');
  }, [register, resettedFields]);

  const submitData = (data, onSubmitCallBack) => {
    setResettedFields([])
    const { city, courierIds, orderStatus: { type, subStatus: subStatusId, status }, pickupLocation, dateRange: { value, duration: { startDate, endDate } } } = data
    let dateRange
    if(value) {
      dateRange = { [value]: { from: startDate || 1, to: endDate || -1 } }
    }

    const adaptedData = {
      ...(city && {city}),
      ...(type && {type}), //order type
      ...(dateRange && {dateRange}),
      ...(subStatusId && {subStatus: SUB_STATUS_FILTERS_OPTIONS.find(({key}) => key === subStatusId)?.label?.toLowerCase()}), //BE uses the label not the id
      ...(status && {status}),
      ...(courierIds && {courierIds}),
      ...(pickupLocation && {pickupLocation})
      // printLabel: true //??
    }
    onSubmitCallBack(adaptedData)
  }

  return ({
    handleSubmit,
    setValue,
    submitData,
    dirtyFields,
    form,
    resetField: handleResetField,
    setResettedFields,
    resettedFields,
    reset: handleReset,
    formProps,
    isDirty
  })
}

export default useFiltersForm
