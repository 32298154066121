import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

//SLICE NAMES CANNOT BE DUPLICATES!
const sliceName = "analyticsSlice";

export const fetchTopCouriers = createAsyncThunk(
  `${sliceName}/fetchTopCouriers`,
  async (data, thunkAPI) => {
    const response = await thunkAPI.extra.apiClient.post({
      url: "top-couriers-analytics",
      data,
    });
    return response?.data?.body || [];
  }
);

export const fetchSuccessRate = createAsyncThunk(
  `${sliceName}/fetchSuccessRate`,
  async (data, thunkAPI) => {
    const response = await thunkAPI.extra.apiClient.post({
      url: "success-rate-analytics",
      data,
    });
    return response?.data?.body || [];
  }
);

export const fetchGovernorateSections = createAsyncThunk(
  `${sliceName}/fetchGovernorateSections`,
  async (data, thunkAPI) => {
    const response = await thunkAPI.extra.apiClient.post({
      url: "governorate-sections",
      data,
    });
    return response?.data?.body || [];
  }
);

export const fetchSLA = createAsyncThunk(
  `${sliceName}/fetchSLA`,
  async (data, thunkAPI) => {
    const response = await thunkAPI.extra.apiClient.post({
      url: "sla-analytics",
      data,
    });
    return response?.data?.body || [];
  }
)

export const fetchUnsuccessfulReasons = createAsyncThunk(
  `${sliceName}/fetchUnsuccessfulReasons`,
  async (data, thunkAPI) => {
    const response = await thunkAPI.extra.apiClient.post({
      url: "rejection-reason-analytics",
      data,
    });
    return response?.data?.body || {};
  }
);

export const fetchFirstAttemptSLA = createAsyncThunk(
  `${sliceName}/fetchFirstAttemptSLA`,
  async (data, thunkAPI) => {
    const response = await thunkAPI.extra.apiClient.post({
      url: "first-attempt-sla-analytics",
      data,
    });
    return response?.data?.body || [];
  }
);

export const fetchTopCities = createAsyncThunk(
  `${sliceName}/fetchTopCities`,
  async (data, thunkAPI) => {
    const response = await thunkAPI.extra.apiClient.post({
      url: "top-cities-analytics",
      data,
    });
    return response?.data?.body || [];
  }
);

export const fetchTopAreas = createAsyncThunk(
  `${sliceName}/fetchTopAreas`,
  async (data, thunkAPI) => {
    const response = await thunkAPI.extra.apiClient.post({
      url: "top-areas-analytics",
      data,
    });
    return response?.data?.body || [];
  }
);

export const analyticsSlice = createSlice({
  name: sliceName,
  initialState: {
    value: 0,
  },
  reducers: {},
});

export default analyticsSlice.reducer;
