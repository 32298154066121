/* eslint-disable max-len */
import React from "react";

const SameDayIcon = ({ width="14", height="14", color="#2A272B" }) => (
  <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.93205 14C5.87614 14 5.82023 13.9888 5.76918 13.9618C5.61847 13.8875 5.54798 13.721 5.60389 13.5703L6.66669 8.54225H4.34769C4.23344 8.54225 4.12892 8.4905 4.06329 8.405C3.99766 8.3195 3.98307 8.20925 4.01953 8.11025L5.49801 2.21375C5.54662 2.0855 5.67789 2 5.82617 2L11.3052 2C11.4341 2 11.5532 2.0675 11.6139 2.17325C11.6747 2.279 11.665 2.40725 11.5921 2.50625L9.42567 5.89625H11.6523C11.7836 5.89625 11.9051 5.966 11.9634 6.074C12.0218 6.18425 12.0096 6.31475 11.9318 6.4115L6.21159 13.8718C6.14353 13.955 6.039 14 5.93205 14Z" stroke={color} strokeLinecap="round"/>
  </svg>
);

export default SameDayIcon;
