import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

//SLICE NAMES CANNOT BE DUPLICATES!
const sliceName = "dashboard";

export const fetchMerchantBalance = createAsyncThunk(`${sliceName}/fetchMerchantBalance`, async (_, thunkAPI) => {
  const response = await thunkAPI.extra.apiClient.get({ url: 'current-balance' });
  return response.data?.body;
});

export const dashboardSlice = createSlice({
  name: sliceName,
  initialState: {},
  reducers: {
    extraReducers: (builder) => {
      // Add reducers for additional action types here
      builder.addCase(fetchMerchantBalance.fulfilled, (state, action) => ({
        ...state,
        ...action.payload,
      }));
    },
  },
});

export default dashboardSlice.reducer;