import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

//SLICE NAMES CANNOT BE DUPLICATES!
const sliceName = "ordersSlice";

export const fetchOrders = createAsyncThunk(
  `${sliceName}/fetch`,
  async (data, thunkAPI) => {
    const response = await thunkAPI.extra.apiClient.post({
      url: "orders-listing",
      data,
    });
    return response?.data?.body || [];
  }
);

export const printLabelAction = createAsyncThunk(
  `${sliceName}/fetchOrderPrintLabel`,
  async (parameterObject, thunkAPI) => {
    //TODO: translate
    const response = await thunkAPI.extra.apiClient.post({
      url: "label-stream",
      data: parameterObject,
      config: { responseType: "arraybuffer" },
      isJsonContent: false,
      logErrorMsg: true,
      errorMsg: "Failed to print label.",
    });
    return response;
  }
);

export const printManifestoAction = createAsyncThunk(
  `${sliceName}/fetchPrintManifesto`,
  async (parameterObject, thunkAPI) => {
    //TODO: translate
    const response = await thunkAPI.extra.apiClient.post({
      url: "manifesto-stream",
      data: parameterObject,
      config: { responseType: "arraybuffer" },
      isJsonContent: false,
      logErrorMsg: true,
      errorMsg: "Failed to print label.",
    });
    return response;
  }
);

export const getOrderDetails = createAsyncThunk(
  `${sliceName}/fetchOrderDetails`,
  async (orderId, thunkAPI) => {
    const response = await thunkAPI.extra.apiClient.get({
      url: `orders/${orderId}/details`,
      isJsonContent: false,
      logErrorMsg: true,
      errorMsg: "Failed to get order details.",
    });
    return response;
  }
);

export const exportOrdersAction = createAsyncThunk(
  `${sliceName}/fetchExportOrdersAction`,
  async (data, thunkAPI) => {
    //TODO: translate
    const response = await thunkAPI.extra.apiClient.post({
      url: "orders-export",
      data,
      isJsonContent: true,
      logErrorMsg: true,
      errorMsg: "Failed to export orders.",
    });
    const responseData = response?.data?.body || {}
    return {orders: responseData.orders || [], isOverLimit: responseData.isOverLimit || false};
  }
);

export const getAWBLabels = createAsyncThunk(
  `${sliceName}/getAWBLabels`,
  async (parameterObject, thunkAPI) => {
    const response = await thunkAPI.extra.apiClient.post({
      url: "orders-awb",
      data: parameterObject,
      isJsonContent: true,
      errorMsg: "Failed to get thermal labels data",
    });
    return response?.data?.body?.orders ? response?.data?.body?.orders : [];
  }
);

export const ordersSlice = createSlice({
  name: sliceName,
  initialState: {
    value: 0,
    isFiltersApplied: false,
    currentOrderId: null,
    isOrdersLoading: false,
    initActiveTab: {},
  },
  reducers: {
    setCurrentOrderId: (state, action) => {
      state.currentOrderId = action.payload;
    },
    setInitActiveTab: (state, action) => {
      state.initActiveTab = action.payload;
    },
    setIsFiltersApplied: (state, action) => {
      state.isFiltersApplied = action.payload;
    },
    extraReducers: (builder) => {
      // Add reducers for additional action types here
      builder
        .addCase(fetchOrders.fulfilled, (state) => {
          state.isOrdersLoading = true; //TODO check why this is not working
        })

        .addCase(printLabelAction.fulfilled, (state, action) => ({
          ...state,
          ...action.payload,
        }))
        .addCase(printManifestoAction.fulfilled, (state, action) => ({
          ...state,
          ...action.payload,
        }))
        .addCase(getOrderDetails.fulfilled, (state, action) => ({
          ...state,
          ...action.payload,
        }))
        .addCase(exportOrdersAction.fulfilled, (state, action) => ({
          ...state,
          ...action.payload,
        }));
    },
  },
});

export const selectCurrentOrderId = (state) => state[sliceName].currentOrderId;

export const selectIsFiltersApplied = (state) =>
  state[sliceName].isFiltersApplied;

export const selectInitActiveTab = (state) => state[sliceName].initActiveTab;

export const { setCurrentOrderId, setIsFiltersApplied, setInitActiveTab } =
  ordersSlice.actions;

export const selectIsOrdersLoading = (state) => {
  console.log(state);
};

export default ordersSlice.reducer;
