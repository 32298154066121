import { styled } from '@mui/material/styles';
import * as Collapsible from "@radix-ui/react-collapsible";


const CollapsibleWrapper = styled(Collapsible.Trigger)(({ theme: { palette } }) => ({
  display: "flex",
  width: '100%',
  flexDirection: 'row',
  paddingTop: '1rem',
  justifyContent: 'space-between',
  alignItems: 'center',
  position: 'sticky',
  background: 'white',
  top: 0,
  border: 'none',
  paddingLeft: '15px',
  paddingRight: '15px',
  borderBottom: `1px solid ${palette.lightGreyOutline}`
}));

const Styled = {
  CollapsibleWrapper,
}

export default Styled