import {
  ListItem,
  ListItemIcon,
  ListItemButton,
  ListItemText,
  Box as MuiBox,
} from "@mui/material";
import { styled } from "@mui/material/styles";

const ListIcon = styled(ListItemIcon)(({ theme: { palette } }) => ({
  color: palette.navbar.icons.inactive,
  width: 28,
  height: 28,
  minWidth: 28,
  alignItems: 'center',
  display: 'flex',
  justifyContent: "center",
}));

const ListItemBtn = styled(ListItemButton, {
  shouldForwardProp: (prop) => prop !== "isMobileView",
})(({ theme: { palette }, isMobileView }) => ({
  minHeight: 48,
  justifyContent: "center",
  px: 2.5,
  display: 'flex',
  gap: (isMobileView? 0 : 8),
  alignItems: 'center',
  color: palette.secondary,
  "& .MuiListItemText-root .MuiListItemText-primary": {
    color: palette.navbar.icons.inactive,
    fontFamily: 'Poppins',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '28px'
  },
  "&.MuiListItemButton-root":{
    "&.Mui-selected, &.Mui-selected:hover, &.Mui-selected:focus": {
      backgroundColor: 'transparent',
      "& .MuiListItemIcon-root": {
        "& path": {
          fill: palette.navbar.icons.active
        },
        "& rect": {
          fill: palette.navbar.icons.active
        }
      },
      "& .MuiListItemText-root .MuiListItemText-primary": {
        color: palette.navbar.icons.active,
        fontWeight: 700
      }
    },
  }
}));

const ListItm = styled(ListItem)(({ theme }) => ({
  display: "block",
  "& path": {
    width: "24",
    height: "24",
    fill: theme.palette.navbar.icons.inactive
  },
  "& rect": {
    width: "24",
    height: "24",
    fill: theme.palette.navbar.icons.inactive
  },
  "&.MuiListItem-root": {
    "&.Mui-selected, &.Mui-selected:hover, &.Mui-selected:focus": {
      backgroundColor: 'transparent',
    },
    "&:hover": {
      backgroundColor: "rgba(127, 125, 128, 0.2)",
    }
  },
}));


const ListItmText = styled(ListItemText)(({ theme: { palette }, open }) => ({
  color: palette.navbar.text.color,
  opacity: open ? 1 : 0
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  ...theme.mixins.toolbar,
}));

const Box = styled(MuiBox)(() => ({
  display: "flex",
}));


const Styled = {
  Box,
  ListIcon,
  ListItemBtn,
  DrawerHeader,
  ListItm,
  ListItmText,
};

export default Styled;
