/* eslint-disable max-len */
import React from "react";

const CourierRotateIcon = ({ width = "20", height = "20", color = "#7F7D80" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M7 4L3.46319 4C2.80991 4 2.18339 4.22827 1.72145 4.6346C1.25951 5.04093 1 5.59203 1 6.16667L1 10.5L7 10.5"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17 2.1875L17 10.5L7 10.5L7 2.1875C7 1.87256 7.1317 1.57051 7.36612 1.34781C7.60054 1.12511 7.91848 1 8.25 1L15.75 1C16.0815 1 16.3995 1.12511 16.6339 1.34781C16.8683 1.57051 17 1.87256 17 2.1875Z"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.5 13C6.16304 13 6.79893 12.7366 7.26777 12.2678C7.73661 11.7989 8 11.163 8 10.5C8 9.83696 7.73661 9.20107 7.26777 8.73223C6.79893 8.26339 6.16304 8 5.5 8C4.83696 8 4.20107 8.26339 3.73223 8.73223C3.26339 9.20107 3 9.83696 3 10.5C3 11.163 3.26339 11.7989 3.73223 12.2678C4.20107 12.7366 4.83696 13 5.5 13Z"
      fill="white"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.5 13C14.163 13 14.7989 12.7366 15.2678 12.2678C15.7366 11.7989 16 11.163 16 10.5C16 9.83696 15.7366 9.20107 15.2678 8.73223C14.7989 8.26339 14.163 8 13.5 8C12.837 8 12.2011 8.26339 11.7322 8.73223C11.2634 9.20107 11 9.83696 11 10.5C11 11.163 11.2634 11.7989 11.7322 12.2678C12.2011 12.7366 12.837 13 13.5 13Z"
      fill="white"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default CourierRotateIcon;
