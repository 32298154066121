import React from 'react'

import { Dialog } from '../../../common/components/dialog'
import Button from '../../../common/components/button'
import { DeleteOrderContent } from '../../../common/components/delete-order-content'
import { SUMMARY_IDS } from '../bulk-create-orders.constants'

const DeleteRowDialog = ({ deleteRowModalState, t, setDeleteRowModalState, setDataSummary }) => {
  const onClose = () => setDeleteRowModalState({ isOpen: false, rowData: {}, deleteRow: () => {} });

  const handleDeleteRow = () => {
    deleteRowModalState.deleteRow();
    const isError = deleteRowModalState.rowData?.isError;

    setDataSummary((dataSummary) => {
      const failedCount = dataSummary[SUMMARY_IDS.FAILED];
      return({ 
        ...dataSummary,
        [SUMMARY_IDS.FAILED]: isError && (failedCount > 0) ? failedCount -1: failedCount,
        [SUMMARY_IDS.DELETED]: dataSummary[SUMMARY_IDS.DELETED] + 1
      })})
    onClose()
  }

  const deleteOrderDialogActions =  [
    {
      key: "close",
      content: (
        <Button
          color="secondary"
          onClick={onClose}
          label={t('bulkCreateOrders.uploadView.deleteModal.noButton')}
          size={{ width: 156, height: 36 }}
        />
      ),
    },
    {
      key: "delete",
      content: (
        <Button
          onClick={handleDeleteRow}
          label={t('bulkCreateOrders.uploadView.deleteModal.deleteButton')}
          size={{ width: 156, height: 36 }}
        />
      ),
    },
  ];

  return <Dialog
    content={
      <DeleteOrderContent
        description={t('bulkCreateOrders.uploadView.deleteModal.title')}
        actionButtons={deleteOrderDialogActions}
      />
    }
    isOpen={deleteRowModalState.isOpen}
    setIsDialogOpen={onClose}
  />
}

export default DeleteRowDialog