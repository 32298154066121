import React from "react";
import { Box, SvgIcon, Typography, useTheme } from "@mui/material";

import Styled from "../select-courier.styled";
import { LeftArrowIcon, SortingIcon } from "../../../assets/icons";
import IconButton from "../../../common/components/icon-button";

const Header = ({ isMobileView, onBack, setDrawerOpen, orderSummary, showBackButton, pageTitle, t }) => {
  const theme = useTheme();

  const filterItems = (items) => isMobileView? items.filter(({key}) => key !== 'zone') : items

  const renderSortingButton = () => {
    return (
      <IconButton  icon={<SortingIcon />} onClick={() => setDrawerOpen(true)}/>
    );
  };

  const renderSummary = ({ items }) =>
    filterItems(items).map(({ key, title, value }, index) => (
      <Styled.OrderSummarySubContainer key={key} $isMobileView={isMobileView}>
        <Styled.OrderSummaryTitle variant="semiBoldSmall" color={theme.palette.grey.main} maxWidth={isMobileView? "100px": "fit-content"}>
          {isMobileView? '': `${title}:`}
          <Typography
            variant="boldSmall"
            color={theme.palette.grey.main}
            paddingLeft="4px"
            textTransform={"capitalize"}
            textOverflow="ellipsis"
          >
            {key === 'amount'? `${t('mutualizedEgpLabel')} `: ''}{value}{(key === 'city' && isMobileView) ? `, ${items.find(({ key }) => key=== 'zone')?.value}` : null}
          </Typography>
        </Styled.OrderSummaryTitle>
        {isMobileView? (index < items.length - 2 ? <Styled.OrderSummaryDot /> : null): null}
      </Styled.OrderSummarySubContainer>
    ));

  return (
    <Box>
      <Styled.HeaderWrapper $isMobileView={isMobileView}>
        {/* Title Section */}
        <Box display="flex" gap={isMobileView ? "8px" : "16px"} alignItems="center" >
          {showBackButton?
            <Styled.BackIconButton onClick={() => onBack()}>
              <LeftArrowIcon width="20" height="20"/>
            </Styled.BackIconButton>: null
          }
          <Typography variant="bold">{pageTitle}</Typography>
        </Box>

        {/* Sorting Button For Mobile View */}
        {isMobileView ? renderSortingButton() : null}
      </Styled.HeaderWrapper>

      {/* Summary Section */}
      <Styled.OrderSummaryContainer $isMobileView={isMobileView}>
        { !isMobileView &&
          <Typography variant="boldSmall" color={theme.palette.grey.main}>
            {t('selectCourier.summary.title')}
          </Typography>
        }
        {renderSummary({ items: orderSummary })}
      </Styled.OrderSummaryContainer>
    </Box>
  );
};

export default Header;
