import { Button, IconButton, Checkbox as MuiCheckbox, Box, Badge } from "@mui/material";
import { styled } from "@mui/system";

const ActionButtonWrapper = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== "hoverColor",
})(({ theme: { palette }, hoverColor = null }) => ({
  height: 32,
  width: 32,
  backgroundColor: palette.lightGreyOutline,
  ...(hoverColor ? {
    ":hover": {
      backgroundColor: hoverColor,
    }} : {}),
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
}))

const AwaitingActionsButton = styled(Button, { shouldForwardProp: (prop) => prop !== "isActive"})(({ theme: { palette }, isActive }) => ({
  color: palette.grey.main,
  padding: 12,
  backgroundColor: palette.white,
  border: `1px solid ${palette.lightGreyOutline}`,
  borderRadius: 8,
  height: 32,
  textTransform: 'none',
  boxShadow: '0px 0px 12px 0px rgba(42, 39, 43, 0.04)',
  ...(isActive && {border: `1px solid ${palette.black}`})
}))

const Checkbox = styled(MuiCheckbox, {
  shouldForwardProp: (prop) => prop !== "marginTop",
})(({ theme: { palette }, marginTop, noPadding }) => ({
  color: palette.text.light,
  ...(marginTop? { marginTop } : {}),
  ...(noPadding? { padding: 0 } : {}),
}))
const EditCourierContent = styled(Box)(({ $isMobileView }) => ({
  width: '100%',
  height: ($isMobileView? '90%': '100%'),
  display: 'inline-flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'center'
}));

const EditCourierSubContent = styled(Box, {
  shouldForwardProp: (prop) => prop !== "$isMobileView",
})(({ $isMobileView }) => ({
  display: "flex",
  alignItems: 'center',
  alignContent: 'center',
  gap: '16px',
  margin: ($isMobileView? "12px":"12px 24px"),
  flexDirection: "column"
}));

const EditCourierSortByGrid = styled(Box)(() => ({ 
  display: 'flex', 
  height: "54px",
  flexWrap: 'wrap', 
  alignContent: 'stretch',
  alignItems: 'center', 
  gap: "6px",
  cursor: 'pointer'
}));

const EditCourierSortByItem = styled(Box, {
  shouldForwardProp: (prop) => prop !== "$selected",
})(({ $selected, theme: { palette } }) => ({ 
  display: 'flex',
  padding: '4px 12px 4px 12px',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '12px',
  background: palette.lightGreyOutline,
  border: ($selected? `1px solid ${palette.black}`: 'none'),
}));

const EditCourierListContent = styled(Box)(({ $isMobileView })=>({
  width: '100%',
  maxHeight: ($isMobileView? "auto":"calc(100vh - 140px - 80px)"),
  overflowY: 'scroll',
  scrollbarWidth: "none",
  "&::-webkit-scrollbar": {
    display: "none",
  },
  "&-ms-overflow-style:": {
    display: "none",
  },

}));

const EditCourierActionButtonContent = styled(Box)(() => ({
  width: "100%",
  display: "flex",
  padding: "24px",
  alignItems: "center",
  justifyContent: "center",
}));

const FiltersBadge = styled(Badge)({
  '& .MuiBadge-badge': {
    top: 4,
    right: 5
  },
});

const SelectAllLabel = styled(Button)(({ theme: { palette, typography } }) => ({
  ...typography.semiBoldSmall,
  minWidth: 'unset',
  backgroundColor: 'transparent',
  textTransform: 'none',
  padding: 0,
  textDecoration: "underline",
  cursor: "pointer",
  color: palette.text.light,
  "&:hover": {
    color: "black",
    textDecoration: "underline",
    backgroundColor: 'transparent'
  }
}))

const Styled = {ActionButtonWrapper, AwaitingActionsButton, Checkbox, SelectAllLabel,
  EditCourierContent, EditCourierSubContent ,EditCourierSortByGrid, EditCourierSortByItem, EditCourierListContent,
  EditCourierActionButtonContent, FiltersBadge }

export default Styled