import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@mui/material";

import { Select } from "../../../common/components/form-field/components/select/select";
import useAdaptAreas from "../../../common/hooks/use-adapt-areas";
import { selectAreas } from "../../../utils/common.slice";
import { fetchAreas } from "../../../utils/common.slice";

const CityFilter = ({ setValue, value, t }) => {
  const areas = useSelector(selectAreas)

  const mapOptions = (options = [], lang) => {
    const optionLabel = lang === 'en'? 'name': 'name_ar'
    return options?.map((option) => ({label: option[optionLabel], value: option[optionLabel]}))
  }

  const { filterOptions, onInput, selectOptions, inputLanguage } = useAdaptAreas({ mapOptionsCbk: mapOptions, value, options: areas })

  const dispatch = useDispatch()

  useEffect(() => {
    if(areas.length === 0) dispatch(fetchAreas())
  }, [])

  return (
    <Box padding="16px 24px">
      <Select
        value={value? { label: value, value: value } : ''}
        options={selectOptions}
        onChange={(value) => setValue('city', value.value)}
        placeholder={t('listing.filters.city.selectAreaPlaceholder')}
        filterOptions={filterOptions}
        onInput={onInput}
        inputLanguage={inputLanguage}
        height={36}
        hasLeftPadding
      />
    </Box>
  )
}

export default CityFilter