import { Typography, Box } from "@mui/material";
import { styled } from '@mui/material/styles';

const Wrapper = styled('div')`
  height: 100%;
  width: 100%;
  flex-direction: column;
  overflow-x: auto;
  overflow-y: scroll;
  display: flex;
  gap: 12px;
  padding: 20px 48px 28px 40px;
`

const GridWrapper = styled('div')(({ disabled }) => `
height: 100%;
overflow: auto;
  > div:last-child {
    height: 100%;
  }
  .dsg-container {
    opacity: ${disabled? 0.5 : 1};
  }
`)

const InfoIconWrapper = styled('div')(({ theme: { palette }, isError, background = 'greyBorder', infoColor="gray", disabled  }) => `
display: flex;
align-items: center;
cursor: ${disabled? 'initial': 'pointer'};

  > svg {
    circle:first-of-type {
      fill: ${isError ? palette.lightGreyOutline : palette[background]};
    }
    circle:nth-of-type(2) {
      fill: ${isError ? palette.red : palette[infoColor]};
    }
    path {
      stroke: ${isError ? palette.red : palette[infoColor]};
    }
  }
    ${!disabled && `
      :hover {
        svg {
          circle:first-of-type {
            fill: ${isError ? palette.red : palette.black};
          }
          circle:nth-of-type(2) {
            fill: ${palette.white};
          }
        path {
          stroke: ${palette.white};
        }
      }
  `}
`)

const RowIndicator = styled('td')(({ theme: { palette } }) => ({
  border: `1px solid ${palette.lightGreyOutline}`,
  textAlign: 'center'
}))

const DeleteIconButton = styled('button')(({ theme: { palette } }) => ({
  backgroundColor: palette.background.light,
  height: '100%',
  width: '40px',
  borderRadius: 0,
  border: 'transparent',
  cursor: 'pointer',
  ":hover": {
    '> svg': {
      'path': {
        stroke: palette.black
      }
    }
  },
  ":disabled": {
    ":hover": {
      '> svg': {
        'path': {
          stroke: '#7F7D80'
        }
      }
    },
    cursor: 'initial',
    opacity: 0.5
  },
  padding: 0
}))

const CornerIndicator = styled('td')(({ theme: { palette, typography } }) => ({
  color: palette.gray,
  width: 40,
  maxWidth: 40,
  padding: '0 14px',
  border: `1px solid ${palette.lightGreyOutline}`,
  backgroundColor: palette.background.light,
  ...(typography.boldText)
}))

const ErrorMsgWrapper = styled(Box)(({ theme: { palette } }) => ({
  border: `1px solid ${palette.red}`,
  height: '100%',
  width: '100%'
}))

const ErrorMsg = styled(Typography)(({ theme: { palette, typography } }) => ({
  color: palette.red,
  maxWidth: '80%',
  maxHeight: 20,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'pre',
  ...typography.regular
}))

const InputField = styled('input')(({ theme: { typography } }) => `
 ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
  }
  :focus, :focus-visible {
    border-color: transparent;
    outline: aliceblue; //hack to remove black border
  }
  padding-left: 8px;
  width: 100%;
  height: 100%;
  border-color: transparent;
  border-radius: 0;
  font-size: ${typography.regular.fontSize};
  font-family: ${typography.regular.fontFamily};
  font-weight: ${typography.regular.fontWeight};
  line-height: ${typography.regular.lineHeight};
`)

export { InfoIconWrapper }
const Styled = { Wrapper, RowIndicator, CornerIndicator, InfoIconWrapper, ErrorMsgWrapper, ErrorMsg, InputField, GridWrapper, DeleteIconButton }

export default Styled