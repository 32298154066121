import React, { useEffect } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { Typography, Box, Checkbox } from "@mui/material";
import {
  CheckedCircleIcon,
  UnCheckedCircleIcon,
} from "../../../../../../assets/icons";

import { ITEM_STATUSES } from "./success-rate-constants";

const SuccessMeter = ({ statusSuccessRate, t }) => {
  const [successPercentage, setSuccessPercentage] = React.useState(0);
  const [isIncludeProcessing, setIsIncludeProcessing] = React.useState(false);

  const getSuccessPercentage = () => {
    if (!statusSuccessRate) return 0;
    const successfulOrders = statusSuccessRate?.find(
      (item) => item.status === ITEM_STATUSES.SUCCESS
    );
    const unSuccessfulOrders = statusSuccessRate?.find(
      (item) => item.status === ITEM_STATUSES.UNSUCCESSFUL
    );
    const processingOrders = statusSuccessRate?.find(
      (item) => item.status === ITEM_STATUSES.PROCESSING
    );

    if ((successfulOrders?.count ?? 0) === 0) return 0;

    const totalOrders =
      (successfulOrders?.count ?? 0) +
      (unSuccessfulOrders?.count ?? 0) +
      (processingOrders?.count ?? 0);

    if (isIncludeProcessing)
      return Math.round((successfulOrders?.count / totalOrders) * 100);
    else
      return Math.round(
        (successfulOrders.count /
          (totalOrders - (processingOrders?.count ?? 0))) *
          100
      );
  };

  useEffect(() => {
    setSuccessPercentage(getSuccessPercentage());
  }, [statusSuccessRate, isIncludeProcessing]);

  return (
    <Box display={"flex"} flexDirection={"column"} width={"50%"} alignItems={"flex-end"}>
      <Box position={"relative"} mt={"50px"}>
        <CircularProgress
          variant="determinate"
          value={successPercentage}
          size={200}
          sx={{color:"#19CF98"}}
        />
        <Box
          position={"absolute"}
          display={"flex"}
          flexDirection={"column"}
          alignItems={"center"}
          justifyContent={"center"}
          top={0}
          left={0}
          bottom={0}
          right={0}
          textAlign={"center"}
        >
          <Typography variant="extraBold">{`${successPercentage}%`}</Typography>
          <Typography variant="mediumText">{t("analytics.successMeterLabel")}</Typography>
        </Box>
      </Box>
      <Box mt={"20px"} mb={"20px"}>
        <Checkbox
          icon={<UnCheckedCircleIcon />}
          checkedIcon={<CheckedCircleIcon />}
          onChange={() => setIsIncludeProcessing(!isIncludeProcessing)}
          size="large"
        />
        <Typography variant="mediumText">
          {t("analytics.deliveryPerformance.successRate.includeProcessing")}
        </Typography>
      </Box>
    </Box>
  );
};

export default SuccessMeter;
