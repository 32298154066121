import React from "react";
import { useTheme } from "@mui/material";
import useViewport from "../../../../utils/use-viewport";
import { useTranslation } from "react-i18next";

import Styled from "../../analytics.styled";
import { PersonIcon } from "../../../../assets/icons";
import { renderTitle } from "../../analytics.utils";
import TopCities from "./components/top-cities";
import TopAreas from "./components/top-areas";

const GeographicalAnalysis = ({
  topCities,
  isTopCitiesLoading,
  topAreas,
  isTopAreasLoading,
}) => {
  const { isMobileView } = useViewport();
  const theme = useTheme();
  const { t } = useTranslation();
  return (
    <Styled.SubContentWrapper $isMobileView={isMobileView}>
      {renderTitle({
        title: t("analytics.geographicalAnalysis.title"),
        theme,
        Icon: PersonIcon,
      })}
      <Styled.DeliveryPerformanceCard>
        <TopCities
          isMobileView={isMobileView}
          theme={theme}
          data={topCities}
          isLoading={isTopCitiesLoading}
        />
        <TopAreas
          isMobileView={isMobileView}
          theme={theme}
          data={topAreas?.topAreas}
          isLoading={isTopAreasLoading}
        />
      </Styled.DeliveryPerformanceCard>
    </Styled.SubContentWrapper>
  );
};

export default GeographicalAnalysis;
