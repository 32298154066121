import * as React from 'react';
import { unstable_useNumberInput as useNumberInput } from '@mui/base/unstable_useNumberInput';
import { Box, IconButton, Input, styled } from '@mui/material';
import {  SmallArrowDownIcon, SmallArrowUpIcon } from '../../../../assets/icons';

const CompactNumberInput = (props) => {
  const {
    getRootProps,
    getInputProps,
    getIncrementButtonProps,
    getDecrementButtonProps,
  } = useNumberInput(props);

  const inputProps = getInputProps();

  return (
    <StyledInputRoot {...getRootProps()} required={inputProps.required}>
      <Input fullWidth {...inputProps} disableUnderline={true} min="0"  />
      <Box>
        <StyledStepperButton disableRipple hasBottomBorder={true} className="increment" {...getIncrementButtonProps()}>
          <SmallArrowUpIcon />
        </StyledStepperButton>
        <StyledStepperButton disableRipple className="decrement" {...getDecrementButtonProps()} disabled={inputProps.value <= 1}>
          <SmallArrowDownIcon />
        </StyledStepperButton>
      </Box>
    </StyledInputRoot>
  );
};

export function NumberInput ({ disabled, defaultValue, name, hasError, value, setFormValue, placeholder }) {
  return (
    <CompactNumberInput
      aria-label="Compact number input"
      disabled={disabled}
      placeholder={placeholder}
      defaultValue={defaultValue}
      value={value}
      required={hasError}
      onChange={(event, val) => setFormValue(name,  val)}
    />
  );
}

const StyledInputRoot = styled('div')(({ theme, required }) => `
    display: flex;
    row-gap: 1px;
    height: 36px;
    overflow: auto;
    border-radius: 8px;
		padding-left: 12px;
    border-style: solid;
    border-width: 1px;
    border-color: ${theme.palette[required? 'red': 'greyBorder']};
		input:-webkit-autofill {
    -webkit-box-shadow:0 0 0 50px white inset;
    -webkit-text-fill-color: #333;
    }
  `,
);

const StyledStepperButton = styled(IconButton, { shouldForwardProp: (prop) => prop !== "hasBottomBorder" })(({ theme, hasBottomBorder }) => ({
  display: 'flex',
  width: 28,
  height: 17,
  padding: 0,
  borderLeft: `1px solid ${theme.palette.greyBorder}`,
  borderBottom: (hasBottomBorder? `1px solid ${theme.palette.greyBorder}`: 0),
  borderRadius: 0,
  backgroundColor: (theme.palette.lightGreyOutline),
  borderColor: (theme.palette.greyBorder),
}))