/* eslint-disable max-len */
import React from "react"

const AmountFilledIcon = ({ width="14", height="14", color = "#2A272B" }) => (
  <svg width={width} height={height} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.3999 3.64014V10.3601C1.3999 11.5921 3.88879 12.6001 6.9999 12.6001C10.111 12.6001 12.5999 11.5921 12.5999 10.3601V3.64014" fill="#FBFBFB"/>
    <path d="M1.3999 3.64014V10.3601C1.3999 11.5921 3.88879 12.6001 6.9999 12.6001C10.111 12.6001 12.5999 11.5921 12.5999 10.3601V3.64014" stroke={color} strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M12.5999 7C12.5999 8.232 10.111 9.24 6.9999 9.24C3.88879 9.24 1.3999 8.232 1.3999 7" stroke={color} strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M6.9999 5.8799C10.0927 5.8799 12.5999 4.87702 12.5999 3.6399C12.5999 2.40278 10.0927 1.3999 6.9999 1.3999C3.90711 1.3999 1.3999 2.40278 1.3999 3.6399C1.3999 4.87702 3.90711 5.8799 6.9999 5.8799Z" fill="#FBFBFB" stroke={color} strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
)

export default AmountFilledIcon
