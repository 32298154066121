import { styled } from "@mui/material";

const CardWrapper = styled('div')(({ theme: { palette, customShadows } }) => ({
  display: 'flex',
  padding: 12,
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  backgroundColor: palette.white,
  borderRadius: 8,
  boxShadow: customShadows.component,
  border: `1px solid ${palette.lightGreyOutline}`
}))

const CardsWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: 8,
  height: '100%',
  overflowY: 'auto'
})

const Styled = { CardWrapper, CardsWrapper }

export default Styled