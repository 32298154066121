import { Input } from "@mui/material";
import { styled } from "@mui/system";

const SearchField = styled(Input, {
  shouldForwardProp: (prop) => prop !== "isFullWidth",
})(({ theme: { palette, typography }, isFullWidth }) => ({
  height: 32,
  maxWidth: isFullWidth ? "100%" : 400,
  borderRadius: 8,
  padding: 15,
  backgroundColor: palette.lightGreyOutline,
  border: "none",
  width: isFullWidth ? "100%" : 400,
  ...typography.regular,
}));

const Styled = {
  SearchField,
};

export default Styled;
