import React from "react";

const SequenceIcon = ({ width="9", height="40", color = "#D4D4D4" }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 9 40" fill="none" >
    <line x1="4.5" y1="9" x2="4.5" y2="40" stroke={color} strokeDasharray="2 2"/>
    <circle cx="4.5" cy="4.5" r="4.5" fill={color} />
  </svg>
);

export default SequenceIcon;
