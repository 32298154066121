import React from "react";
import { Typography, useTheme } from "@mui/material";

import Styled from "./select-courier-cards.styled";
import RenderCourierLogo from "../courier-logo";

export function SelectCourierCards({ rows, selectedCourierId, isMultiSelect, setSelectedCourierId, selectedCourierIds = [], updateCouriers, hideNote, t }) {
  const theme = useTheme();

  const handleOnclickAction = (id) => {
    if(typeof updateCouriers === 'function') {
      updateCouriers(id)
      return
    }
    if(id === selectedCourierId) setSelectedCourierId("");
    else setSelectedCourierId(id);
  }

  const getOrderId = (row) => {
    if(row.id) return row.id;
    else return row._id;
  }

  return (
    <Styled.Container>
      {!hideNote &&
      <Typography variant="semiBoldSmall" color={theme.palette.text.light}>
        {t('selectCourier.multipleOrderSelectionNote')}
      </Typography>
      }
      <Styled.CardsContainer>
        {rows.map((row) => {
          return (
            <Styled.Card key={getOrderId(row)} onClick={()=>handleOnclickAction(getOrderId(row))}
              $selected={isMultiSelect? selectedCourierIds.includes(getOrderId(row)) : selectedCourierId === getOrderId(row) }
            >
              {RenderCourierLogo({ logo: row.logo, height: 36, width: 84 })}
            </Styled.Card>
          );
        })}
      </Styled.CardsContainer>
    </Styled.Container>
  );
}
