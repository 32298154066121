import React from "react";

import Styled from "../filters.styled";

const Chip = ({ isDisabled, isSelected, icon, label, onClick }) => (
  <Styled.Chip
    disabled={isDisabled}
    isSelected={isSelected}
    icon={icon}
    clickable={false}
    label={<Styled.ChipLabel variant="semiBoldSmall" isSelected={isSelected}>{label}</Styled.ChipLabel>}
    variant="outlined"
    onClick={onClick}
  />
)

export default Chip