import { Stack, Typography } from "@mui/material"
import React from "react"
import { UnderProgressIcon } from "../../../assets/icons"

const MobileErrorView = ({ errorMsg }) => (
  <Stack
    display="flex"
    justifyContent="center"
    alignItems="center"
    height="100%"
    gap="40px"
    textAlign="center"
    padding="20px"
  >
    <UnderProgressIcon />
    <Typography variant="boldSideScreenTitle">
      {errorMsg}
    </Typography>
  </Stack>
)

export default MobileErrorView