/* eslint-disable max-len */
import React from 'react'

const ExchangeIcon = () => (
  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M17.0153 4.19995L20.7845 7.96916L17.0153 11.7384" stroke="#2A272B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M4.20001 12.4923L4.20001 9.47692C4.20001 9.07706 4.35886 8.69357 4.6416 8.41083C4.92435 8.12808 5.30783 7.96924 5.7077 7.96924L20.7845 7.96924" stroke="#2A272B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M10.9846 23.8L7.21539 20.0308L10.9846 16.2616" stroke="#2A272B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M23.7999 15.5076V18.5229C23.7999 18.9228 23.6411 19.3063 23.3583 19.589C23.0756 19.8718 22.6921 20.0306 22.2922 20.0306H7.21539" stroke="#2A272B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
)

export default ExchangeIcon