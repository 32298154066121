import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";

const SLACard = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isSelected" && prop !== "theme",
})(({ isSelected, theme }) => ({
  mb: 1,
  "@media (max-width: 1099px)": {
    width: "180px",
  },
  width: "140px",
  height: "80px",
  backgroundColor: theme.palette.lightgrey,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "10px",
  cursor: "pointer",
  border: isSelected ? "1px solid black" : "none",
}));

const Styled = {
  SLACard: SLACard,
};

export default Styled;
