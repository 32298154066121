/* eslint-disable max-len */
import React from "react"

const PrintManifestoIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.15382 11.6155H14.8461C15.1317 11.6155 15.4056 11.7289 15.6076 11.9309C15.8095 12.1329 15.923 12.4068 15.923 12.6924V16.0001C15.923 16.5524 15.4753 17.0001 14.923 17.0001H5.0769C4.52462 17.0001 4.0769 16.5524 4.0769 16.0001V12.6924C4.0769 12.4068 4.19036 12.1329 4.39233 11.9309C4.59429 11.7289 4.86821 11.6155 5.15382 11.6155L" stroke="#7F7D80" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M4.07692 12.6924C3.7913 12.6924 3.51738 12.579 3.31542 12.377C3.11346 12.1751 3 11.9011 3 11.6155V9.46168C3 9.17607 3.11346 8.90215 3.31542 8.70019C3.51738 8.49823 3.7913 8.38477 4.07692 8.38477H15.923C16.2086 8.38477 16.4826 8.49823 16.6845 8.70019C16.8865 8.90215 16.9999 9.17607 16.9999 9.46168V11.6155C16.9999 11.9011 16.8865 12.1751 16.6845 12.377C16.4826 12.579 16.2086 12.6924 15.923 12.6924" stroke="#7F7D80" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M14.3077 3H5.69238V8.38459H14.3077V3Z" stroke="#7F7D80" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M6.76929 14.3076H13.2308" stroke="#7F7D80" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
)

export default PrintManifestoIcon