import React from "react";
import { Box, Typography, IconButton as MuiIconButton, Stack, Skeleton } from "@mui/material";

import { getActivities, toUpperFirstLetter } from "../orders-table.utils";
import { CourierLogo, SubStatus, Activities, ActionBar } from '../components'
import Checkbox from "../../../common/components/checkbox"
import DateTimeLabel from '../../../common/components/date-time-label'
import { STATUS_IDS } from "../orders-table.constants";
import Styled from './cards.styled'
import IconButton from "../../../common/components/icon-button";
import { LeftArrowIcon, MoreIcon, RightArrowIcon } from "../../../assets/icons";
import { HighlightOff } from "@mui/icons-material";

function Cards({ orders, toggleOrder, selectedOrders, setPendingOrder, openActionsMenu, theme, actionBarProps, t,
  handleNavigation, page, totalOrdersCount, isOrdersLoading, setOrderDetailsPage, setClickedOrderId, isSelectAll, setIsSelectAll, searchTerm, activeFilters, selectedTab }) {

  const openOrderDetails = (orderId) => {
    setClickedOrderId(orderId)
    setOrderDetailsPage(true);
  }

  const checkIfNextNavigationDisabled = () => (
    totalOrdersCount < 20 || (page === totalOrdersCount/20)
  )

  const renderCard = (order) => {
    const { id, code, city, customerName, processedOn, amount, status, courierLogo, subStatus, needDataUpdate, supportNote, needReprintLabel } = order
    return (
      <Styled.CardWrapper key={id}>
        {/* left details */}
        <Box display="flex" gap="12px" alignItems="flex-start" alignContent="center" maxWidth="70%">
          <Checkbox
            isItemSelected={selectedOrders?.some((order) => order.id === id)}
            index={id}
            disableRipple
            onChange={() => toggleOrder(order)}
            marginTop={2}
            noPadding
            labelId={`orders-table-checkbox-${id}`}

          />

          <Stack display="flex" width="90%">
            <Typography variant="regularWithUnderline" marginBottom="4px" onClick={()=> {openOrderDetails(id); setPendingOrder(order)}}>
              {code}
            </Typography>
            <Box typography="semiBoldSmall" display="flex" gap="2px">
              <Typography variant="semiBoldSmall" maxWidth="70%" whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
                {customerName}
              </Typography>
              {`(${city})`}
            </Box>
            {amount ?
              <Typography variant="regular">
                {+amount?.toFixed(2)} {t('mutualizedEgpLabel')}
              </Typography>
              : ''}
            <Box marginTop="8px" lineHeight="16px">
              {status === STATUS_IDS.PENDING ?
                <Typography variant="semiBoldSmall" color={theme.palette.text.light}>{toUpperFirstLetter(status)}</Typography>
                : <DateTimeLabel dateTime={processedOn} theme={theme}/>}
            </Box>
            <SubStatus status={status} subStatus={subStatus} theme={theme}/>
          </Stack>
        </Box>

        {/* RIGHT DETAILS */}
        <Stack display="flex" justifyContent="space-between" height='100%'>
          <Box display="flex" gap="8px">
            <CourierLogo imgPath={courierLogo} />
            {status === STATUS_IDS.PENDING && <MuiIconButton disableRipple onClick={(event) => openActionsMenu(event, order)}><MoreIcon /></MuiIconButton>}
          </Box>

          <Activities activities={getActivities({ needDataUpdate, supportNote, needReprintLabel })} />
        </Stack>
      </Styled.CardWrapper>
    )
  };

  const renderCardSkeleton = (id) => (
    <Styled.CardWrapper key={id}>
      <Box display="flex" gap="12px" alignItems="flex-start" alignContent="center">
        <Skeleton width={16} height={28} />

        <Stack display="flex">
          <Skeleton width={100}/>
          <Skeleton height={20} />
          <Skeleton width={40}/>
          <Box marginTop="8px">
            <Skeleton width={100}/>
          </Box>
          <Skeleton  width={110}/>
        </Stack>
      </Box>

      <Skeleton width={40} height={25} sx={{ marginRight: '2px' }}/>
    </Styled.CardWrapper>
  )

  const renderOrders = () => {
    if(orders?.length == 0) {
      return <Stack height='100%' justifyContent='center' alignItems='center' gap="10px">
        <HighlightOff sx={{ width: 50, height: 50 }}/>
        <Typography variant="bold">
          {t('listing.noOrdersFoundLabel')}
        </Typography>
      </Stack>
    }
    return orders.map(renderCard)
  }

  return(
    <Stack width="100%" padding="0 15px" display="flex" gap="8px" overflow="auto">
      <ActionBar
        selectedTab={selectedTab}
        searchTerm={searchTerm}
        activeFilters={activeFilters}
        isSelectAll={isSelectAll}
        setIsSelectAll={setIsSelectAll}
        totalOrdersCount={totalOrdersCount}
        theme={theme}
        orders={orders}
        isMobileView={true}
        selectedOrders={selectedOrders}
        {...actionBarProps}
      />
      <Styled.CardsWrapper>
        {isOrdersLoading? Array(20).fill().map((_, index) => renderCardSkeleton(index)) : renderOrders()}

        {/* BOTTOM Navigation */}
        <Box display="flex" justifyContent="space-between" width="100%" alignItems="center" marginTop="4px" paddingBottom="20px">
          <IconButton
            isDisabled={page === 1}
            icon={<LeftArrowIcon color={theme.palette[page === 1? 'greyBorder': 'gray']} />}
            onClick={() => handleNavigation('left')}
          />
          <Typography variant="regular" color={theme.palette.text.light}>{totalOrdersCount > 20? page * 20 : totalOrdersCount} - {totalOrdersCount}</Typography>
          <IconButton
            isDisabled={checkIfNextNavigationDisabled()}
            icon={<RightArrowIcon color={theme.palette[checkIfNextNavigationDisabled()? 'greyBorder': 'gray']} />}
            onClick={() => handleNavigation('right')}
          />
        </Box>
      </Styled.CardsWrapper>
    </Stack>
  )
}

export default Cards;