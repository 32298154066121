export const toDaysAndHoursStr = (totalMinutes) => {
  const absTotal = Math.abs(totalMinutes);
  const mins = totalMinutes % 60;
  const hours = Math.floor(absTotal / 60);
  const days = Math.floor(hours / 24);
  const absHours = hours % 24;
  if (days === 0 && hours > 0) {
    return absHours + "hr";
  } else if (days === 0 && hours === 0) {
    return mins + "mins";
  }
  return days + "d " + absHours + "hr";
};
