
const ORDERS_IDS = {
  DELIVERY: 'delivery',
  RETURN: 'return',
  EXCHANGE: 'exchange',
  CASH_SETTLEMENT: 'cashSettlement'
}

const deliveryAmountFieldDetails = { name: 'collectionAmount' }

const returnAmountFieldDetails = { name: 'refundAmount' }

// for regex reference => https://www.wikiwand.com/en/Telephone_numbers_in_Egypt
export const PHONE_REGEX = /^(\+20|0020|020|0)?1[0125][0-9]{8}$|^$/;

export const BACKUP_PHONE_REGEX =
  /(^(\+2|002|02)?((2[0-9]{7})|(3)[0-9]{6}|(68|88|97|13|82|45|57|84|64|47|95|46|50|86|48|92|66|96|65|93|62|40|69|55|13)[0-9]{7})$)|(^((\+20|0020|020|0)?1[0125][0-9]{8})$)|(^$)/;

export { ORDERS_IDS, deliveryAmountFieldDetails, returnAmountFieldDetails }