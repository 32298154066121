import React from "react"

const ClosedEyeIcon = () => (
  <svg width="12px" height="12px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" >
    <path d="M2 10C2 10 5.5 14 12 14C18.5 14 22 10 22 10" stroke="#AAA8AA" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M4 11.6445L2 14" stroke="#AAA8AA" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M22 14L20.0039 11.6484" stroke="#AAA8AA" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M8.91406 13.6797L8 16.5" stroke="#AAA8AA" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M15.0625 13.6875L16 16.5" stroke="#AAA8AA" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
)

export default ClosedEyeIcon