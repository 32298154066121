import dayjs from 'dayjs';

const getDeliveryData = ({ merchantName = '', customer }) => {
  const {
    name, phone, backupPhone, city, zone
  } = customer || {}

  return ([
    {id: 'من', label: merchantName },
    {id: 'الي', label: name },
    {id: 'التليفون', label: `${phone}${phone && backupPhone && ' - '}${backupPhone}` },
    {id: 'المنطقة', label: `${city}${zone && city && ' - '}${zone}`}
  ])}


const adaptDate = (date) => dayjs(new Date (date)).format('ddd, D MMM YYYY, h:mm a');

const truncateText = (text, maxLength = 150) => {
  if(!text) return ''
  if (text.length > maxLength) {
    return text.substring(0, maxLength).trim() + '...';
  } else {
    return text;
  }
}

export { getDeliveryData, truncateText, adaptDate}