import { BREACH_STATUS_SEVERITY_IDS } from "./first-attempt-sla.constants";

export const getStatusSeverity = (percentage) => {
  switch (true) {
  case percentage === null:
    return BREACH_STATUS_SEVERITY_IDS.NA;
  case percentage <= 9:
    return BREACH_STATUS_SEVERITY_IDS.GOOD;
  case percentage > 9 && percentage <= 19:
    return BREACH_STATUS_SEVERITY_IDS.MEDIUM;
  default:
    return BREACH_STATUS_SEVERITY_IDS.BAD;
  }
};