import { useDispatch } from "react-redux"
import { useForm } from "react-hook-form"
import { Box, Divider, Drawer, Grid, Typography } from "@mui/material"
import React, { useEffect, useMemo, useState } from "react"

import { getAddPickupLocationsFields } from "../create-order.utils"
import FormField from "../../../common/components/form-field"
import { addPickupLocation } from "../create-order.slice"
import Styled from "./add-pickup-location-drawer.styled"
import { CloseIcon } from "../../../assets/icons"
import { PHONE_REGEX } from "../create-order.constants"
import Button from "../../../common/components/button"
import toEnglishNumber from "../../../utils/toEnglishNumber"


const AddPickupLocationDrawer = ({ isMobileView, t, isDrawerVisible, toggleDrawer, theme, areas, dropDownsOptions, setDropDownsOptions, setFormValue, setPickupLocations }) => {
  const dispatch = useDispatch()
  const [isAddingLocation, setIsAddingLocation] = useState(false)

  const { register, handleSubmit, formState, setValue, watch, reset } = useForm({
    defaultValues: {
      locationName: '',
      pickupLocationArea: '',
      addressLine: '',
      contactName: '',
      contactPhone: '',
      setAsDefault: ''
    }
  });

  const closeDrawer = () => {
    toggleDrawer(false);
    reset();
  }

  const form = watch();

  const handleSetValue = (name, value) => setValue(name, value, { shouldDirty: true })

  useEffect(() => {
    register('locationName', { required: 'Please enter your location' });
    register('pickupLocationArea', { required: 'Please Select pickup location' });
    register('addressLine', {
      required: 'Please enter your address',
      validate: (field) =>  field.split(" ").length > 3 ? true : 'Address must be at least 4 words.',
    });
    register('contactName', { required: 'Please enter contact name' });
    register('contactPhone', { required: 'Please enter contact phone', validate: (value) => PHONE_REGEX.test(toEnglishNumber(value))? true : 'Invalid phone' });
    register('setAsDefault');
  }, [register])

  const { errors, isDirty } = formState

  const onAddPickupLocation = (data) => {
    setIsAddingLocation(true)
    return dispatch(addPickupLocation(data)).then((res) => {
      const locationId = res.payload.data.body._id
      setIsAddingLocation(false)
      if(res.payload) {
        closeDrawer()
        if(locationId) {
          setDropDownsOptions((prevOptions) => {
            let locations = prevOptions.pickupLocations
            const newLocation = { label: data.locationName, value: locationId, area: data?.pickupLocationArea?.label?.split(' - ')?.[0] }
            if(data.setAsDefault) {
              setFormValue('orderDetails.pickupLocation', newLocation)
              if(typeof setPickupLocations === 'function') { //TODO: polish
                setPickupLocations((prevLocations) => [newLocation, ...prevLocations])
              }
              return ({...prevOptions, pickupLocations: [ newLocation, ...locations]})
            }
            if(typeof setPickupLocations === 'function') { //TODO: polish
              setPickupLocations((prevLocations) => [...prevLocations, newLocation])
            }
            return ({...prevOptions, pickupLocations: [ ...locations, newLocation]})
          })
        }
      }})}

  const addPickupLocationFormFields = useMemo(() => [...getAddPickupLocationsFields({ areas })], [areas])

  const onError = (errors) => console.log(errors)

  return (
    <Drawer
      anchor={isMobileView ? "bottom" : "right"}
      open={isDrawerVisible}
      onClose={closeDrawer}
      PaperProps={{
        style: {
          borderTopRightRadius: isMobileView? '8px': '0px',
          borderTopLeftRadius: '8px',
          borderBottomLeftRadius: isMobileView? '0px': '8px'
        }
      }}
    >
      <Styled.DrawerWrapper isMobileView={isMobileView}>
        <Box display="flex" alignItems='center' justifyContent='space-between' padding="20px 16px 16px 24px">
          <Typography variant="boldSideScreenTitle">
            {t('createOrder.addPickupLocationFormTitle')}
          </Typography>
          <Styled.CloseIconButton onClick={closeDrawer}>
            <CloseIcon />
          </Styled.CloseIconButton>
        </Box>
        <Divider sx={{ borderColor:theme.palette.lightGreyOutline  }}/>
        <Styled.DrawerContentWrapper>
          <Typography variant="semiBoldSmall" color={theme.palette.text.light}>
            {t('createOrder.addPickupLocationHelperText')}
          </Typography>
          <Box>
            <Grid container columnSpacing='12px' rowSpacing={2}>
              {addPickupLocationFormFields.map(({ name, ...fieldProps }) => (
                <FormField
                  t={t}
                  fieldKey={name}
                  translationKey={'createOrder.addPickupLocationFields'}
                  key={name}
                  errorType={errors?.[name]?.type}
                  props={fieldProps}
                  value={form[name]}
                  setValue={handleSetValue}
                  name={name}
                  dropDownsOptions={dropDownsOptions}
                />
              ))}
            </Grid>
          </Box>
        </Styled.DrawerContentWrapper>
        <Box padding={2}>
          <Button
            label={t('createOrder.addPickupLocationButton')}
            color="primary"
            isDisabled={!isDirty}
            isLoading={isAddingLocation}
            onClick={handleSubmit(onAddPickupLocation, onError)}
          />
        </Box>
      </Styled.DrawerWrapper>
    </Drawer>
  )
}

export default AddPickupLocationDrawer