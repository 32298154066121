import {
  Box, Typography, LinearProgress, linearProgressClasses, ListItemButton as MuiListItemButton,
  Card as MuiCard, CardContent as MuiCardContent,
} from "@mui/material";
import { styled } from "@mui/system";


const ListContainer = styled(Box)({
  width: "100%",
  height: "100%"
});

const ListItemButton = styled(MuiListItemButton)(({ theme: { palette } }) => ({
  minHeight: 48,
  padding: 0,
  justifyContent: "center",
  color: palette.secondary,
  borderRadius: 12,
  border: `1px solid ${palette.lightGreyOutline}`,
  margin: "0px 0px 8px 0px",
  "&.MuiListItemButton-root": {
    "&.Mui-selected, &.Mui-selected:hover, &.Mui-selected:focus": {
      backgroundColor: 'transparent',
      border: `1px solid ${palette.black}`
    },
  }
}));

const Card = styled(MuiCard)(() => ({
  width: "100%",
  backgroundColor: 'transparent',
  borderRadius: 12,
  flexWrap: "wrap"
}));

const CardContentTop = styled(MuiCardContent)(({ theme: { palette } }) => ({
  height: 'fit-content',
  display: "flex",
  flexWrap: "wrap",
  background: palette.background.light,
  padding: "14px 12px 10px 12px"
}));

const CardTopLeft = styled(Box)(() => ({
  display: "flex",
  flexWrap: "wrap",
  alignContent: "center",
  gap: 4
}));

const CardTopRight = styled(Box)(() => ({
  display: "flex",
  flexWrap: "wrap",
  flex: 1,
  justifyItems: "flex-end",
  flexDirection: "row-reverse",
  gap: 8
}));

const CardContentBottom = styled(MuiCardContent)(({ theme: { palette } })=> ({
  width: "100%",
  justifyContent: 'space-between',
  alignItems: "center",
  display: "flex",
  flexWrap: "wrap",
  background: palette.white,
  "&.MuiCardContent-root:last-child": {
    padding: '13px 12px'
  }
}));

const CardContentBottomContent = styled(Box) ({
  display: "flex",
  flexDirection: "column",
  flexWrap: "wrap",
  gap: 2
});

const CardContentBottomSubContent = styled(Box) ({
  display: "flex",
  flex: 1,
  flexWrap: "wrap"
});

const CardBoldText = styled(Typography)(({ theme: { typography } }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  ...(typography.boldSubtitles)
}));

const CardText = styled(Typography)(({ theme: { typography } }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  ...(typography.regular)
}));

const ProgressLine = styled(LinearProgress)(({ theme, value }) => ({
  height: 5,
  borderRadius: 5,
  width: 20,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.progressLineBar[value <= 30 ? "green" : value <= 60 ? "orange" : "red"],
  },
}));

const SVGIcon = styled('div')(({ theme })=> ({
  ">svg": {
    ">path": {
      stroke: `${theme.palette.gray}`
    },
    ">line": {
      stroke: `${theme.palette.gray}`
    },
    ">rect": {
      fill: `${theme.palette.gray}`
    }
  },
  ">svg:hover": {
    ">path": {
      stroke: `${theme.palette.black}`
    },
    ">line": {
      stroke: `${theme.palette.black}`
    },
    ">rect": {
      fill: `${theme.palette.black}`
    }
  }
}))

const Styled = {
  ListContainer,
  ListItemButton,
  Card,
  CardContentTop,
  CardTopLeft,
  CardTopRight,
  CardContentBottom,
  CardContentBottomContent,
  CardContentBottomSubContent,
  CardBoldText,
  CardText,
  ProgressLine,
  SVGIcon
};

export default Styled;
