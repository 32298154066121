const ORDERS_IDS = {
  TOTAL_ORDERS: "totalOrders",
  SUCCESSFUL: "successful",
  UNSUCCESSFUL: "unsuccessful",
  PROCESSING: "processing",
};

const ORDERS_COLORS = {
  [ORDERS_IDS.TOTAL_ORDERS]: "gray",
  [ORDERS_IDS.SUCCESSFUL]: "limeGreen",
  [ORDERS_IDS.UNSUCCESSFUL]: "red",
  [ORDERS_IDS.PROCESSING]: "orange",
};

const ORDERS_CARDS = [
  { key: ORDERS_IDS.TOTAL_ORDERS, index: 1 },
  { key: ORDERS_IDS.PROCESSING, index: 2 },
  { key: ORDERS_IDS.SUCCESSFUL, index: 3 },
  { key: ORDERS_IDS.UNSUCCESSFUL, index: 4 },
];

const SKELETON_ORDERS_SUMMARY_CARDS = [
  { key: ORDERS_IDS.TOTAL_ORDERS, count: 0 },
  { key: ORDERS_IDS.PROCESSING, count: 0 },
  { key: ORDERS_IDS.SUCCESSFUL, count: 0 },
  { key: ORDERS_IDS.UNSUCCESSFUL, count: 0 },
];

const DATE_MENU_ITEMS_KEYS = {
  THIS_MONTH: "thisMonth",
  YESTERDAY: "yesterday",
  LAST_SEVEN_DAYS: "lastSevenDays",
  LAST_MONTH: "lastMonth",
};

const SLA_TYPES = {
  DELIVERY: "delivery",
  RETURN: "return",
};
const SLA_BUCKET_TYPES = {
  DELIVERY: "delivery_bucket",
  RETURN: "return_bucket",
};

const SLA_SECTION_KEYS = {
  ALL: "all",
  CAIRO: "cairo",
  GIZA: "giza",
  ALEXANDRIA: "alexandria",
  DELTA_AND_CANAL: "delta & canal",
  UPPER_AND_REMOTE: "upper & remote",
};

const MENU_IDS = {
  SLA_SECTION: "section-drop-down-menu",
  DATE_MENU: "date-drop-down-menu",
};

const DATE_MENU_ITEMS = [
  {
    key: DATE_MENU_ITEMS_KEYS.THIS_MONTH,
  },
  { key: DATE_MENU_ITEMS_KEYS.YESTERDAY },
  {
    key: DATE_MENU_ITEMS_KEYS.LAST_SEVEN_DAYS,
  },
  {
    key: DATE_MENU_ITEMS_KEYS.LAST_MONTH,
  },
];

const SECTION_MENU_ITEMS = [
  { 
    key: SLA_SECTION_KEYS.ALL 
  },
  {
    key: SLA_SECTION_KEYS.CAIRO,
  },
  {
    key: SLA_SECTION_KEYS.GIZA,
  },
  {
    key: SLA_SECTION_KEYS.ALEXANDRIA,
  },
  {
    key: SLA_SECTION_KEYS.DELTA_AND_CANAL,
  },
  {
    key: SLA_SECTION_KEYS.UPPER_AND_REMOTE,
  },
];

const OTHER_COURIER_ID = "other";

export {
  ORDERS_CARDS,
  ORDERS_COLORS,
  ORDERS_IDS,
  SKELETON_ORDERS_SUMMARY_CARDS,
  DATE_MENU_ITEMS_KEYS,
  OTHER_COURIER_ID,
  SLA_TYPES,
  SLA_BUCKET_TYPES,
  SLA_SECTION_KEYS,
  MENU_IDS,
  DATE_MENU_ITEMS,
  SECTION_MENU_ITEMS,
};
