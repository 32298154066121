import React from "react";
import { TableHead, TableRow, Typography } from "@mui/material";

import Styled from "./table.styled";
import Checkbox from "../checkbox";

function TableHeader({
  headerCells,
  onSelectAllClick,
  theme,
  t,
  isAllOrdersSelected,
  entity,
}) {
  return (
    <TableHead>
      <TableRow>
        <Styled.HeaderCell align="center" sx={{ width: 44 }}>
          <Checkbox
            disableRipple
            isItemSelected={isAllOrdersSelected}
            onChange={onSelectAllClick}
            labelId={`${entity}-table-checkbox-all`}
          />
        </Styled.HeaderCell>
        {headerCells.map(({ id, width }) => (
          <Styled.HeaderCell
            key={id}
            width={width}
            padding="normal"
            align="center"
          >
            <Typography variant="boldText" color={theme.palette.grey.main}>
              {t(`${entity}.table.headerCellsLabels.${id}`)}
            </Typography>
          </Styled.HeaderCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default TableHeader;
