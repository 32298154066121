/* eslint-disable max-len */
import React from "react";
import { Box, Typography} from "@mui/material";

export const DeleteOrderContent = ({ description, actionButtons, isMobileView }) => {
  return (
    <Box 
      width="100%"
      display="flex"
      flexDirection="column" 
      alignItems="center"
      justifyContent="center" 
      gap="24px"
      padding="16px 15px 0px 15px"
      marginBottom="24px">
      <Typography width="100%" variant="mediumText" textAlign={isMobileView? "left":"center"}>
        {description}
      </Typography>
      <Box width="100%" display={"flex"} justifyContent={'center'} gap={"10px"}>
        {actionButtons.map(({ key, content }) => <Box key={key} width={isMobileView? "100%": "156px"}>{content}</Box>)}
      </Box>
    </Box>
  );
};
