import React, { useMemo, useState } from "react";
import { Box } from "@mui/material";

import Styled from "./table.styled";

import TableToolbar from "./table-toolbar";
import TableHeader from "./table-header";
import TableBodyContent from "./table-body";

const Table = ({
  page,
  t,
  handleNavigation,
  totalOrdersCount,
  rows,
  rowsPerPage,
  actionBarProps,
  scrollableRef,
  selectedOrders,
  toggleSelectAll,
  toggleOrder,
  theme,
  headerCells,
  isOrdersLoading,
  resolvedOrders,
  setResolvedOrders,
  entity,
  areaOptions,
  getCellsContent,
  getActionBarComponent
}) => {
  const [isAllOrdersSelected, setIsAllOrdersSelected] = useState(false);
  useMemo(() => {
    const allOrdersSelected =
      selectedOrders?.length && selectedOrders?.length === rows?.length;
    setIsAllOrdersSelected(allOrdersSelected);
  }, [selectedOrders, rows]);

  return (
    <Box width="100%" overflow="auto" height="100%">
      <Box margin="0 40px" height="100%">
        <TableToolbar
          actionBarProps={actionBarProps}
          rowsPerPage={rowsPerPage}
          page={page}
          t={t}
          theme={theme}
          handleNavigation={handleNavigation}
          totalOrdersCount={totalOrdersCount}
          entity={entity}
          getActionBarComponent={getActionBarComponent}
        />
        <Styled.TableContainer>
          <Styled.Table
            aria-labelledby="tableTitle"
            size="medium"
            ref={scrollableRef}
          >
            <TableHeader
              t={t}
              onSelectAllClick={toggleSelectAll}
              isAllOrdersSelected={isAllOrdersSelected}
              theme={theme}
              headerCells={headerCells}
              entity={entity}
            />
            <TableBodyContent
              isOrdersLoading={isOrdersLoading}
              rows={rows}
              selectedOrders={selectedOrders}
              toggleOrder={toggleOrder}
              theme={theme}
              t={t}
              resolvedOrders={resolvedOrders}
              setResolvedOrders={setResolvedOrders}
              entity={entity}
              areaOptions={areaOptions}
              rowsPerPage={rowsPerPage}
              columnsCount={headerCells.length}
              getCellsContent={getCellsContent}
            />
          </Styled.Table>
        </Styled.TableContainer>
      </Box>
    </Box>
  );
};

export default Table;
