import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";

const ProgressBar = styled(Box, {
  shouldForwardProp: (prop) => prop !== "theme" && prop !== "isSelected",
})(({ isSelected }) => ({
  flex: "1",
  marginRight: "10px",
  border: isSelected ? "1px solid black" : "2px solid transparent",
  borderRadius: "10px",
  cursor: "pointer",
}));


const Styled = {
  ProgressBar,
};

export default Styled;
