import { Checkbox as MuiCheckbox } from "@mui/material";
import { styled } from "@mui/system";

const Checkbox = styled(MuiCheckbox, {
  shouldForwardProp: (prop) => prop !== "marginTop" && prop !== "noPadding",
})(({ theme: { palette }, marginTop, noPadding }) => ({
  color: palette.text.light,
  ...(marginTop ? { marginTop } : {}),
  ...(noPadding ? { padding: 0 } : {}),
}));

const Styled = {
  Checkbox,
};

export default Styled;
