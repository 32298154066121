import { Box, IconButton, Chip as MuiChip, Typography, styled } from "@mui/material";
import { DateCalendar, PickersDay } from "@mui/x-date-pickers";

const Chip = styled(MuiChip, { shouldForwardProp: prop => prop !== 'isSelected' })(({ theme: { customShadows, palette }, isSelected }) => ({
  height: '32px',
  padding: '8px 12px 8px 12px',
  borderColor: palette[isSelected? 'black': 'lightGreyOutline'],
  boxShadow: customShadows.component,
  borderRadius: '8px',
  gap: 4,
  cursor: 'pointer',
  ".MuiChip-label" : {
    padding: 0
  }
}))

const ChipLabel = styled(Typography, { shouldForwardProp: prop => prop !== 'isSelected' })(({ theme: { palette }, isSelected }) => ({
  display: 'flex',
  alignItems: 'baseline',
  color: (isSelected? palette.black: palette.gray)
}))

const FiltersWrapper = styled('div')({
  display: 'flex',
  flexDirection: "column",
  justifyContent: 'space-between',
  height: '100%',
  padding: '20px'
})

const Calendar = styled(DateCalendar)(({ theme: { palette, customShadows, typography } }) => ({
  boxShadow: customShadows.dropDown,
  borderRadius: '8px',
  paddingTop: '16px',
  width: '316px',
  backgroundColor: palette.white,
  marginTop: '8px',
  ".css-rhmlg1-MuiTypography-root-MuiDayCalendar-weekDayLabel": {
    color: palette.text.primary,
    ...typography.mediumText
  },
  ".css-7iqc0a-MuiButtonBase-root-MuiPickersDay-root:not(.Mui-selected)": {
    border: 'none'
  }
}))

const CalendarDay = styled(PickersDay, {
  shouldForwardProp: (prop) => prop !== 'isStartOrEndDate' && prop !== 'isInDuration' && prop !== 'isDefaultDate',
})(({ theme, isStartOrEndDate, isInDuration, day, isDefaultDate }) => ({
  borderRadius: 0,
  ...theme.typography.mediumText,
  ...(isInDuration && {
    backgroundColor: theme.palette.lightBlue,
    '&:hover': {
      backgroundColor: theme.palette.primary[theme.palette.mode],
    },
    '&:focus' : {
      backgroundColor: theme.palette.lightBlue
    }
  }),
  ...(isStartOrEndDate && {
    ...theme.typography.boldSubtitles,
    backgroundColor: theme.palette.blue,
    width: 36,
    height: 36,
    color: theme.palette.primary.contrastText,
    borderRadius: '50%',
    '&:hover': {
      backgroundColor: theme.palette.blue,
    },
    '&:focus' : {
      backgroundColor: theme.palette.blue,
    }
  }),
  ...(day.day() === 0 && {
    borderTopLeftRadius: '50%',
    borderBottomLeftRadius: '50%',
  }),
  ...(day.day() === 6 && {
    borderTopRightRadius: '50%',
    borderBottomRightRadius: '50%',
  }),
  ...(isDefaultDate && {
    ".css-oy93b2-MuiButtonBase-root-MuiPickersDay-root:not(.Mui-selected)":{
      border: 'none',
    },
    ...theme.typography.boldSubtitles
  })
}));

const CalendarDayWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isStartDate' && prop !== 'isEndDate',
})(({ isStartDate, isEndDate, theme }) => ({
  ...(isStartDate || isEndDate) && {
    backgroundColor: theme.palette.lightBlue
  },
  ...(isStartDate) && {
    borderTopLeftRadius: '50%',
    borderBottomLeftRadius: '50%',
  },
  ...(isEndDate) && {
    borderTopRightRadius: '50%',
    borderBottomRightRadius: '50%',
  },
}))

const CalendarButton = styled(IconButton)(({ theme: { palette } }) => ({
  padding: 0,
  "&.MuiIconButton-root": {
    '&:hover': {
      backgroundColor: 'transparent'
    },
    ">svg": {
      ">path": {
        stroke: `${palette.gray}`,
      },
    },
    ">svg:hover": {
      ">path": {
        stroke: `${palette.black}`
      }
    },
  }
}))

const Styled = { Chip, ChipLabel, CalendarDay, CalendarDayWrapper, Calendar, CalendarButton, FiltersWrapper }

export default Styled