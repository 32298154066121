import React from 'react';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import StyledLoader from './loader.styled';

const Loader = ({ isLoading }) => (
  isLoading && (
    <StyledLoader>
      <Box sx={{ display: "flex" }}>
        <CircularProgress color="secondary" />
      </Box>
    </StyledLoader>
  )
);

export default Loader;
