import { styled } from '@mui/material/styles';
import { Chip, Button, IconButton, Link } from '@mui/material';


const OrderTypeButton = styled(Chip, { shouldForwardProp: prop => prop !== 'isSelected' })(({ theme: { customShadows, palette }, isSelected }) => ({
  height: '100px',
  width: '100%',
  borderColor: palette[isSelected? 'black': 'lightGreyOutline'],
  boxShadow: customShadows.component,
  borderRadius: '12px',
  gap: 4,
  cursor: 'pointer',
  backgroundColor: palette.white,
  ":hover": {
    backgroundColor: palette.lightgrey,
  },
  ".MuiChip-label" : {
    padding: 0
  }
}))

const UploadPanel = styled(Button)(({ theme: { palette } }) => ({
  height: '100%',
  width: '100%',
  backgroundColor: palette.white,
  borderRadius: '12px',
  border: `1px dashed ${palette.lightGreyOutline}`,
  ":disabled": { color: palette.disabledGrey, border: `1px dashed ${palette.greyBorder}` },
  "&:hover": {
    backgroundColor: palette.background.light,
  },
  color: palette.gray,
  borderColor: palette.greyBorder,
  textTransform: 'none',
}))

const SummaryWrapper = styled('div')(({ theme: { palette, typography, customShadows } }) => ({
  backgroundColor: palette.white,
  display: 'flex',
  padding: '8px 20px',
  color: palette.gray,
  borderRadius: '24px',
  gap: '12px',
  width: 'fit-content',
  boxShadow: customShadows.component,
  borderColor: palette.lightgrey,
  ...typography.boldSmall
}));

const AddPickupLocationButton = styled(Button)(({ theme: { palette } }) => ({
  padding: 0,
  color: palette.black,
  textTransform: 'none',
  '&:hover': {
    backgroundColor: 'transparent',
  }
}))

const BackButton = styled(IconButton)(({ theme: { palette } }) => ({
  padding: 0,
  '&:hover': {
    backgroundColor: 'transparent',
    'svg': {
      'path': {
        stroke: palette.black
      }
    }
  }
}))

const ExportButton = styled(Button)(({ theme: { palette, typography } }) => ({
  color: palette.gray,
  textDecorationColor: palette.gray,
  cursor: 'pointer',
  textTransform: 'none',
  padding: 0,
  ":disabled": {
    opacity: 0.4,
    color: palette.gray,
  },
  ":hover": {
    backgroundColor: 'transparent',
    textDecoration: 'underline',
    color: `${palette.black}`,
  },
  ...(typography.semiBoldSmallUnderline)
}))

const SampleFile = styled(Link)(({ theme: { palette, typography } }) => ({
  color: palette.gray,
  textDecorationColor: palette.gray,
  cursor: 'pointer',
  ":hover": {
    color: `${palette.black}`,
  },
  ...(typography.semiBoldSmallUnderline)
}))

const Styled = { OrderTypeButton, UploadPanel, SummaryWrapper, BackButton, SampleFile, ExportButton, AddPickupLocationButton }

export default Styled