/* eslint-disable max-len */
import React from 'react'

const CantOpenIcon = () => (
  <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3.49902 4.10001C3.49902 3.54772 3.94674 3.10001 4.49902 3.10001L12.4954 3.10001C13.0477 3.10001 13.4954 3.54772 13.4954 4.10001L13.4954 11.9C13.4954 12.4523 13.0477 12.9 12.4954 12.9L4.49902 12.9C3.94674 12.9 3.49902 12.4523 3.49902 11.9L3.49902 4.10001Z" stroke="#2A272B"/>
    <path d="M9.65039 11.0153H11.5728" stroke="#2A272B" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M4.24077 4.55435C3.90143 3.88901 4.38472 3.10001 5.13159 3.10001L11.8628 3.10001C12.6097 3.10001 13.093 3.88901 12.7537 4.55435L12.2358 5.56973C12.065 5.90459 11.7209 6.11539 11.345 6.11539L5.64946 6.11539C5.27356 6.11539 4.92942 5.90459 4.75863 5.56973L4.24077 4.55435Z" stroke="#2A272B"/>
    <path d="M3.68004 3.36418L1.96631 6.11537" stroke="#2A272B" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M15.0364 6.11542L13.312 3.36428" stroke="#2A272B" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M15.0474 1.94748L2.44736 14.5475" stroke="#2A272B" strokeLinecap="round"/>
  </svg>
)

export default CantOpenIcon