import toast from "react-hot-toast";

import { HEADERS, INIT_ROW, VALIDATED_STATUS, openPackageOptions } from "./bulk-create-orders.constants";
import { createOrder, validateOrder } from "./bulk-create-order.slice";
import toEnglishNumber from "../../utils/toEnglishNumber";
import { ARABIC_REGEX } from '../../constants'
import InputField from "./components/input-field";
import DropDownField from "./components/drop-down-field";

const isNotEmpty = (content) => ( typeof content === 'object' && content.value !== "")
const isRowUpdated = (row) => Object.entries(row).some(([, content]) => isNotEmpty(content))

const mapDataToBEFormat = (row, locationId) => ({
  customerDetails: {
    address: {
      addressLine: row.addressLine.value,
      area: row.area.value?.value || '',
      city: row.area.value?.group  || row.area.value?.value || '', //for cities only and custom we don't have group so we send the value
    },
    name: row.name.value,
    phone: row.phone.value,
    backupPhone: row.backupPhone.value,
    referenceNumber: row.referenceNumber.value,
    shippingNotes: row.shippingNotes.value
  },
  orderDetails: {
    amount: row.amount.value,
    description: row.description.value,
    locationId,
    noOfItems: row.noOfItems.value,
    openPackageAllowed: row.openPackageAllowed.value,
    packageType: row.packageType.value,
  },
  instaShip: true
})

const getErroredOrder = ({ errors, order}) => {
  let updatedOrder = order
  errors.forEach(({ key, value }) => {
    const cellKey = key === 'city'? 'area': key

    updatedOrder={
      ...updatedOrder,
      isError: true,
      [cellKey]: { ...updatedOrder[cellKey], ...value }
    }
  })

  return updatedOrder
}

const getSpecialMerchantErroredOrder = ({ data, order, errors }) => {
  const { area, city, confidence } = data
  let newOrder = order;
  if(errors?.length > 0) {
    newOrder = getErroredOrder({ errors, order })
  }

  if(confidence === 'confident') {
    return {
      ...newOrder,
      isError: false,
      area: { value: { label: area, value: area, group: city }, isSpecialValidated: true }
    }
  }

  return  {
    ...newOrder,
    isError: true,
    area: { value: { label: area || city, value: area || city }, failedValue: '', failureReason: 'Couldn\'t validate city' }
  }
}

const getPhoneNumber = (number) => {
  let englishPhoneNumber = toEnglishNumber(number)

  if (englishPhoneNumber && englishPhoneNumber[0] !== '0') {
    englishPhoneNumber = `0${englishPhoneNumber}`
  }

  return englishPhoneNumber
}

const getOpenPackageAllowedValue = (value) => {
  if(value === '' || value === null) return false
  if(value !== false) return true

  return value
}

const getAdaptedData = (data) => data.map(row => {
  if(isRowUpdated(row)) {
    return {
      ...row,
      openPackageAllowed: { ...row.openPackageAllowed, value: getOpenPackageAllowedValue(row.openPackageAllowed.value)},
      packageType:  { ...row.packageType, value: row.packageType.value || 'parcel' },
      noOfItems: { ...row.noOfItems, value: toEnglishNumber(row.noOfItems.value)|| '1' },
      amount: { ...row.amount, value: toEnglishNumber(row.amount.value) || '' },
      phone: { ...row.phone, value: getPhoneNumber(row.phone.value) || ''},
      backupPhone: { ...row.backupPhone, value: getPhoneNumber(row.backupPhone.value) || ''},
    }
  }
  return row
})

const getDropdownData = (responses) => {
  let options = { openPackageAllowed: openPackageOptions }
  let allAreas = []

  responses.forEach(({ payload }) =>
  {
    options = {...options, [payload?.id]: payload?.data }
    if(payload?.type === 'allAreas') {
      allAreas = payload?.data
    }
  })

  return { allAreas, options }
}

const getSelectFieldCsvMappedData = ({ isSpecialMerchant, key, dropDownOptions, allAreas, row, optionsId }) => {
  const paste = getSelectPastedData({
    columnData: { key, isSpecialMerchant, options: dropDownOptions?.[optionsId], allAreas },
    value: row[HEADERS[key]]
  })

  return paste
}

const mapCsvFileToFEFormat = ({ data, isSpecialMerchant, dropDownOptions, allAreas }) => (
  data.map(row => {
    return {
      name: { value: row[HEADERS.name] || '' },
      addressLine: { value: row[HEADERS.addressLine] || '' },
      referenceNumber: { value: row[HEADERS.referenceNumber] || '' },
      description: { value: row[HEADERS.description] || '' },
      shippingNotes: { value: row[HEADERS.shippingNotes] || '' },
      amount: { value: toEnglishNumber(row[HEADERS.amount]) || ''},
      noOfItems: { value: toEnglishNumber(row[HEADERS.noOfItems]) },
      phone: { value: getPhoneNumber(row[HEADERS.phone] || '')},
      backupPhone: { value: getPhoneNumber(row[HEADERS.backupPhone] || '') },
      area: getSelectFieldCsvMappedData({ isSpecialMerchant, key: 'area', dropDownOptions, allAreas, row, optionsId: 'areas' }),
      openPackageAllowed: getSelectFieldCsvMappedData({ isSpecialMerchant, key: 'openPackageAllowed', dropDownOptions, allAreas, row, optionsId: 'openPackageAllowed' }),
      packageType: getSelectFieldCsvMappedData({ isSpecialMerchant, key: 'packageType', dropDownOptions, allAreas, row, optionsId: 'packageTypes' }),
    };
  })
)

const applyRowStyles = ({ 
  row, bgColor = '0064FA', fontColor = 'FFFFFFFF', fontSize = 11, bold, border
}) => {
  row.height = 45;
  row.eachCell(cell => {
    cell.alignment = {
      vertical: 'middle',
      wrapText: true,
      horizontal: 'center' // Center horizontally
    };
    cell.font = {
      size: fontSize,
      color: { argb: fontColor },
      name: 'Helvetica Neue',
      ...(bold? {bold: true}: null)
    };
    cell.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: bgColor }
    };
    if(border) {
      cell.border = {
        top: { style: 'thin', color: { argb: 'FFD9D9D9' } },    // Top border
        left: { style: 'thin', color: { argb: 'FFD9D9D9' } },   // Left border
        bottom: { style: 'thin', color: { argb: 'FFD9D9D9' } }, // Bottom border
        right: { style: 'thin', color: { argb: 'FFD9D9D9' } }   // Right border
      };
    }
  });
};

const applyStyleToErrorCell = (row) => {
  if(row._number === 1 || row._number === 2) return //escape first two

  const errorCell = row.getCell(1); // Get the first cell of the row
  if (errorCell.value) { // Check if the cell has a value
    errorCell.font = {
      color: { argb: 'ffd42a5d' },
    };
    errorCell.alignment = {
      vertical: 'middle',
      wrapText: true,
      horizontal: 'center' // Center horizontally
    };
    errorCell.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FFFFFFFF' }
    };
    errorCell.border = {
      top: { style: 'thin', color: { argb: 'FFD9D9D9' } },    // Top border
      left: { style: 'thin', color: { argb: 'FFD9D9D9' } },   // Left border
      bottom: { style: 'thin', color: { argb: 'FFD9D9D9' } }, // Bottom border
      right: { style: 'thin', color: { argb: 'FFD9D9D9' } }   // Right border
    };
  }}

//map data to download it
const mapDataToCsvFormat = (data, options) => (
  data.map(row => {
    if(isRowUpdated(row)) {
      const rowErrors = Object.values(row).map((value) => value.failureReason)?.filter(Boolean) || [];
      return {
        errors: rowErrors.join(', '),
        name: row.name?.value,
        phone: row.phone?.value,
        addressLine: row.addressLine?.value,
        area: row.area?.value?.group ? `${row.area.value.group} ${row.area.value.label}` : row.area.value.label,
        amount: row.amount?.value,
        description: row.description?.value,
        openPackageAllowed: (options.openPackageAllowed || []).find((option) => option.value === row.openPackageAllowed?.value)?.label,
        packageType: (options.packageTypes || []).find((option) => option.value === row.packageType?.value)?.label,
        noOfItems: row.noOfItems?.value,
        shippingNotes: row.shippingNotes?.value,
        backupPhone: row.backupPhone?.value,
        referenceNumber: row.referenceNumber?.value,
      }
    }
    return {}
  })
)

const verifyUploadedCsvHeaders = (headers) => {
  const set = new Set(headers);
  return Object.values(HEADERS).every(element => set.has(element));
};

const onPasteAreaField = ({ columnData, rowData = {}, searchedValue, nameKey, isArabic }) => {
  if(columnData.isSpecialMerchant && !rowData.isSpecialValidated && !columnData?.isUploadView) {
    const city = columnData.options?.find(((city) => searchedValue?.includes(city[nameKey]?.trim()?.trim()?.toLowerCase())))?.[nameKey] || ''
    return ({ value: { label: city, value: city }, isSpecialValidated: rowData.isSpecialValidated })
  }

  const areasKey = isArabic? 'arabicAreas': 'englishAreas'
  let area = ''

  const option = columnData.allAreas?.find((option) => {
    if(searchedValue.includes(option?.[nameKey]?.trim()?.toLowerCase())) {
      area = option?.[areasKey]?.find((area) => searchedValue?.includes(area?.trim()?.toLowerCase()))
      return Boolean(area)
    }
  });

  return ({ value: { label: area, value: area, group: option?.[nameKey] }, isSpecialValidated: rowData.isSpecialValidated })
}

const getSelectCopiedData = ({ columnData, rowData }) => {
  if(columnData.key === 'area') {
    const { value = {} } = rowData;
    const { group, label } = value || {}

    if(group) return `${group} ${label}`

    return label
  }

  return (columnData.options || []).find((choice) => choice.value === rowData.value)?.label ?? null
};

const getSelectPastedData =  ({ value: pastedValue, rowData, columnData }) => {
  const searchedValue = pastedValue?.toString()?.trim()?.toLowerCase();
  const isArabic = ARABIC_REGEX.test(pastedValue);
  const nameKey = isArabic? 'name_ar': 'name'

  if(columnData.key === 'area') return onPasteAreaField({ columnData, rowData, searchedValue, nameKey, isArabic })

  const updatedValue = (columnData.options || []).find((choice) => choice?.label?.trim()?.toLowerCase()?.includes(searchedValue))?.value ?? null

  return ({ value: updatedValue })
};

async function sendBatchRequests(requests, batchSize = 10) {
  for (let i = 0; i < requests.length; i += batchSize) {
    const batch = requests.slice(i, i + batchSize);
    await Promise.all(batch.map(req => req())); // Assuming req is a function that returns a promise
  }
}

const validateOrders = async ({ dispatch, orders, currentPickupLocationId, isSpecialMerchant, abortController, setCreatedOrdersCount,  isCreationSkipped }) => {
  let validatedOrders = Array(orders.length).fill({})
  let erroredRowsIndices = []
  let areaValidationStatus = VALIDATED_STATUS.NOT_YET
  const validateAbortController = new AbortController()
  abortController.current = validateAbortController
  const signal = abortController.current.signal;

  const ordersPromisees = orders.map((order, index) => async () => {
    if(!isRowUpdated(order)) { //skip empty rows
      validatedOrders[index] = order
      return Promise.resolve();
    }

    return dispatch(validateOrder({ data: mapDataToBEFormat(order, currentPickupLocationId), index, signal })).then((response) => {
      if(isCreationSkipped) { setCreatedOrdersCount((prevCount) => prevCount+=1) } //update count here in case we don't go to create step

      const responseBody = response.payload?.data?.body
      const { errors, confidence, valid, ...restData } = responseBody || {}

      const shouldSpecialValidateCity = isSpecialMerchant && confidence && !order?.area?.isSpecialValidated

      if (shouldSpecialValidateCity) {
        validatedOrders[index] = getSpecialMerchantErroredOrder({ data: { ...restData, confidence }, order, errors })
        //if not some order already failed
        if(areaValidationStatus !== VALIDATED_STATUS.SOME_FAILED) {
          areaValidationStatus = VALIDATED_STATUS.ALL_VALIDATED
        }

        if(valid === false || confidence !== 'confident') {
          erroredRowsIndices = [...erroredRowsIndices, index]
          areaValidationStatus = VALIDATED_STATUS.SOME_FAILED
        }

      } else if(errors?.length > 0) {
        erroredRowsIndices = [...erroredRowsIndices, index]
        validatedOrders[index] = getErroredOrder({ errors, order })
        areaValidationStatus = VALIDATED_STATUS.SOME_FAILED
      } else {
        validatedOrders[index] =  {...order, isError: false, area: { ...order.area, isSpecialValidated: order.area.isSpecialValidated }}
        //if not some order first time validated or failed
        if(![VALIDATED_STATUS.SOME_FAILED, VALIDATED_STATUS.ALL_VALIDATED].includes(areaValidationStatus)) {
          areaValidationStatus = VALIDATED_STATUS.ALREADY_VALIDATED
        }
      }
      setCreatedOrdersCount(erroredRowsIndices.length) //hacky way to update the errored rows length
    })})

  await sendBatchRequests(ordersPromisees, 10)

  return { validatedOrders, erroredRowsIndices, areaValidationStatus, validateAbortController }
}


const createBulkOrders = async ({ dispatch, orders, onSuccess, onError, abortController, currentPickupLocationId, setCreatedOrdersCount }) => {
  let failedToVerifyOrdersCount = 0
  let updatedOrders = []
  let failedOrders = []
  let createdOrdersCount = 0
  let failedToUploadOrdersCount = 0
  const updateAbortController = new AbortController();
  abortController.current = updateAbortController;
  const signal = abortController.current.signal

  for await (const order of orders) {
    if(!isRowUpdated(order) || order.isError) { //skip empty rows
      if(order.isError) {
        failedOrders = [...failedOrders, {...order, isError: true}]
        failedToVerifyOrdersCount+=1
      } else {
        updatedOrders = [...updatedOrders, order]
      }
      await Promise.resolve();
    } else {
      const response = await dispatch(createOrder({ data: mapDataToBEFormat(order, currentPickupLocationId), signal }));
      setCreatedOrdersCount((prevCount) => prevCount+=1)
      if(response?.payload?.data?.status === 'success') {
        updatedOrders = [...updatedOrders, INIT_ROW]
        createdOrdersCount+=1
      } else {
        failedToUploadOrdersCount+=1
        failedOrders = [...failedOrders, {...order, isError: true}]
      }
    }
  }

  const totalFailedOrdersCount = failedToVerifyOrdersCount + failedToUploadOrdersCount;

  updatedOrders = [...failedOrders, ...updatedOrders]
  const isAborted = abortController.current?.signal?.aborted
  if(totalFailedOrdersCount > 0) {
    if(!isAborted){
      if(failedToVerifyOrdersCount > 0) toast.error(`Failed to validate ${failedToVerifyOrdersCount} orders. Please fix errors and try Again.`)
      if(failedToUploadOrdersCount > 0) toast.error(`Failed to upload ${failedToUploadOrdersCount} orders. Please check and try Again.`)
    }
    onError(updatedOrders, totalFailedOrdersCount, createdOrdersCount, isAborted);
  } else {
    if(!isAborted) toast('All orders uploaded successfully')
    onSuccess(updatedOrders, isAborted);
  }
}


const selectColumn = (columnData) => ({
  component: DropDownField,
  columnData,
  disableKeys: true,
  keepFocus: true,
  deleteValue: ({ rowData }) => ({ value: '', isSpecialValidated: rowData?.isSpecialValidated || columnData?.isUploadView }),
  copyValue: ({ rowData }) => getSelectCopiedData({ columnData, rowData }),
  pasteValue: ({ value, rowData }) => getSelectPastedData({ value, rowData, columnData })
})

const customColumn = (columnData) => ({
  component: InputField,
  columnData,
  deleteValue: () => ({ value: '' }),
  copyValue: ({ rowData }) => rowData.value,
  pasteValue: ({ value }) => {
    const pastedValue = columnData.subType === 'number' ? toEnglishNumber(value) : value

    return ({ value: pastedValue, isError: false })}
})
export { 
  getDropdownData,
  verifyUploadedCsvHeaders, 
  isRowUpdated,
  createBulkOrders,
  applyRowStyles,
  applyStyleToErrorCell,
  selectColumn,
  customColumn,
  getAdaptedData, 
  validateOrders, 
  mapCsvFileToFEFormat, 
  mapDataToCsvFormat
}
