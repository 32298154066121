/* eslint-disable max-len */
import React, { useMemo } from "react";
import { Trans } from "react-i18next";
import { Box, Typography } from "@mui/material";
import IconButton from "../../../common/components/icon-button";

import { CheckCircleOutlineIcon, DeleteIcon } from "../../../assets/icons";

const getActionBarComponent = ({
  selectedOrders = [],
  handleClearOrder,
  handleDeleteOrder,
  theme,
  t,
}) => {

  const selectedOrdersCount = useMemo(
    () => selectedOrders?.length,
    [selectedOrders]
  );

  return (
    <Box
      display="flex"
      gap="8px"
      flexDirection={"row"}
      justifyContent={"flex-start"}
      width={"fit-content"}
    >
      <IconButton
        icon={<CheckCircleOutlineIcon />}
        title={t("actionCenter.actionBar.clearButtonTitle")}
        isDisabled={!selectedOrdersCount}
        onClick={handleClearOrder}
      />
      {selectedOrdersCount > 0 && (
        <Box display="flex" alignItems="center" gap="4px">
          <IconButton
            icon={<DeleteIcon />}
            title={t("actionCenter.actionBar.deleteButtonTitle")}
            onClick={handleDeleteOrder}
          />
          <Typography
            paddingLeft="12px"
            variant="semiBoldSmall"
            color={theme.palette.text.light}
          >
            <Trans
              i18nKey={'actionCenter.ordersSelectedLabel'}
              values={{ ordersCount: selectedOrdersCount}}
            />
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default getActionBarComponent;
