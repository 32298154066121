/* eslint-disable max-len */
import React from 'react';


const DeliverOrderIcon = () => (
  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16.8 9.79993L21.7515 9.79993C22.6661 9.79993 23.5433 10.1195 24.19 10.6884C24.8367 11.2572 25.2 12.0288 25.2 12.8333V18.8999H16.8" stroke="#2A272B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M2.8 7.26248L2.8 18.9L16.8 18.9L16.8 7.26248C16.8 6.82155 16.6156 6.39869 16.2874 6.08691C15.9593 5.77513 15.5141 5.59998 15.05 5.59998L4.55 5.59998C4.08587 5.59998 3.64075 5.77513 3.31257 6.08691C2.98438 6.39869 2.8 6.82155 2.8 7.26248Z" stroke="#2A272B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M18.9 22.4C17.9717 22.4 17.0815 22.0313 16.4251 21.3749C15.7687 20.7185 15.4 19.8283 15.4 18.9C15.4 17.9718 15.7687 17.0815 16.4251 16.4251C17.0815 15.7688 17.9717 15.4 18.9 15.4C19.8283 15.4 20.7185 15.7688 21.3749 16.4251C22.0312 17.0815 22.4 17.9718 22.4 18.9C22.4 19.8283 22.0312 20.7185 21.3749 21.3749C20.7185 22.0313 19.8283 22.4 18.9 22.4Z" fill="white" stroke="#2A272B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M7.7 22.4C6.77174 22.4 5.8815 22.0313 5.22512 21.3749C4.56875 20.7185 4.2 19.8283 4.2 18.9C4.2 17.9718 4.56875 17.0815 5.22512 16.4251C5.8815 15.7688 6.77174 15.4 7.7 15.4C8.62825 15.4 9.51849 15.7688 10.1749 16.4251C10.8312 17.0815 11.2 17.9718 11.2 18.9C11.2 19.8283 10.8312 20.7185 10.1749 21.3749C9.51849 22.0313 8.62825 22.4 7.7 22.4Z" fill="white" stroke="#2A272B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
)

export default DeliverOrderIcon;