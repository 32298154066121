/* eslint-disable max-len */
import React from "react"

const PrintLabelIcon = ({ width="20", height="20", color="#2A272B" }) => (
  <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.625 6.5V13.4999" stroke={color} strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M7.375 6.5V13.4999" stroke={color} strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M9.125 6.5V13.4999" stroke={color} strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M11.75 6.5V13.4999" stroke={color} strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M14.375 6.5V13.4999" stroke={color} strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M6.44615 3H3.53846C3.39565 3 3.25869 3.05673 3.15771 3.15771C3.05673 3.25869 3 3.39565 3 3.53846V6.44612" stroke={color} strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M17.0001 6.44612V3.53846C17.0001 3.39565 16.9434 3.25869 16.8424 3.15771C16.7414 3.05673 16.6045 3 16.4616 3H13.554" stroke={color} strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M13.554 17.0001H16.4616C16.6045 17.0001 16.7414 16.9433 16.8424 16.8424C16.9434 16.7414 17.0001 16.6044 17.0001 16.4616V13.554" stroke={color} strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M3 13.554V16.4616C3 16.6044 3.05673 16.7414 3.15771 16.8424C3.25869 16.9433 3.39565 17.0001 3.53846 17.0001H6.44615" stroke={color} strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
)

export default PrintLabelIcon