/* eslint-disable react/no-array-index-key */
import React from "react";
import { Skeleton } from "@mui/material";

import Styled from "./unsuccessful-reason.styled";

const RejectionReasonSkeleton = () =>
  Array(5)
    .fill()
    .map((_, index) => (
      <Styled.RejectionReasonWrapper key={index} isLastRow={index === 4}>
        <Skeleton width={"100%"} height={"35px"} />
      </Styled.RejectionReasonWrapper>
    ));

export default RejectionReasonSkeleton;
