const BREACH_STATUS_SEVERITY_IDS = {
  NA: "na",
  GOOD: "good",
  MEDIUM: "medium",
  BAD: "bad",
};

const BREACH_STATUS_SEVERITY_COLORS = {
  [BREACH_STATUS_SEVERITY_IDS.NA]: "grey",
  [BREACH_STATUS_SEVERITY_IDS.GOOD]: "limeGreen",
  [BREACH_STATUS_SEVERITY_IDS.BAD]: "red",
  [BREACH_STATUS_SEVERITY_IDS.MEDIUM]: "orange",
};

export { BREACH_STATUS_SEVERITY_COLORS, BREACH_STATUS_SEVERITY_IDS };
