import React, { useLayoutEffect, useRef } from "react"
import ErrorPlaceholder from "./error-placeholder"
import { AreaSelect } from "../../../common/components/form-field/area-select"
import CustomSelectField from "./custom-select-field"

const DropDownField = (
  ({
    rowData,
    setRowData,
    focus,
    stopEditing,
    columnData,
  }) => {
    const ref = useRef()
  
    const { key, options, theme, allAreas, isSpecialMerchant, isUploadView } = columnData
    const { value, failureReason, failedValue, isSpecialValidated } = rowData
  
    const isAllAreas = isSpecialValidated || isUploadView
  
    useLayoutEffect(() => {
      if (focus) {
        ref.current?.focus()
      } else {
        ref.current?.blur()
      }
    }, [focus])
  
    if(failureReason && !focus) {
      return <ErrorPlaceholder ref={ref} failureReason={failureReason} value={failedValue || value.label } isForDropdown />
    }
  
    if(key === 'area') return(
      <AreaSelect
        noBorders
        height='100%'
        hideRefreshIcon
        value={value}
        hasGreyIcon
        options={isAllAreas ? allAreas : options}
        noPlaceholder
        isOrderValidated={isAllAreas}
        isSpecialMerchant={isSpecialMerchant}
        setValue={(_, updatedValue) => {setRowData({ value: updatedValue, isSpecialValidated: isAllAreas }); setTimeout(stopEditing, 0);}}
        props={{ ref: ref, menuPortalTarget: document.body, menuIsOpen: focus, onMenuClose: () => stopEditing({ nextRow: false }) }}
      />
    )
  
    return (
      <CustomSelectField
        activeRef={ref}
        theme={theme}
        focus={focus}
        options={options}
        rowData={rowData}
        setRowData={setRowData}
        columnData={columnData}
        stopEditing={stopEditing}
      />
    )
  }
)

export default DropDownField