import { STATUS_IDS, statusTabsInfo } from "../orders-table.constants"

const FILTERS_IDS = {
  STATUS: 'orderStatus',
  DATE: 'dateRange',
  CITY: 'city',
  PRINT_LABEL: 'printLabel',
  COURIERS: 'courierIds',
  PICKUP_LOCATION: 'pickupLocation'
}

const FILTERS = [
  {id: FILTERS_IDS.STATUS },
  {id: FILTERS_IDS.DATE },
  {id: FILTERS_IDS.CITY },
  // {id: FILTERS_IDS.PRINT_LABEL }, //no logic implemented yet
  {id: FILTERS_IDS.PICKUP_LOCATION },
  {id: FILTERS_IDS.COURIERS}
]

const STATUS_SUB_FILTERS_IDS = {
  TYPE: 'type',
  STATUS: 'status',
  SUB_STATUS: 'subStatus'
}


//We use labels here in BE mapping
const SUB_STATUS_FILTERS_OPTIONS = [
  { key: 'new', statusKey: STATUS_IDS.PENDING, label: 'New' },
  { key: 'pickedUp', statusKey: STATUS_IDS.PROCESSING, label: 'Picked Up' },
  { key: 'atCourierHub', statusKey: STATUS_IDS.PROCESSING, label: 'At Courier Hub'},
  { key: 'outForDelivery', statusKey: STATUS_IDS.PROCESSING, label: 'Out for Delivery'},
  { key: 'failedAttempt', statusKey: STATUS_IDS.PROCESSING, label: 'Failed Attempt' },
  { key: 'deliveredToCustomer', statusKey: STATUS_IDS.SUCCESSFUL, label: 'Delivered to Customer' },
  { key: 'failedAndReturnedToMerchant', statusKey: STATUS_IDS.UNSUCCESSFUL, label: 'Delivery Failed & Returned to Merchant' },
  { key: 'problematic', statusKey: STATUS_IDS.UNSUCCESSFUL, label: 'Problematic'},
]

const STATUS_SUB_FILTERS = [
  {id: STATUS_SUB_FILTERS_IDS.TYPE, options: [{ key: 'delivery' }, { key: 'return' }, { key: 'exchange' }]},
  {id: STATUS_SUB_FILTERS_IDS.STATUS, options: statusTabsInfo, withIcons: true  },
  { id: STATUS_SUB_FILTERS_IDS.SUB_STATUS, withIcons: true, options: SUB_STATUS_FILTERS_OPTIONS },
]

const DATE_RANGES = [
  {id: 'createdOn' },
  {id: 'processedOn' },
  {id: 'closedOn' }
]

const PRINT_LABELS = [
  {id: 'notPrinted' },
  {id: 'singlePrint' },
  {id: 'multiplePrints' }
]

const MONTHS = [
  "January", "February", "March", "April", "May", "June", "July",
  "August", "September", "October", "November", "December"
]

const MONTHS_SHORT = [
  "Jan.", "Feb.", "Mar.", "Apr.", "May.", "Jun.",
  "Jul.", "Aug.", "Sep.", "Oct.", "Nov.", "Dec."
]

export { FILTERS, FILTERS_IDS, STATUS_SUB_FILTERS, STATUS_SUB_FILTERS_IDS, DATE_RANGES, MONTHS, MONTHS_SHORT, PRINT_LABELS, SUB_STATUS_FILTERS_OPTIONS }