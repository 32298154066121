import React from "react";

import Styled from "./button.styled";
import { Typography } from "@mui/material";

//color: primary/secondary -> primary: blueBtn, secondary: whiteBtn
const Button = (
  { isDisabled = false, color='primary', typography='boldSubtitles', onHover = () => {}, loadingLabel = '',
    variant = 'contained', size = {width : '100%', height : 36}, onClick, label, isLoading,
    ...props  }) => (
  <Styled.Button
    disabled={isDisabled || isLoading}
    disableRipple
    variant={variant}
    $color={color}
    $size={size}
    onClick={onClick}
    disableElevation
    onMouseEnter={onHover}
    {...props}
  >
    {isLoading? loadingLabel || <Styled.CircularProgress size={20} $color={color} /> :
      <Typography variant={typography}>
        {label}
      </Typography>
    }
  </Styled.Button>
)

export default Button