import { Box, InputAdornment, Typography } from "@mui/material"
import React, { useState } from "react"

import Styled from "../form-field.styled"

const MultilineInput = ({ name, value, setValue, PrefixIcon, error, isDisabled, theme, placeholder}) => {

  const [currentValue, setCurrentValue] = useState(value)

  const handleInput = (e) => {
    if(currentValue?.length >= 150 && e.nativeEvent.inputType === 'insertText') return
    setCurrentValue(e.target.value)
    setValue(name, e.target.value)
  }

  return (
    <Styled.TextAreaWrapper error={error}>
      <>
        <Box display="flex" justifyContent="center" alignItems="start" padding="8px 0 0 12px">
          <PrefixIcon color={isDisabled ? theme.palette.gray : theme.palette.black} />
        </Box>
        <Styled.MultiLineTextField
          value={currentValue}
          sx={{ width: '100%', }}
          disabled={isDisabled}
          autoFocus={error}
          type='text'
          id={name}
          onChange={(e) => setValue(name, e.target.value)}
          placeholder={placeholder}
          multiline
          onInput={handleInput}
          name={name}
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                <Typography  variant="semiBoldSmall" color={theme.palette.text.light}>{currentValue?.length || 0}/150</Typography>
              </InputAdornment>
            ),
          }}
        />
      </>
    </Styled.TextAreaWrapper >
  )
}

export default MultilineInput