import { useEffect, useState } from "react"

const useAdaptAreas = ({ mapOptionsCbk, isOrderValidated, options, value }) => {
  const ARABIC_REGEX = /[\u0600-\u06FF]/;
  const initLang = ARABIC_REGEX.test(value)? 'ar' : 'en';

  const [selectOptions, setSelectOptions] = useState(mapOptionsCbk(options, initLang) || [])
  const [inputLanguage, setInputLanguage] = useState(initLang)

  useEffect(() => {
    setSelectOptions(mapOptionsCbk(options, initLang))
    setInputLanguage(initLang)
  }, [options, isOrderValidated, initLang])

  const onInput = (e) => {
    if(!e) return
    if(ARABIC_REGEX.test(e)){
      if(inputLanguage === 'ar') return

      setSelectOptions(mapOptionsCbk(options, 'ar'))
      setInputLanguage('ar')
      return
    }

    if(inputLanguage !== 'en') {
      setSelectOptions(mapOptionsCbk(options, 'en'))
      setInputLanguage('en')
    }
  }

  const filterOptions = (option, query) => {
    const searchedOption = (query || '').replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
    const queryRegex = new RegExp(searchedOption, 'i');
    return (queryRegex.test(option.label) || queryRegex.test(option.data.group))
  }

  return { selectOptions, onInput, inputLanguage, filterOptions }
}

export default useAdaptAreas