/* eslint-disable react/jsx-filename-extension */

import React from "react";
import { Typography } from "@mui/material";
import Styled from "../../analytics.styled";

import { getComasFormattedNumbers } from "../../../../utils/getComasFormattedNumbers";
import { CircleIcon } from "../../../../assets/icons";
import { ORDERS_COLORS } from "../../analytics.constants";

const getRenderedCard = ({ count, key, isMobileView, t }) => (
  <Styled.OrderSummaryCard $isMobileView={isMobileView} key={key}>
    <Typography variant="extraBold">
      {getComasFormattedNumbers({ num: count })}
    </Typography>
    <Styled.CardContentWrapper>
      <CircleIcon fill={ORDERS_COLORS[key]} />
      <Typography variant="regular" fontSize={14}>
        {t(`listing.tabsLabels.${key}`)}
      </Typography>
    </Styled.CardContentWrapper>
  </Styled.OrderSummaryCard>
);

export { getRenderedCard };
