import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Box, Typography, useTheme } from "@mui/material";
import toast from "react-hot-toast";

import { CircleIcon, SingleOrderIcon, WalletOutlineIcon } from "../../assets/icons";
import useViewport from "../../utils/use-viewport";
import Styled from "./dashboard.styled";
import { fetchMerchantBalance } from "./dashboard.slice"
import { STATUS_COLORS, statusTabsInfo } from "../orders/orders-table.constants";
import { roundBalanceText } from "./dashboard.utils";
import {getComasFormattedNumbers} from "../../utils/getComasFormattedNumbers";
import { fetchOrdersCount } from "../../utils/common.slice";
import { MENU_ITEMS_INDEX } from '../navbar/navbar.utils';
import { setSelectedIndex } from '../navbar/navbar.slice';
import { useTranslation } from "react-i18next";
import AccountActions from "./Account-actions";

export function Dashboard() {
  const { isMobileView } = useViewport();
  const dispatch = useDispatch();
  const theme = useTheme();
  const { t } = useTranslation()

  const [ordersCards, setOrdersCards] = useState([])
  const [merchantBalance, setMerchantBalance] = useState(0)

  useEffect(() => {
    // Navbar overview focus
    dispatch(setSelectedIndex(MENU_ITEMS_INDEX.OVERVIEW))

    dispatch(fetchOrdersCount({})).then((res) => setOrdersCards([ ...statusTabsInfo.map((tab) => ({ ...tab, count: res?.payload?.count?.[tab.key] || 0 }))]))

    dispatch(fetchMerchantBalance()).then((res)=>{
      if(res.payload && res.payload?.balance !== undefined){
        setMerchantBalance({
          title: t('dashboard.wallet.currentBalance'),
          value: roundBalanceText({ balance: res.payload?.balance })
        });
      } else {
        toast.error(t('dashboard.errors.failedToGetBalance'));
        setMerchantBalance({
          title: t('dashboard.wallet.currentBalance'),
          value: roundBalanceText({ balance: 0 })
        });
      }
    })
  }, [])


  const renderTitle = ({ Icon, title }) => {
    return (
      <Box display="flex" gap="8px" height="20px">
        <Icon stroke={theme.palette.black} width="20" height="20" />
        <Typography variant="boldSubtitles">{title}</Typography>
      </Box>
    );
  };

  const renderCard = ({ count, key }) => (
    <Styled.Card $isMobileView={isMobileView} key={key}>
      <Typography variant="extraBold" color={theme.palette.black}>
        {getComasFormattedNumbers({ num: count})}
      </Typography>
      <Styled.CardContentWrapper>
        <CircleIcon fill={STATUS_COLORS[key]} />
        <Typography variant="regular" fontSize={14}>{t(`listing.tabsLabels.${key}`)}</Typography>
      </Styled.CardContentWrapper>
    </Styled.Card>
  );

  return (
    <Styled.Container $isMobileView={isMobileView}>
      {/* Title */}
      <Typography variant="bold" color={theme.palette.text.primary}>
        {t('dashboard.title')}
      </Typography>

      {/* Content */}
      <Styled.ContentWrapper $isMobileView={isMobileView}>
        <Styled.SubContentsWrapper $isMobileView={isMobileView}>
          <Styled.SubContentWrapper $isMobileView={isMobileView}>
            {renderTitle({ title: t('dashboard.orders.title'), Icon: SingleOrderIcon })}
            <Styled.Grid $isMobileView={isMobileView}>
              {ordersCards.map(({ count, key, noIcon, iconColor })=>renderCard({ count, key, noIcon, iconColor }))}
            </Styled.Grid>
          </Styled.SubContentWrapper>

          <Styled.SubContentWrapper $isMobileView={isMobileView}>
            {renderTitle({ title: t('dashboard.wallet.title'), Icon: WalletOutlineIcon })}
            <Styled.WalletCardWrapper $isMobileView={isMobileView}>
              <Styled.WalletCard>
                <Typography variant="extraBold" color={theme.palette.black}>{merchantBalance.value}</Typography>
                <Typography variant="regular" fontSize={14}>{merchantBalance.title}</Typography>
              </Styled.WalletCard>
            </Styled.WalletCardWrapper>
          </Styled.SubContentWrapper>
        </Styled.SubContentsWrapper>

        <AccountActions renderTitle={renderTitle} t={t} isMobileView={isMobileView} />
      </Styled.ContentWrapper>
    </Styled.Container>
  );
}
