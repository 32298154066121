/* eslint-disable max-len */
import React from "react";
import { Box, Dialog as MuiDialog, useTheme } from "@mui/material";

import { CrossIcon } from "../../../assets/icons";
import Styled from "./dialog.styled";

export const Dialog = ({
  content,
  width = "480px",
  height = "200px",
  isOpen,
  setIsDialogOpen,
}) => {
  const theme = useTheme();
  return (
    <MuiDialog
      PaperProps={{ sx: { width: width, height: height, borderRadius: "8px" } }}
      open={isOpen}
      onClose={() => setIsDialogOpen(false)}
    >
      <Box
        height="4%"
        display={"flex"}
        flexDirection={"column"}
        alignItems={"flex-end"}
        padding={"8px"}
      >
        <Styled.IconButton disableRipple onClick={() => setIsDialogOpen(false)}>
          <CrossIcon color={theme.palette.gray} />
        </Styled.IconButton>
      </Box>
      <Box height="96%" display={"flex"} alignItems={"center"}>
        {content}
      </Box>
    </MuiDialog>
  );
};
