import { IconButton, styled } from "@mui/material";

const CloseIconButton = styled(IconButton)(({ theme }) => ({
  "&.MuiIconButton-root": {
    '&:hover': {
      backgroundColor: 'transparent'
    },
    ">svg": {
      ">path": {
        stroke: `${theme.palette.gray}`,
      },
    },
    ">svg:hover": {
      ">path": {
        stroke: `${theme.palette.black}`
      }
    },
  }
}));

const DrawerWrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== "isMobileView",
})(({ theme: { palette }, isMobileView }) => ({
  height: '100%',
  width: (isMobileView ? '100%' : 394),
  backgroundColor: palette.white,
}));

const DrawerContentWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  padding: '12px 24px'
})

const Styled = {
  CloseIconButton,
  DrawerWrapper,
  DrawerContentWrapper
}

export default Styled
