import React, { useState } from "react";
import { Box } from "@mui/material";

import { stableSort, getComparator } from "../../../utils/sortArray";
import SortingDrawer from "./sorting-drawer";
import { SelectCourierCardsDetailed } from "../../../common/components/select-courier-cards-detailed";
import Styled from "../select-courier.styled";

export function CourierCards({rows, selectedCourierId, setSelectedCourierId, isDrawerOpen, setDrawerOpen }) {

  const [orderBy, setOrderBy] = useState('');

  const visibleRows = React.useMemo(
    () =>
      stableSort(rows, getComparator('asc', orderBy)),
    ['asc', orderBy],
  );

  return (
    <Box overflow="auto">
      {/* Content List */}
      <Styled.ListContainer>
        <SelectCourierCardsDetailed
          rows={visibleRows}
          selectedCourierId={selectedCourierId}
          setSelectedCourierId={setSelectedCourierId}
        />
      </Styled.ListContainer>
      {/* Mobile Sorting Drawer */}
      <SortingDrawer
        isDrawerOpen={isDrawerOpen}
        setDrawerOpen={setDrawerOpen}
        orderBy={orderBy}
        setOrderBy={setOrderBy}
      />
    </Box>
  );
}
