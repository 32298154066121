import React, { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Box, Stack, Typography, useTheme } from "@mui/material";


import Styled from "./header.styled";
import { SingleOrderIcon, MultipleOrdersIcon, FincartSmLogo, ArrowDownIcon, LogoutIcon, LockIcon } from "../../assets/icons";
import Menu from "../../common/components/menu";
import useViewport from "../../utils/use-viewport";
import { useNavigate } from "react-router-dom";
import { setCurrentOrderId } from "../orders/orders.slice";
import { selectAuthData } from "../../utils/common.slice";
import Button from "../../common/components/button";
import Drawer from "../../common/components/drawer";
import ChangePassword from "../../common/components/change-password";


export default function Header({
  appDirection,
  //TODO: activate on re-add lang switch
  //  setAppDirection,
}) {
  const navigate = useNavigate();
  const theme = useTheme()
  const { isMobileView } = useViewport()
  const dispatch = useDispatch()

  const [profileMenuAnchorEl, setProfileMenuAnchorEl] = useState(null);
  const [createMenuAnchorEl, setCreateMenuAnchorEl] = useState(null);
  //TODO: readd on adding lang switch
  // const [currentLanguage, setCurrentLanguage] = useState('en');
  const [isPasswordDrawerOpen, setIsPasswordDrawerOpen] = useState(false);

  const { t } = useTranslation();

  const authData = useSelector(selectAuthData)
  const username = useMemo(() =>  authData?.token? decodeURI(JSON.parse(atob(authData.token.split('.')[1]))?.name) : 'username', [authData])

  const isProfileMenuOpen = Boolean(profileMenuAnchorEl);
  const isCreateMenuOpen = Boolean(createMenuAnchorEl);

  const profileMenuId = "account-drop-down-menu";
  const createMenuId = "create-drop-down-menu";

  const openProfileMenu = (event) => setProfileMenuAnchorEl(event.currentTarget);
  const openCreateMenu = (event) => setCreateMenuAnchorEl(event.currentTarget);

  const closeProfileMenu = () => setProfileMenuAnchorEl(null);
  const closeCreateMenu = () => setCreateMenuAnchorEl(null);

  const handleLogout = () => {
    localStorage.removeItem("fincartLoginData");
    // eslint-disable-next-line no-undef
    window.location.replace(process.env.REACT_APP_LANDING_URL)
  }

  const navigateTo = (path) => {
    navigate(path);
    dispatch(setCurrentOrderId(null))
    closeCreateMenu();
  }

  const UserInfo = () => (
    <Stack>
      <Typography variant="regular">{username}</Typography>
      <Typography variant="semiBoldSmall" color={theme.palette.limeGreen}>{t('header.profileMenuItems.active')}</Typography>
    </Stack>
  )

  //TODO: activate on re-add lang switch

  // const updateAppDirection = (dir) => {
  //   setAppDirection(dir)
  //   dispatch(setGlobalAppDirection(dir))
  // }

  //TODO: activate on re-add lang switch
  // const switchLang = () => {
  //   if(currentLanguage === 'ar') {
  //     setCurrentLanguage('en');
  //     updateAppDirection('ltr');
  //   } else {
  //     setCurrentLanguage('ar');
  //     updateAppDirection('rtl');
  //   }

  //   closeProfileMenu()
  // }

  const changePassword = () => {
    closeProfileMenu()
    setIsPasswordDrawerOpen(true)
  }

  const profileMenuItems =  [
    { key: 'username', component: UserInfo,  onClick: () => {}, icon: () => {}, hasBottomDivider: true, textVariant: 'regular', renderComponent: true, hasTransparentBackground: true },
    // { key: 'switchLanguage', text: switchLanguage, onClick: closeProfileMenu, icon: Language, hasBottomDivider: true  },
    { key: 'changePassword', onClick: changePassword, icon: LockIcon },
    { key: 'logout', onClick: handleLogout, icon: LogoutIcon }
  ]

  const createMenuItems =  [
    { key: 'singleOrder', onClick: ()=> navigateTo("/order/manage"), icon: SingleOrderIcon, hasBottomDivider: true },
    { key: 'multiOrders', onClick: () => navigateTo("/orders/manage"), icon: MultipleOrdersIcon, hasBottomDivider: true },
    // { key: 'pickupRequest', onClick: closeCreateMenu, icon: PickupRequestIcon, hasBottomDivider: true },
    // { key: 'supportTicket', onClick: closeCreateMenu, icon: TicketSmIcon },
  ]

  const adaptedCreateMenuItems = useMemo(() => createMenuItems.filter(({ key}) => 
    isMobileView? key!=='multiOrders':  true), [isMobileView
  ])

  const ArrowDownContainer = () => (
    <Box display="flex" width="20" height="20" sx={{ rotate: isCreateMenuOpen? '180deg' : '360deg' }}>
      <ArrowDownIcon stroke={theme.palette.white} />
    </Box>
  )

  return (
    <Box>
      <Styled.Header position="static">
        <Styled.Toolbar isMobileView={isMobileView}>
          <Box width='100%' display="flex" justifyContent={isMobileView? "space-between" : 'flex-end'} alignItems="center">
            {isMobileView && <FincartSmLogo />}
            <Box display="flex" gap="12px" width="142px">
              <Button
                aria-controls={open ? createMenuId : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={openCreateMenu}
                endIcon={<ArrowDownContainer />}
                size={{ width: 'fit-content' }}
                disableElevation
                label={t('header.createButton')}
              />
              <Styled.IconButton
                size="large"
                edge="end"
                aria-controls={profileMenuId}
                aria-haspopup="true"
                onClick={openProfileMenu}
                color="secondary"
                disableRipple
              >
                <Styled.Avatar>
                  <Typography variant="boldSideScreenTitle">
                    {username?.charAt(0)}
                  </Typography>
                </Styled.Avatar>
              </Styled.IconButton>
            </Box>
          </Box>
        </Styled.Toolbar>
      </Styled.Header>
      <Menu
        items={profileMenuItems}
        id={profileMenuId}
        anchorEl={profileMenuAnchorEl}
        isMenuOpen={isProfileMenuOpen}
        onCloseMenu={closeProfileMenu}
        theme={theme}
        dir={appDirection}
        t={t}
        translationKey={'header.profileMenuItems'}
      />
      <Menu
        items={adaptedCreateMenuItems}
        id={createMenuId}
        anchorEl={createMenuAnchorEl}
        isMenuOpen={isCreateMenuOpen}
        onCloseMenu={closeCreateMenu}
        theme={theme}
        dir={appDirection}
        t={t}
        translationKey={'header.createMenuItems'}
      />
      <Drawer
        anchor={isMobileView? 'bottom': 'right'}
        title={t('changePassword.mutualizedLabel')}
        open={isPasswordDrawerOpen}
        content={<ChangePassword t={t} paddings={isMobileView? "12px 15px" : "16px 24px"} onSuccessCbk={() => setIsPasswordDrawerOpen(false)} />}
        isMobileView={isMobileView}
        isOpen={isPasswordDrawerOpen}
        theme={theme}
        closeDrawer={() => setIsPasswordDrawerOpen(false)}
      />
    </Box>
  );
}
