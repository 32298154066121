import React, { useMemo, useState } from "react"
import { Box, Tooltip, tooltipClasses, useTheme } from "@mui/material"

import { ACTIVITIES_IDS } from "../orders-table.constants"
import { NotificationIcon, PrintLabelWithWarningIcon, VerifyIcon } from "../../../assets/icons"
import Styled from "./components.styled"

function Activities ({ activities = []  }) {
  const theme = useTheme()
  const [hoveredActivityType, setHoveredActivityType] = useState(false)

  const filteredActivities = useMemo(() => activities.filter(({ enabled }) => enabled), [activities])
  const hoverColor = useMemo(() => theme.palette[hoveredActivityType === ACTIVITIES_IDS.NOTE? 'black': 'red'], [hoveredActivityType])

  const renderTooltipContent = (type) => {
    if(type === ACTIVITIES_IDS.NOTE) return (
      <>
        <NotificationIcon
          iconOuterStroke={theme.palette[hoveredActivityType === type? 'white': 'gray']}
          iconInnerStroke={theme.palette[hoveredActivityType === type? 'black': 'white']}
        />
      </>
    )

    if(type === ACTIVITIES_IDS.REPRINT) return (
      <>
        <PrintLabelWithWarningIcon
          iconOuterStroke={theme.palette[hoveredActivityType === type? 'white': 'red']}
          iconInnerStroke={theme.palette[hoveredActivityType === type? 'red': 'white']}
        />
      </>
    )

    if(type === ACTIVITIES_IDS.UPDATE) return (
      <VerifyIcon
        iconOuterStroke={theme.palette[hoveredActivityType === type? 'white': 'red']}
        iconInnerStroke={theme.palette[hoveredActivityType === type? 'red': 'white']}
      />
    )
  }

  return (
    <Box width='100%' justifyContent="flex-end" display="flex" gap="4px">
      {filteredActivities.map(({ type, hint }) =>
        <Tooltip key={type} title={hint}
          onMouseEnter={() => setHoveredActivityType(type)}
          onMouseLeave={() => setHoveredActivityType(null)}
          slotProps={{
            popper: { sx: {
              [`&.${tooltipClasses.popper}[data-popper-placement*="bottom"] .${tooltipClasses.tooltip}`]:
              { marginTop: '5px', backgroundColor: theme.palette.black, ...(theme.typography.boldSmall) } }
            },
          }}>
          <Styled.ActionButtonWrapper
            hoverColor={hoverColor}
          >
            {renderTooltipContent(type)}
          </Styled.ActionButtonWrapper>
        </Tooltip>)}
    </Box>
  )
}

export default Activities