import { PACKAGING_INSURANCE_FILES } from "./constants";

const onClickingPackagingAndInsurance = () => {
  PACKAGING_INSURANCE_FILES.forEach((file) => {
    const link = document.createElement('a');
    link.href = file.url;
    link.download = file.name;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  });
}

export { onClickingPackagingAndInsurance }