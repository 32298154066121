import { Tab as MuiTab, Tabs as MuiTabs, TableContainer as MuiTableContainer, Toolbar as MuiToolbar, styled, TableCell as MuiTableCell,
  Table as MuiTable, LinearProgress, linearProgressClasses, IconButton as MuiIconButton } from "@mui/material";

const Container = styled('div')(({ theme, isMobileView }) =>({
  display: 'flex',
  flexDirection: 'column',
  gap: isMobileView? 12: 24,
  alignItems: 'center',
  height: '100%',
  overflow: 'auto',
  backgroundColor: theme.palette.background.light,
}))

const HeaderWrapper = styled('div')(({ theme: { palette }, isMobileView }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  height: 52,
  width: '100%',
  border: `1px solid ${palette.lightGreyOutline}`,
  boxShadow: palette.boxShadowMain,
  backgroundColor: palette.white,
  padding: isMobileView? 0 : '0px 40px'
}))

const Tabs = styled(MuiTabs, {
  shouldForwardProp: (prop) => prop !== "isMobileView",
})(({ isMobileView }) =>({
  paddingLeft: (isMobileView? 15: 0),
  overflow: 'auto',
  ".css-145v6pe-MuiButtonBase-root-MuiTabScrollButton-root.Mui-disabled": {
    display: 'none'
  },
  ".css-ps0m23-MuiTabs-indicator": {
    height: '1px'
  }
}))

const Tab = styled(MuiTab, {
  shouldForwardProp: (prop) => prop !== "opacity",
})(({opacity}) => ({
  gap: 4,
  alignItems: 'center',
  opacity: opacity,
  padding: 0,
  marginRight: 20,
  textTransform: 'none',
  minHeight: 52,
  minWidth: 0
}))

const Toolbar = styled(MuiToolbar)(({ theme: { palette, typography } }) => ({
  justifyContent: 'space-between',
  backgroundColor: palette.background.light,
  marginBottom: 8,
  borderTop: 'none',
  '@media all': {
    minHeight: 32,
    padding: 0,
    '.MuiTablePagination-toolbar': {
      minHeight: 20,
      '.MuiTablePagination-displayedRows': {
        margin: '2px 5px',
        color: palette.text.light,
        ...(typography.semiBoldSmall)
      },
      '.MuiTablePagination-actions': {
        marginLeft: 0
      }
    },
  },
}))

const TableContainer = styled(MuiTableContainer)(({ theme: { palette, customShadows } }) => ({
  height: 'calc(100% - 68px)', //40 -> top bar height + 28 -> space from below
  borderRadius: "12px",
  border: `1px solid ${palette.lightGreyOutline}`,
  borderCollapse: 'separate',
  backgroundColor: palette.white,
  boxShadow: customShadows.component
}))

const Table = styled(MuiTable)(({ theme: { palette } }) => ({
  overflow: 'auto',
  "& .MuiTableRow-root th:first-of-type": {
    borderTopLeftRadius: "12px"
  },
  "& .MuiTableRow-root th:last-child": {
    borderTopRightRadius: "12px",
    borderRight: '0px'
  },
  "& .MuiTableRow-root td:last-child": {
    borderRight: '0px'
  },
  "& .MuiTableRow-root td": {
    borderRight: `1px solid ${palette.lightGreyOutline}`
  },
  "& .MuiTableRow-root th": {
    borderRight: `1px solid ${palette.lightGreyOutline}`,
  },
  "& .MuiTableCell-root": {
    borderBottom: `1px solid ${palette.lightGreyOutline}`
  },
  "& .MuiTableBody-root": {
    "& .MuiTableRow-root": {
      "&:hover": {
        boxShadow: palette.header.headerMenu.boxShadow,
        backgroundColor: 'white !important',
        border: `1px solid ${palette.lightGreyOutline}`
      }
    }
  },
  borderRadius: "12px",
  paddingBottom: '12px'
}))

const TableIconButton = styled(MuiIconButton)(({ theme })=>({
  "&.MuiIconButton-root": {
    '&:hover': {
      "svg": {
        ">circle": {
          fill: theme.palette.black
        }
      }
    }
  }
}));

const HeaderCell = styled(MuiTableCell)(({ theme: { palette }}) => ({
  height: '44px',
  padding: '0',
  position: 'sticky',
  top: 0,
  zIndex: 2,
  backgroundColor: palette.background.light
}))

const TableCell = styled(MuiTableCell)({
  padding: '13px 0'
})

const AttemptsProgressLine = styled(LinearProgress)(({ theme, value }) => ({
  height: 5,
  borderRadius: 5,
  width: 20,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette[value === 100? 'red' : 'limeGreen']
  },
}));

const ActionButtonContained = styled('button')(({ theme: { palette, typography }}) => ({
  width: "100%",
  minHeight: "44px",
  backgroundColor: palette.blue,
  padding: '7px 16px 7px 16px',
  color: palette.white,
  textTransform: 'none',
  borderRadius: '20px',
  border: `1px solid ${palette.header.shadowColor}`,
  ...(typography.boldSubtitles),
  cursor: 'pointer'
}))

const ActionButtonOutline = styled('button')(({ theme: { palette, typography }}) => ({
  width: "100%",
  minHeight: "44px",
  backgroundColor: palette.white,
  padding: '7px 16px 7px 16px',
  color: palette.blue,
  textTransform: 'none',
  borderRadius: '20px',
  border: `1px solid ${palette.header.shadowColor}`,
  ...(typography.boldSubtitles),
  cursor: 'pointer'
}))

const Styled = {
  Container,
  Tabs,
  Tab,
  Toolbar,
  AttemptsProgressLine,
  HeaderWrapper,
  TableContainer,
  Table,
  TableIconButton,
  TableCell,
  HeaderCell,
  ActionButtonContained,
  ActionButtonOutline
}

export default Styled