
import React from "react"
import { Grid, Typography, Box, useTheme, Skeleton } from '@mui/material'

import { Select } from './components/select/select';
import { AreaSelect } from './area-select';
import Styled from './form-field.styled';
import { NumberInput } from "./components/number-input";
import MultilineInput from "./components/multiline-input";
import { FIELDS_TYPES } from "../../../constants";
import CheckboxField from "./components/checkbox-field";

const FormField = ({
  props, t, translationKey, fieldKey, errorType, setValue, isSpecialMerchant, isOrderValidated, validateAreaField, isAreaRefreshButtonDisabled, hideRefreshIcon,
  value, name, dropDownsOptions, isDisabled, isMobileView, isHidden,
  isLoading }) => {
  if(isHidden) return

  const theme = useTheme()

  const {
    isOptional, type, lg = 4, xs = 12, md = 4, noPlaceholder, supportsMultiLine,
    prefixIcon: PrefixIcon = () => {}, isOpenPackage, optionsId, menuButtonProps
  } = props

  const renderFieldContent = (fieldType) => {
    switch (fieldType) {
    case FIELDS_TYPES.SELECT:
      return (
        <Select
          value={value}
          menuButtonProps={menuButtonProps}
          hasError={Boolean(errorType)}
          errorType={errorType}
          disabled={isDisabled}
          options={dropDownsOptions?.[optionsId]}
          onChange={(value) => setValue(name, value)}
          height={36}
          placeholder={t(`${translationKey}.${fieldKey}.placeholder`)}
        />
      )

    case FIELDS_TYPES.NESTED_DROP_DOWN:
      return (
        <AreaSelect
          theme={theme}
          placeholder={isSpecialMerchant && !isOrderValidated? t('createOrder.searchByCityPlaceholder') :
            t(`${translationKey}.${fieldKey}.placeholder`)
          }
          setValue={setValue}
          name={name}
          value={value}
          isSpecialMerchant={isSpecialMerchant}
          validateAreaField={validateAreaField}
          isOrderValidated={isOrderValidated}
          isRefreshButtonDisabled={isAreaRefreshButtonDisabled}
          hasError={Boolean(errorType)}
          options={dropDownsOptions?.[optionsId]}
          hideRefreshIcon={hideRefreshIcon}
          height={36}
        />
      )

    case FIELDS_TYPES.NUMBER:
      return (
        <NumberInput
          disabled={isDisabled}
          name={name}
          value={value}
          hasError={Boolean(errorType)}
          placeholder={t(`${translationKey}.${fieldKey}.placeholder`)}
          setFormValue={setValue}
        />
      )

    case FIELDS_TYPES.CHECKBOX:
      return (
        <CheckboxField
          name={name}
          setValue={setValue}
          value={value}
          isOpenPackage={isOpenPackage}
          label={t(`${translationKey}.${fieldKey}.label`)}
          theme={theme}
          placeholder={!noPlaceholder && t(`${translationKey}.${fieldKey}.placeholder`)}
        />
      )
    default: return (
      isMobileView && supportsMultiLine? (
        <MultilineInput
          name={name}
          value={value}
          PrefixIcon={PrefixIcon}
          placeholder={t(`${translationKey}.${fieldKey}.placeholder`)}
          isDisabled={isDisabled}
          setValue={setValue}
          error={Boolean(errorType)}
          theme={theme}
        /> ) :
        <Styled.Input
          error={Boolean(errorType)}
          startAdornment={ <PrefixIcon color={isDisabled ? theme.palette.gray : theme.palette.black} />}
          disabled={isDisabled}
          $isDisabled={isDisabled}
          value={value}
          onChange={(e) => setValue(name, e.target.value)}
          type='text'
          id={name}
          disableUnderline
          autoFocus={Boolean(errorType)}
          placeholder={t(`${translationKey}.${fieldKey}.placeholder`)}
        />
    )}
  }

  return (
    <Grid item lg={lg} xs={xs} md={md}>
      <Styled.Field>
        {type !== FIELDS_TYPES.CHECKBOX &&
        <Box display="flex" gap="4px">
          <Typography variant="boldSmall" color={isDisabled? theme.palette.text.light: theme.palette.black}>{t(`${translationKey}.${fieldKey}.label`)}</Typography>
          {isOptional && (
            <Typography variant="semiBoldSmall" color={theme.palette.text.light}> ({t('mutualizedOptionalLabel')})</Typography>
          )}
        </Box>
        }
        {isLoading? <Skeleton height={46} width={isOpenPackage? '300px': '100%'} /> : renderFieldContent(type)}
        {errorType && errorType !== 'required' &&
         <Typography
           color={theme.palette.red}
           variant="semiBoldSmall"
         >
           {t(`${translationKey}.${fieldKey}.errorMsg.${errorType}`)}
         </Typography>}
      </Styled.Field>
    </Grid>
  )
}

export default FormField