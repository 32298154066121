/* eslint-disable max-len */
import React from 'react';

const CashSettlementIcon = () => (
  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.19995 8.12024L4.19995 19.8802C4.19995 22.0362 8.55551 23.8002 14 23.8002C19.4444 23.8002 23.7999 22.0362 23.7999 19.8802V8.12024" stroke="#2A272B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M23.7999 14C23.7999 16.156 19.4444 17.92 14 17.92C8.55551 17.92 4.19995 16.156 4.19995 14" stroke="#2A272B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M14 12.04C19.4123 12.04 23.7999 10.2849 23.7999 8.11995C23.7999 5.955 19.4123 4.19995 14 4.19995C8.58756 4.19995 4.19995 5.955 4.19995 8.11995C4.19995 10.2849 8.58756 12.04 14 12.04Z" stroke="#2A272B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>

)

export default CashSettlementIcon