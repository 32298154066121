import { NavLink } from "react-router-dom";
import {
  HomeIcon,
  OrdersIcon,
  AnalyticsIcon,
  WarningSquareIcon,
} from "../../assets/icons";

import { PERMS_IDS } from "../../constants";

const MENU_ITEMS_ENUM = {
  OVERVIEW: "overview",
  ORDERS: "orders",
  ACTION_CENTER: "actionCenter",
  ANALYTICS: "analytics",
  PICKUPS: "pickups",
  SUPPORT: "support",
};

const MENU_ITEMS_INDEX = {
  OVERVIEW: 0,
  ORDERS: 1,
  ACTION_CENTER: 2,
  ANALYTICS: 3,
  PICKUPS: 4,
  SUPPORT: 5,
};

const getMenuItems = () => {
  const menuItems = [
    {
      key: MENU_ITEMS_ENUM.OVERVIEW,
      component: NavLink,
      path: "/",
      icon: HomeIcon,
    },
    {
      key: MENU_ITEMS_ENUM.ORDERS,
      component: NavLink,
      path: "/orders",
      icon: OrdersIcon,
    },
    {
      key: MENU_ITEMS_ENUM.ACTION_CENTER,
      component: NavLink,
      path: "/action-center",
      icon: WarningSquareIcon,
      PERMS_ID: PERMS_IDS.ACTION_CENTER
    },
    {
      key: MENU_ITEMS_ENUM.ANALYTICS,
      component: NavLink,
      path: "/analytics",
      icon: AnalyticsIcon,
      PERMS_ID: PERMS_IDS.ANALYTICS,
    },

    // {
    //   key: MENU_ITEMS_ENUM.PICKUPS,
    //   component: NavLink,
    //   path: "/pickups",
    //   icon: PickupIcon,
    // },
    // {
    //   key: MENU_ITEMS_ENUM.SUPPORT,
    //   component: NavLink,
    //   path: "/Support",
    //   icon: TicketIcon,
    // },
  ];

  return menuItems;
};

export { getMenuItems, MENU_ITEMS_INDEX, MENU_ITEMS_ENUM };
