/* eslint-disable max-len */
import React from 'react'

const ReturnIcon = () => (
  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.72309 5.59998L4.20001 10.1818L8.72309 14.7637" stroke="#2A272B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M4.20001 10.1818L17.7692 10.1818C19.3687 10.1818 20.9027 10.8254 22.0336 11.9711C23.1646 13.1168 23.8 14.6707 23.8 16.2909C23.8 17.9112 23.1646 19.4651 22.0336 20.6108C20.9027 21.7564 19.3687 22.4001 17.7692 22.4001H10.2308" stroke="#2A272B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
)

export default ReturnIcon