import React, { useState, useRef, useEffect } from "react";
import { useTheme, Typography, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import Styled from "./action-center.styled";
import { headerCells } from "./action-center.constants";
import { UnderProgressIcon } from "../../assets/icons";
import Loader from "../../common/components/loader";

import {
  deleteOrderDialogActions,
  ClearOrderContent,
  clearOrderDialogActions,
  getCellsContent,
} from "./action-center.utlis";

import getActionBarComponent from "./components/action-bar";

import SearchField from "../../common/components/search-field";
import { Dialog } from "../../common/components/dialog";
import Table from "../../common/components/table";

import {
  fetchMissingAreaOrders,
  clearMissingAreaOrder,
} from "./action-center.slice";

import { deleteOrdersAction, fetchAreas } from "../../utils/common.slice";

import useViewPort from "../../utils/use-viewport";

import { DeleteOrderContent } from "../../common/components/delete-order-content";

const ActionCenter = () => {
  const dispatch = useDispatch();

  const { isMobileView } = useViewPort();

  const theme = useTheme();
  const { t } = useTranslation();

  const [page, setPage] = useState(1);

  const [orders, setOrders] = useState([]);
  const [selectedOrders, setSelectedOrders] = useState([]);
  const [totalOrdersCount, setTotalOrdersCount] = useState(0);
  const [isOrdersLoading, setIsOrdersLoading] = useState(true);
  const [areaOptions, setAreaOptions] = useState([]);

  const [isLoaderOpen, setIsLoaderOpen] = useState(false);

  const [resolvedOrders, setResolvedOrders] = useState([]);
  const [isErrorInClear, setIsErrorInClear] = useState(false);

  const [searchTerm, setSearchTerm] = useState("");
  const [isSearchModeOn, setIsSearchModeOn] = useState(false);

  const scrollableRef = useRef();

  const [isClearOrderDialog, setClearOrderDialog] = useState(false);
  const [clearDialogDescription, setClearDialogDescription] = useState("");

  const [isDeleteOrderDialog, setDeleteOrderDialog] = useState(false);
  const [deleteDialogDescription, setDialogDescription] = useState("");

  const handleNavigation = (direction) => {
    scrollableRef?.current?.scrollIntoView();
    setSelectedOrders([]);
    const nextPageIndex = page + (direction === "left" ? -1 : 1);
    handleFetchOrders({ page: nextPageIndex, skipUpdateSummary: true });
    setPage(nextPageIndex);
  };

  const toggleSelectAll = () => {
    if (selectedOrders?.length === orders.length) {
      setSelectedOrders([]);
    } else {
      setSelectedOrders(orders);
    }
  };

  const toggleOrder = (order) =>
    setSelectedOrders((prevOrders) => {
      if (prevOrders && prevOrders.find(({ id }) => id === order.id)) {
        return prevOrders.filter(({ id }) => id !== order.id);
      }
      return [...(prevOrders ?? []), order];
    });

  const onSearch = ({ e, nextSearchTerm }) => {
    setSearchTerm(e?.target?.value || nextSearchTerm);
    setIsSearchModeOn(true);
    if (e?.target?.value?.length >= 4 || nextSearchTerm) {
      setPage(1);
      handleFetchOrders({
        code: e?.target?.value || nextSearchTerm,
        isFromSearch: true,
      });
    }
  };

  const handleClearOrder = () => {
    // Check if any selected order is not resolved (user did not press on area dropdown) and does not have a suggested area
    const unhandledOrder = selectedOrders.find(
      (order) =>
        !resolvedOrders.some((ord) => ord._id === order._id) &&
        !order.suggestedArea
    );
    if (unhandledOrder) {
      setClearDialogDescription(t("actionCenter.clearModal.errorMsg"));
      setIsErrorInClear(true);
    } else {
      let clearDialogDescription = `${t("actionCenter.clearModal.msg")} ${
        selectedOrders.length > 1
          ? `${selectedOrders.length} ${t("actionCenter.ordersLabel")}`
          : selectedOrders[0]?.code
      }?`;
      setClearDialogDescription(clearDialogDescription);
      setIsErrorInClear(false);
    }

    setClearOrderDialog(true);
  };

  const handleDeleteOrder = () => {
    let deleteDialogDescription = `${t("actionCenter.deleteModal.msg")} ${
      selectedOrders.length > 1
        ? `${selectedOrders.length} ${t("actionCenter.ordersLabel")}`
        : selectedOrders[0]?.code
    }?`;

    setDeleteOrderDialog(true);
    setDialogDescription(deleteDialogDescription);
  };

  const deleteOrders = () => {
    let selectedOrderIds = selectedOrders.map(({ id }) => id);
    let selectedOrderCodes = selectedOrders.map(({ code }) => code);
    dispatch(deleteOrdersAction({ selectedOrderIds, selectedOrderCodes })).then(
      () => handleFetchOrders({})
    );
    setDeleteOrderDialog(false);
  };

  const clearOrders = async () => {
    const ordersToClear = resolvedOrders;

    selectedOrders.forEach((order) => {
      // Check if order has suggested area but not in resolvedOrders array
      if (
        order.suggestedArea &&
        !ordersToClear.some((ord) => ord.id === order.id)
      ) {
        // Add the order to resolvedOrders array
        const resolvedAddress = {
          id: order.id,
          area: order.suggestedArea,
          city:order.city
        };
        ordersToClear.push(resolvedAddress);
      }
    });

    try {
      const clearPromises = ordersToClear.map((order) =>
        dispatch(clearMissingAreaOrder(order))
      );
      setIsLoaderOpen(true);
      await Promise.all(clearPromises);
      setIsLoaderOpen(false);
      setClearOrderDialog(false);
      handleFetchOrders({});
    } catch (error) {
      // Handle any errors that occurred during order clearing
      console.error("Error clearing orders:", error);
    }
  };

  const handleFetchOrders = ({ code = searchTerm, page = 1 }) => {
    if (!isOrdersLoading) setIsOrdersLoading(true);
    //order is important here as we override tabs filter if we have applied filters
    const nextData = {
      filters: {
        ...(code && { code }),
      },
      limit: 10,
      page,
    };

    dispatch(fetchMissingAreaOrders(nextData)).then((res) => {
      setOrders(res.payload?.orders || []);
      setTotalOrdersCount(res.payload?.count || 0);
      setIsOrdersLoading(false);
      setSelectedOrders([]);
    });
  };

  const handleFetchAreas = () => {
    dispatch(fetchAreas()).then((res) =>
      setAreaOptions(res.payload?.data || [])
    );
  };

  useEffect(() => {
    //Fetch orders
    handleFetchOrders({});
    handleFetchAreas();
  }, []);

  useEffect(() => {
    if (!searchTerm && isSearchModeOn) {
      handleFetchOrders({ code: "", isFromSearch: true });
      setPage(1);
      setIsSearchModeOn(false);
    }
  }, [searchTerm, isSearchModeOn]);

  const actionBarProps = {
    selectedOrders: selectedOrders,
    handleClearOrder,
    handleDeleteOrder,
    theme: theme,
    t,
  };

  const tableProps = {
    rowsPerPage: 10,
    page: page,
    t: t,
    handleNavigation: handleNavigation,
    totalOrdersCount: totalOrdersCount,
    rows: orders,
    actionBarProps: actionBarProps,
    scrollableRef: scrollableRef,
    selectedOrders: selectedOrders,
    toggleSelectAll: toggleSelectAll,
    toggleOrder: toggleOrder,
    theme: theme,
    headerCells: headerCells,
    isOrdersLoading: isOrdersLoading,
    resolvedOrders: resolvedOrders,
    setResolvedOrders: setResolvedOrders,
    entity: "actionCenter",
    areaOptions: areaOptions,
    getCellsContent: getCellsContent,
    getActionBarComponent: getActionBarComponent,
  };

  return (
    <Styled.Container>
      {isMobileView?
        <Stack display="flex" justifyContent="center" alignItems="center" height="100%" gap="40px" textAlign="center" padding="20px">
          <UnderProgressIcon />
          <Typography variant="boldSideScreenTitle">{t(`actionCenter.notAvailable`)}</Typography>
        </Stack>:
        (
          <>
            <Styled.HeaderWrapper>
              <Typography
                pt={2}
                variant={"boldSubtitles"}
                color={theme.palette["black"]}
              >
                {`${t(`actionCenter.header.title`)} (${totalOrdersCount})`}
              </Typography>
              <SearchField
                theme={theme}
                onSearch={onSearch}
                searchTerm={searchTerm}
                placeholder={t("actionCenter.table.search.placeholder")}
              />
            </Styled.HeaderWrapper>

            <Table {...tableProps} />
            <Dialog
              content={
                <DeleteOrderContent
                  description={deleteDialogDescription}
                  actionButtons={deleteOrderDialogActions(
                    setDeleteOrderDialog,
                    deleteOrders,
                    t
                  )}
                  isMobileView={false}
                />
              }
              isOpen={isDeleteOrderDialog}
              setIsDialogOpen={setDeleteOrderDialog}
            />
            <Dialog
              content={
                <ClearOrderContent
                  description={clearDialogDescription}
                  actionButtons={clearOrderDialogActions(
                    setClearOrderDialog,
                    clearOrders,
                    t
                  )}
                  isMobileView={false}
                  isError={isErrorInClear}
                />
              }
              isOpen={isClearOrderDialog}
              setIsDialogOpen={setClearOrderDialog}
            />
            <Loader isLoading={isLoaderOpen} />
          </>
        )
      }
    </Styled.Container>
  );
};

export default ActionCenter;
