import React from "react";
import { Box, TablePagination, IconButton } from "@mui/material";

import { LeftArrowIcon, RightArrowIcon } from "../../../assets/icons";
import Styled from "./table.styled";

function getActionsComponent(props) {
  const { count, page, handleNavigation, rowsPerPage, theme } = props;
  const isPrevDisabled = page === 0;
  const isAfterDisabled = page >= Math.ceil(count / rowsPerPage) - 1;

  const getIconColor = (isDisabled) =>
    isDisabled ? theme.palette.greyBorder : theme.palette.gray;

  return (
    <Box display="flex" gap="20px">
      <IconButton
        onClick={() => handleNavigation("left")}
        disabled={isPrevDisabled}
        aria-label="previous page"
        disableRipple
        sx={{ padding: 0 }}
      >
        {<LeftArrowIcon color={getIconColor(isPrevDisabled)} />}
      </IconButton>
      <IconButton
        onClick={() => handleNavigation("right")}
        disabled={isAfterDisabled}
        disableRipple
        aria-label="next page"
        sx={{ padding: 0 }}
      >
        {<RightArrowIcon color={getIconColor(isAfterDisabled)} />}
      </IconButton>
    </Box>
  );
}

function TableToolbar({
  rowsPerPage,
  page,
  handleNavigation,
  actionBarProps,
  totalOrdersCount,
  theme,
  t,
  getActionBarComponent,
}) {
  const pagesCount = totalOrdersCount / rowsPerPage;

  return (
    <Styled.Toolbar>
      {getActionBarComponent(actionBarProps)}
      <TablePagination
        rowsPerPageOptions={[]}
        component="div"
        count={totalOrdersCount}
        rowsPerPage={rowsPerPage}
        onPageChange={() => {}}
        page={page - 1}
        options={{ pagination: false }}
        onRowsPerPageChange={() => {}}
        ActionsComponent={(props) =>
          getActionsComponent({ ...props, theme, handleNavigation })
        }
        labelDisplayedRows={() =>
          `${page}-${pagesCount > 1 ? Math.ceil(pagesCount) : 1} ${t(
            "listing.labels.pages"
          )}`
        }
      />
    </Styled.Toolbar>
  );
}

export default TableToolbar;
