import { createTheme } from "@mui/material";
import { blue, grey } from "@mui/material/colors";

const boldSideScreenTitlesTypo = {
  fontSize: "18px",
  fontFamily: "'Poppins', 'Noto Sans Arabic'",
  fontStyle: "normal",
  fontWeight: 700,
  lineHeight: "28px",
};

const semiBoldSmallTypo = {
  fontSize: "11px",
  fontFamily: "'Poppins', 'Noto Sans Arabic'",
  fontStyle: "normal",
  fontWeight: 600,
  lineHeight: "16px",
};

const regularTypo = {
  fontSize: "13px",
  fontFamily: "'Poppins', 'Noto Sans Arabic'",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "20px",
};

export const getTheme = (direction) =>
  createTheme({
    direction,
    typography: {
      regularWithUnderline: {
        fontSize: "13px",
        fontFamily: "'Poppins', 'Noto Sans Arabic'",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "20px",
        textDecorationLine: "underline",
      },
      regular: {
        ...regularTypo,
      },
      regularResizable: { //TODO check other regular
        ...regularTypo,
        '@media (max-width:767px)': {
          fontSize: '11px',
          fontFamily: "'Poppins', 'Noto Sans Arabic'",
          fontStyle: 'normal',
          fontWeight: 400,
          lineHeight: '15px',
        },
      },
      extraBold: {
        fontFamily: "'Poppins', 'Noto Sans Arabic'",
        fontSize: "32px",
        fontStyle: "normal",
        fontWeight: 800,
        lineHeight: "40px",
      },
      mediumText: {
        fontSize: "14px",
        fontFamily: "'Poppins', 'Noto Sans Arabic'",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "20px",
      },
      bold: {
        fontSize: "23px",
        fontFamily: "'Poppins', 'Noto Sans Arabic'",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "32px",
        "@media (max-width:767px)": {
          ...boldSideScreenTitlesTypo,
        },
      },
      semiBoldXl: {
        fontSize: '42px',
        fontFamily: "'Poppins', 'Noto Sans Arabic'",
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: '50px',
        '@media (max-width:767px)': {
          fontSize: '16px',
          fontFamily: "'Poppins', 'Noto Sans Arabic'",
          fontStyle: 'normal',
          fontWeight: 600,
          lineHeight: '30px',
        },
      },
      boldSubtitles: {
        fontSize: "14px",
        fontFamily: "'Poppins', 'Noto Sans Arabic'",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "20px",
      },
      boldSmall: {
        fontSize: "11px",
        fontFamily: "'Poppins', 'Noto Sans Arabic'",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "16px",
      },
      boldMedium: {
        fontSize: '14px',
        fontFamily: "'Poppins', 'Noto Sans Arabic'",
        fontWeight: 700,
        lineHeight: '20px',
        '@media (max-width:767px)': {
          fontSize: '11px',
          fontFamily: "'Poppins', 'Noto Sans Arabic'",
          fontWeight: 700,
          lineHeight: '15px'
        },
      },
      semiBoldLarge: {
        fontSize: '18px',
        fontFamily: "'Poppins', 'Noto Sans Arabic'",
        fontWeight: 700,
        lineHeight: '20px',
        '@media (max-width:767px)': {
          fontSize: '10px',
          fontFamily: "'Poppins', 'Noto Sans Arabic'",
          fontWeight: 700,
          lineHeight: '10px'
        },
      },
      underLineDropDown: {
        fontSize: '14px',
        fontFamily: "'Poppins', 'Noto Sans Arabic'",
        fontWeight: 700,
        lineHeight: '20px',
        textDecorationLine: 'underline'
      },
      large: {
        fontSize: '16px',
        fontFamily: "'Poppins', 'Noto Sans Arabic'",
        fontWeight: 400,
        lineHeight: '20px',
        '@media (max-width:767px)': {
          fontSize: '10px',
          fontFamily: "'Poppins', 'Noto Sans Arabic'",
          fontWeight: 400,
          lineHeight: '10px'
        },
      },
      largeText: {
        fontSize: '18px',
        fontFamily: "'Poppins', 'Noto Sans Arabic'",
        fontWeight: 400,
        lineHeight: '28px',
        '@media (max-width:767px)': {
          fontSize: '12px',
          fontFamily: "'Poppins', 'Noto Sans Arabic'",
          fontWeight: 400,
          lineHeight: '10px'
        },
      },
      boldLarge: {
        fontSize: '18px',
        fontFamily: "'Poppins', 'Noto Sans Arabic'",
        fontWeight: 700,
        lineHeight: '20px',
      },
      boldUnderline: {
        fontSize: '13px',
        fontFamily: "'Poppins', 'Noto Sans Arabic'",
        fontWeight: 700,
        lineHeight: '20px',
        textDecorationLine: "underline",
      },
      semiBoldSmall: {
        ...semiBoldSmallTypo,
      },
      semiBoldSmallUnderline: {
        ...semiBoldSmallTypo,
        textDecorationLine: "underline",
      },
      boldSideScreenTitle: {
        ...boldSideScreenTitlesTypo,
      },
      boldText: {
        fontSize: "13px",
        fontFamily: "'Poppins', 'Noto Sans Arabic'",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "20px",
      },
    },
    palette: {
      lightGreyOutline: "#F4F4F4",
      greyBorder: "#D4D4D4",
      orange: "#F9B300",
      limeGreen: "#19CF98",
      white: "#fff",
      lightRed: "#FEF9FA",
      red: "#D42A5D",
      blue: "#0064FA",
      buttonHover: "#1160D9",
      lightBlue: "#0064fa1a",
      gray: "#7F7D80",
      lightgrey: "#FBFBFB",
      disabledBackground: "#f8f8f8",
      black: "#2A272B",
      grey: { main: "#7F7D80", neutral: "#e0e0e0" },
      boxShadowMain:
        "0px 16px 16px -16px rgba(42, 39, 43, 0.08)" /* Pop-ups & Hover & Tabs BG - Shadow */,
      green: { main: "#19CF98" },
      progressLineBar: {
        red: "#D42A5D",
        orange: "#F9B300",
        green: "#19CF98",
      },
      blackBorder: "#2a272b",
      darkGreyBorder: "#DDD",
      disabledGrey: "#c9c9ca",
      background: {
        default: "#FBFBFB",
        light: "#FBFBFB",
      },
      primary: {
        main: blue[500],
      },
      secondary: {
        main: "#2A272B",
      },
      text: {
        primary: "#2A272B",
        light: "#AAA8AA",
      },
      actionButtonBackground: {
        backgroundColor: "#0064FA",
        color: "#FFFFFF",
        borderColor: "#F4F4F4",
      },
      navbar: {
        icons: {
          active: "rgba(255, 255, 255, 1)",
          inactive: grey[500],
        },
        text: {
          color: grey[500],
        },
      },
      header: {
        background: "#ffffff",
        iconColor: "#2A272B",
        shadowColor: "#F4F4F4",
        headerMenu: {
          color: "rgb(55, 65, 81)",
          border: "1px solid #F4F4F4",
          boxShadow: "0px 2px 16px 0px #2A272B14",
        },
      },
      boxShadow: {
        showOptionalFieldsSwitch: "rgba(42, 39, 43, 0.04)",
        dashboardCards: "0px 0px 10px 0px rgba(98, 92, 99, 0.05)",
      },
    },
    customShadows: {
      component: "0px 0px 12px 0px rgba(42, 39, 43, 0.04)",
      subComponent: "0px 0px 12px 0px #2A272B0A", //Pop-up/Hover/Tabs Background - Shadow
      dropDown: "0px 2px 16px 0px rgba(42, 39, 43, 0.08)",
    },
    // spacing: [0, 4, 8, 16, 32, 64],
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1536,
      },
    },
  });