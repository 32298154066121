/* eslint-disable max-len */
import React from "react"

const CashSmallIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2.3999 4.64014V11.3601C2.3999 12.5921 4.88879 13.6001 7.9999 13.6001C11.111 13.6001 13.5999 12.5921 13.5999 11.3601V4.64014" stroke="#2A272B" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M13.5999 8C13.5999 9.232 11.111 10.24 7.9999 10.24C4.88879 10.24 2.3999 9.232 2.3999 8" stroke="#2A272B" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M7.9999 6.88C11.0927 6.88 13.5999 5.87712 13.5999 4.64C13.5999 3.40288 11.0927 2.4 7.9999 2.4C4.90711 2.4 2.3999 3.40288 2.3999 4.64C2.3999 5.87712 4.90711 6.88 7.9999 6.88Z" stroke="#2A272B" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
)

export default CashSmallIcon