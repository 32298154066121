import React from "react";
import Styled from "../../analytics.styled";
import { useTheme } from "@mui/material";
import useViewport from "../../../../utils/use-viewport";
import { useTranslation } from "react-i18next";
import { SingleOrderIcon } from "../../../../assets/icons";

import {  renderTitle } from "../../analytics.utils";
import {SKELETON_ORDERS_SUMMARY_CARDS} from "../../analytics.constants"
import { getRenderedCard } from "./orders-summary.utils";

const OrdersSummary = ({ ordersSummary }) => {
  const { isMobileView } = useViewport();
  const theme = useTheme();
  const { t } = useTranslation();

  if(!ordersSummary.length) ordersSummary = SKELETON_ORDERS_SUMMARY_CARDS;
  return (
    <Styled.OrderSummarySubContentWrapper $isMobileView={isMobileView}>
      { renderTitle({
        title: t("analytics.orders.title"),
        Icon: SingleOrderIcon,
        theme,
      })}

      <Styled.OrderSummaryGrid $isMobileView={isMobileView}>
        {ordersSummary.map(({ count, key }) =>
          getRenderedCard({ count, key, isMobileView, theme, t })
        )}
      </Styled.OrderSummaryGrid>
    </Styled.OrderSummarySubContentWrapper>
  );
};

export default OrdersSummary;
