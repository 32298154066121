import React, { useEffect, useState } from "react";
import { Box, TableHead, TableRow, Typography, SvgIcon, TableBody, TableSortLabel, useTheme, Stack} from "@mui/material";

import { toDaysAndHoursStr } from "../../../utils/convertMinutedToString";
import { serviceData, tableHeaders } from "../select-courier.constants";
import RenderCourierLogo from "../../../common/components/courier-logo";
import RenderCourierRecommended from "../../../common/components/courier-recommended-text";
import { stableSort, getComparator } from "../../../utils/sortArray";
import { getProgressLineValueAndColor } from "../../../utils/getProgressLineValueAndColor";
import Styled from "../select-courier.styled";
import { useTranslation } from "react-i18next";

export function CourierTable({ rows, selectedCourierId, setSelectedCourierId }) {
  const theme = useTheme();
  const { t } = useTranslation();

  const [orderBy, setOrderBy] = useState('');
  const [orderDirection, setOrderDirection] = useState('asc');


  useEffect(()=>{
    if(rows.length > 0) setSelectedCourierId(rows[0].id)
  }, []);


  const renderService = (services) =>  (
    <Stack display="flex" gap="4px">
      {(services || []).split(',').map((service) => {
        let serviceDetails = serviceData.find(data => data.key.toLowerCase() === service.toLowerCase())
        const { key, icons } = serviceDetails || {}

        const renderContent = () => {
          if(!serviceDetails) return '-'


          return (
            <>
              <SvgIcon component={icons} inheritViewBox />
              <Typography variant="boldSmall">{t(`selectCourier.services.${key}`)}</Typography>
            </>
          )
        }

        return (
          <Box
            key={key}
            display="flex"
            justifyContent="center"
            flexDirection="row"
            alignItems="center"
            gap="4px"
          >
            {renderContent()}
          </Box>
        );})}
    </Stack>
  );

  const renderCellContent = ({
    title, subTitle = undefined, titleVariant = "regular", subTitleVariant = "regular",
  }) => (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      typography="regular"
    >
      {titleVariant ? (
        <Typography variant={titleVariant}>{title}</Typography>
      ) : (
        title
      )}
      {subTitleVariant ? (
        <Typography variant={subTitleVariant}>{subTitle}</Typography>
      ) : (
        subTitle
      )}
    </Box>
  );

  const renderProgressBar = (color) => {
    return (
      <Styled.ProgressLine
        variant="determinate"
        value={getProgressLineValueAndColor(color)}
      />
    );
  };


  const visibleRows = React.useMemo(
    () =>
      stableSort(rows, getComparator(orderDirection, orderBy)),
    [orderDirection, orderBy],
  );

  const handleRequestSort = (property) => () => {
    const isAsc = orderBy === property && orderDirection === 'asc';
    setOrderDirection(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  return (
    <Box height='100%' overflow="auto">
      {/* Couriers Table Section */}
      <Styled.TableContainer>
        <Styled.Table>
          <TableHead>
            <TableRow>
              {tableHeaders.map(({ id }) => (
                <Styled.HeaderCell key={id} sortDirection={orderBy === id? orderDirection: false}>
                  <TableSortLabel
                    disabled={id === 'courier' || id === 'services'}
                    active={orderBy === id}
                    direction={orderBy === id ? orderDirection : "asc"}
                    onClick={handleRequestSort(id)}>
                    <Styled.TableHeaderText>{t(`selectCourier.headerCellsLabels.${id}`)}</Styled.TableHeaderText>
                  </TableSortLabel>
                </Styled.HeaderCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody sx={{ overflow: 'auto' }}>
            {visibleRows.map((row) => {
              const {
                id,
                logo,
                success_rate,
                estimated_shipping,
                special_service,
                estimated_return,
                total_fees,
                is_recommended,
                color,
                return_color,
              } = row;

              return (
                <TableRow
                  hover
                  sx={{ cursor: 'pointer' }}
                  onClick={() => setSelectedCourierId(id)}
                  key={id}
                  selected={selectedCourierId === id}>
                  <Styled.TableCell align="center" padding="normal">
                    {renderCellContent({
                      title: RenderCourierLogo({ logo }),
                      subTitle: is_recommended ? RenderCourierRecommended({ theme, t }) : null, })}
                  </Styled.TableCell>
                  <Styled.TableCell align="center" padding="normal">
                    {renderCellContent({ title: success_rate * 100 + "%" })}
                  </Styled.TableCell>
                  <Styled.TableCell align="center" padding="normal">
                    {renderCellContent({
                      title: toDaysAndHoursStr(estimated_shipping),
                      subTitle: renderProgressBar(color),
                    })}
                  </Styled.TableCell>
                  <Styled.TableCell align="center" padding="normal">
                    {renderService(special_service ? special_service : "-")}
                  </Styled.TableCell>
                  <Styled.TableCell align="center" padding="normal">
                    {renderCellContent({
                      title: toDaysAndHoursStr(estimated_return),
                      subTitle: renderProgressBar(return_color),
                    })}
                  </Styled.TableCell>
                  <Styled.TableCell align="center" padding="normal">
                    {t('mutualizedEgpLabel')} {+total_fees?.toFixed(2)}
                  </Styled.TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Styled.Table>
      </Styled.TableContainer>
    </Box>
  );
}
