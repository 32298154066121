/* eslint-disable max-len */
import React from "react";

const DeliveryTruckIcon = () => {
  return (
    <svg
      width="20"
      height="18"
      viewBox="0 0 18 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11 4L14.5368 4C15.1901 4 15.8166 4.22827 16.2785 4.6346C16.7405 5.04093 17 5.59203 17 6.16667V10.5L11 10.5"
        stroke="#2A272B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1 2.1875L1 10.5L11 10.5L11 2.1875C11 1.87256 10.8683 1.57051 10.6339 1.34781C10.3995 1.12511 10.0815 1 9.75 1L2.25 1C1.91848 1 1.60054 1.12511 1.36612 1.34781C1.1317 1.57051 1 1.87256 1 2.1875Z"
        stroke="#2A272B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.5 13C11.837 13 11.2011 12.7366 10.7322 12.2678C10.2634 11.7989 10 11.163 10 10.5C10 9.83696 10.2634 9.20107 10.7322 8.73223C11.2011 8.26339 11.837 8 12.5 8C13.163 8 13.7989 8.26339 14.2678 8.73223C14.7366 9.20107 15 9.83696 15 10.5C15 11.163 14.7366 11.7989 14.2678 12.2678C13.7989 12.7366 13.163 13 12.5 13Z"
        fill="white"
        stroke="#2A272B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.5 13C3.83696 13 3.20107 12.7366 2.73223 12.2678C2.26339 11.7989 2 11.163 2 10.5C2 9.83696 2.26339 9.20107 2.73223 8.73223C3.20107 8.26339 3.83696 8 4.5 8C5.16304 8 5.79893 8.26339 6.26777 8.73223C6.73661 9.20107 7 9.83696 7 10.5C7 11.163 6.73661 11.7989 6.26777 12.2678C5.79893 12.7366 5.16304 13 4.5 13Z"
        fill="white"
        stroke="#2A272B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default DeliveryTruckIcon;
