
import { Box, } from "@mui/material";
import { styled } from "@mui/material/styles";

const Container = styled(Box, {
  shouldForwardProp: (prop) => prop !== "$isMobileView"
})(({ $isMobileView }) => ({
  width: "100%",
  height: "100%",
  display: "flex",
  overflow: "auto",
  flexDirection: "column",
  gap: ($isMobileView? "16px" : "20px"),
  padding: ($isMobileView? '16px 20px' : '16px 48px 24px 40px'),
}));

const SubContentsWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== "$isMobileView"
})(({ $isMobileView }) => ({
  display: 'flex',
  flexDirection: ($isMobileView? 'column': 'row'),
  gap: ($isMobileView? '28px': '12px'),
  height: ($isMobileView? 'unset': '50%'),
  maxHeight: ($isMobileView? 'unset': '236px')
}))

const ContentWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== "$isMobileView"
})(({ $isMobileView }) => ({
  width: "100%",
  height:  ($isMobileView? "unset" : "100%"),
  display: "flex",
  flexDirection: "column",
  gap: ($isMobileView? "28px" : "36px"),
}));

const SubContentWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== "$isMobileView"
})(({ $isMobileView }) => ({
  width: ($isMobileView? "100%" : "50%"),
  gap: ($isMobileView? "8px" : "12px"),
  display: 'flex',
  flexDirection: 'column'
}));

const Grid = styled(Box)(({ $isMobileView }) => ({
  height: `${$isMobileView? '200px': '100%'}`,
  display: 'grid',
  gridTemplateColumns: 'repeat(2, 1fr)',
  flexWrap: 'wrap',
  alignContent: 'stretch',
  gap: ($isMobileView? '8px' : "12px" )
}));

const Card = styled(Box, {
  shouldForwardProp: (prop) => prop !== "$isMobileView"
})(({ theme: {palette} }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: "12px",
  border: `1px solid ${palette.lightGreyOutline}`,
  background: palette.white,
  boxShadow: palette.boxShadow.dashboardCards
}));

const CardContentWrapper = styled(Box)(() => ({
  display: 'inline-flex',
  alignItems: 'center',
  gap: 5
}));

const WalletCardWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== "$isMobileView"
})(({ $isMobileView, theme: {palette} }) => ({
  width: "100%",
  height: `${$isMobileView? '160px': '100%'}`,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "12px",
  border: `1px solid ${palette.lightGreyOutline}`,
  background: palette.white,
  boxShadow: palette.boxShadow.dashboardCards,
}));

const WalletCard = styled(Box)(({ theme: { palette } }) => ({
  width: "100%",
  height: "calc(100% - 33.33px)",
  display: "flex",
  flexDirection: "column",
  flex: 1,
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "8px",
  border: `1px solid ${palette.lightGreyOutline}`,
  margin:'20px',
  background: palette.background.light,
}));


const Styled = {
  Container,
  ContentWrapper,
  SubContentWrapper,
  SubContentsWrapper,
  Grid,
  Card,
  CardContentWrapper,
  WalletCardWrapper,
  WalletCard
};

export default Styled;
