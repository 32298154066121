import React from "react"
import { Box, Typography } from "@mui/material"

import { STATUS_COLORS } from "../orders-table.constants"
import { CircleIcon } from "../../../assets/icons"
import { toUpperFirstLetter } from "../orders-table.utils"

const SubStatus = ({status, subStatus, theme}) => {
  const color = STATUS_COLORS[status]

  return (
    <Box display="flex" gap="4px" alignItems="center">
      <CircleIcon fill={theme.palette[color]}/>
      <Typography variant="regular">
        {toUpperFirstLetter(subStatus)}
      </Typography>
    </Box>
  )
}

export default SubStatus