import React from "react";
import { TableHead, TableRow, Typography } from "@mui/material";

import Styled from "../orders.styled";
import Checkbox from "../../../common/components/checkbox"


function TableHeader(props) {
  const { headerCells, onSelectAllClick, theme, t, isAllOrdersSelected } = props;

  return (
    <TableHead>
      <TableRow>
        <Styled.HeaderCell align="center" sx={{ width: 44 }}>
          <Checkbox
            disableRipple
            isItemSelected={isAllOrdersSelected}
            onChange={onSelectAllClick}
            labelId={"orders-table-checkbox-all"}
          />
        </Styled.HeaderCell>
        {headerCells.map(({ id, width }) => (
          <Styled.HeaderCell key={id} width={width} padding='normal' align='center'>
            <Typography variant="boldText" color={theme.palette.grey.main}>
              {t(`listing.table.headerCellsLabels.${id}`)}
            </Typography>
          </Styled.HeaderCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default TableHeader