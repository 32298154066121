/* eslint-disable max-len */
import React from "react";

const SuccessRateIcon = ({ width="20", height="11", color="#2A272B" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 20 11"
    fill="none"
  >
    <path
      d="M13.4617 0.5H19.0001V5.92857"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19 0.5L11.1769 8.16786C11.0475 8.29219 10.8735 8.36183 10.6923 8.36183C10.5111 8.36183 10.3371 8.29219 10.2077 8.16786L7.02307 5.04643C6.89366 4.9221 6.71967 4.85245 6.53846 4.85245C6.35724 4.85245 6.18325 4.9221 6.05384 5.04643L1 10"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SuccessRateIcon;
