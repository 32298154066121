/* eslint-disable react/no-array-index-key */
import React from "react";
import Styled from "./first-attempt-sla.styled";

const FirstAttemptSLASkeleton = () => {
  return Array(5).fill().map((_, index) => <Styled.SkeletonSections key={index} />
  );
};

export default FirstAttemptSLASkeleton;
