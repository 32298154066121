export const getStyles = ({ palette, typography, direction, disabled, placeholderTypo, getBorderColor, height, noBorders, darkTheme, isRtl, isRight = false }) => ({
  container: () => ({
    width: '100%',
    position: 'relative',
  }),
  indicatorSeparator: (provided) => ({ //line between value and dropdown arrow, we hide it
    ...provided,
    height: 0,
  }),
  menu: (provided) => ({
    ...provided,
    width: "max-content",
    maxWidth: '100%',
    minWidth: "100%",
    boxShadow: '0px 2px 16px rgba(42, 39, 43, 0.08)',
    zIndex: 5,
    borderRadius: "10px",
    ...(isRight && { right: 0 }),
    overflow: "hidden",
  }),
  menuList: (provided) => ({
    ...provided,
    paddingBlock: "0px",
    zIndex: '100000000000000',
  }),
  option: (provided, props) => ({
    ...provided,
    minHeight: "35px",
    color: palette.black,
    borderBottom: `1px solid ${palette.lightGreyOutline}`,
    display: 'flex',
    justifyContent: `${isRtl? 'flex-end': 'flex-start'}`,
    cursor: 'pointer',
    ...(typography.regular),
    ...(props?.isSelected && { backgroundColor: 'transparent', fontWeight: 700, }),
    textAlign: `${isRtl? 'right': 'left'}`,
    ":hover": {
      backgroundColor: palette.lightGreyOutline
    },
    ":focus": {
      backgroundColor: 'transparent'
    },
  }),
  valueContainer: (provided) => ({
    ...provided,
    cursor: disabled ? 'default' : 'pointer',
    justifyContent: `${isRtl? 'flex-end': 'flex-start'}`,
    ...(typography[placeholderTypo]),
    padding: 0
  }),
  singleValue: (provided) => ({
    ...provided,
    ...(darkTheme && {color: palette.white}),
    ...(typography[placeholderTypo]),
    margin: 0
  }),
  control: (provided) => ({
    ...provided,
    borderWidth: "1px",
    gap: "4px",
    borderColor: getBorderColor(),
    opacity: disabled? 0.5 : 1,
    boxShadow: "none",
    display: 'flex',
    flexDirection: `${isRtl? 'row-reverse': 'row'}`,
    minHeight: height || 'fit-content',
    ...(direction === 'rtl'? { paddingRight: '8px' } : { paddingLeft: '8px' }),
    ":hover": {
      borderColor:
      getBorderColor(),
    },
    ":focus-within": {
      borderColor: noBorders ? 'transparent' : palette.blackBorder,
    },
    borderRadius: "8px",
    paddingEnd: "20px",
    // height: `${height}px`,
    ...(isRtl && { paddingLeft: '5px' }),
    boxSizing: "border-box",
    backgroundColor: 'transparent'
  }),
  input: (provided) => ({
    ...provided,
    padding: 0,
    margin: 0
  }),
  placeholder: (provided) => ({
    ...provided,
    color: palette.text.light,
    ...(typography.regular),
    // maxHeight: 20
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    cursor: 'pointer',
    color: 'white',
    ...(direction === 'rtl'? {paddingLeft: 8} : {paddingRight: 8}),
    ...(noBorders && { paddingLeft: 0 })
  })
})