import React from "react";
import { Box } from "@mui/material";

const RenderCourierLogo = ({logo, width=72, height=30}) => {
  return (
    <Box width={width} height={height}>
      <img
        // eslint-disable-next-line no-undef
        src={`${process.env.REACT_APP_BASE_URL_MERCHANT}${logo}`}
        width={width}
        height={height}
        style={{ objectFit: "contain" }}
      />
    </Box>
  );
};

export default RenderCourierLogo;
