import React, { useCallback, useState } from "react";
import { Box, Typography } from "@mui/material";

import Header from "./header";
import Styled from "../order-details.styled";
import { DetailsTab } from "./details-tab";
import { ActivityLogsTab } from "./activity-logs-tab";
import { TABS } from "../order-details.constants";

export function Container({orderDetails, orderLogs, t, theme, onCloseOrderDetails }) {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  const updateTabsValue = (e, nextIndex) => {
    setSelectedTabIndex(nextIndex);
  }

  const renderTabLabel = useCallback(
    (index, label) => {
      const isTabActive = index === selectedTabIndex;
      return (
        <Typography variant={isTabActive? 'boldText' : 'regular'} color={theme.palette[isTabActive? 'black' : 'gray']} textTransform={'capitalize'}>
          {`${label}`}
        </Typography>
      )
    },
    [selectedTabIndex],
  )

  return (
    <Box width={"100%"} height={"100%"} bgcolor={theme.palette.background.light}>
      <Box boxShadow={theme.customShadows.subComponent}>
        <Header
          order={orderDetails}
          onCloseOrderDetails={onCloseOrderDetails}
        />
        <Styled.Tabs
          value={selectedTabIndex}
          onChange={updateTabsValue}
          indicatorColor="secondary"
          variant="scrollable"
        >
          {TABS.map((id, index) => (
            <Styled.Tab
              key={index}
              label={renderTabLabel(index, t(`listing.orderDetails.tabs.${id}`))}/>
          ))}
        </Styled.Tabs>
      </Box>
      {selectedTabIndex === 0? (<ActivityLogsTab orderLogs={orderLogs}/>): (<DetailsTab orderDetails={orderDetails}/>)}
    </Box>
  );
}
