import * as React from "react";

import { Route, Routes } from "react-router-dom";
import { Dashboard } from "./dashboard";
import { CreateOrder } from "./create-order";
import Orders from "./orders";
import { SelectCourier } from "./select-courier";
import BulkCreateOrders from "./bulk-create-order";
import ActionCenter from "./action-center";
import Analytics from "./analytics";

import { PERMS_IDS } from "../constants";

import { useSelector } from "react-redux";
import { selectAuthData } from "../utils/common.slice";

export function Router() {
  const authData = useSelector(selectAuthData);
  const hasActionCenterPermission = (authData?.permissions || []).includes(
    PERMS_IDS.ACTION_CENTER
  );

  const hasAnalyticsPermission = authData?.permissions?.includes(
    PERMS_IDS.ANALYTICS
  );

  return (
    <Routes>
      <Route path="/" element={<Dashboard />} />
      <Route path="/orders" element={<Orders />} />
      <Route path="/order/manage" element={<CreateOrder />} />
      <Route path="/orders/manage" element={<BulkCreateOrders />} />
      <Route path="/order/manage/select-courier" element={<SelectCourier />} />
      <Route path="/orders/select-courier" element={<SelectCourier />} />
      <Route path="/pickups" element={<Dashboard />} />
      <Route path="/support" element={<Dashboard />} />
      <Route
        path="/analytics"
        element={hasAnalyticsPermission ? <Analytics /> : <Dashboard />}
      />
      <Route
        path="/action-center"
        element={hasActionCenterPermission ? <ActionCenter /> : <Dashboard />}
      />
    </Routes>
  );
}
