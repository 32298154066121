import { styled } from "@mui/material";

const Container = styled("div")(({ theme, isMobileView }) => ({
  display: "flex",
  flexDirection: "column",
  gap: isMobileView ? 12 : 24,
  alignItems: "center",
  height: "100%",
  overflow: "auto",
  backgroundColor: theme.palette.background.light,
}));

const HeaderWrapper = styled("div")(({ theme: { palette }, isMobileView }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "flex-start",
  height: 52,
  width: "100%",
  border: `1px solid ${palette.lightGreyOutline}`,
  boxShadow: palette.boxShadowMain,
  backgroundColor: palette.white,
  padding: isMobileView ? 0 : "0px 40px",
}));

const Styled = {
  Container,
  HeaderWrapper,
};

export default Styled;
