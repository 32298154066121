import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { adaptOrderDataToBackendFormat } from "../create-order/create-order.utils";

//SLICE NAMES CANNOT BE DUPLICATES!
const sliceName = "selectCourier";

export const fetchCouriers = createAsyncThunk(
  `${sliceName}/fetch`,
  async ({ amount, zone, city, orderType, packageType }, thunkAPI) => {
    const response = await thunkAPI.extra.apiClient.get({
      url: `couriers?order_type=${orderType}&package_type=${packageType}&amount=${amount}&zone=${zone}&city=${city}`,
    });
    return response?.data?.body?.couriers || [];
  }
);

export const createNewOrder = createAsyncThunk(
  `${sliceName}/createNewOrder`,
  async (data, thunkAPI) => {
    //TODO: translate
    const { orderType, amount: orderAmount, ...restOrderData } = data;
    const amount = orderAmount || 0;
    const response = await thunkAPI.extra.apiClient.post({
      url: `orders/${orderType}`,
      successMsg: "Order created successfully",
      errorMsg: "Failed to create order.",
      data: {
        instaShip: data.instaShip || false,
        ...adaptOrderDataToBackendFormat({ ...restOrderData, amount }),
        courierId: data.selectedCourierId,
      },
    });
    return response?.data || null;
  }
);

export const fetchCouriersForBulkOrders = createAsyncThunk(
  `${sliceName}/fetchCouriersForBulkOrders`,
  async (data, thunkAPI) => {
    const response = await thunkAPI.extra.apiClient.post({
      url: "orders/couriers/available",
      data: data,
    });
    return response?.data?.body?.couriers || [];
  }
);

export const updateOrderCourierAction = createAsyncThunk(
  `${sliceName}/updateOrderCourierAction`,
  async ({ order, courierId }, thunkAPI) => {
    //TODO: translate
    const response = await thunkAPI.extra.apiClient.put({
      url: `orders/${order.id}`,
      errorMsg: "Failed to update order.",
      data: {
        orderDetails: { type: order.type },
        customerDetails: {},
        courierId: courierId,
      },
    });
    return response?.data || null;
  }
);

export const updateBulkOrdersCourierAction = createAsyncThunk(
  `${sliceName}/updateBulkOrdersCourierAction`,
  async (parameterObject, thunkAPI) => {
    const response = await thunkAPI.extra.apiClient.put({
      url: "orders/couriers/bulk",
      errorMsg: "Failed to update orders.",
      data: parameterObject,
    });

    return response?.data || null;
  }
);

export const selectCourierSlice = createSlice({
  name: sliceName,
  initialState: {},
  reducers: {
    extraReducers: (builder) => {
      // Add reducers for additional action types here
      builder.addCase(fetchCouriers.fulfilled, (state, action) => ({
        ...state,
        ...action.payload,
      }));

      builder.addCase(createNewOrder.fulfilled, (state, action) => ({
        ...state,
        ...action.payload,
      }));

      builder.addCase(
        fetchCouriersForBulkOrders.fulfilled,
        (state, action) => ({
          ...state,
          ...action.payload,
        })
      );
    },
  },
});

export const { handleCourierSelection } = selectCourierSlice.actions;

export default selectCourierSlice.reducer;
