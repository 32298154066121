/* eslint-disable max-len */
import React from 'react'

const OpenEyeIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16.722 9.33C16.901 9.51375 17 9.75248 17 10C17 10.2475 16.901 10.4862 16.722 10.67C15.5891 11.8 13.0103 14 10 14C6.98966 14 4.41091 11.8 3.27799 10.67C3.09905 10.4862 3 10.2475 3 10C3 9.75248 3.09905 9.51375 3.27799 9.33C4.41091 8.2 6.98966 6 10 6C13.0103 6 15.5891 8.2 16.722 9.33Z" stroke="#AAA8AA" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M10 12C11.1046 12 12 11.1046 12 10C12 8.89543 11.1046 8 10 8C8.89543 8 8 8.89543 8 10C8 11.1046 8.89543 12 10 12Z" stroke="#AAA8AA" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
)

export default OpenEyeIcon