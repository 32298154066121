import React from "react";

const WarningSquareIcon = () => (
  <svg
    width="24"
    height="21"
    viewBox="0 0 24 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.2635 3.03885C11.0313 1.69522 12.9687 1.69522 13.7365 3.03885L22.2901 18.0077C23.052 19.341 22.0893 21 20.5536 21H3.44636C1.91071 21 0.94798 19.341 1.70987 18.0077L10.2635 3.03885Z"
      fill="#FBFBFB"
    />
    <path
      d="M12 9L12 14"
      stroke="#2A272B"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 18C12.5523 18 13 17.5523 13 17C13 16.4477 12.5523 16 12 16C11.4477 16 11 16.4477 11 17C11 17.5523 11.4477 18 12 18Z"
      fill="#2A272B"
      stroke="#2A272B"
      strokeWidth="0.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default WarningSquareIcon;
