const headerCells = [
  {
    id: "code",
    width: 176,
  },
  {
    id: "details",
    width: 168,
  },

  {
    id: "address",
    width: 204,
  },
  {
    id: "suggestedArea",
    width: 204,
  },
];

const SUGGESTED_AREA_CONFIDENCE_LEVEL = {
  CONFIDENT: "confident",
  NEUTRAL: "neutral",
  UNCONFIDENT: "unconfident",
};

const SUGGESTED_AREA_CONFIDENCE_LEVEL_COLOR = {
  [SUGGESTED_AREA_CONFIDENCE_LEVEL.CONFIDENT]: "limeGreen",
  [SUGGESTED_AREA_CONFIDENCE_LEVEL.NEUTRAL]: "orange",
  [SUGGESTED_AREA_CONFIDENCE_LEVEL.UNCONFIDENT]: "red",
};

export {
  headerCells,
  SUGGESTED_AREA_CONFIDENCE_LEVEL,
  SUGGESTED_AREA_CONFIDENCE_LEVEL_COLOR,
};
