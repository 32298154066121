/* eslint-disable max-len */
import React from 'react'


const ImportIcon = ({ stroke = "#7F7D80", width = '36', height = '36' }) => (
  <svg width={width} height={height} viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M25.56 12.6H27.72C28.0064 12.6 28.2811 12.7115 28.4836 12.9101C28.6862 13.1087 28.7999 13.378 28.7999 13.6588V27.7412C28.7999 28.022 28.6862 28.2913 28.4836 28.4899C28.2811 28.6884 28.0064 28.8 27.72 28.8H8.27995C7.99352 28.8 7.71882 28.6884 7.51628 28.4899C7.31374 28.2913 7.19995 28.022 7.19995 27.7412V13.6588C7.19995 13.378 7.31374 13.1087 7.51628 12.9101C7.71882 12.7115 7.99352 12.6 8.27995 12.6H10.44" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M18 21.6V7.19995" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M14.3999 19.7999L17.9999 23.3999L21.5999 19.7999" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
)

export default ImportIcon