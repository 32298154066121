import { Box, Typography } from "@mui/material"
import React from "react"

import Styled from "./bulk-create-order.styled"
import { BulkInfoIcon } from "../../assets/icons"
import { BlackTooltip } from "../../common/components/black-tooltip"

const ColumnHeaderCell = ({ theme, isOptional, t, noInfo, i18nKey }) => (
  <Box display="flex" alignItems="center" gap="4px">
    <Typography variant="boldText" color={theme.palette.gray}>
      {t(`bulkCreateOrders.headerCells.${i18nKey}.label`)}
    </Typography>

    {isOptional && (
      <Typography variant="semiBoldSmall" color={theme.palette.text.light}>
        ({t('mutualizedOptionalLabel')})
      </Typography>
    )}

    {!noInfo && (
      <BlackTooltip title={t(`bulkCreateOrders.headerCells.${i18nKey}.info`)} placement="top">
        <Styled.InfoIconWrapper>
          <BulkInfoIcon />
        </Styled.InfoIconWrapper>
      </BlackTooltip>
    )}
  </Box>
)

export default ColumnHeaderCell