import React from "react"

import Styled from "./icon-button.styled"
import { BlackTooltip } from "../black-tooltip"

const IconButton = ({ icon: Icon, onClick, onHover = () => {}, isDisabled, iconSize='32px', tooltip = false, title='' }) => (
  <BlackTooltip
    disabled={tooltip}
    title={title}>
    <Styled.IconButton disabled={isDisabled} onMouseOver={onHover} onClick={onClick} $iconSize={iconSize} disableRipple>
      {Icon}
    </Styled.IconButton>
  </BlackTooltip>
)

export default IconButton