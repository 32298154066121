import { IconButton as MuiIconButton, styled } from "@mui/material";

const IconButton = styled(MuiIconButton)(({ theme }) => ({
  "&.MuiIconButton-root": {
    "&:hover": {
      backgroundColor: "transparent",
    },
    ">svg": {
      ">path": {
        stroke: `${theme.palette.gray}`,
      },
    },
    ">svg:hover": {
      ">path": {
        stroke: `${theme.palette.black}`,
      },
    },
  },
}));

const Styled = { IconButton };

export default Styled;
