import React, { useLayoutEffect, useRef } from "react"
import ErrorPlaceholder from "./error-placeholder"
import Styled from "../bulk-create-order.styled"

const InputField = (
  ({
    rowData,
    setRowData,
    focus,
    columnData
  }) => {
    const ref = useRef()
  
    const { subType = 'text'} = columnData
    const { value, failureReason } = rowData || {}
    useLayoutEffect(() => {
      if (focus) {
        ref.current?.focus()
      } else {
        ref.current?.blur()
      }
    }, [focus])
  
    if(failureReason && !focus) {
      return <ErrorPlaceholder ref={ref} failureReason={failureReason} value={value} />
    }
  
    return (
      <Styled.InputField
        ref={ref}
        value={value}
        type={subType}
        onChange={(e) => setRowData({ value: e.currentTarget.value})}
        autoFocus={focus}
        autocomplete="off"
      />
    )
  }
)

export default InputField
  