import { styled } from "@mui/material/styles";
import { Box, Paper } from "@mui/material";

const TableWrapper = styled(Paper)({
  border: "1px solid #FAFAFA",
  padding: 16,
  marginBottom: 20,
});

const RejectionReasonWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isLastRow",
})(({ isLastRow,  theme: { palette } }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  borderBottom: isLastRow ? "none" :`1px solid ${palette.darkGreyBorder}`,
  width: "100%",
  paddingBottom: 16,
  marginBottom: 16,
  "&:last-child": { paddingBottom: 0, marginBottom: 0 },
}));
const Styled = {
  TableWrapper,
  RejectionReasonWrapper,
};

export default Styled;
