/* eslint-disable react/jsx-filename-extension */

import React from "react";
import { isEqual } from "lodash";
import { Box, Typography } from "@mui/material";
import { DATE_MENU_ITEMS_KEYS } from "./analytics.constants";
const renderTitle = ({ Icon, title, theme }) => {
  return (
    <Box display="flex" gap="8px" height="20px">
      <Icon stroke={theme.palette.black} width="20" height="20" />
      <Typography variant="boldSubtitles">{title}</Typography>
    </Box>
  );
};

const getDateRange = (key = DATE_MENU_ITEMS_KEYS.THIS_MONTH) => {
  const currentDate = new Date();

  const today = new Date(
    Date.UTC(
      currentDate.getUTCFullYear(),
      currentDate.getUTCMonth(),
      currentDate.getUTCDate()
    )
  );

  const startOfMonth = new Date(
    Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), 1)
  );
  const endOfMonth = new Date(
    Date.UTC(currentDate.getFullYear(), currentDate.getMonth() + 1, 0)
  );

  const startOfLastMonth = new Date(
    Date.UTC(currentDate.getFullYear(), currentDate.getMonth() - 1, 1)
  );
  const endOfLastMonth = new Date(
    Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), 0)
  );

  const sevenDaysAgo = new Date(
    Date.UTC(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate() - 7
    )
  );

  const yesterday = new Date(
    Date.UTC(
      currentDate.getUTCFullYear(),
      currentDate.getUTCMonth(),
      currentDate.getUTCDate() - 1
    )
  );

  const DATES = {
    [DATE_MENU_ITEMS_KEYS.THIS_MONTH]: {
      from: startOfMonth,
      to: endOfMonth,
    },
    [DATE_MENU_ITEMS_KEYS.LAST_MONTH]: {
      from: startOfLastMonth,
      to: endOfLastMonth,
    },
    [DATE_MENU_ITEMS_KEYS.YESTERDAY]: {
      from: yesterday,
      to: yesterday,
    },
    [DATE_MENU_ITEMS_KEYS.LAST_SEVEN_DAYS]: {
      from: sevenDaysAgo,
      to: today,
    },
  };
  const selectedDates = DATES[key];

  const dateRange = {
    dateRange: {
      processedOn: {
        from: selectedDates.from,
        to: selectedDates.to,
      },
    },
  };

  return dateRange;
};

const didValueChange = (prevValue, currentValue) => {
  return !isEqual(prevValue, currentValue);
};

const capitalizeFirstLetterOfEachWord = (string) => {
  return string
    .split(" ")
    .map((word) => {
      if (word.length === 0) return word;
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(" ");
};

export { renderTitle, getDateRange, didValueChange, capitalizeFirstLetterOfEachWord };
