/* eslint-disable max-len */
import React from "react";

const CanNotOpenIcon = ({ width="16", height="12", color="#2A272B" }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.99902 2.09998C2.99902 1.54769 3.44674 1.09998 3.99902 1.09998L11.9954 1.09998C12.5477 1.09998 12.9954 1.54769 12.9954 2.09998L12.9954 9.89997C12.9954 10.4523 12.5477 10.9 11.9954 10.9L3.99902 10.9C3.44674 10.9 2.99902 10.4523 2.99902 9.89997L2.99902 2.09998Z"
      stroke={color}
    />
    <path
      d="M9.15063 9.0152H11.073"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.74077 2.55431C3.40143 1.88898 3.88472 1.09998 4.63159 1.09998L11.3628 1.09998C12.1097 1.09998 12.593 1.88898 12.2537 2.55432L11.7358 3.5697C11.565 3.90456 11.2209 4.11536 10.845 4.11536L5.14946 4.11536C4.77356 4.11536 4.42942 3.90456 4.25863 3.5697L3.74077 2.55431Z"
      stroke={color}
    />
    <path
      d="M3.18016 1.36414L1.46643 4.11532"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.5362 4.11539L12.8119 1.36425"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default CanNotOpenIcon;
