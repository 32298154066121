import React from "react";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import Styled from "../../../../analytics.styled";
import RejectionReasonTable from "./rejection-reason-table";

const UnSuccessfulReasons = ({ isMobileView, theme, data, isLoading }) => {
  const { t } = useTranslation();
  return (
    <Styled.Card $isMobileView={isMobileView}>
      <Box
        display={"flex"}
        flexDirection={"column"}
        width={"95%"}
        height={"100%"}
      >
        <Typography
          variant="mediumText"
          color={theme.palette.text.light}
          sx={{ mt: 2, mb: 2, pl: 1 }}
        >
          {t("analytics.unsuccessfulReasons.title", {
            totalOrders: data.totalOrders || 0,
          })}
        </Typography>
        <Box>
          <RejectionReasonTable
            data={data.rejectionReasons}
            theme={theme}
            t={t}
            isLoading={isLoading}
          />
        </Box>
      </Box>
    </Styled.Card>
  );
};

export default UnSuccessfulReasons;
