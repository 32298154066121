import { CashSettlementIcon, DeliverOrderIcon, ExchangeIcon, ReturnIcon } from '../../assets/icons';
import { ORDERS_IDS } from '../create-order/create-order.constants';


const INIT_ROW = {
  name: {value: ''}, phone: {value: ''}, addressLine: {value: ''},
  area: {value: ''}, amount: {value: ''}, description: {value: ''},
  openPackageAllowed: {value: ''}, packageType: {value: ''},
  noOfItems: {value: ''}, shippingNotes: {value: ''},
  backupPhone: {value: ''}, referenceNumber: {value: ''}
}

const INIT_ROWS = Array(100).fill(INIT_ROW)

// TODO: translate
const openPackageOptions = [{ label: 'Yes', value: true }, {label: 'No', value: false}]


const VALIDATED_STATUS = {
  NOT_YET: 'not-yet', //validation is not run
  SOME_FAILED: 'some-failed', //validation failed
  ALL_VALIDATED: 'all-validated', //validation run once and all validated
  ALREADY_VALIDATED: 'already-validated' //already all validated, ready to be created
}

const VIEW_MODES = {
  CREATE: 'create',
  CSV_UPLOAD: 'csv-upload',
}

const HEADERS = {
  name: 'Name',
  phone: 'Phone',
  addressLine: 'Address',
  area: 'City',
  amount: 'Amount',
  packageType: 'Package Type',
  noOfItems: 'No. of Items',
  openPackageAllowed: 'Open Package',
  description: 'Description (optional)',
  shippingNotes: 'Shipping Notes (optional)',
  backupPhone: 'Backup Phone (optional)',
  referenceNumber: 'Reference Number (optional)'
}

const INFO_ROW = {
  name: 'Full Name',
  phone: '11 Digits',
  addressLine: 'At least 4 words, preferably in Arabic and includes building, floor and apartment no.',
  area: 'Select from the city dropdown',
  amount : 'Total amount with shipping fees. If no cash collection, write 0',
  packageType: 'If parcel, leave empty',
  noOfItems: 'If 1 item, leave empty',
  openPackageAllowed: 'If no, leave empty',
  description: 'Description in Arabic of the items you\'re shipping',
  shippingNotes: 'Notes in Arabic you want the courier to consider while delivering your order',
  backupPhone: 'Mobile or landline',
  referenceNumber: 'Ex. Shopify order ID',
}

const COLUMNS = [
  { key: 'name', type: 'custom', noInfo: true, minWidth: 180, sheetWidth: 17},
  { key: 'phone', type: 'custom', subType: 'number', minWidth: 140, sheetWidth: 13},
  { key: 'addressLine', type: 'custom', minWidth: 320, sheetWidth: 41,},
  { key: 'area', type: 'select', optionsId: 'areas', noInfo: true, minWidth:  320, sheetWidth: 23},
  { key: 'amount', type: 'custom', subType: 'number', minWidth: 100, sheetWidth: 25},
  { key: 'packageType', type: 'select', optionsId: 'packageTypes', minWidth:  140, sheetWidth: 19},
  { key: 'noOfItems', type: 'custom', subType: 'number', minWidth:  120, sheetWidth: 24},
  { key: 'openPackageAllowed', type: 'select', optionsId: 'openPackageAllowed', minWidth:  140, sheetWidth: 19},
  { key: 'description', type: 'custom', minWidth: 300, isOptional: true, sheetWidth: 36},
  { key: 'shippingNotes', type: 'custom', minWidth: 240, isOptional: true, sheetWidth: 36},
  { key: 'backupPhone', type: 'custom', subType: 'number', minWidth:  200, isOptional: true, sheetWidth: 23},
  { key: 'referenceNumber', type: 'custom', minWidth: 200, isOptional: true, sheetWidth: 25},
]

const ORDER_TYPES = [
  { key: ORDERS_IDS.DELIVERY, icon: DeliverOrderIcon, i18nKey: 'deliver' },
  { key: ORDERS_IDS.RETURN, icon: ReturnIcon, i18nKey: 'return', disabled: true},
  { key: ORDERS_IDS.EXCHANGE, icon: ExchangeIcon, i18nKey: 'exchange', disabled: true },
  { key: ORDERS_IDS.CASH_SETTLEMENT, icon: CashSettlementIcon, i18nKey: 'cashSettlement', disabled: true },
]

const SUMMARY_IDS = {
  UPLOADED: 'uploaded',
  CREATED: 'created',
  FAILED: 'failed',
  DELETED: 'deleted'
}

const INIT_DATA_SUMMARY = { [SUMMARY_IDS.UPLOADED]: 0, [SUMMARY_IDS.CREATED]: 0,  [SUMMARY_IDS.FAILED]: 0, [SUMMARY_IDS.DELETED]: 0 }
const SUMMARY_LIST = [SUMMARY_IDS.UPLOADED, SUMMARY_IDS.CREATED, SUMMARY_IDS.FAILED, SUMMARY_IDS.DELETED]

export { INIT_ROW, INIT_DATA_SUMMARY, openPackageOptions, INIT_ROWS, COLUMNS, VALIDATED_STATUS, ORDER_TYPES, VIEW_MODES, SUMMARY_LIST, SUMMARY_IDS, HEADERS, INFO_ROW }