/* eslint-disable react/jsx-filename-extension */
import React from "react";
import { Box, Typography } from "@mui/material";
import { CourierLogo } from "../../../../../orders/components";

const getCustomLabel = (courier, theme) => {
  return (
    <Box flex="0 0 auto" ml={2} mr={1}>
      {courier.logo ? (
        <CourierLogo imgPath={courier.logo} />
      ) : (
        <Typography variant="semiBoldSmall" color={theme.palette.text.primary}>
          -
        </Typography>
      )}
    </Box>
  );
};

const getCompletedPercentage = (percentage) => {
  return percentage > 0
    ? Math.round(percentage.toFixed(0))
    : 100;
};

export { getCustomLabel, getCompletedPercentage }