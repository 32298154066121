/* eslint-disable max-len */
import React from "react"

const DeleteIcon = ({ width="20", height="20", color = "#7F7D80" }) => (
  <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4 6.5H16" stroke={color} strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M5.5 6.5H14.5V15.5C14.5 15.7652 14.3946 16.0196 14.2071 16.2071C14.0196 16.3946 13.7652 16.5 13.5 16.5H6.5C6.23478 16.5 5.98043 16.3946 5.79289 16.2071C5.60536 16.0196 5.5 15.7652 5.5 15.5V6.5Z" stroke={color} strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M7.5 6.5V6C7.5 5.33696 7.76339 4.70107 8.23223 4.23223C8.70107 3.76339 9.33696 3.5 10 3.5C10.663 3.5 11.2989 3.76339 11.7678 4.23223C12.2366 4.70107 12.5 5.33696 12.5 6V6.5" stroke={color} strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M8.5 8.50012V14.0001" stroke={color} strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M11.5 8.50012V14.0001" stroke={color} strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
)

export default DeleteIcon