/* eslint-disable max-len */
import React from "react"

const CalendarIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.23047 3V7.30769" stroke="#2A272B" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M13.7695 3V7.30769" stroke="#2A272B" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M4.07692 5.15381C3.79131 5.15381 3.51739 5.26727 3.31542 5.46923C3.11346 5.67119 3 5.94511 3 6.23073V15.923C3 16.2087 3.11346 16.4826 3.31542 16.6845C3.51739 16.8865 3.79131 17 4.07692 17H15.9231C16.2087 17 16.4826 16.8865 16.6846 16.6845C16.8865 16.4826 17 16.2087 17 15.923V6.23073C17 5.94511 16.8865 5.67119 16.6846 5.46923C16.4826 5.26727 16.2087 5.15381 15.9231 5.15381H13.7692M4.18909 5.15381H13.3766" stroke="#2A272B" strokeLinecap="round" strokeLinejoin="round"/>
    <line x1="3" y1="7.5" x2="17" y2="7.5" stroke="#2A272B"/>
    <rect x="5.5" y="9.5" width="2" height="2" rx="0.5" fill="#2A272B"/>
    <rect x="5.5" y="13" width="2" height="2" rx="0.5" fill="#2A272B"/>
    <rect x="9" y="9.5" width="2" height="2" rx="0.5" fill="#2A272B"/>
    <rect x="9" y="13" width="2" height="2" rx="0.5" fill="#2A272B"/>
    <rect x="12.5" y="9.5" width="2" height="2" rx="0.5" fill="#2A272B"/>
    <rect x="12.5" y="13" width="2" height="2" rx="0.5" fill="#2A272B"/>
  </svg>
)

export default CalendarIcon