/* eslint-disable react/no-array-index-key */
import React from "react";

import { Box, Skeleton, TableRow, TableBody } from "@mui/material";
import Styled from "./table.styled";
const TableSkeleton = ({ rowsPerPage, columnsCount }) => {
  const rows = Array(rowsPerPage).fill(null);
  const cellsCount = Array(columnsCount).fill(null);
  return (
    <TableBody>
      {rows.map((_, index) => {
        return (
          <TableRow key={index}>
            <Styled.TableCell padding="checkbox" align="center">
              <Box width="100%" display="flex" justifyContent="center">
                <Skeleton height="27px" width="15px" />
              </Box>
            </Styled.TableCell>
            {cellsCount.map((_, index) => (
              <Styled.TableCell key={index} align="center">
                <Box width="100%" display="flex" justifyContent="center">
                  <Skeleton width="90%" />
                </Box>
              </Styled.TableCell>
            ))}
          </TableRow>
        );
      })}
    </TableBody>
  );
};

export default TableSkeleton;
