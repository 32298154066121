import { Checkbox, FormControlLabel, Typography } from "@mui/material"
import React from "react"

import { CheckedIcon, SquareIcon } from "../../../../assets/icons"

const CheckboxField = ({ name, setValue, theme, isOpenPackage, label, value, placeholder }) => (
  <FormControlLabel
    control={
      <Checkbox
        size="md"
        color="secondary"
        checked={value}
        disableRipple
        onChange={(e) => setValue(name, e.target.checked)}
        icon={<SquareIcon />}
        checkedIcon={<CheckedIcon />}
      />
    }
    label={(
      <>
        <Typography
          color={theme.palette.text[isOpenPackage? 'primary': 'light']}
          variant={isOpenPackage? 'regular': 'semiBoldSmall'}>
          {label}
        </Typography>

        {placeholder && <Typography variant="regular" color={theme.palette.text.light}>{placeholder}</Typography>}
      </>
    )}
  />
)

export default CheckboxField