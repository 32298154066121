/* eslint-disable max-len */
import React from "react"

const ExportIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M14.2 7H15.4C15.5591 7 15.7117 7.06886 15.8243 7.19143C15.9368 7.31401 16 7.48025 16 7.65359V15.3464C16 15.5198 15.9368 15.686 15.8243 15.8086C15.7117 15.9311 15.5591 16 15.4 16H4.6C4.44087 16 4.28826 15.9311 4.17574 15.8086C4.06321 15.686 4 15.5198 4 15.3464V7.65359C4 7.48025 4.06321 7.31401 4.17574 7.19143C4.28826 7.06886 4.44087 7 4.6 7H5.8" stroke="#7F7D80" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M10 11V3" stroke="#7F7D80" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M12 5L10 3L8 5" stroke="#7F7D80" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
)

export default ExportIcon
