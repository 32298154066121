import { Typography } from "@mui/material";
import React from "react";
import Styled from "./menu.styled";

import {
  MISSING_AREA_SUBSTATUS,
  ORDERS_ACTIONS_IDS,
} from "../../../components/orders/orders-table.constants";

function Menu({
  id,
  isMenuOpen,
  onCloseMenu,
  onClickMenuItem,
  translationKey,
  t,
  anchorEl,
  items,
  theme,
  dir,
  currentSelectedOrders,
}) {
  // remove all actions except for delete if sub_status = missing order
  const isMissingArea = currentSelectedOrders
    ? currentSelectedOrders[0]?.subStatus === MISSING_AREA_SUBSTATUS
    : false;
  if (isMissingArea) {
    items = items.filter(({ key }) => key === ORDERS_ACTIONS_IDS.DELETE);
  }

  const onSelectingMenuItem = (key, onClick) => {
    if (typeof onClickMenuItem === "function") onClickMenuItem(key);
    if (typeof onClick === "function")  onClick();
  };
  return (
    <Styled.Menu
      id={id}
      open={isMenuOpen}
      onClose={onCloseMenu}
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      dir={dir}
    >
      {items.map(
        ({
          key,
          onClick,
          icon: Icon,
          hasBottomDivider,
          component: Component,
          textVariant = "semiBoldSmall",
          renderComponent = false,
          hasTransparentBackground = false,
        }) => (
          <Styled.MenuItem
            key={key}
            onClick={() => onSelectingMenuItem(key, onClick)}
            hasTransparentBackground={hasTransparentBackground}
            disableRipple
            sx={{
              borderBottom: hasBottomDivider
                ? `1px solid ${theme.palette.lightGreyOutline}`
                : "none",
            }}
          >
            {Icon && (
              <Icon stroke={theme.palette.black} color={theme.palette.black} />
            )}
            {renderComponent ? (
              <Component />
            ) : (
              <Typography
                variant={textVariant}
                color={theme.palette.text.primary}
              >
                {t(`${translationKey}.${key}`)}
              </Typography>
            )}
          </Styled.MenuItem>
        )
      )}
    </Styled.Menu>
  );
}

export default Menu;
