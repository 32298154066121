import { styled, MenuItem as MuiMenuItem, Menu as MuiMenu, alpha } from "@mui/material";

const MenuItem = styled(MuiMenuItem) (({ hasTransparentBackground }) => ({
  gap: 4,
  padding: '8px 12px',
  alignItems: 'center',
  "&.MuiMenuItem-root": {
    "&:focus, &:hover" : {
      background: (hasTransparentBackground? "transparent": "auto")
    }
  }
}));


const Menu = styled(MuiMenu)(({ theme: { palette, spacing } }) => ({
  "& .MuiPaper-root": {
    borderRadius: '8px',
    marginTop: spacing(1),
    minWidth: 180,
    color: palette.header.headerMenu.color,
    border: palette.header.headerMenu.border,
    boxShadow: palette.header.headerMenu.boxShadow,
    padding: 0,
    "& .MuiMenu-list": {
      padding: 0,
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 14,
        color: palette.text.secondary,
        marginRight: spacing(1),
      },
      "&:active": {
        backgroundColor: alpha( palette.primary.main, palette.action.selectedOpacity)
      },
    },
  },
}));

const Styled = {
  MenuItem,
  Menu
}

export default Styled