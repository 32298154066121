/* eslint-disable react/jsx-filename-extension */
import React from "react";
import { Box, Typography } from "@mui/material";
import Button from "../../common/components/button";

import DateTimeLabel from "../../common/components/date-time-label";
import SuggestedArea from "./components/suggested-area";

const getCellsContent = (
  order,
  t,
  theme,
  resolvedOrders,
  setResolvedOrders,
  areaOptions
) => {
  const {
    code,
    createdAt,
    customerName,
    amount,
    city,
    area,
    address_line,
    suggestedArea,
    suggestedAreaConfidence = undefined,
  } = order;

  return [
    {
      id: "code",
      title: code,
      details: <DateTimeLabel dateTime={createdAt} theme={theme} />,
      titleVariant: "regularWithUnderline",
    },
    {
      id: "details",
      title: customerName,
      details: amount
        ? `${t("mutualizedEgpLabel")} ${+amount?.toFixed(2)}`
        : t("listing.labels.withoutCash"),
    },
    {
      id: "address",
      title: `${address_line ? `${address_line}, ` : ""} ${
        area ? `${area}, ` : ""
      }${city ?? ""}`,
      details: "",
    },
    {
      id: "suggestedArea",
      title: (
        <SuggestedArea
          confidence={suggestedAreaConfidence}
          address={{ area, city, suggestedArea }}
          theme={theme}
          placeholder={t("actionCenter.suggestedArea.placeholder")}
          name={"customerDetail.area"}
          order={order}
          areaOptions={areaOptions}
          resolvedOrders={resolvedOrders}
          setResolvedOrders={setResolvedOrders}
        />
      ),
    },
  ];
};

const deleteOrderDialogActions = (setDeleteOrderDialog, deleteOrders, t) => [
  {
    key: "close",
    content: (
      <Button
        color="secondary"
        onClick={() => setDeleteOrderDialog(false)}
        label={t("listing.deleteModal.noButton")}
        size={{ width: 156, height: 36 }}
      />
    ),
  },
  {
    key: "delete",
    content: (
      <Button
        onClick={() => {
          deleteOrders();
        }}
        label={t("listing.deleteModal.deleteButton")}
        size={{ width: 156, height: 36 }}
      />
    ),
  },
];

const clearOrderDialogActions = (setClearOrderDialog, clearOrders, t) => [
  {
    key: "close",
    content: (
      <Button
        color="secondary"
        onClick={() => setClearOrderDialog(false)}
        label={t("actionCenter.clearModal.cancelButton")}
        size={{ width: 156, height: 36 }}
      />
    ),
  },
  {
    key: "clear",
    content: (
      <Button
        onClick={() => {
          clearOrders();
        }}
        label={t("actionCenter.clearModal.clearButton")}
        size={{ width: 156, height: 36 }}
      />
    ),
  },
];

const ClearOrderContent = ({
  description,
  actionButtons,
  isError,
  isMobileView,
}) => {
  return (
    <Box
      width="100%"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      gap="24px"
      padding="16px 15px 0px 15px"
      marginBottom="24px"
    >
      <Typography
        width="100%"
        variant="mediumText"
        textAlign={isMobileView ? "left" : "center"}
      >
        {description}
      </Typography>
      <Box width="100%" display={"flex"} justifyContent={"center"} gap={"10px"}>
        {actionButtons.map(
          ({ key, content }) =>
            !(key === "clear" && isError) && (
              <Box key={key} width={isMobileView ? "100%" : "156px"}>
                {content}
              </Box>
            )
        )}
      </Box>
    </Box>
  );
};

export {
  getCellsContent,
  deleteOrderDialogActions,
  clearOrderDialogActions,
  ClearOrderContent,
};
