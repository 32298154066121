import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

//SLICE FOR SHARED APIS BETWEEN COMPONENTS
const sliceName = "common";

export const fetchAreas = createAsyncThunk(
  `${sliceName}/fetchAreas`,
  async (data, thunkAPI) => {
    const response = await thunkAPI.extra.apiClient.get({ url: "config/gov" });

    return {
      id: "areas",
      type: "allAreas",
      data: response.data.body.governorates.map((record) => {
        return {
          name: record.name,
          name_ar: record.name_ar,
          arabicAreas: record?.areas?.map(({ name_ar }) => name_ar),
          englishAreas: record?.areas?.map(({ name_en }) => name_en),
        };
      }),
    };
  }
);

export const deleteOrdersAction = createAsyncThunk(
  `${sliceName}/deleteOrdersAction`,
  async (parameterObject, thunkAPI) => {
    const response = await thunkAPI.extra.apiClient.put({
      url: "orders/status/cancelled",
      data: parameterObject.isSelectAll
        ? parameterObject
        : { ids: parameterObject.selectedOrderIds },
      isJsonContent: true,
      logErrorMsg: true,
      errorMsg: "Failed to delete order",
      successMsg: parameterObject.isSelectAll
        ? "Orders deleted successfully"
        : parameterObject.selectedOrderIds.length === 1
          ? `Order ${parameterObject.selectedOrderCodes[0]} deleted successfully`
          : `${parameterObject.selectedOrderIds.length} orders deleted successfully`,
    });
    return response;
  }
);

export const fetchOrdersCount = createAsyncThunk(
  `${sliceName}/fetchOrdersCount`,
  async ({ filters = {}, updateCount = true }, thunkAPI) => {
    const response = await thunkAPI.extra.apiClient.post({
      url: "orders-summary",
      data: { filters: { type: "Delivery", ...filters } },
    });
    return { count: response?.data?.body || [], updateCount };
  }
);

export const fetchCouriersList = createAsyncThunk(
  `${sliceName}/fetchCouriersList`,
  async (_, thunkAPI) => {
    const response = await thunkAPI.extra.apiClient.get({
      url: "orders/couriers/list",
    });
    return response;
  }
);

export const commonSlice = createSlice({
  name: sliceName,
  initialState: {
    ordersCount: null,
    authData: {},
    appDirection: "ltr",
    areas: [],
  },
  reducers: {
    setNewAuthData: (state, action) => {
      state.authData = action.payload;
    },
    setGlobalAppDirection: (state, action) => {
      state.appDirection = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAreas.fulfilled, (state, action) => ({
        ...state,
        areas: action.payload.data,
      }))

      .addCase(fetchOrdersCount.fulfilled, (state, action) => ({
        ...state,
        ...(action.payload.updateCount && {
          ordersCount: action.payload.count,
        }),
        ...action.payload,
      }));
  },
});

export const { setNewAuthData, setGlobalAppDirection } = commonSlice.actions;

export const selectOrdersCount = (state) => state[sliceName].ordersCount;
export const selectAuthData = (state) => state[sliceName].authData;
export const selectAppDirection = (state) => state[sliceName].appDirection;
export const selectAreas = (state) => state[sliceName].areas;

export default commonSlice.reducer;
