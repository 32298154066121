import React from "react";

const UnCheckedCircleIcon = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="7" cy="7" r="6" stroke="#7F7D80" />
    </svg>
  );
};

export default UnCheckedCircleIcon;
