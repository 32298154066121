import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

//SLICE NAMES CANNOT BE DUPLICATES!
const sliceName = "bulkCreateOrders";

export const validateOrder = createAsyncThunk(`${sliceName}/addPickupLocation`, async ({ data, index, signal }, thunkAPI) => {
  const response = await thunkAPI.extra.apiClient.post({
    url: 'orders/bulk/validate',
    //TODO: translate
    errorMsg: `Failed to retrieve validation data for row ${index + 1}, please update your data and try again`,
    config: { signal },
    data});
  return response;
})

export const createOrder = createAsyncThunk(`${sliceName}/createBulkOrder`, async ({ data, signal }, thunkAPI) => {
  const response = await thunkAPI.extra.apiClient.post({ url: 'orders/bulk/create', data, config: { signal },
  });
  return response;
})


export const bulkCreateOrdersSlice = createSlice({
  name: sliceName,
  initialState: {},
  reducers: {},
  extraReducers: () => {},
},);


export default bulkCreateOrdersSlice.reducer;