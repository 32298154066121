import React, { useMemo } from "react";
import { Typography, Box } from "@mui/material";

import Styled from "./first-attempt-sla.styled";
import { CircleIcon } from "../../../../../../assets/icons";
import { BREACH_STATUS_SEVERITY_COLORS } from "./first-attempt-sla.constants";
import { getStatusSeverity } from "./first-attempt-sla.utils";
import FirstAttemptSLASkeleton from "./first-attempt-sla-skeleton";
import { capitalizeFirstLetterOfEachWord } from "../../../../analytics.utils";
const SectionCards = ({ data, isLoading }) => {
  if (isLoading) return <FirstAttemptSLASkeleton />;

  const sectionsData = useMemo(
    () =>
      data?.length ? data : Array(5).fill({ section: "", percentage: null }),
    [data]
  );

  const getLabel = (section) =>
    capitalizeFirstLetterOfEachWord(section)
  const getFillColor = (percentage) =>
    BREACH_STATUS_SEVERITY_COLORS[getStatusSeverity(percentage)];

  return sectionsData.map((data, index) => {
    const { percentage = 0, section = "" } = data || {};

    return (
      <Styled.Sections key={section ?? index}>
        <Typography
          ml={2}
          justifySelf="start"
          alignSelf="center"
          variant="boldSubtitles"
        >
          {`${index + 1}. ${getLabel(section)}`}
        </Typography>

        <Typography
          mr={2}
          justifySelf={"end"}
          alignSelf={"center"}
          variant={"boldSubtitles"}
        >
          <Box display="flex" alignItems="center" gap="2px">
            {`${percentage ?? 0}%`}
            <CircleIcon fill={getFillColor(percentage)} />
          </Box>
        </Typography>
      </Styled.Sections>
    );
  });
};

export default SectionCards;
