/* eslint-disable max-len */
import React from "react"

const NotificationIcon = ({ iconOuterStroke = {iconOuterStroke}, iconInnerStroke="#F4F4F4" }) => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.07692 0.5H12.9231C13.1859 0.5 13.5 0.76181 13.5 1.21739V12.7826C13.5 13.2382 13.1859 13.5 12.9231 13.5H1.07692C0.81415 13.5 0.5 13.2382 0.5 12.7826V1.21739C0.5 0.76181 0.81415 0.5 1.07692 0.5Z" fill={iconOuterStroke} stroke={iconOuterStroke} strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M3 4H11" stroke={iconInnerStroke} strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M3 7H11" stroke={iconInnerStroke} strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M3 10H7" stroke={iconInnerStroke} strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
)

export default NotificationIcon