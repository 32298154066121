/* eslint-disable max-len */
import React, { useMemo } from "react";
import { Box, Typography, useTheme } from "@mui/material";

import Styled from "../order-details.styled";
import { AddressIcon, AmountIcon, CanNotOpenIcon, CourierIcon, CourierRotateIcon, DescriptionIcon, PhoneIcon, PrintLabelIcon, SingleOrderIcon, SuccessRateIcon, } from "../../../assets/icons";
import { toDaysAndHoursStr } from '../../../utils/convertMinutedToString'
import { useTranslation } from "react-i18next";

export function DetailsTab({ orderDetails }) {
  const {
    externalId,
    totalFees,
    refNumber,
    orderDetails: { type: orderType, packageType: { en: packageType }, amount, noOfItems, description, openPackageAllowed, location: {name: courierLocation} },
    customerDetails: {name: customerName, phone, shippingNotes, backupPhone, address: { city, area, addressLine }},
    courier: { name: courierName, estimated_shipping_minutes: estShipping, estimated_return_minutes: estReturning, success_rate: successRate }
  } = orderDetails;

  const theme = useTheme();
  const { t } = useTranslation()

  const orderSectionI18nKey = 'listing.orderDetails.detailsTab.orderSection'
  const customerSectionI18nKey = 'listing.orderDetails.detailsTab.customerSection'
  const courierSectionI18nKey = 'listing.orderDetails.detailsTab.courierSection'

  const orderSection = [
    { key: 'amount', icon: AmountIcon, description: `${amount? `${t('mutualizedEgpLabel')} ${+amount?.toFixed(2)}`: t(`${orderSectionI18nKey}.withoutCashLabel`)}`, subDescription: "" },
    { key: 'packageType', icon: SingleOrderIcon, description: packageType, subDescription: "" },
    { key: 'items', icon: DescriptionIcon, description: `${noOfItems> 0? `${noOfItems} ${t(`${orderSectionI18nKey}.itemsLabel`)} ${description && `: ${description}` }`: ''}`, subDescription: "" },
    { key: 'package open', icon: CanNotOpenIcon, description: `${openPackageAllowed ? t(`${orderSectionI18nKey}.openPackageAllowedLabel`) : t(`${orderSectionI18nKey}.openPackageNotAllowedLabel`)}`, subDescription: `${openPackageAllowed ? t(`${orderSectionI18nKey}.notInsuredLabel`) : ""}` },
  ];

  const customerSection = [
    { key: 'phone', icon: PhoneIcon, description: `${phone} ${backupPhone && `- ${backupPhone}`}`, subDescription: "" },
    { key: 'address', icon: AddressIcon, description: `${addressLine}, ${area}, ${city}`, subDescription: "" },
    { key: 'note', icon: DescriptionIcon, description: `${shippingNotes? `"${shippingNotes}"`:''}`, subDescription: "" },
    { key: 'ref', icon: SingleOrderIcon, description: refNumber, subDescription: "" },
  ];

  const courierSection = [
    { key: 'trackingNo', icon: PrintLabelIcon, description: externalId, subDescription: "" },
    { key: 'office', icon: AddressIcon, description: courierLocation, subDescription: "" },
    { key: 'est shipping', icon: CourierIcon, description: `${toDaysAndHoursStr(estShipping)} ${t(`${courierSectionI18nKey}.estShippingLabel`)}`, subDescription: "" },
    { key: 'est returning', icon: CourierRotateIcon, description: `${toDaysAndHoursStr(estReturning)} ${t(`${courierSectionI18nKey}.estReturnLabel`)}`, subDescription: "" },
    { key: 'success rate', icon: SuccessRateIcon, description: `${successRate*100}% ${t(`${courierSectionI18nKey}.successRateLabel`)}`, subDescription: "" },
    { key: 'fees', icon: AmountIcon, description: `${t('mutualizedEgpLabel')} ${+totalFees?.toFixed(2)}`, subDescription: "" },
  ];

  const sections = useMemo(() => [
    { title: `${t(`${orderSectionI18nKey}.title`)} - ${orderType}`, component: orderSection },
    { title: `${t(`${customerSectionI18nKey}.title`)} - ${customerName}`, component: customerSection },
    { title: `${t(`${courierSectionI18nKey}.title`)} - ${courierName}`, component: courierSection, isHidden: !courierName},
  ], [orderDetails])

  const renderTitle = (title) => {
    return <Box width={'100%'} padding={'12px 24px'}><Typography variant="boldText" textTransform={'capitalize'}>{title}</Typography></Box>;
  };

  const renderContent = (Icon, description, subDescription) => {
    return (
      description ? (
        <Box display={"flex"} gap={"8px"} alignItems="flex-start" padding={'0px 24px'}>

          <Box display={"flex"} maxWidth={'20px'} padding={'1px'}>
            <Icon width="20" height="20" color={theme.palette.black} />
          </Box>
          <Box display={"flex"} gap={1}>
            <Typography variant="regular" textTransform={'capitalize'}>{description}</Typography>
            {subDescription? (<Typography variant="regular" textTransform={'capitalize'} color={theme.palette.gray}>({subDescription})</Typography>): null }
          </Box>
        </Box>
      ) : null
    );
  };

  const renderSection = ({ title, component }) => {
    return (
      <Box display={'flex'} flexDirection={'column'}>
        {title ? renderTitle(title) : null}
        <Box display={'flex'} flexDirection={'column'} gap={'8px'} width={"100%"} bgcolor={theme.palette.white} padding={'8px 0px 14px 0px'}>
          {component.map(({ key, icon: Icon, description, subDescription }) => <Box key={key}>{renderContent(Icon, description, subDescription)}</Box> )}
        </Box>
      </Box>
    );
  };

  return (
    <Styled.TabContainer>
      {sections.filter(({ isHidden }) => !isHidden).map(({ title, component }) => (
        <Box key={title}>{renderSection({ title, component })}</Box>
      ))}
    </Styled.TabContainer>
  );
}
