const progressLineValues = {
  green: { key: "green", value: 30 },
  orange: { key: "orange", value: 60 },
  red: { key: "red", value: 100 },
};

export const getProgressLineValueAndColor = (color) => {
  if (color?.includes(progressLineValues.red.key)) {
    return progressLineValues.red.value;
  } else if (color?.includes(progressLineValues.orange.key)) {
    return progressLineValues.orange.value;
  } else {
    return progressLineValues.green.value;
  }
};
