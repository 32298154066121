import React from "react";
import { Tooltip, styled, tooltipClasses } from "@mui/material";

export const BlackTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme, isError }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette[isError? 'red': 'black'],
  },
  [`& .${tooltipClasses.tooltip}`]: {
    ...(theme.typography.boldSmall),
    backgroundColor: theme.palette[isError? 'red': 'black'],
  },
}));