import React, { useMemo } from "react";
import { Box, Typography } from "@mui/material";

import Styled from "./unsuccessful-reason.styled";
import RejectionReasonSkeleton from "./skeleton";
import { isLastRow } from "./unsuccessful-reason.const";
import { capitalizeFirstLetterOfEachWord } from "../../../../analytics.utils";

const RejectionReasonTable = ({ data, theme, t, isLoading }) => {
  if (isLoading)
    return (
      <Styled.TableWrapper>
        <RejectionReasonSkeleton />
      </Styled.TableWrapper>
    );

  const rejectionReasons = useMemo(
    () => data ?? Array(6).fill({ reason: "", count: null, percentage: 0 }),
    [data]
  );

  const renderLabel = (label, variant = "boldSubtitles") => (
    <Typography
      variant={variant}
      color={theme.palette.text.primary}
      sx={{ mt: 2, mb: 2, pl: 1 }}
    >
      {label}
    </Typography>
  );

  const getCountLabel = (count) => {
    return t("analytics.unsuccessfulReasons.ordersCount", { count });
  };

  return (
    <Styled.TableWrapper>
      {rejectionReasons.map((row, i) => (
        <Styled.RejectionReasonWrapper
          key={row.reason}
          isLastRow={isLastRow(i, data?.length ?? 6)}
        >
          <Box sx={{ flexGrow: 1 }}>
            {renderLabel(
              `${i + 1}. ${capitalizeFirstLetterOfEachWord(row.reason)}`
            )}
            {renderLabel(getCountLabel(row.count), "mediumText")}
          </Box>

          <Box textAlign="right">{renderLabel(`${row.percentage}%`)}</Box>
        </Styled.RejectionReasonWrapper>
      ))}
    </Styled.TableWrapper>
  );
};

export default RejectionReasonTable;
