import { styled, IconButton as MuiIconButton, ButtonBase as MuiButtonBase, Divider as MuiDivider, Typography } from "@mui/material";
import BarcodeComponent from "react-barcode";

const PrintViewWrapper = styled('div')({
  backgroundColor: 'rgb(0,0,0,0.8)',
  position: 'absolute',
  top: 0,
  left: 0,
  zIndex: 3,
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  height: '100%'
})

const HeaderBar = styled('div', {
  shouldForwardProp: (prop) => prop !== "isMobileView"
})(({ isMobileView }) => ({
  height: 80,
  display: 'flex',
  paddingLeft: 15,
  justifyContent: isMobileView ? 'left' : 'center',
  alignItems: 'center'
}))

const ButtonsWrapper = styled('div')({
  display: 'flex',
  gap: 20
})

const ButtonBase = styled(MuiButtonBase, {
  shouldForwardProp: (prop) => prop !== "selected"
})(({ theme: { palette, typography }, selected }) => ({
  color: selected ? palette.white : palette.gray,
  ...(selected && { textDecoration: 'underline' }),
  ...typography.boldMedium
}))

const HeaderRightBlock = styled('div', {
  shouldForwardProp: (prop) => prop !== "isMobileView"
})(({ isMobileView }) => ({
  position: 'absolute',
  display: 'flex',
  gap: isMobileView ? 12 : 24,
  right: isMobileView ? 15 : 48
}))

const IconButton = styled(MuiIconButton)({
  padding: 0
})

const PrintPaperWrapper = styled('div')({
  maxWidth: "747px",
  width: "100%",
  padding: "15px 25px",
  backgroundColor: "white",
  "@media print": {
    marginTop: '4vh',
    minHeight: '40vh !important',
    width: '100% !important',
    padding: '0.2cm 0.6cm 0 0.6cm'
  }
})

const PrintLabelsWrapper = styled('div')(({ theme: { palette }, isPaperView }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: 'calc(100% - 80px)',
  gap: '14px',
  "::-webkit-scrollbar": { backgroundColor: 'transparent' },
  "::-webkit-scrollbar-thumb": { backgroundColor: palette.gray, borderRadius: 25, },
  overflow: 'auto',
  alignItems: 'center',
  breakAfter: "page",
  "@media print": {
    gap: isPaperView ? '1vh !important' : 0,
    "@page" : {
      marginLeft: 0,
      marginRight: 0,
      marginTop: 0,
      marginBottom: 0
    }}
}))

const PrintThermalLabelWrapper = styled('div')(({ theme: { palette } }) => ({
  width: 300,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  backgroundColor: palette.white,
  "@media print": {
    minHeight: '100vh !important',
    width: '100% !important',
  }
}))

const FincartImage = styled('img', {
  shouldForwardProp: (prop) => prop !== "width" && prop !== "height" && prop !== "isPaperView"
})(({ width = 85, height = 23, isPaperView }) => ({
  width: `${width}px`,
  height: `${height}px`,
  ...(!isPaperView && { "@media print": { width: 190, height: 29 } })
}))

const Barcode = styled(BarcodeComponent)({
  width: 160,
  height: 80,
  "@media print": { width: 400, height: 180, paddingTop: 15, paddingBottom: 20, paddingRight: 15}
})

const Divider = styled(MuiDivider)(({ theme: { palette } }) => ({
  backgroundColor: palette.black,
}))

const PaperPackageInfoWrapper = styled('div')({
  borderLeft: `1px solid rgba(0, 0, 0, 0.12);`,
  display: 'flex',
  flexDirection: 'column',
  width: '50%'
})

const PrintThermalInfo = styled('div', {
  shouldForwardProp: (prop) => prop !== "minHeight" && prop !== "minHeightPrint" && prop !== "noBottomBorder"
})(({ minHeight = 120, minHeightPrint = 15, noBottomBorder = false}) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'start',
  padding: '10px',
  minHeight: `${minHeight}px`,
  ...!noBottomBorder && {borderBottom: `1px solid black`},
  "@media print": {
    minHeight: `${minHeightPrint}vh`,
    ...!noBottomBorder && {borderBottom: `2px solid black`},
  }
}))

const CodLabel = styled(Typography)(({ theme: { typography } }) => ({
  ...typography.boldLarge,
  "@media print": {
    paddingTop: '15px',
    lineHeight: '60px',
    fontSize: '60px',
  },
}))

const ThermalFooter = styled('div')({
  height: '10%',
  borderTop: '1px solid black',
  "@media print": {
    borderTop: '2px solid black',
  },
})

const FooterLabel = styled(Typography)(({ theme: { typography } }) => ({
  ...typography.semiBoldSmall,
  "@media print": {
    padding: '15px 10px',
    lineHeight: '30x',
    fontSize: '30px',
  },
}))

const PrintThermalBoldLabel = styled(Typography)(({ theme: { typography } }) => ({
  ...typography.boldMedium,
  "@media print": {
    padding: '0 10px',
    lineHeight: '40px',
    fontSize: '30px',
  },
}))

const PrintThermalRegularLabel = styled(Typography,  {
  shouldForwardProp: (prop) => prop !== "maxWidth"
})(({ theme: { typography }, maxWidth }) => ({
  ...typography.regularResizable,
  ...(maxWidth && { maxWidth }),
  overflowWrap: 'break-word',
  "@media print": {
    paddingLeft: 10,
    // paddingBottom: '20px',
    lineHeight: '40px',
    fontSize: '30px',
  },
}))

const Styled = {
  PrintViewWrapper, HeaderBar, HeaderRightBlock, IconButton, PrintThermalInfo, PrintThermalBoldLabel, PrintPaperWrapper, Barcode, CodLabel, PrintThermalRegularLabel, ThermalFooter,
  ButtonsWrapper, ButtonBase, PrintThermalLabelWrapper, PrintLabelsWrapper, PaperPackageInfoWrapper, FincartImage, Divider, FooterLabel
}

export default Styled