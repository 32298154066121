/* eslint-disable max-len */
import React from "react"

const FiltersIcon = ({ color = "#2A272B" }) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2.61538 4.23077C3.50754 4.23077 4.23077 3.50754 4.23077 2.61538C4.23077 1.72323 3.50754 1 2.61538 1C1.72323 1 1 1.72323 1 2.61538C1 3.50754 1.72323 4.23077 2.61538 4.23077Z" stroke={color} strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M4.23071 2.61548H14.9999" stroke={color} strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M8.00003 9.61547C8.89218 9.61547 9.61541 8.89224 9.61541 8.00009C9.61541 7.10794 8.89218 6.3847 8.00003 6.3847C7.10788 6.3847 6.38464 7.10794 6.38464 8.00009C6.38464 8.89224 7.10788 9.61547 8.00003 9.61547Z" stroke={color} strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M1 7.99988H6.38462" stroke={color} strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M9.61536 7.99988H15" stroke={color} strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M13.3845 14.9999C14.2767 14.9999 14.9999 14.2766 14.9999 13.3845C14.9999 12.4923 14.2767 11.7691 13.3845 11.7691C12.4924 11.7691 11.7692 12.4923 11.7692 13.3845C11.7692 14.2766 12.4924 14.9999 13.3845 14.9999Z" stroke={color} strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M11.7692 13.3846H1" stroke={color} strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
)

export default FiltersIcon