import { styled } from '@mui/material/styles';
import { TextField, Input as MuiInput } from '@mui/material';

const FormSection = styled('div')({
  display: 'flex',
  alignItems: 'center',
  flexWrap: 'wrap',
})

const Input = styled(MuiInput, {
  shouldForwardProp: (prop) => (prop !== "error" && prop !== '$isDisabled'),
})(({ theme: { palette, typography }, error, $isDisabled }) => ({
  ...typography.regular,
  opacity: $isDisabled ? 0.5: 1,
  padding: '8px 12px',
  borderRadius: '8px',
  border: error ? `1px solid ${palette.red}` : `1px solid  ${palette.greyBorder}`,
  display: 'flex',
  gap: 4,
  height: 36,
  "&:hover" : {
    border: `1px solid ${palette.black}`
  },
  ":focus-within": {
    border: `1px solid ${palette.black}`
  },
  '.MuiInput-input': {
    borderRadius: '8px',
    border: 'none',
    width : '100%',
    ':-webkit-autofill': {
      '-webkit-box-shadow':'0 0 0 50px white inset',
      '-webkit-text-fill-color': '#333'
    },
    '&:disabled': { backgroundColor: palette.white },
    '&:focus-visible' : {
      outline: 'none'
    },
    ...typography.regular,
  },
}))

const TextAreaWrapper = styled('div', {
  shouldForwardProp: (prop) => (prop !== "error"),
}) (({ theme: { palette }, error }) => ({
  display: 'flex',
  flexDirection: 'row',
  padding: 'none',
  borderRadius: '8px',
  minHeight: '58px',
  border: `1px solid ${error? palette.red : palette.greyBorder}`,
  ":-webkit-autofill": {
    '-webkit-box-shadow':'0 0 0 50px white inset',
    '-webkit-text-fill-color': '#333'
  },
  ":focus-within": {
    borderColor: `${palette.blackBorder}`,
    outline: 'none'
  },
  '& .MuiOutlinedInput-root ': {
    padding: '8px', border: 'none'
  },
  "& fieldset": { border: 'none' }
}));


const Field = styled('div')(({ fullWidth }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 4,
  ...(fullWidth && {width: '100%'}),
}));

const MultiLineTextField = styled(TextField)(({ theme: { typography } }) => ({
  '.MuiOutlinedInput-root': {
    ...(typography.regular),
    display: 'flex',
    alignItems: 'flex-end',
    "textarea:-webkit-autofill": {
      '-webkit-box-shadow':'0 0 0 50px white inset',
      '-webkit-text-fill-color': '#333'
    },
  },
}))

const Styled = {
  Field,
  FormSection,
  Input,
  TextAreaWrapper,
  MultiLineTextField,
}

export default Styled