/* eslint-disable react/no-array-index-key */
import React from "react";
import { Box, Typography } from "@mui/material";
import TopCitiesStyled from "./top-cities.styled";
import TopCitiesSkeleton from "./top-cities-skeleton";

const sections = ({ data, isLoading, isSecondColumn }) => {
  if (isLoading) return <TopCitiesSkeleton />;

  const getLabel = (type, label, alignment, isSecondColumn, index) => (
    <Typography
      {...(alignment === "start" && { ml: 2 })}
      {...(alignment === "end" && { mr: 2 })}
      justifySelf={alignment}
      alignSelf={"center"}
      variant={"boldSubtitles"}
    >
      {type === "city"
        ? `${isSecondColumn ? index + 6 : index + 1}. ${label}`
        : label}
    </Typography>
  );

  return (
    <Box>
      {data.map((city, index) => (
        <TopCitiesStyled.Sections key={index}>
          {getLabel("city", city?.city ?? "", "start", isSecondColumn, index)}
          {getLabel(
            "percentage",
            `${city?.percentage ?? 0}%`,
            "end",
            isSecondColumn,
            index
          )}
        </TopCitiesStyled.Sections>
      ))}
    </Box>
  );
};

export default sections;
