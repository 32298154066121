import React from 'react'
import Select  from 'react-select'

import { ArrowIcon } from '../../../assets/icons'

const CustomSelectField = ({ activeRef, theme, columnData, focus, options, rowData, setRowData, stopEditing }) => {
  return (
    <Select
      ref={activeRef}
      closeMenuOnScroll={true}
      components={
        {
          DropdownIndicator: ({ selectProps: { menuIsOpen } }) => <ArrowIcon isDown={!menuIsOpen} color={theme.palette.gray} size={20} />
        }
      }
      placeholder=''
      styles={{
        container: (provided) => ({
          ...provided,
          flex: 1,
          alignSelf: 'stretch',
          width: '100%',
          pointerEvents: focus ? undefined : 'none',
          ...(theme.typography.regular),
        }),
        menu: (provided) => ({
          ...provided,
          width: '100%',
          boxShadow: '0px 2px 16px rgba(42, 39, 43, 0.08)',
          zIndex: 5,
          borderRadius: "10px",
          overflow: "hidden",
        }),
        menuList: (provided) => ({
          ...provided,
          paddingBlock: "0px",
          zIndex: '100000000000000',
        }),
        control: (provided) => ({
          ...provided,
          height: '100%',
          border: 'none',
          boxShadow: 'none',
          background: 'none',
          paddingRight: '8px',
        }),
        option: (provided, props) => ({
          ...provided,
          width: '100%',
          ":hover": {
            backgroundColor: theme.palette.lightGreyOutline,
            cursor: "pointer"
          },
          borderBottom: `1px solid ${theme.palette.lightGreyOutline}`,
          ...(theme.typography.regular),
          ...(props?.isSelected && { backgroundColor: 'transparent', fontWeight: 700, color: 'black' }),
        }),
        indicatorSeparator: (provided) => ({
          ...provided,
          opacity: 0,
        }),
      }}
      isDisabled={columnData.disabled}
      value={options?.find(({ value }) => value === rowData.value) ?? null}
      menuIsOpen={focus}
      menuPortalTarget={document.body}
      onChange={(choice) => {
        if (choice === null) return;

        setRowData({ ...rowData, value: choice.value });
        setTimeout(stopEditing, 0);
      }}
      onMenuClose={() => stopEditing({ nextRow: false })}
      options={options}
    />
  )
}

export default CustomSelectField