import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useTheme, Box, CircularProgress } from "@mui/material";

import { Container } from "./components/container";
import { getOrderDetails, getOrderLogs } from "./order-details.slice";
import { useTranslation } from "react-i18next";

export function OrderDetails({ orderId, onCloseOrderDetails }) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [orderDetails, setOrderDetails] = useState({});
  const [orderLogs, setOrderLogs] = useState(null);

  useEffect(() => {
    setIsLoading(true);
    let promises = [
      dispatch(getOrderLogs(orderId)),
      dispatch(getOrderDetails(orderId)),
    ];
    Promise.all(promises).then((results) => {
      results.forEach((res) => {
        if (res.type.includes("fetchOrderLogs")) {
          if (res?.payload) setOrderLogs(res.payload);
        }
        if (res.type.includes("fetchOrderDetails")) {
          const orderDetails = res?.payload?.data?.body?.order || {};
          if (Object.keys(orderDetails)?.length === 0) {
            onCloseOrderDetails();
          } else {
            setOrderDetails(res?.payload?.data?.body?.order || {});
          }
        }
      });
      setIsLoading(false);
    });
  }, []);

  return (
    <Box width="100%" height="100%" overflow="auto">
      {isLoading ? (
        <Box
          width={"100%"}
          height={"100%"}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <CircularProgress color="secondary" />
        </Box>
      ) : (
        Object.keys(orderDetails)?.length > 0 && (
          <Container
            t={t}
            orderDetails={orderDetails}
            orderLogs={orderLogs}
            theme={theme}
            onCloseOrderDetails={onCloseOrderDetails}
          />
        )
      )}
    </Box>
  );
}
