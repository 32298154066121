/* eslint-disable max-len */
import React from "react";

const AnalyticsIcon = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="4" height="18" rx="2" fill="white" />
    <rect x="7" y="6" width="4" height="12" rx="2" fill="white" />
    <rect x="14" y="10" width="4" height="8" rx="2" fill="white" />
  </svg>
);

export default AnalyticsIcon;
