import React, { useEffect, useRef, useState } from "react";
import Highlighter from "react-highlight-words";
import { components } from "react-select";
import { Box, Typography, IconButton } from "@mui/material";
import { Autorenew } from "@mui/icons-material";
import * as Collapsible from "@radix-ui/react-collapsible";

import { Select } from "./components/select/select";
import Styled from "./area-select.styled";
import useAdaptAreas from "../../hooks/use-adapt-areas";
import { LargeArrowDownIcon, LargeArrowUpIcon } from "../../../assets/icons";

export function AreaSelect({
  props,
  options,
  setValue,
  name,
  value,
  hasError,
  placeholder,
  noPlaceholder,
  noBorders,
  noLeftPadding,
  isSpecialMerchant,
  isRefreshButtonDisabled,
  hideRefreshIcon,
  validateAreaField,
  isOrderValidated,
  hasGreyIcon,
  height,
}) {
  const [isValidatingField, setIsValidatingField] = useState(false)

  const mapOptions = (options, lang) => {
    if(isSpecialMerchant && !isOrderValidated) {
      const optionLabel = lang === 'en'? 'name': 'name_ar'
      return options?.map((option) => ({label: option[optionLabel], value: option[optionLabel]}))
    }

    const optionLabel = lang === "en" ? "name" : "name_ar";
    const optionsType = lang === "en" ? "englishAreas" : "arabicAreas";
    return options?.map((option) => ({
      label: option[optionLabel],
      options: option[optionsType]?.map((city) => ({
        label: city,
        value: city,
        group: option[optionLabel],
      })),
    }));
  };

  const handleValidateField = () => {
    setIsValidatingField(true)
    validateAreaField().then(() => setIsValidatingField(false))
  }

  const { filterOptions, onInput, selectOptions, inputLanguage, } =
    useAdaptAreas({ mapOptionsCbk: mapOptions, options, isOrderValidated, value: value.label });

  const prepareOptionLabel = (option, meta) => {
    if (meta.context === "value")
      return (
        <Box display="flex" gap="4px" paddingRight="12px" flexDirection={inputLanguage === 'ar'? 'row-reverse': 'row'}>
          <Typography variant="regular">
            {option.group}
          </Typography>{" "}
          <Typography
            variant="regular"
            color={isSpecialMerchant && !isOrderValidated? 'black': 'grey'}
          >
            {option.label}
          </Typography>
        </Box>
      );

    if (meta.context === "menu" && meta.inputValue.length > 2)
      return (
        <Highlighter
          searchWords={[meta.inputValue]}
          textToHighlight={option.label}
          highlightClassName="font-bold bg-transparent"
          activeStyle={{ background: "red" }}
          highlightStyle={{ background: "transparent", fontWeight: 700 }}
        />
      );

    return option.label;
  };

  return (
    <Box display="flex" gap="4px" flex="1" alignSelf="stretch">
      <Select
        onChange={(value) => setValue(name, value)}
        hasGreyIcon={hasGreyIcon}
        value={value}
        hasError={hasError}
        onInput={onInput}
        noPlaceholder={noPlaceholder}
        inputLanguage={inputLanguage}
        noBorders={noBorders}
        filterOption={(option, query) => filterOptions(option, query)}
        placeholder={placeholder}
        formatOptionLabel={(option, meta) => prepareOptionLabel(option, meta)}
        options={selectOptions}
        height={height}
        noLeftPadding={noLeftPadding}
        components={{
          GroupHeading: () => null,
          Group: AreaGroup,
        }}
        {...props}
      />
      {isSpecialMerchant && !hideRefreshIcon &&
       <IconButton disabled={isValidatingField || isRefreshButtonDisabled} disableRipple onClick={handleValidateField} sx={{ padding: '0 0 0 10px' }}>
         <Autorenew />
       </IconButton>}
    </Box>
  );
}

function AreaGroup({ children, ...props }) {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef();

  useEffect(() => {
    if (props.options.length > 0) setIsOpen(true);
    if (
      props.selectProps.inputValue === "" &&
      props?.selectProps?.value?.group !== props.label
    ) {
      setIsOpen(false);
    }
  }, [props.options.length, props.selectProps.inputValue]);

  const ARABIC_REGEX = /[\u0600-\u06FF]/;
  const flexDirection = ARABIC_REGEX.test(props.data.label)
    ? "row-reverse"
    : "row";

  return (
    <div ref={ref}>
      <Collapsible.Root open={isOpen} onOpenChange={setIsOpen}>
        <Styled.CollapsibleWrapper
          // onClick={scrollWrapperIntoView}
          sx={{ flexDirection: flexDirection }}
        >
          <Box paddingBottom="8px">
            {props.selectProps.inputValue.length > 2 ? (
              <Highlighter
                searchWords={[props.selectProps.inputValue]}
                textToHighlight={props.data.label || ""}
                highlightClassName="font-bold bg-transparent"
                highlightStyle={{ background: "transparent", fontWeight: 700 }}
              />
            ) : (
              <Typography
                variant="regular"
                fontWeight={isOpen ? 700 : 400}
              >
                {props.data.label}
              </Typography>
            )}
          </Box>

          <div>{isOpen ? <LargeArrowUpIcon /> : <LargeArrowDownIcon />}</div>
        </Styled.CollapsibleWrapper>
        <Collapsible.CollapsibleContent>
          <components.Group {...props}>{children}</components.Group>
        </Collapsible.CollapsibleContent>
      </Collapsible.Root>
    </div>
  );
}
