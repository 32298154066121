import { Box, styled, Tabs as MuiTabs, Tab as MuiTab, IconButton } from "@mui/material";
import CircleIcon from '@mui/icons-material/Circle';

const TabContainer = styled(Box)({
  height: 'calc(100% - 213px)',
  overflowY: 'auto',
  display: "flex",
  flexDirection: "column",
  margin: '0px 0px 8px 0px'
});

const Dot = styled(CircleIcon, {
  shouldForwardProp: (prop) => prop !== "$color" && prop !== "$fontSize" && prop !== "$marginTop"
})(({ $color, $marginTop="0px", $fontSize="8px" }) => ({
  color: $color,
  fontSize: $fontSize,
  marginTop: $marginTop
}));

const LogDateContainer = styled(Box)(({ theme: {palette, customShadows} })=>({
  width:'100%',
  display:'flex',
  flexDirection: 'column',
  padding: '12px 16px 16px 16px',
  alignItems: 'flex-start',
  borderRadius: '8px',
  gap: '12px',
  border: `1px solid ${palette.lightGreyOutline}`,
  background: palette.white,
  boxShadow: customShadows
}));

const Tabs = styled(MuiTabs)(({ theme: { palette } }) =>({
  paddingLeft: '24px',
  overflow: 'auto',
  backgroundColor: palette.white,
  ".css-145v6pe-MuiButtonBase-root-MuiTabScrollButton-root.Mui-disabled": {
    display: 'none'
  },
  ".css-ps0m23-MuiTabs-indicator": {
    height: '2px'
  }
}))

const Tab = styled(MuiTab, {
  shouldForwardProp: (prop) => prop !== "opacity",
})(({opacity}) => ({
  gap: 4,
  alignItems: 'center',
  opacity: opacity,
  padding: 0,
  marginRight: 20,
  textTransform: 'none',
  minHeight: 52,
  minWidth: 60
}))

const OrderBillingStatusContainer = styled(Box)(({ theme })=>({
  width: "18px",
  height: "18px",
  padding: "3px",
  backgroundColor: theme.palette.limeGreen,
  "&:hover": {
    backgroundColor: theme.palette.black,
    ">svg": {
      ">path": {
        stroke : theme.palette.black
      }
    }
  }
}));

const CloseIconButton = styled(IconButton)(({ theme }) => ({
  "&.MuiIconButton-root": {
    '&:hover': {
      backgroundColor: 'transparent'
    },
    ">svg": {
      ">path": {
        stroke: `${theme.palette.gray}`,
      },
    },
    ">svg:hover": {
      ">path": {
        stroke: `${theme.palette.black}`
      }
    },
  }
}));

const Styled = { TabContainer, Dot, Tab, Tabs, LogDateContainer, OrderBillingStatusContainer, CloseIconButton }

export default Styled
