/* eslint-disable max-len */
import React from "react"

const CourierIcon = ({ width="20", height="20", color="#7F7D80" }) => (
  <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ flexShrink: 0 }}>
    <path d="M12 7H15.5368C16.1901 7 16.8166 7.22827 17.2785 7.6346C17.7405 8.04093 18 8.59203 18 9.16667V13.5H12" stroke={color} strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M2 5.1875V13.5H12V5.1875C12 4.87256 11.8683 4.57051 11.6339 4.34781C11.3995 4.12511 11.0815 4 10.75 4H3.25C2.91848 4 2.60054 4.12511 2.36612 4.34781C2.1317 4.57051 2 4.87256 2 5.1875Z" stroke={color} strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M13.5 16C12.837 16 12.2011 15.7366 11.7322 15.2678C11.2634 14.7989 11 14.163 11 13.5C11 12.837 11.2634 12.2011 11.7322 11.7322C12.2011 11.2634 12.837 11 13.5 11C14.163 11 14.7989 11.2634 15.2678 11.7322C15.7366 12.2011 16 12.837 16 13.5C16 14.163 15.7366 14.7989 15.2678 15.2678C14.7989 15.7366 14.163 16 13.5 16Z" fill="white" stroke={color} strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M5.5 16C4.83696 16 4.20107 15.7366 3.73223 15.2678C3.26339 14.7989 3 14.163 3 13.5C3 12.837 3.26339 12.2011 3.73223 11.7322C4.20107 11.2634 4.83696 11 5.5 11C6.16304 11 6.79893 11.2634 7.26777 11.7322C7.73661 12.2011 8 12.837 8 13.5C8 14.163 7.73661 14.7989 7.26777 15.2678C6.79893 15.7366 6.16304 16 5.5 16Z" fill="white" stroke={color} strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
)

export default CourierIcon
