import React from "react";
import { Box, List, ListItemButton, Typography, useTheme } from "@mui/material";

import Styled from "../select-courier.styled";
import { courierSortingBy } from "../select-courier.constants";
import { useTranslation } from "react-i18next";
import DrawerWithTitle from "../../../common/components/drawer";

const SortingDrawer = ({ isDrawerOpen, setDrawerOpen , setOrderBy }) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const handleSortBy = (index) => {
    setDrawerOpen(false);
    setOrderBy(courierSortingBy[index].key)
  }

  const DrawerContent = (
    <Box padding="0 15px 0px 15px">
      <List disablePadding>
        {courierSortingBy.map(({key}, index) => (
          <Box key={key}>
            <Styled.SortingListItem disablePadding onClick={() => handleSortBy(index)}>
              <ListItemButton color={theme.palette.white}>
                <Typography variant="mediumText" paddingTop="8px" paddingBottom="8px">{t(`selectCourier.sortBy.options.${key}`)}</Typography>
              </ListItemButton>
            </Styled.SortingListItem>
          </Box>
        ))}
      </List>
    </Box>
  )

  return (
    <DrawerWithTitle
      isMobileView
      title={t('selectCourier.sortBy.title')}
      anchor="bottom"
      open={isDrawerOpen}
      closeDrawer={() => setDrawerOpen(false)}
      content={DrawerContent} theme={theme}
    />
  );
};

export default SortingDrawer;
