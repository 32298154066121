import React from "react";
import { Box, TableRow, Typography, TableBody } from "@mui/material";

import TableSkeleton from "./table-skeleton";

import Styled from "./table.styled";

import Checkbox from "../checkbox";

const TableBodyContent = ({
  isOrdersLoading,
  rows,
  selectedOrders,
  toggleOrder,
  resolvedOrders,
  setResolvedOrders,
  entity,
  t,
  theme,
  areaOptions,
  rowsPerPage,
  columnsCount,
  getCellsContent,
}) => {
  const renderCellContent = (row, { title, details }) => {
    return (
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        typography="regular"
      >
        {title}
        {details && (
          <Typography variant="boldSmall" paddingRight="2px">
            {details}
          </Typography>
        )}
      </Box>
    );
  };
  
  if (isOrdersLoading) {
    return (
      <TableSkeleton rowsPerPage={rowsPerPage} columnsCount={columnsCount} />
    );
  }

  if (rows.length === 0) return;

  return (
    <TableBody>
      {rows.map((row, index) => {
        const { id: orderId } = row;
        const isItemSelected = Boolean(
          selectedOrders?.find((order) => order.id === orderId)
        );

        return (
          <TableRow
            hover
            role="checkbox"
            aria-checked={isItemSelected}
            tabIndex={-1}
            key={orderId}
            selected={false}
          >
            <Styled.TableCell padding="checkbox" align="center">
              <Checkbox
                onChange={() => toggleOrder(row)}
                isItemSelected={isItemSelected}
                index={index}
                disableRipple
                labelId={`${entity}-table-checkbox-${index}`}
              />
            </Styled.TableCell>
            {getCellsContent(
              row,
              t,
              theme,
              resolvedOrders,
              setResolvedOrders,
              areaOptions
            ).map(({ id, ...content }) => (
              <Styled.TableCell key={id} align="center">
                {renderCellContent(row, content)}
              </Styled.TableCell>
            ))}
          </TableRow>
        );
      })}
    </TableBody>
  );
};

export default TableBodyContent;
