import React from "react";
import { CheckedIcon, SquareIcon } from "../../../assets/icons";
import Styled from "./checkbox.styled";

const Checkbox = ({
  isItemSelected,
  noPadding,
  onChange,
  disableRipple,
  marginTop = 0,
  labelId,
}) => {
  return (
    <Styled.Checkbox
      size="small"
      color="secondary"
      icon={<SquareIcon />}
      checkedIcon={<CheckedIcon />}
      onChange={onChange}
      checked={isItemSelected}
      disableFocusRipple={disableRipple}
      disableRipple={disableRipple}
      marginTop={marginTop}
      noPadding={noPadding}
      inputProps={{ "aria-labelledby": labelId }}
    />
  );
};

export default Checkbox;
