import React, { useState, useEffect } from "react";
import { Box, Typography, Skeleton } from "@mui/material";

import Styled from "./success-rate.styled";
import { capitalizeFirstLetterOfEachWord } from "../../../../analytics.utils";

const Sections = ({
  sections,
  selectedFilters,
  setSelectedFilters,
  isLoading,
}) => {
  const [selectedBox, setSelectedBox] = useState(null);

  const handleClick = (section) => {
    const selectedSection = section === selectedBox ? null : section;
    setSelectedBox(selectedSection);
    setSelectedFilters({
      ...selectedFilters,
      section: selectedSection,
    });
  };

  if (!sections.length) {
    sections = Array(5).fill(null);
  }

  useEffect(() => {
    if (!selectedFilters.section) setSelectedBox(null);
  }, [selectedFilters]);

  return (
    <Box
      sx={{
        position: "relative",
        marginLeft: "20px",
        marginRight: "30px",
        marginTop: "10px",
      }}
    >
      {sections.map((section, index) => {
        if (isLoading)
          return (
            <Skeleton
              key={section ? section : `skeleton_${index}`}
              width={"250px"}
              height={"60px"}
            />
          );
        return (
          <Styled.Sections
            key={section ? section : `skeleton_${index}`}
            isSelected={selectedBox && selectedBox === section}
            onClick={() => {
              if (section) handleClick(section);
            }}
          >
            <Typography
              variant={
                selectedBox && selectedBox === section
                  ? "boldSubtitles"
                  : "mediumText"
              }
            >
              {section
                ? capitalizeFirstLetterOfEachWord(section)
                : "-----"}
            </Typography>
          </Styled.Sections>
        );
      })}
    </Box>
  );
};

export default Sections;
