import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";

const ProgressBar = styled(Box)({
  flex: "1",
  marginRight: "10px",
  border: "2px solid transparent",
  borderRadius: "10px",
});

const Styled = {
  ProgressBar,
};

export default Styled;
