import { Box } from "@mui/material";
import { styled } from "@mui/system";

const Container = styled(Box)(() => ({
  display: "flex",
  width: "100%",
  height: "100%",
  flexDirection: 'column',
  gap: "12px",
}));

const CardsContainer = styled(Box)(() => ({
  display: "flex",
  width: "100%",
  alignItems: "center",
  alignContent: "center",
  gap: "12px",
  flexWrap: "wrap",
}));

const Card = styled(Box,{
  shouldForwardProp: (prop) => prop !== "$selected"
})(({ $selected,  theme: { customShadows, palette } }) => ({
  display: "flex",
  width: "48%",
  height: "80px",
  justifyContent: "center",
  alignItems: "center",
  flexShrink: 0,
  borderRadius: '8px',
  cursor: 'pointer',
  border: ($selected? `1px solid ${palette.black}` : `1px solid ${palette.lightGreyOutline}`),
  background: palette.white,
  boxShadow: customShadows.component,
}));

const Styled = {
  Container,
  CardsContainer,
  Card,
};

export default Styled;
