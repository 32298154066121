import React from "react";
import Styled from "../../analytics.styled";
import { useTheme } from "@mui/material";
import useViewport from "../../../../utils/use-viewport";
import { useTranslation } from "react-i18next";

import { DeliveryTruckIcon } from "../../../../assets/icons";
import {  renderTitle } from "../../analytics.utils";
import { SLA_TYPES } from "../../analytics.constants";

import TopCouriers from "./components/top-couriers";
import SuccessRate from "./components/success-rate";
import DeliverySLA from "./components/sla/delivery-sla";
import ReturnSLA from "./components/sla/return-sla";
import UnSuccessfulReasons from "./components/unsuccessful-reasons";
import FirstAttemptSLA from "./components/first-attempt-sla";

const DeliveryPerformance = ({
  topCouriers,
  isTopCouriersLoading,
  successRate,
  governorateSections,
  isGovernorateSectionsLoading,
  deliverySLAs,
  returnSLAs,
  selectedDeliverySLASection,
  selectedReturnSLASection,
  setDeliverySlaSection,
  setReturnSlaSection,
  isDeliverySLALoading,
  isReturnSLALoading,
  unsuccessfulReasons,
  isUnsuccessfulReasonsLoading,
  firstAttemptSLAs,
  isFirstAttemptSLALoading,
  selectedFilters,
  setSelectedFilters,
}) => {
  const { isMobileView } = useViewport();
  const theme = useTheme();
  const { t } = useTranslation();
  return (
    <Styled.SubContentWrapper $isMobileView={isMobileView}>
      { renderTitle({
        title: t("analytics.deliveryPerformance.title"),
        Icon: DeliveryTruckIcon,
        theme,
      })}

      <Styled.DeliveryPerformanceCard>
        <TopCouriers
          isMobileView={isMobileView}
          topCouriers={topCouriers}
          isLoading={isTopCouriersLoading}
          selectedFilters={selectedFilters}
          setSelectedFilters={setSelectedFilters}
          theme={theme}
        />
        <SuccessRate
          isMobileView={isMobileView}
          successRate={successRate}
          governorateSections={governorateSections}
          isGovernorateSectionsLoading={isGovernorateSectionsLoading}
          selectedFilters={selectedFilters}
          setSelectedFilters={setSelectedFilters}
          theme={theme}
          t={t}
        />
      </Styled.DeliveryPerformanceCard>
      <Styled.DeliveryPerformanceCard $type={"sla"}>
        <DeliverySLA
          slas={deliverySLAs}
          selectedSection={selectedDeliverySLASection}
          setSection={setDeliverySlaSection}
          type={SLA_TYPES.DELIVERY}
          isLoading={isDeliverySLALoading}
          theme={theme}
        />
        <ReturnSLA
          slas={returnSLAs}
          selectedSection={selectedReturnSLASection}
          setSection={setReturnSlaSection}
          type={SLA_TYPES.RETURN}
          isLoading={isReturnSLALoading}
          theme={theme}
        />
      </Styled.DeliveryPerformanceCard>
      <Styled.DeliveryPerformanceCard>
        <UnSuccessfulReasons
          isMobileView={isMobileView}
          theme={theme}
          data={unsuccessfulReasons}
          isLoading={isUnsuccessfulReasonsLoading}
        />
        <FirstAttemptSLA
          isMobileView={isMobileView}
          theme={theme}
          data={firstAttemptSLAs}
          isLoading={isFirstAttemptSLALoading}
        />
      </Styled.DeliveryPerformanceCard>
    </Styled.SubContentWrapper>
  );
};

export default DeliveryPerformance;
