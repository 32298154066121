import React from "react";
import SLACard from "../sla-card";

import Styled from "../../../../../analytics.styled";

const ReturnSLA = ({
  slas,
  selectedSection,
  setSection,
  isLoading,
  type,
  theme,
}) => {
  return (
    <Styled.Card $isMobileView={false}>
      <SLACard
        data={slas}
        selectedSection={selectedSection}
        setSection={setSection}
        isLoading={isLoading}
        type={type}
        theme={theme}
      />
    </Styled.Card>
  );
};

export default ReturnSLA;
