/* eslint-disable max-len */
import axios from "axios";
import toast from "react-hot-toast";

// eslint-disable-next-line no-undef
const BASE_URL = process.env.REACT_APP_BASE_URL_MERCHANT;

const getDefaultConfig = (isJsonContent) => {
  const authData = JSON.parse(localStorage.getItem("fincartLoginData"));
  const token = authData?.token

  return {
    logErrorMsg: true,
    headers: {
      ...(isJsonContent && { "Content-Type": "application/json" }),
      Authorization: token,
    },
  };
};

const put = ({
  config = {},
  url = "",
  data,
  errorMsg = null,
  successMsg = null,
  isJsonContent = true,
}) =>
  axios
    .put(`${BASE_URL}${url}`, data, {
      ...getDefaultConfig(isJsonContent),
      ...config,
    })
    .then((res) => {
      if (successMsg) {
        toast.success(successMsg);
      }
      return res;
    })
    .catch((res) => {
      if (res.response.status === 401) {
        // eslint-disable-next-line no-undef
        window.location.replace(process.env.REACT_APP_LANDING_URL);
        localStorage.removeItem("fincartLoginData");
      }
      if (errorMsg) {
        toast.error(errorMsg);
      }
    });

const post = ({
  config = {},
  url = "",
  data,
  errorMsg = null,
  successMsg = null,
  isJsonContent = true,
}) =>
  axios
    .post(`${BASE_URL}${url}`, data, {
      ...getDefaultConfig(isJsonContent),
      ...config,
    })
    .then((res) => {
      if (successMsg) {
        toast.success(successMsg);
      }
      return res;
    })
    .catch((res) => {
      if (res.response.status === 401) {
        // eslint-disable-next-line no-undef
        window.location.replace(process.env.REACT_APP_LANDING_URL);
        localStorage.removeItem("fincartLoginData");
      }
      if (errorMsg) {
        toast.error(errorMsg);
      }
      return res;
    });

const get = ({ config = {}, url = "", errorMsg, successMsg }) =>
  axios
    .get(`${BASE_URL}${url}`, { ...getDefaultConfig(true), ...config })
    .then((res) => {
      if (successMsg) {
        toast.success(successMsg);
      }
      return res;
    })
    .catch((res) => {
      if (res.response.status === 401) {
        // eslint-disable-next-line no-undef
        window.location.replace(process.env.REACT_APP_LANDING_URL);
        localStorage.removeItem("fincartLoginData");
      }
      if (errorMsg) {
        toast.error(errorMsg);
      }
    });

const apiClient = { post, put, get };
export const client = { apiClient };
