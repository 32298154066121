/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from "react";
import { Box, Typography, Stack} from "@mui/material";
import Styled from "../../../../analytics.styled";
import { useTranslation } from "react-i18next";

import { OTHER_COURIER_ID } from "../../../../analytics.constants";

import CourierProgressBar from "./courier-progress-bar";

const TopCouriers = ({
  isMobileView,
  topCouriers,
  isLoading,
  selectedFilters,
  setSelectedFilters,
  theme,
}) => {
  const { t } = useTranslation();
  const [selectedBar, setSelectedBar] = useState(null);

  let { couriers } = topCouriers;
  if (!couriers || !couriers.length) couriers = Array(4).fill({ percentage: 0 });

  const handleClick = (id) => {
    const selectedCourierId = id === selectedBar ? null : id;
    setSelectedBar(selectedCourierId);
    if (selectedCourierId) {
      if (selectedCourierId === OTHER_COURIER_ID) {
        const otherCourierIds = couriers.filter(
          (courier) => courier._id === selectedCourierId
        );
        setSelectedFilters({
          ...selectedFilters,
          courierIds: otherCourierIds[0]?.orderIds,
        });
      } else
        setSelectedFilters({
          ...selectedFilters,
          courierIds: [selectedCourierId],
        });
    } else setSelectedFilters({ ...selectedFilters, courierIds: null });
  };

  useEffect(() => {
    if (!selectedFilters.courierIds) setSelectedBar(null);
  }, [selectedFilters]);

  return (
    <Styled.Card $isMobileView={isMobileView}>
      <Box
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"space-between"}
        width={"95%"}
        height={"100%"}
      >
        <Typography
          variant="mediumText"
          color={theme.palette.text.light}
          sx={{ mt: 2, mb: 2, pl: 1 }}
        >
          {t("analytics.topCouriers.title")}
        </Typography>

        <Stack sx={{ width: "100%" }}>
          {couriers?.map((courier, i) => (
            <CourierProgressBar
              key={i}
              courier={courier}
              maxPercentage={Math.round(couriers[0].percentage.toFixed(0))}
              handleClick={handleClick}
              isSelected={selectedBar === courier._id}
              isLoading={isLoading}
              theme={theme}
              t={t}
            />
          ))}
        </Stack>
      </Box>
    </Styled.Card>
  );
};

export default TopCouriers;
