import React, { useState } from 'react'
import { Box, Typography, Stack } from '@mui/material'

import { LeftArrowIcon, BulkInfoIcon } from "../../../assets/icons"
import { COLUMNS, HEADERS, INFO_ROW, SUMMARY_LIST } from '../bulk-create-orders.constants'
import { DeleteOrderContent } from '../../../common/components/delete-order-content'
import { BlackTooltip } from '../../../common/components/black-tooltip'
import { Dialog } from '../../../common/components/dialog'
import Button from '../../../common/components/button'
import Styled from './upload-file-view.styled'
import { InfoIconWrapper } from '../bulk-create-order.styled'
import { saveAs } from 'file-saver';
import ExcelJS from 'exceljs';
import { applyRowStyles, applyStyleToErrorCell, mapDataToCsvFormat } from '../bulk-create-order.utils'

const UploadFileViewHeader = ({ t, dataSummary, data, options, onLeave, toast, updatedOrdersLength }) => {
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false)

  const deleteOrderDialogActions =  [
    {
      key: "close",
      content: (
        <Button
          color="secondary"
          onClick={() => setIsConfirmDialogOpen(false)}
          label={t('bulkCreateOrders.uploadView.confirmModal.noButton')}
          size={{ width: 156, height: 36 }}
        />
      ),
    },
    {
      key: "delete",
      content: (
        <Button
          onClick={onLeave}
          label={t('bulkCreateOrders.uploadView.confirmModal.leaveButton')}
          size={{ width: 156, height: 36 }}
        />
      ),
    },
  ];

  const ExcelExport = () => {
    const exportToExcel = () => {
      const orders = mapDataToCsvFormat(data, options)
      const cols = COLUMNS.map(({ key, sheetWidth, ...rest }) => ({
        header: HEADERS[key],
        width: sheetWidth,
        key,
        ...rest
      }))

      const adaptedCols = [{ header: 'Errors', key: 'errors', width: 15 }, ...cols]

      // Create a new workbook and add a worksheet
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('Orders');

      // Define the columns for the worksheet
      worksheet.columns = adaptedCols;

      // Add rows to the worksheet
      orders.forEach(order => {
        worksheet.addRow(order);
      });

      worksheet.insertRow(2, {errors: '', ...INFO_ROW})

      applyRowStyles({row: worksheet.getRow(1), bold: true });
      applyRowStyles({row: worksheet.getRow(2), bgColor: 'FFFFFFFF', fontColor: 'ff7f7d80', fontSize: 9, border: true});
    
      worksheet.eachRow(row => {
        applyStyleToErrorCell(row);
      });

      // Generate the Excel file and download it
      workbook.xlsx.writeBuffer().then(buffer => {
        const blob = new Blob([buffer], { type: 'application/octet-stream' });
        saveAs(blob, `Fincart failed orders.xlsx`);
      });
    
      if(dataSummary.created > 0) {
        toast(`${dataSummary.created}/${dataSummary.uploaded} ${t('bulkCreateOrders.uploadView.createdSuccessfullyMsg')}`);
      } else {
        toast('All orders are exported') //TODO: translate
      }
      onLeave();
    };

    return (
      <Box display="flex" gap="4px" alignItems="center">
        <Styled.ExportButton onClick={exportToExcel} disableRipple disabled={dataSummary?.failed === 0 || updatedOrdersLength === 0}>
          {t(`bulkCreateOrders.uploadView.summary.skipAndExportButton`)}
        </Styled.ExportButton>

        {dataSummary?.failed === 0 ? (
          <InfoIconWrapper background="disabledBackground" infoColor="disabledGrey" disabled>
            <BulkInfoIcon />
          </InfoIconWrapper>
        ):
          <Box>
            <BlackTooltip title={t('bulkCreateOrders.uploadView.summary.skipAndExportHint')} placement="top">
              <InfoIconWrapper background="lightGreyOutline">
                <BulkInfoIcon />
              </InfoIconWrapper>
            </BlackTooltip>
          </Box>
        }
      </Box>
    );
  }

  return (
    <Stack width="100%" gap="24px">
      <Box display="flex" gap="20px" alignItems="center">
        <Styled.BackButton disableRipple onClick={() => setIsConfirmDialogOpen(true)}>
          <LeftArrowIcon />
        </Styled.BackButton>

        <Typography variant="bold">
          {t('bulkCreateOrders.uploadView.previewButton')}
        </Typography>
      </Box>

      <Box display="flex" width="100%" justifyContent="space-between">
        <Styled.SummaryWrapper>
          {t(`bulkCreateOrders.uploadView.summary.title`)}
          {SUMMARY_LIST.map((id) =>
            <Box key={id}>{t(`bulkCreateOrders.uploadView.summary.types.${id}`)} : {dataSummary[id] || 0}</Box>
          )}
        </Styled.SummaryWrapper>

        <ExcelExport />
      </Box>

      <Dialog
        content={
          <DeleteOrderContent
            description={t('bulkCreateOrders.uploadView.confirmModal.title')}
            actionButtons={deleteOrderDialogActions}
          />
        }
        isOpen={isConfirmDialogOpen}
        setIsDialogOpen={setIsConfirmDialogOpen}
      />
    </Stack>
  )
}

export default UploadFileViewHeader