/* eslint-disable max-len */
import React from "react";

const OrdersIcon = ({ width="24", height="24", color="white" }) => (
  <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M17.4615 0H2.53846C1 0 0 1 0 2.53846V17.4615C0 19 1 19.9999 2.53846 19.9999H17.4615C19 19.9999 19.9999 19 19.9999 17.4615V2.53846C19.9999 1 19 0 17.4615 0ZM13.0769 1.05261V8.74489C13.0769 8.9489 12.9958 9.14456 12.8516 9.28882C12.7073 9.43308 12.5116 9.51412 12.3076 9.51412H7.69226C7.48825 9.51412 7.29259 9.43308 7.14834 9.28882C7.00408 9.14456 6.92303 8.9489 6.92303 8.74489V1.05261H13.0769ZM12.3076 15.4038C11.8934 15.4038 11.5576 15.7396 11.5576 16.1538C11.5576 16.568 11.8934 16.9038 12.3076 16.9038H16.1538C16.568 16.9038 16.9038 16.568 16.9038 16.1538C16.9038 15.7396 16.568 15.4038 16.1538 15.4038H12.3076Z" fill={color}/>
  </svg>
);


export default OrdersIcon;
