import { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { PHONE_REGEX } from "./create-order.constants";
import toEnglishNumber from "../../utils/toEnglishNumber";

const useCreateOrderForm = ({ t }) => {

  const defaultValues = useMemo(() => (
    {
      customerDetails: {
        name: '',
        phone: '',
        area: '',
        address: '',
      },
      optionalInfo : {
        shippingNotes: '',
        backupPhone: '',
        referenceNumber: ''
      },
      orderDetails: {
        type: { value: "delivery", label: t('createOrder.orderTypes.delivery') },
        pickupLocation: '',
        paymentType: '',
        collectionAmount: '',
        packageType: '',
        noOfItems: 1,
        description: '',
        openPackageAllowed: false
      },
    })
  , [])

  const { register, unregister, handleSubmit, formState, getValues, setValue, watch, reset } = useForm({ defaultValues });

  const { errors, isValid, isDirty, dirtyFields } = formState

  const handleSetValue = (name, value, config) => setValue(name, value, { shouldDirty: true, shouldValidate: Object.keys(errors)?.length > 0, ...config })

  const registerCommonOrderDetailsField = () => {
    //Registering the fields that are common on (Delivery, return, exchange) orders
    register('orderDetails.type')
    register('orderDetails.pickupLocation', { required: true, validate: (value) => Boolean(value?.toString()?.trim())});
    register('orderDetails.paymentType', {required: true});
    register('orderDetails.collectionAmount', { required: true, validate: (value) => Boolean(value?.toString()?.trim())}); //refundAmount on refund on exchange case
    register('orderDetails.noOfItems', {required: true, validate: (value) => { if(value <= 0)  return false}});
    register('orderDetails.description');
  }

  const registerDeliveryOrderDetailsFields = () => {
    //Registering the fields that are required on (Delivery) orders only
    register('optionalInfo.referenceNumber');
    register('optionalInfo.shippingNotes')
    register('optionalInfo.backupPhone', { validate: (value) => PHONE_REGEX.test(toEnglishNumber(value)) });

    register('orderDetails.packageType', {required: true});
    register('orderDetails.openPackageAllowed');
  }

  useEffect(() => {
    register('isOrderValidated')
    register('customerDetails.name', { required: true, validate: (value) => Boolean(value?.toString()?.trim()) });
    register('customerDetails.phone', {
      required: true,
      validate: (value) => PHONE_REGEX.test(toEnglishNumber(value))
    });
    register('customerDetails.area', {
      required: true,
    });
    register('customerDetails.address', {
      validate: (field) =>  field.split(" ").length > 3,
      required: true,
    });

    registerCommonOrderDetailsField();
    registerDeliveryOrderDetailsFields();
  }, [register]);

  const form = watch()

  return ({
    form,
    isValid,
    errors,
    isDirty,
    defaultValues,
    dirtyFields,
    getValues,
    reset,
    handleSubmit,
    setValue: handleSetValue,
    registerCommonOrderDetailsField,
    registerDeliveryOrderDetailsFields,
    register,
    unregister,
  })
}

export default useCreateOrderForm