/* eslint-disable max-len */
import React from "react"

const CardIcon = ({ color="#2A272B" }) => (
  <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.2384 4H3.76144C3.28563 4 2.8999 4.37702 2.8999 4.84211V11.1579C2.8999 11.623 3.28563 12 3.76144 12H13.2384C13.7142 12 14.0999 11.623 14.0999 11.1579V4.84211C14.0999 4.37702 13.7142 4 13.2384 4Z" stroke={color} strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M13.2384 4H3.76144C3.28563 4 2.8999 4.37702 2.8999 4.84211V11.1579C2.8999 11.623 3.28563 12 3.76144 12H13.2384C13.7142 12 14.0999 11.623 14.0999 11.1579V4.84211C14.0999 4.37702 13.7142 4 13.2384 4Z" stroke={color} strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M10.1001 9.6L11.7001 9.6" stroke={color} strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M2.8999 6.4L14.0999 6.4" stroke={color} strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
)

export default CardIcon
