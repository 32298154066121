import React, { useEffect } from "react";
import { List, ListItem, Box, useTheme} from "@mui/material";

import { serviceData } from "../../../components/select-courier/select-courier.constants";
import { getProgressLineValueAndColor } from "../../../utils/getProgressLineValueAndColor";
import { toDaysAndHoursStr } from "../../../utils/convertMinutedToString";
import RenderCourierLogo from "../courier-logo";
import RenderCourierRecommended from "../courier-recommended-text";
import Styled from "./select-courier-cards-detailed.styled";
import { useTranslation } from "react-i18next";
import { BlackTooltip } from "../black-tooltip";

export function SelectCourierCardsDetailed({rows, selectedCourierId, setSelectedCourierId }) {
  const theme = useTheme();
  const { t } = useTranslation()

  useEffect(() => {
    if(rows.length > 0) setSelectedCourierId(rows[0].id)
  }, []);

  const renderProgressBar = (color) => {
    return (
      <Box display={'flex'} flexDirection={'column'} justifyContent={'center'} marginX={'5px'}>
        <Styled.ProgressLine
          variant="determinate"
          value={getProgressLineValueAndColor(color)}
        />
      </Box>
    );
  };

  const renderService = (services) => (services || []).split(',').map((service) => {
    let serviceDetails = serviceData.find(data => data.key.toLowerCase() === service.toLowerCase())
    if (!serviceDetails) return

    const { icons: Icon, key } = serviceDetails

    return (
      <BlackTooltip key={key} title={t(`selectCourier.services.${key}`)}>
        <Styled.SVGIcon display="flex" justifyContent="center" alignItems="center" >
          <Icon/>
        </Styled.SVGIcon>
      </BlackTooltip>
    );
  })

  const renderCardTop = ({ logo, special_service, total_fees, is_recommended}) => {
    return (
      <Styled.CardContentTop>
        <Styled.CardTopLeft>
          {RenderCourierLogo({ logo: logo, height: 24 })}
          {is_recommended? RenderCourierRecommended({ theme, t }) : null}
        </Styled.CardTopLeft>
        <Styled.CardTopRight>
          <Styled.CardBoldText>
            {t('mutualizedEgpLabel')} {+total_fees?.toFixed(2)}
          </Styled.CardBoldText>
          {special_service? renderService(special_service): null}
        </Styled.CardTopRight>
      </Styled.CardContentTop>
    );
  }

  const renderCardBottom = ({ success_rate, estimated_shipping, estimated_return, color, return_color}) => {
    return (
      <Styled.CardContentBottom>
        <Styled.CardContentBottomContent>
          <Styled.CardContentBottomSubContent>
            <Styled.CardBoldText>
              {toDaysAndHoursStr(estimated_shipping)}
            </Styled.CardBoldText>
            {renderProgressBar(color)}
          </Styled.CardContentBottomSubContent>
          <Styled.CardText>
            {t('selectCourier.shippingTimeLabel')}
          </Styled.CardText>
        </Styled.CardContentBottomContent>
        <Styled.CardContentBottomContent>
          <Styled.CardBoldText>
            {success_rate * 100 + "%"}
          </Styled.CardBoldText>
          <Styled.CardText>
            {t('selectCourier.successRateLabel')}
          </Styled.CardText>
        </Styled.CardContentBottomContent>
        <Styled.CardContentBottomContent>
          <Styled.CardContentBottomSubContent>
            <Styled.CardBoldText>
              {toDaysAndHoursStr(estimated_return)}
            </Styled.CardBoldText>
            {renderProgressBar(return_color)}
          </Styled.CardContentBottomSubContent>
          <Styled.CardText>{t('selectCourier.returnTimeLabel')}</Styled.CardText>
        </Styled.CardContentBottomContent>
      </Styled.CardContentBottom>
    );
  }

  const renderCardContent = (row) => {
    const {
      logo,
      success_rate,
      estimated_shipping,
      special_service,
      estimated_return,
      total_fees,
      is_recommended,
      color,
      return_color,
    } = row;
    return (
      <Styled.Card variant="outline">
        {renderCardTop({ logo, special_service, total_fees, is_recommended})}
        {renderCardBottom({ success_rate, estimated_shipping, estimated_return, color, return_color})}
      </Styled.Card>
    );
  };

  return (
    <Box>
      {/* Content List */}
      <Styled.ListContainer>
        <List disablePadding>
          {rows.map((row) => {
            return (
              <ListItem
                key={row.id}
                onClick={() => setSelectedCourierId(row.id)}
                disablePadding>
                <Styled.ListItemButton
                  selected={selectedCourierId === row.id}
                  disableGutters
                  dense>
                  {renderCardContent(row)}
                </Styled.ListItemButton>
              </ListItem>
            );
          })}
        </List>
      </Styled.ListContainer>
    </Box>
  );
}
